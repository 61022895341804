import React from 'react';
// import { NavLink } from 'react-router-dom';
// import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './Footer.css';
import './ionicons.min.css';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <section className="related">
      {/* <svg id="clouds" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M-5 100 Q 0 20 5 100 Z
                         M0 100 Q 5 0 10 100
                         M5 100 Q 10 30 15 100
                         M10 100 Q 15 10 20 100
                         M15 100 Q 20 30 25 100
                         M20 100 Q 25 -10 30 100
                         M25 100 Q 30 10 35 100
                         M30 100 Q 35 30 40 100
                         M35 100 Q 40 10 45 100
                         M40 100 Q 45 50 50 100
                         M45 100 Q 50 20 55 100
                         M50 100 Q 55 40 60 100
                         M55 100 Q 60 60 65 100
                         M60 100 Q 65 50 70 100
                         M65 100 Q 70 20 75 100
                         M70 100 Q 75 45 80 100
                         M75 100 Q 80 30 85 100
                         M80 100 Q 85 20 90 100
                         M85 100 Q 90 50 95 100
                         M90 100 Q 95 25 100 100
                         M95 100 Q 100 15 105 100 Z"
        />
      </svg> */}
      <footer className="footer-10">
        <div className="container-footer padding-footer">
          <div className="row mb-5 pb-3 no-gutters">
            <div className="col-md-4 mb-md-0 mb-4 d-flex">
              <div className="con con-1 w-100 py-5">
                <div className="con-info w-100 text-center">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="ion-ios-call" />
                  </div>
                  <div className="text">
                    <span>(+34) 649 69 61 50</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-md-0 mb-4 d-flex">
              <div className="con con-2 w-100 py-5">
                <div className="con-info w-100 text-center">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="ion-ios-mail" />
                  </div>
                  <div className="text">
                    <span>contacto@duonion.com</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-md-0 mb-4 d-flex">
              <div className="con con-3 w-100 py-5">
                <div className="con-info w-100 text-center">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="ion-ios-pin" />
                  </div>
                  <div className="text">
                    <span>
                      Carr. de San Bartolomé, nº 48,
                      35500 Arrecife - Lanzarote, Las Palmas
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 mb-md-0 mb-6">
                  <h2 className="footer-heading">Enlaces</h2>
                  <ul className="list-unstyled">
                    <li><a href="/PaginaEntrada" className="d-block">Quienes Somos</a></li>
                    <li><a href="/infoTarifa" className="d-block">Tarifa</a></li>
                    <li><a href="/Contacto" className="d-block">Contacto</a></li>
                  </ul>
                </div>
                <div className="col-md-6 mb-md-0 mb-6">
                  <h2 className="footer-heading">Políticas</h2>
                  <ul className="list-unstyled">
                    <li><a href="/legal/CondicionesGenerales" className="d-block">Condiciones Generales</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 pt-4 border-top">
            <div className="col-md-6 col-lg-8 mb-md-0 mb-4">
              <p className="copyright mb-0">
                Copyright &copy;
                <script>document.write(new Date().getFullYear());</script>
                {' '}
                {t('paginaprincipal.footer.rights')}
                {' '}
                {t('paginaprincipal.footer.by')}
                {' '}
                <a href="https://duonion.com" target="_blank" rel="noreferrer">duonion.com</a>
              </p>
            </div>
            {/* <div className="col-md-6 col-lg-4 text-md-right">
              <ul className="ftco-footer-social p-0">
                <li className="ftco-animate"><a href="#"
                // data-toggle="tooltip" data-placement="top"
                // title="Twitter"><span className="ion-logo-twitter" /></a></li>
                <li className="ftco-animate"><a href="#"
                // data-toggle="tooltip" data-placement="top"
                //title="Facebook"><span className="ion-logo-facebook" /></a></li>
                <li className="ftco-animate"><a href="#"
                // data-toggle="tooltip" data-placement="top"
                //title="Instagram"><span className="ion-logo-instagram" /></a></li>
              </ul>
            </div> */}
          </div>
        </div>
      </footer>
    </section>

  );
}

export default Footer;
