import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import {
//   Card, Container, Form, Button, Row, Col,
// } from 'react-bootstrap';
import {
  Container, Card, CardContent,
  Grid, Button, FormGroup, TextField, FormLabel,
} from '@mui/material';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { addPictureShop, addShop } from '../../../services/mesonero-api/api';
import resizeFile from '../../../services/resizeImage/ResizeImage';
import HeaderWithRouter from '../MenuCuenta';
// import { AddLocalResponseFail, AddLocalResponseSuccess }
// from '../../../services/response-api/responseApi';

function AñadirLocal() {
  const datos = JSON.parse(localStorage.getItem('datosUsuario'));

  // const [showSuccess, setShowSuccess] = React.useState(false);
  // const [showFail, setShowFail] = React.useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <HeaderWithRouter />
        <hr />
        <Card>
          {/* <Card.Header bsPrefix="card-header-custom"
          // style={{ textAlign: 'center' }}><h2>Añadir los Datos del Local</h2></Card.Header> */}
          <CardContent>
            <h2 style={{ textAlign: 'center' }}>{t('App.Cuenta.AñadirLocal.title')}</h2>
          </CardContent>
          <CardContent>
            <Formik
              initialValues={{
                name: '',
                phoneNumber: '',
                address: '',
                country: 'España',
                province: '',
                postalCode: '',
                web: '',
                capacity: '',
                tables_number: '',
                file: null,
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required(t('App.Cuenta.AñadirLocal.form.yup.name')),
                address: Yup.string().required(t('App.Cuenta.AñadirLocal.form.yup.address')),
                capacity: Yup.string().matches(/^[0-9]+$/gi, 'El valor debe ser un número'),
                tables_number: Yup.string().matches(/^[0-9]+$/gi, 'El valor debe ser un número').required(t('App.Cuenta.AñadirLocal.form.yup.tablesNumber')),
                postalCode: Yup.string().matches(/^[0-9]\d{4}/, 'El valor debe ser un código postal'),
                phoneNumber: Yup.string().matches(/^(\+34|0034|34)?[6789]\d{8}$/, 'Debe ser un número de teléfono'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  const data = {
                    shop: {
                      name: values.name,
                      address: values.address,
                      country: values.country,
                      province: values.province,
                      postalCode: values.postalCode,
                      phoneNumber: values.phoneNumber,
                      website: values.web,
                      capacity: parseInt(values.capacity, 10),
                      tablesNumber: parseInt(values.tables_number, 10),
                      enterpriseId: parseInt(datos.enterprise.id, 10),
                    },
                  };
                  const consultaAPI = async () => {
                    const dataShop = await addShop(data);
                    if (dataShop.status === 200) {
                      toast.success('El local se ha añadido satisfactoriamente');
                      if (values.file !== null) {
                        const formData = new FormData();
                        formData.append('shopId', dataShop.data.shop.id);
                        formData.append('picture', values.file);
                        const dataImageShop = await addPictureShop(formData, dataShop.data.shop.id);
                        if (dataImageShop.status === 200) {
                          toast.success('La foto del local se ha añadido satisfactoriamente');
                        }
                      }
                      history.push('/App');
                    }
                  };
                  consultaAPI();
                  setSubmitting(false);
                }, 250);
              }}
            >
              {(props) => {
                const {
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  /* y otras más */
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item lg={6} xs={12}>
                        <FormGroup>
                          <TextField
                            label={t('App.Cuenta.AñadirLocal.form.name')}
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <FormGroup>
                          {/* <Form.Label>Web</Form.Label> */}
                          <TextField
                            label={t('App.Cuenta.AñadirLocal.form.web')}
                            name="web"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.web}
                            error={touched.web && Boolean(errors.web)}
                            helperText={touched.web && errors.web}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item lg={12} xs={12}>
                        <FormGroup>
                          {/* <Form.Label>Dirección</Form.Label> */}
                          <TextField
                            label={t('App.Cuenta.AñadirLocal.form.address')}
                            name="address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address}
                            error={touched.address && Boolean(errors.address)}
                            helperText={touched.address && errors.address}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item lg={4} xs={12}>
                        <FormGroup>
                          {/* <Form.Label>País</Form.Label> */}
                          <TextField
                            label={t('App.Cuenta.AñadirLocal.form.country')}
                            name="country"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.country}
                            error={touched.country && Boolean(errors.country)}
                            helperText={touched.country && errors.country}
                            disabled
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item lg={5} xs={12}>
                        <FormGroup>
                          {/* <Form.Label>Provincia</Form.Label> */}
                          <TextField
                            label={t('App.Cuenta.AñadirLocal.form.province')}
                            name="province"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.province}
                            error={touched.province && Boolean(errors.province)}
                            helperText={touched.province && errors.province}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item lg={3} xs={12}>
                        <FormGroup>
                          {/* <Form.Label>Código Postal</Form.Label> */}
                          <TextField
                            label={t('App.Cuenta.AñadirLocal.form.postalCode')}
                            name="postalCode"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.postalCode}
                            error={touched.postalCode && Boolean(errors.postalCode)}
                            helperText={touched.postalCode && errors.postalCode}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item lg={12} xs={12}>
                        <FormGroup>
                          {/* <Form.Label>Número de Teléfono</Form.Label> */}
                          <TextField
                            label={t('App.Cuenta.AñadirLocal.form.phoneNumber')}
                            name="phoneNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                            error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                            helperText={touched.phoneNumber && errors.phoneNumber}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item lg={3} xs={12}>
                        <FormGroup>
                          {/* <Form.Label>Aforo</Form.Label> */}
                          <TextField
                            label={t('App.Cuenta.AñadirLocal.form.capacity')}
                            name="capacity"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.capacity}
                            error={touched.capacity && Boolean(errors.capacity)}
                            helperText={touched.capacity && errors.capacity}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item lg={3} xs={12}>
                        <FormGroup>
                          {/* <Form.Label>Número de Mesas</Form.Label> */}
                          <TextField
                            label={t('App.Cuenta.AñadirLocal.form.tablesNumber')}
                            name="tables_number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tables_number}
                            error={touched.tables_number && Boolean(errors.tables_number)}
                            helperText={touched.tables_number && errors.tables_number}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <FormGroup className="position-relative mb-3">
                          <FormLabel>{t('App.Cuenta.AñadirLocal.form.pictureTitle')}</FormLabel>
                          <input
                            id="file"
                            type="file"
                            name="file"
                            label={t('App.Cuenta.AñadirLocal.form.pictureInformation')}
                            onChange={async (event) => {
                              const image = await resizeFile(event.target.files[0]);
                              setFieldValue('file', image);
                            }}
                            className="form-control"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item lg={3} xs={3}>
                        <div> </div>
                      </Grid>
                      <Grid item lg={6} xs={6}>
                        <FormGroup>
                          <Button variant="contained" type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Loading' : t('App.Cuenta.AñadirLocal.form.buttonForm')}
                          </Button>
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </Container>
      <br />
      <br />
    </>
  );
}
export default AñadirLocal;
