import React, { /* useCallback, */ useEffect } from 'react';
// import {
//   Container, Button, Table, /* Image, */
// } from 'react-bootstrap';
import {
  Container, Card, CardHeader, CardMedia, CardContent, CardActions,
  Grid, Button, Collapse, Typography, FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
// import i18n from 'i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
// import { /* DeleteMenutButton, EditMenu, */ SectionMenu } from '../Carta/Modal/Modals';
import {
  getMenus, getImageMenu, getProducts, getImageProduct, updatePositionMenu,
  updatePositionVisibleMenu,
} from '../../../../services/mesonero-api/api';

import celery from '../../../../Icons/allergen-celery.svg';
import crustaceans from '../../../../Icons/allergen-crustaceans.svg';
import eggs from '../../../../Icons/allergen-eggs.svg';
import fish from '../../../../Icons/allergen-fish.svg';
import cereals from '../../../../Icons/allergen-gluten.svg';
import lupin from '../../../../Icons/allergen-lupin.svg';
import milk from '../../../../Icons/allergen-milk.svg';
import molluscs from '../../../../Icons/allergen-mollusks.svg';
import mustard from '../../../../Icons/allergen-mustard.svg';
import nuts from '../../../../Icons/allergen-nuts.svg';
import peanuts from '../../../../Icons/allergen-peanuts.svg';
import sesame from '../../../../Icons/allergen-sesame-seeds.svg';
import soya from '../../../../Icons/allergen-soy.svg';
import sulphites from '../../../../Icons/allergen-sulphur-dioxide.svg';

import traceCelery from '../../../../Icons/allergen-traces-celery.svg';
import traceCrustaceans from '../../../../Icons/allergen-traces-crustaceans.svg';
import traceEggs from '../../../../Icons/allergen-traces-eggs.svg';
import traceFish from '../../../../Icons/allergen-traces-fish.svg';
import traceCereals from '../../../../Icons/allergen-traces-gluten.svg';
import traceLupin from '../../../../Icons/allergen-traces-lupin.svg';
import traceMilk from '../../../../Icons/allergen-traces-milk.svg';
import traceMolluscs from '../../../../Icons/allergen-traces-mollusks.svg';
import traceMustard from '../../../../Icons/allergen-traces-mustard.svg';
import traceNuts from '../../../../Icons/allergen-traces-nuts.svg';
import tracePeanuts from '../../../../Icons/allergen-traces-peanuts.svg';
import traceSesame from '../../../../Icons/allergen-traces-sesame-seeds.svg';
import traceSoya from '../../../../Icons/allergen-traces-soy.svg';
import traceSulphites from '../../../../Icons/allergen-traces-sulphur-dioxide.svg';

import es from '../../../../Banderas/es.svg';
import en from '../../../../Banderas/en.svg';
import fr from '../../../../Banderas/fr.svg';
import it from '../../../../Banderas/it.svg';
import de from '../../../../Banderas/de.svg';
import pt from '../../../../Banderas/pt.svg';

import {
  AddLanguageMenuModal, DeleteMenutButton, EditMenuMobile, EditMenuPC,
} from '../Carta/Modal/Modals';
import useWindowDimensions from '../../../../services/sizeScreen/windowsDimension';

SwiperCore.use([Navigation, Pagination]);

const ExpandMore = styled((props) => {
  const {
    /* expand, */ children, onClick, ariaExpanded, ariaLabel, className,
  } = props;
  return (
    <IconButton
      // expand={expand.toString()}
      // eslint-disable-next-line react/no-children-prop
      children={children}
      onClick={onClick}
      aria-expanded={ariaExpanded}
      aria-label={ariaLabel}
      className={className}
    />
  );
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function ListaMenus() {
  // const [modalShowMenu, setModalShowMenu] = React.useState(false);
  // const [ShowMenu, setShowMenu] = React.useState(false);
  const [deleteMenuShow, setDeleteMenuShow] = React.useState(false);
  const [editMenuShowPC, setEditMenuShowPC] = React.useState(false);
  const [editMenuShowMobile, setEditMenuShowMobile] = React.useState(false);
  const [showChangeLanguage, setShowChangeLanguage] = React.useState(false);

  const [selectMenu, setMenu] = React.useState();

  const [expandState, setExpandState] = React.useState([]);

  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  const handleExpandClick = (index) => {
    // expandState.forEach()
    const aux = [...expandState];
    const targetState = aux[index];
    aux[index] = !targetState;
    setExpandState(aux);
  };

  const shopIdActual = localStorage.getItem('shop_id');
  const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'));

  const [respuestaAPIMenus, setRespuestaAPIMenus] = React.useState([]);
  const [respuestaAPIProducts, setRespuestaAPIProducts] = React.useState([]);

  const [imagesMenu, setImagesMenu] = React.useState([]);
  const [imagesProduct, setImagesProduct] = React.useState([]);

  // const [listMenusChangeLanguage, setListMenusChangeLanguage] = React.useState([]);

  const [allergensMenu, setAllergensMenu] = React.useState([]);

  const [language, setLanguage] = React.useState('es');

  let contador = 0;

  async function fetchImageMenu(idMenu) {
    let image;
    try {
      image = await getImageMenu(datosUsuario.enterprise.id, idMenu);
    } catch (e) {
      image = null;
    }
    return image;
  }

  async function fetchImageProduct(idProduct) {
    let image;
    try {
      image = await getImageProduct(datosUsuario.enterprise.id, idProduct);
    } catch (e) {
      image = null;
    }
    return image;
  }

  function sortMenus(menus) {
    menus.sort((a, b) => a.listPosition - b.listPosition);
    return menus;
  }

  function sortProducts(products) {
    products.sort((a, b) => a.listPosition - b.listPosition);
    return products;
  }

  // async function fetchDataChangeLanguage(idiom) {
  //   const menusChangeLanguage = {};
  //   const menus = await getMenus(datosUsuario.enterprise.id, shopIdActual, idiom);

  //   for (let i = 0; i < menus.length; i += 1) {
  //     menusChangeLanguage[menus[i].id] = null;
  //   }

  //   for (let i = 0; i < respuestaAPIMenus.length; i += 1) {
  //     if (!(respuestaAPIMenus[i].id in menusChangeLanguage)) {
  //       menus.push(respuestaAPIMenus[i]);
  //     }
  //   }

  //   const menusSort = sortMenus(menus);

  //   setListMenusChangeLanguage(menusSort);
  // }

  async function fetchData() {
    const menus = sortMenus(
      await getMenus(datosUsuario.enterprise.id, shopIdActual),
    );
    const products = sortProducts(
      await getProducts(datosUsuario.enterprise.id, shopIdActual),
    );
    setRespuestaAPIMenus(menus);
    setRespuestaAPIProducts(products);

    const aux = [];
    const listImageMenu = [];
    const listImageProduct = [];
    const listAllergensMenu = [];

    menus.forEach((menu) => {
      if (menu.pictures.length !== 0) {
        const imagen = fetchImageMenu(menu.id);
        listImageMenu.push(imagen);
      } else {
        listImageMenu.push(null);
        // }
        aux.push(false);
      }
    });
    setImagesMenu(await Promise.all(listImageMenu));
    setExpandState(aux);

    menus.forEach((menu) => {
      menu.products.forEach((product) => {
        if (product.pictures.length !== 0) {
          const imagen = fetchImageProduct(product.id);
          listImageProduct.push(imagen);
        } else {
          listImageProduct.push(null);
        }
      });
    });
    setImagesProduct(await Promise.all(listImageProduct));

    menus.forEach((menu) => {
      const auxListAllergensMenu = new Set();
      menu.products.forEach((product) => {
        product.allergens.forEach((allergen) => {
          auxListAllergensMenu.add(allergen.name);
        });
      });
      listAllergensMenu.push(auxListAllergensMenu);
    });
    setAllergensMenu(listAllergensMenu);
  }

  useEffect(() => {
    fetchData();
  }, []);

  function sumContador() {
    contador += 1;
  }

  async function leftMenu(targetMenu) {
    let menu1;
    let menu2;
    for (let i = 0; i < respuestaAPIMenus.length; i += 1) {
      if (respuestaAPIMenus[i].id === targetMenu.id) {
        menu1 = respuestaAPIMenus[i - 1];
        menu2 = respuestaAPIMenus[i];
        break;
      }
    }
    const listPositionProduct1 = menu1.listPosition;
    menu1.listPosition = menu2.listPosition;
    menu2.listPosition = listPositionProduct1;

    await updatePositionMenu(targetMenu.id, 2);
    fetchData();
  }

  async function rightMenu(targetMenu) {
    let menu1;
    let menu2;
    for (let i = 0; i < respuestaAPIMenus.length; i += 1) {
      if (respuestaAPIMenus[i].id === targetMenu.id) {
        menu1 = respuestaAPIMenus[i + 1];
        menu2 = respuestaAPIMenus[i];
        break;
      }
    }
    const listPositionProduct1 = menu1.listPosition;
    menu1.listPosition = menu2.listPosition;
    menu2.listPosition = listPositionProduct1;

    await updatePositionMenu(targetMenu.id, 1);
    fetchData();
  }

  async function visibleMenu(targetMenu) {
    await updatePositionVisibleMenu(targetMenu.id);
    fetchData();
  }

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <>
      <Container style={{ maxWidth: '1200px' }}>
        <Grid container sx={{ marginTop: '15px', marginBottom: '15px', alignItems: 'center' }}>
          <Grid item lg={1}>
            <Link to="/App/InfoLocal/Informacion/PaginaInicialLocal">
              <Button>
                <ArrowBackIcon />
              </Button>
            </Link>
          </Grid>
          <Grid item lg={3}>
            <Typography variant="h6">
              {t('App.InfoLocal.Informacion.Menus.route')}
            </Typography>
          </Grid>
        </Grid>
        <Container style={{ maxWidth: '1000px' }}>
          <Grid container>
            <Grid item lg={3} xs={6}>
              <Link to="/App/InfoLocal/Informacion/AñadirMenu">
                <Button variant="contained" style={{ width: '100%' }}>
                  <em>{t('App.InfoLocal.Informacion.Menus.addButton')}</em>
                </Button>
              </Link>
            </Grid>
            <Grid item lg={3} xs={6} style={{ textAlign: 'end' }}>
              <FormControl>
                <InputLabel id="idiomas" style={{ marginLeft: '20px' }}>{t('App.InfoLocal.Informacion.Menus.language')}</InputLabel>
                <Select
                  labelId="idiomas"
                  id="idiomas"
                  value={language}
                  label="idioma"
                  onChange={handleChange}
                  style={{ marginLeft: '20px' }}
                >
                  <MenuItem value="es">
                    <img
                      src={es}
                      style={{ height: 24, width: 24 }}
                      alt="flagEs"
                    />
                  </MenuItem>
                  <MenuItem value="en">
                    <img
                      src={en}
                      style={{ height: 24, width: 24 }}
                      alt="flagEn"
                    />
                  </MenuItem>
                  <MenuItem value="fr">
                    <img
                      src={fr}
                      style={{ height: 24, width: 24 }}
                      alt="flagFr"
                    />
                  </MenuItem>
                  <MenuItem value="it">
                    <img
                      src={it}
                      style={{ height: 24, width: 24 }}
                      alt="flagIt"
                    />
                  </MenuItem>
                  <MenuItem value="de">
                    <img
                      src={de}
                      style={{ height: 24, width: 24 }}
                      alt="flagDe"
                    />
                  </MenuItem>
                  <MenuItem value="pt">
                    <img
                      src={pt}
                      style={{ height: 24, width: 24 }}
                      alt="flagPt"
                    />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {
            // eslint-disable-next-line no-nested-ternary
            (respuestaAPIMenus.length === 0
              || respuestaAPIProducts.length === 0) ? <div />
              : (
                width > 720
                  ? (
                    <Swiper
                      slidesPerView={2}
                      spaceBetween={30}
                      navigation
                      modules={Navigation}
                    >
                      <div>
                        <Grid container spacing={3}>
                          {respuestaAPIMenus.map((menu, a) => (
                            <SwiperSlide
                              // eslint-disable-next-line react/no-array-index-key
                              key={a}
                            >
                              <div>
                                {(menu.name[language] === null)
                                  && (
                                    <div className="noLanguageMenu">
                                      <Button
                                        variant="contained"
                                        data-rowid={a}
                                        onClick={(e) => {
                                          setShowChangeLanguage(true);
                                          setMenu(respuestaAPIMenus[e.currentTarget.getAttribute('data-rowid')]);
                                        }}
                                      >
                                        {t('App.InfoLocal.Informacion.Menus.addButtonLanguage')}
                                      </Button>
                                    </div>
                                  )}
                                <Grid item lg={6} xs={6} sx={menu.visible ? { backgroundColor: 'white' } : { opacity: '0.2' }}>
                                  <Card style={{ marginTop: '20px', marginBottom: '20px' }}>
                                    <CardHeader
                                      title={menu.name[language] === null
                                        ? menu.name.es : menu.name[language]}
                                      action={
                                        (
                                          <div>
                                            <Button
                                              data-rowid={a}
                                              style={{ minWidth: '0px', padding: '4px' }}
                                              onClick={
                                                menu.id
                                                  === respuestaAPIMenus[0].id
                                                  ? null
                                                  : (e) => {
                                                    leftMenu(respuestaAPIMenus[e.currentTarget.getAttribute('data-rowid')]);
                                                  }
                                              }
                                            >
                                              {menu.id === respuestaAPIMenus[0].id ? <ArrowCircleLeftIcon sx={{ color: 'gray' }} /> : <ArrowCircleLeftIcon sx={{ color: 'black' }} />}
                                            </Button>
                                            <Button
                                              data-rowid={a}
                                              style={{ minWidth: '0px', padding: '4px' }}
                                              onClick={
                                                menu.id
                                                  // eslint-disable-next-line max-len
                                                  === respuestaAPIMenus[respuestaAPIMenus.length - 1].id
                                                  ? null
                                                  : (e) => {
                                                    rightMenu(respuestaAPIMenus[e.currentTarget.getAttribute('data-rowid')]);
                                                  }
                                              }
                                            >
                                              {
                                                menu.id
                                                  // eslint-disable-next-line max-len
                                                  === respuestaAPIMenus[respuestaAPIMenus.length - 1].id
                                                  ? <ArrowCircleRightIcon sx={{ color: 'gray' }} />
                                                  : <ArrowCircleRightIcon sx={{ color: 'black' }} />
                                              }
                                            </Button>
                                          </div>
                                        )
                                      }
                                    />
                                    {imagesMenu[a] !== null
                                      ? (
                                        <CardMedia
                                          component="img"
                                          height="250"
                                          image={imagesMenu[a]}
                                          alt="imageMenu"
                                          sx={{ objectFit: 'contain', padding: '8px', boxSizing: 'border-box' }}
                                        />
                                      )
                                      : (
                                        <div
                                          style={{
                                            height: '250px',
                                            width: '100%',
                                            backgroundColor: '#ECECEC',
                                          }}
                                        />
                                      )}
                                    <CardContent>
                                      <Grid container spacing={2}>
                                        <Grid item lg={10} xs={10}>
                                          {menu.description[language] === null
                                            ? menu.description.es
                                            : menu.description[language]}
                                        </Grid>
                                        <Grid item lg={2} xs={2} style={{ direction: 'rtl' }}>
                                          €
                                          {' '}
                                          {menu.price !== null && String(menu.price).replace('.', ',')}
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                          {allergensMenu[a] === undefined ? <div> </div>
                                            : Array.from(allergensMenu[a]).map((allergens) => {
                                              switch (allergens) {
                                                case 'celery':
                                                  return (
                                                    <img
                                                      src={celery}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'crustaceans':
                                                  return (
                                                    <img
                                                      src={crustaceans}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'eggs':
                                                  return (
                                                    <img
                                                      src={eggs}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'fish':
                                                  return (
                                                    <img
                                                      src={fish}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'cereals':
                                                  return (
                                                    <img
                                                      src={cereals}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'lupin':
                                                  return (
                                                    <img
                                                      src={lupin}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'milk':
                                                  return (
                                                    <img
                                                      src={milk}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'molluscs':
                                                  return (
                                                    <img
                                                      src={molluscs}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'mustard':
                                                  return (
                                                    <img
                                                      src={mustard}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'nuts':
                                                  return (
                                                    <img
                                                      src={nuts}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'peanuts':
                                                  return (
                                                    <img
                                                      src={peanuts}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'sesame':
                                                  return (
                                                    <img
                                                      src={sesame}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'soya':
                                                  return (
                                                    <img
                                                      src={soya}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'sulphites':
                                                  return (
                                                    <img
                                                      src={sulphites}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_celery':
                                                  return (
                                                    <img
                                                      src={traceCelery}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_crustaceans':
                                                  return (
                                                    <img
                                                      src={traceCrustaceans}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_eggs':
                                                  return (
                                                    <img
                                                      src={traceEggs}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_fish':
                                                  return (
                                                    <img
                                                      src={traceFish}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_cereals':
                                                  return (
                                                    <img
                                                      src={traceCereals}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_lupin':
                                                  return (
                                                    <img
                                                      src={traceLupin}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_milk':
                                                  return (
                                                    <img
                                                      src={traceMilk}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_molluscs':
                                                  return (
                                                    <img
                                                      src={traceMolluscs}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_mustard':
                                                  return (
                                                    <img
                                                      src={traceMustard}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_nuts':
                                                  return (
                                                    <img
                                                      src={traceNuts}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_peanuts':
                                                  return (
                                                    <img
                                                      src={tracePeanuts}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_sesame':
                                                  return (
                                                    <img
                                                      src={traceSesame}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_soya':
                                                  return (
                                                    <img
                                                      src={traceSoya}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_sulphites':
                                                  return (
                                                    <img
                                                      src={traceSulphites}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                default:
                                                  return (
                                                    <img
                                                      src={celery}
                                                      style={{ height: 32, width: 32 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                              }
                                            })}
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                    <CardActions disableSpacing>
                                      <Button
                                        data-rowid={a}
                                        onClick={(e) => {
                                          visibleMenu(respuestaAPIMenus[e.currentTarget.getAttribute('data-rowid')]);
                                        }}
                                      >
                                        {menu.visible ? <VisibilityOffIcon sx={{ color: 'black' }} /> : <VisibilityIcon sx={{ color: 'black' }} />}
                                      </Button>

                                      <Button
                                        variant="outline-secondary"
                                        data-rowid={a}
                                        onClick={(e) => {
                                          setEditMenuShowPC(true);
                                          setMenu(respuestaAPIMenus[e.currentTarget.getAttribute('data-rowid')]);
                                        }}
                                      >
                                        <EditIcon />
                                      </Button>

                                      <Button
                                        variant="outline-secondary"
                                        data-rowid={a}
                                        onClick={(e) => {
                                          setDeleteMenuShow(true);
                                          setMenu(respuestaAPIMenus[e.currentTarget.getAttribute('data-rowid')]);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </Button>

                                      <ExpandMore
                                        expand={expandState[a]}
                                        onClick={() => handleExpandClick(a)}
                                        ariaExpanded={expandState[a]}
                                        ariaLabel="show more"
                                      >
                                        <ExpandMoreIcon />
                                      </ExpandMore>
                                    </CardActions>
                                    <Collapse in={expandState[a]} timeout="auto" unmountOnExit>
                                      {menu.products.map((product, i) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <CardContent key={i}>
                                          <Grid
                                            container
                                            // eslint-disable-next-line react/no-array-index-key
                                            style={{
                                              border: '1px solid #EEEEEE',
                                              borderRadius: '10px',
                                              height: '134.5px',
                                            }}
                                          >
                                            <Grid item lg={3} xs={3}>
                                              {imagesProduct[contador] !== null
                                                ? (
                                                  <img
                                                    src={imagesProduct[contador]}
                                                    style={{
                                                      width: '100%',
                                                      height: '100%',
                                                      objectFit: 'cover',
                                                      borderTopLeftRadius: '10px',
                                                      borderBottomLeftRadius: '10px',
                                                    }}
                                                    alt="imageProduct"
                                                  />
                                                )
                                                : (
                                                  <div
                                                    style={{
                                                      height: '100%',
                                                      width: '100%',
                                                      backgroundColor: '#ECECEC',
                                                      objectFit: 'cover',
                                                      borderTopLeftRadius: '10px',
                                                      borderBottomLeftRadius: '10px',
                                                    }}
                                                  />
                                                )}
                                            </Grid>
                                            <Grid item lg={9} xs={9} style={{ paddingLeft: '20px' }}>
                                              <Grid container>
                                                <Grid item lg={12} xs={12} style={{ marginTop: '25px' }}>
                                                  <Typography variant="h6" noWrap>
                                                    {product.name[language] === null
                                                      ? product.name.es
                                                      : product.name[language]}
                                                  </Typography>
                                                </Grid>
                                                <Grid item lg={12} xs={12}>
                                                  <Typography
                                                    variant="body1"
                                                    sx={{
                                                      width: '100%',
                                                      display: '-webkit-box',
                                                      WebkitLineClamp: '2',
                                                      WebkitBoxOrient: 'vertical',
                                                      overflow: 'hidden',
                                                    }}
                                                  >
                                                    {product.description[language] === null
                                                      ? product.description.es
                                                      : product.description[language]}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          {sumContador()}
                                        </CardContent>
                                      ))}
                                    </Collapse>
                                  </Card>
                                </Grid>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Grid>
                      </div>
                    </Swiper>
                  )
                  : (
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={30}
                      navigation
                      modules={Navigation}
                    >
                      <div>
                        <Grid container spacing={3}>
                          {respuestaAPIMenus.map((menu, a) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <SwiperSlide key={a}>
                              <div>
                                {(menu.name[language] === null)
                                  && (
                                    <div className="noLanguageMenu">
                                      <Button
                                        variant="contained"
                                        data-rowid={a}
                                        onClick={(e) => {
                                          setShowChangeLanguage(true);
                                          setMenu(respuestaAPIMenus[e.currentTarget.getAttribute('data-rowid')]);
                                        }}
                                      >
                                        {t('App.InfoLocal.Informacion.Menus.addButtonLanguage')}
                                      </Button>
                                    </div>
                                  )}
                                <Grid item lg={6} xs={6}>
                                  <Card style={{ marginTop: '20px', marginBottom: '20px' }}>
                                    <CardHeader
                                      title={menu.name[language] === null
                                        ? menu.name.es : menu.name[language]}
                                      action={
                                        (
                                          <div>
                                            <Button
                                              data-rowid={a}
                                              style={{ minWidth: '0px', padding: '4px' }}
                                              onClick={
                                                menu.id
                                                  === respuestaAPIMenus[0].id
                                                  ? null
                                                  : (e) => {
                                                    leftMenu(respuestaAPIMenus[e.currentTarget.getAttribute('data-rowid')]);
                                                  }
                                              }
                                            >
                                              {menu.id === respuestaAPIMenus[0].id ? <ArrowCircleLeftIcon sx={{ color: 'gray' }} /> : <ArrowCircleLeftIcon sx={{ color: 'black' }} />}
                                            </Button>
                                            <Button
                                              data-rowid={a}
                                              style={{ minWidth: '0px', padding: '4px' }}
                                              onClick={
                                                menu.id
                                                  // eslint-disable-next-line max-len
                                                  === respuestaAPIMenus[respuestaAPIMenus.length - 1].id
                                                  ? null
                                                  : (e) => {
                                                    rightMenu(respuestaAPIMenus[e.currentTarget.getAttribute('data-rowid')]);
                                                  }
                                              }
                                            >
                                              {
                                                menu.id
                                                  // eslint-disable-next-line max-len
                                                  === respuestaAPIMenus[respuestaAPIMenus.length - 1].id
                                                  ? <ArrowCircleRightIcon sx={{ color: 'gray' }} />
                                                  : <ArrowCircleRightIcon sx={{ color: 'black' }} />
                                              }
                                            </Button>
                                          </div>
                                        )
                                      }
                                    />
                                    {imagesMenu[a] !== null
                                      ? (
                                        <CardMedia
                                          component="img"
                                          height="194"
                                          image={imagesMenu[a]}
                                          alt="imageMenu"
                                          sx={{ objectFit: 'contain', padding: '8px', boxSizing: 'border-box' }}
                                        />
                                      )
                                      : (
                                        <div
                                          style={{
                                            height: '194px',
                                            width: '100%',
                                            backgroundColor: '#ECECEC',
                                          }}
                                        />
                                      )}
                                    <CardContent>
                                      <Grid container spacing={2}>
                                        <Grid item lg={9} xs={9}>
                                          <Typography
                                            sx={{
                                              width: '100%',
                                              display: '-webkit-box',
                                              WebkitLineClamp: '4',
                                              WebkitBoxOrient: 'vertical',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            {menu.description[language] === null
                                              ? menu.description.es
                                              : menu.description[language]}
                                          </Typography>
                                        </Grid>
                                        <Grid item lg={3} xs={3} style={{ direction: 'rtl' }}>
                                          €
                                          {' '}
                                          {menu.price !== null && String(menu.price).replace('.', ',')}
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                          {allergensMenu[a] === undefined ? <div> </div>
                                            : Array.from(allergensMenu[a]).map((allergens) => {
                                              switch (allergens) {
                                                case 'celery':
                                                  return (
                                                    <img
                                                      src={celery}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'crustaceans':
                                                  return (
                                                    <img
                                                      src={crustaceans}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'eggs':
                                                  return (
                                                    <img
                                                      src={eggs}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'fish':
                                                  return (
                                                    <img
                                                      src={fish}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'cereals':
                                                  return (
                                                    <img
                                                      src={cereals}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'lupin':
                                                  return (
                                                    <img
                                                      src={lupin}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'milk':
                                                  return (
                                                    <img
                                                      src={milk}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'molluscs':
                                                  return (
                                                    <img
                                                      src={molluscs}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'mustard':
                                                  return (
                                                    <img
                                                      src={mustard}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'nuts':
                                                  return (
                                                    <img
                                                      src={nuts}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'peanuts':
                                                  return (
                                                    <img
                                                      src={peanuts}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'sesame':
                                                  return (
                                                    <img
                                                      src={sesame}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'soya':
                                                  return (
                                                    <img
                                                      src={soya}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 'sulphites':
                                                  return (
                                                    <img
                                                      src={sulphites}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_celery':
                                                  return (
                                                    <img
                                                      src={traceCelery}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_crustaceans':
                                                  return (
                                                    <img
                                                      src={traceCrustaceans}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_eggs':
                                                  return (
                                                    <img
                                                      src={traceEggs}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_fish':
                                                  return (
                                                    <img
                                                      src={traceFish}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_cereals':
                                                  return (
                                                    <img
                                                      src={traceCereals}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_lupin':
                                                  return (
                                                    <img
                                                      src={traceLupin}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_milk':
                                                  return (
                                                    <img
                                                      src={traceMilk}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_molluscs':
                                                  return (
                                                    <img
                                                      src={traceMolluscs}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_mustard':
                                                  return (
                                                    <img
                                                      src={traceMustard}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_nuts':
                                                  return (
                                                    <img
                                                      src={traceNuts}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_peanuts':
                                                  return (
                                                    <img
                                                      src={tracePeanuts}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_sesame':
                                                  return (
                                                    <img
                                                      src={traceSesame}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_soya':
                                                  return (
                                                    <img
                                                      src={traceSoya}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                case 't_sulphites':
                                                  return (
                                                    <img
                                                      src={traceSulphites}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                                default:
                                                  return (
                                                    <img
                                                      src={celery}
                                                      style={{ height: 24, width: 24 }}
                                                      key={allergens.id}
                                                      alt="allergen"
                                                    />
                                                  );
                                              }
                                            })}
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                    <CardActions disableSpacing>
                                      <Button
                                        variant="outline-secondary"
                                        data-rowid={a}
                                        onClick={(e) => {
                                          setEditMenuShowMobile(true);
                                          setMenu(respuestaAPIMenus[e.currentTarget.getAttribute('data-rowid')]);
                                        }}
                                      >
                                        <EditIcon />
                                      </Button>

                                      <Button
                                        variant="outline-secondary"
                                        onClick={(e) => {
                                          setDeleteMenuShow(true);
                                          setMenu(respuestaAPIMenus[e.currentTarget.getAttribute('data-rowid')]);
                                        }}
                                      >
                                        <DeleteIcon sx={{ color: red[400] }} />
                                      </Button>

                                      <ExpandMore
                                        expand={expandState[a]}
                                        onClick={() => handleExpandClick(a)}
                                        ariaExpanded={expandState[a]}
                                        ariaLabel="show more"
                                      >
                                        <ExpandMoreIcon />
                                      </ExpandMore>
                                    </CardActions>
                                    <Collapse in={expandState[a]} timeout="auto" unmountOnExit>
                                      {menu.products.map((product, i) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <CardContent key={i} style={{ padding: '4px' }}>
                                          <Grid
                                            container
                                            // eslint-disable-next-line react/no-array-index-key
                                            style={{
                                              border: '1px solid #EEEEEE',
                                              borderRadius: '10px',
                                              height: '84px',
                                            }}
                                          >
                                            <Grid item lg={3} xs={3} style={{ height: '100%' }}>
                                              {imagesProduct[contador] !== null
                                                ? (
                                                  <img
                                                    src={imagesProduct[contador]}
                                                    style={{
                                                      width: '100%',
                                                      height: '100%',
                                                      objectFit: 'cover',
                                                      borderTopLeftRadius: '10px',
                                                      borderBottomLeftRadius: '10px',
                                                    }}
                                                    alt="imageProduct"
                                                  />
                                                )
                                                : (
                                                  <div
                                                    style={{
                                                      height: '100%',
                                                      width: '100%',
                                                      backgroundColor: '#ECECEC',
                                                      objectFit: 'cover',
                                                      borderTopLeftRadius: '10px',
                                                      borderBottomLeftRadius: '10px',
                                                    }}
                                                  />
                                                )}
                                            </Grid>
                                            <Grid item lg={9} xs={9} style={{ paddingLeft: '20px', height: '100%' }}>
                                              <Grid container style={{ height: '100%' }}>
                                                <Grid item lg={12} xs={12} style={{ marginTop: '4px' }}>
                                                  <Typography sx={{ fontSize: '15px', fontWeight: '600', width: '100%' }} noWrap>
                                                    {product.name[language] === null
                                                      ? product.name.es
                                                      : product.name[language]}
                                                  </Typography>
                                                </Grid>
                                                <Grid item lg={12} xs={12} style={{ marginTop: '4px' }}>
                                                  <Typography
                                                    sx={{
                                                      height: '44px',
                                                      fontSize: '14px',
                                                      lineHeight: '1',
                                                      display: '-webkit-box',
                                                      WebkitLineClamp: '3',
                                                      WebkitBoxOrient: 'vertical',
                                                      overflow: 'hidden',
                                                      // white-space: nowrap;
                                                    }}
                                                  >
                                                    {product.description[language] === null
                                                      ? product.description.es
                                                      : product.description[language]}
                                                  </Typography>

                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          {sumContador()}
                                        </CardContent>
                                      ))}
                                    </Collapse>
                                  </Card>
                                </Grid>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Grid>
                      </div>
                    </Swiper>
                  )
              )
          }
          <DeleteMenutButton
            show={deleteMenuShow}
            onHide={() => setDeleteMenuShow(false)}
            menu={selectMenu}
            // eslint-disable-next-line react/jsx-no-bind
            fetchdata={fetchData}
            width={width}
          />
          <EditMenuPC
            show={editMenuShowPC}
            onHide={() => setEditMenuShowPC(false)}
            menu={selectMenu}
            productsValue={respuestaAPIProducts}
            // eslint-disable-next-line react/jsx-no-bind
            fetchdata={fetchData}
            language={language}
          />
          <EditMenuMobile
            show={editMenuShowMobile}
            onHide={() => setEditMenuShowMobile(false)}
            menu={selectMenu}
            productsValue={respuestaAPIProducts}
            // eslint-disable-next-line react/jsx-no-bind
            fetchdata={fetchData}
            language={language}
          />
          <AddLanguageMenuModal
            show={showChangeLanguage}
            onHide={() => setShowChangeLanguage(false)}
            menu={selectMenu}
            language={language}
            // eslint-disable-next-line react/jsx-no-bind
            fetchdata={fetchData}
            width={width}
          />
        </Container>
      </Container>
      <br />
      <br />
    </>
  );
}

export default ListaMenus;
