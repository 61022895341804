import React from 'react';
import Formulario from './Formulario';

function Añadir() {
  return (
    <>
      <Formulario />
      <br />
      <br />
    </>
  );
}

export default Añadir;
