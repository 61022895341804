import Resizer from 'react-image-file-resizer';

export default (file) => new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    720,
    720,
    'JPEG',
    85,
    0,
    (uri) => {
      resolve(uri);
    },
    'file',
  );
});
