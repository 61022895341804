import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Button, Card, CardContent, Container, FormGroup, Grid, TextField,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

// import { Link } from 'react-router-dom';
import { getVerification } from '../../services/mesonero-api/api';

function verification() {
  // const [show, setShow] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Container style={{ marginTop: '2%', marginBottom: '2%' }}>
      <Card className="card-login">
        {/* <Card.Header bsPrefix='card-header-custom'> <h2>Inicio de sesión </h2></Card.Header> */}
        <CardContent>
          <h2 style={{ textAlign: 'center' }}>{t('paginaprincipal.verification.title')}</h2>
          <hr />
        </CardContent>
        <CardContent>
          <Formik
            initialValues={{
              email: '',
              codeVerification: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup
                .string(t('paginaprincipal.verification.form.yup.email'))
                .email(t('paginaprincipal.verification.form.yup.invalid.email'))
                .required(t('paginaprincipal.verification.form.yup.required.email')),
              codeVerification: Yup
                .number(t('paginaprincipal.verification.form.yup.verificationCode'))
                // .min(8, 'Password should be of minimum 8 characters length')
                .positive()
                .required(t('paginaprincipal.verification.form.yup.required.verificationCode')),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                const consultaAPI = async () => {
                  try {
                    const consulta = await getVerification(values.email, values.codeVerification);
                    if (consulta.status === 200) {
                      toast.success(t('paginaprincipal.verification.form.toast.verificationAccount'));
                      const location = {
                        pathname: '/Paginaprincipal/Login',
                      };
                      history.push(location);
                    }
                  } catch (error) {
                    console.log(error);
                  }
                };
                consultaAPI();

                setSubmitting(false);
              }, 250);
            }}
          >
            {(props) => {
              const {
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* y otras más */
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                      <FormGroup>
                        {/* <FormLabel>Usuario</FormLabel> */}
                        <TextField
                          label={t('paginaprincipal.verification.form.email')}
                          id="email"
                          name="email"
                          // placeholder="Correo Electrónico"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <FormGroup>
                        {/* <FormLabel>Constraseña</FormLabel> */}
                        <TextField
                          label={t('paginaprincipal.verification.form.verificationCode')}
                          id="codeVerification"
                          name="codeVerification"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.codeVerification}
                          error={touched.codeVerification && Boolean(errors.codeVerification)}
                          helperText={touched.codeVerification && errors.codeVerification}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <FormGroup>
                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Loading' : t('paginaprincipal.verification.form.checkAccount')}
                        </Button>
                      </FormGroup>
                    </Grid>

                  </Grid>
                </form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
}

export default verification;
