import React from 'react';
// import Alert from 'react-bootstrap/Alert';
import { Alert } from '@mui/material';

export function AddProductResponseSuccess(setShow) {
  return (
    <Alert severity="success" onClose={() => setShow(false)}>
      <p>
        El producto se ha añadido satisfactoriamente
      </p>
    </Alert>
  );
}

export function AddProductResponseFail(setShow) {
  return (
    <Alert severity="error" onClose={() => setShow(false)}>
      <p>
        Ha habido un problema al añadir el producto. Vuelva a intentarlo
      </p>
    </Alert>
  );
}

export function AddMenuResponseSuccess(setShow) {
  return (
    <Alert severity="success" onClose={() => setShow(false)}>
      <p>
        El menú se ha añadido satisfactoriamente
      </p>
    </Alert>
  );
}

export function AddMenuResponseFail(setShow) {
  return (
    <Alert severity="error" onClose={() => setShow(false)}>
      <p>
        Ha habido un problema al añadir el menú. Vuelva a intentarlo
      </p>
    </Alert>
  );
}

export function AddLocalResponseSuccess(setShow) {
  return (
    <Alert severity="success" onClose={() => setShow(false)}>
      <p>
        El local se ha añadido satisfactoriamente
      </p>
    </Alert>
  );
}

export function AddLocalResponseFail(setShow) {
  return (
    <Alert severity="error" onClose={() => setShow(false)}>
      <p>
        Ha habido un problema al añadir el local. Vuelva a intentarlo
      </p>
    </Alert>
  );
}

export function AddCategoryResponseSuccess(setShow) {
  return (
    <Alert severity="success" onClose={() => setShow(false)}>
      <p>
        La categoría se ha añadido satisfactoriamente
      </p>
    </Alert>
  );
}

export function AddCategoryResponseFail(setShow) {
  return (
    <Alert severity="error" onClose={() => setShow(false)}>
      <p>
        Ha habido un problema al añadir la categoría. Vuelva a intentarlo
      </p>
    </Alert>
  );
}
