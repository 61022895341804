import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { Container, Table, Button } from 'react-bootstrap';
import {
  Container, /* Card, CardContent, */
  Grid, Button, Typography, FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
// import i18n from 'i18next';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from '@mui/material/colors';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { getCategories, updatePositionCategory } from '../../../../services/mesonero-api/api';
import {
  AddCategoryModal, AddLanguageCategoryModal, DeleteCategoryButton, UpdateCategoryModal,
} from '../Carta/Modal/Modals';
import useWindowDimensions from '../../../../services/sizeScreen/windowsDimension';

import es from '../../../../Banderas/es.svg';
import en from '../../../../Banderas/en.svg';
import fr from '../../../../Banderas/fr.svg';
import it from '../../../../Banderas/it.svg';
import de from '../../../../Banderas/de.svg';
import pt from '../../../../Banderas/pt.svg';

function Category() {
  const [modalShow, setModalShow] = React.useState(false);
  const [editCategoryShow, setEditCategoryShow] = React.useState(false);
  const [updateCategoryShow, setUpdateCategoryShow] = React.useState(false);
  const [selectCategory, setSelectCategory] = React.useState(undefined);
  const [showChangeLanguage, setShowChangeLanguage] = React.useState(false);

  const { t } = useTranslation();
  const [language, setLanguage] = React.useState('es');

  const [respuestaAPI, setRespuestaAPI] = React.useState([]);

  // const [listCategoriesChangeLanguage, setListCategoriesChangeLanguage] = React.useState([]);

  const shopIdActual = localStorage.getItem('shop_id');
  const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'));

  const { width } = useWindowDimensions();

  function sortCategories(categories) {
    categories.sort((a, b) => a.listPosition - b.listPosition);
    return categories;
  }

  // const fetchDataChangeLanguage = async (idiom) => {
  //   const categoriesChangeLanguage = {};
  //   const categories = await getCategories(datosUsuario.enterprise.id, shopIdActual, idiom);

  //   for (let i = 0; i < categories.length; i += 1) {
  //     categoriesChangeLanguage[categories[i].id] = null;
  //   }
  //   for (let i = 0; i < respuestaAPI.length; i += 1) {
  //     if (!(respuestaAPI[i].id in categoriesChangeLanguage)) {
  //       categories.push(respuestaAPI[i]);
  //     }
  //   }

  //   const categoriesSort = sortCategories(categories);
  //   setListCategoriesChangeLanguage(categoriesSort);
  // };

  const fetchData = async () => {
    const categories = sortCategories(
      await getCategories(datosUsuario.enterprise.id, shopIdActual),
    );
    setRespuestaAPI(categories);
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function upCategory(targetCategory) {
    let category1;
    let category2;
    for (let i = 0; i < respuestaAPI.length; i += 1) {
      if (respuestaAPI[i].id === targetCategory.id) {
        category1 = respuestaAPI[i - 1];
        category2 = respuestaAPI[i];
        break;
      }
    }
    const listPositionProduct1 = category1.listPosition;
    category1.listPosition = category2.listPosition;
    category2.listPosition = listPositionProduct1;

    await updatePositionCategory(targetCategory.id, 2);
    fetchData();
  }

  async function downCategory(targetCategory) {
    let category1;
    let category2;
    for (let i = 0; i < respuestaAPI.length; i += 1) {
      if (respuestaAPI[i].id === targetCategory.id) {
        category1 = respuestaAPI[i + 1];
        category2 = respuestaAPI[i];
        break;
      }
    }
    const listPositionProduct1 = category1.listPosition;
    category1.listPosition = category2.listPosition;
    category2.listPosition = listPositionProduct1;

    await updatePositionCategory(targetCategory.id, 1);
    fetchData();
  }

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <>
      <Container style={{ maxWidth: '1200px' }}>
        <Grid container sx={{ marginTop: '15px', marginBottom: '15px', alignItems: 'center' }}>
          <Grid item lg={1}>
            <Link to="/App/InfoLocal/Informacion/PaginaInicialLocal">
              <Button>
                <ArrowBackIcon />
              </Button>
            </Link>
          </Grid>
          <Grid item lg={3}>
            <Typography variant="h6">
              {t('App.InfoLocal.Informacion.Categoria.route')}
            </Typography>
          </Grid>
        </Grid>
        <Container style={{ maxWidth: '1000px' }}>
          <Grid container>
            <Grid item lg={3} xs={6}>
              <Button variant="contained" style={{ width: '100%' }} onClick={() => setModalShow(true)}><em>{t('App.InfoLocal.Informacion.Categoria.addButton')}</em></Button>
              <AddCategoryModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                fetchdata={fetchData}
                width={width}
              />
            </Grid>
            <Grid item lg={3} xs={6} style={{ textAlign: 'end' }}>
              <FormControl>
                <InputLabel id="idiomas" style={{ marginLeft: '20px' }}>{t('App.InfoLocal.Informacion.Categoria.language')}</InputLabel>
                <Select
                  labelId="idiomas"
                  id="idiomas"
                  value={language}
                  label="idioma"
                  onChange={handleChange}
                  style={{ marginLeft: '20px' }}
                >
                  <MenuItem value="es">
                    <img
                      src={es}
                      style={{ height: 24, width: 24 }}
                      alt="flagEs"
                    />
                  </MenuItem>
                  <MenuItem value="en">
                    <img
                      src={en}
                      style={{ height: 24, width: 24 }}
                      alt="flagEn"
                    />
                  </MenuItem>
                  <MenuItem value="fr">
                    <img
                      src={fr}
                      style={{ height: 24, width: 24 }}
                      alt="flagFr"
                    />
                  </MenuItem>
                  <MenuItem value="it">
                    <img
                      src={it}
                      style={{ height: 24, width: 24 }}
                      alt="flagIt"
                    />
                  </MenuItem>
                  <MenuItem value="de">
                    <img
                      src={de}
                      style={{ height: 24, width: 24 }}
                      alt="flagDe"
                    />
                  </MenuItem>
                  <MenuItem value="pt">
                    <img
                      src={pt}
                      style={{ height: 24, width: 24 }}
                      alt="flagPt"
                    />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <br />

          {
            // eslint-disable-next-line no-nested-ternary
            (respuestaAPI.length === 0)
              ? <div />
              : (
                // eslint-disable-next-line no-nested-ternary
                width > 720
                  ? (
                    <Container>
                      <Typography variant="h4" sx={{ marginBottom: '8px' }}>
                        {t('App.InfoLocal.Informacion.Categoria.name')}
                      </Typography>
                      {respuestaAPI.length !== 0
                        && respuestaAPI.map((category, a) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div key={a}>
                            {(category.name[language] === null)
                              && (
                                <div className="noLanguageCategory">
                                  <Button
                                    variant="contained"
                                    data-rowid={a}
                                    onClick={(e) => {
                                      setShowChangeLanguage(true);
                                      setSelectCategory(respuestaAPI[e.currentTarget.getAttribute('data-rowid')]);
                                    }}
                                  >
                                    {t('App.InfoLocal.Informacion.Categoria.addButtonLanguage')}
                                  </Button>
                                  <AddLanguageCategoryModal
                                    show={showChangeLanguage}
                                    onHide={() => setShowChangeLanguage(false)}
                                    categoryData={selectCategory}
                                    fetchdata={fetchData}
                                    width={width}
                                    language={language}
                                  />
                                </div>
                              )}
                            <Grid
                              container
                              // eslint-disable-next-line react/no-array-index-key
                              key={a}
                              style={{
                                marginBottom: '10px', marginLeft: '16px', border: '1px solid #EEEEEE', borderRadius: '10px', maxWidth: '700px',
                              }}
                            >
                              <Grid item lg={7} xs={7}>
                                <div
                                  style={{
                                    marginLeft: '16px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    height: '100%',
                                    fontSize: '22px',
                                  }}
                                >
                                  <Typography variant="h5" noWrap>
                                    {category.name[language] === null ? category.name.es
                                      : category.name[language]}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item lg={5} xs={5}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <Button
                                    variant="outline-secondary"
                                    data-rowid={a}
                                    onClick={(e) => {
                                      setUpdateCategoryShow(true);
                                      setSelectCategory(respuestaAPI[e.currentTarget.getAttribute('data-rowid')]);
                                    }}
                                  >
                                    <EditIcon />
                                  </Button>
                                  <UpdateCategoryModal
                                    show={updateCategoryShow}
                                    onHide={() => setUpdateCategoryShow(false)}
                                    categoryData={selectCategory}
                                    fetchdata={fetchData}
                                    width={width}
                                    language={language}
                                  />
                                  <Button
                                    variant="outline-secondary"
                                    data-rowid={a}
                                    onClick={(e) => {
                                      setEditCategoryShow(true);
                                      setSelectCategory(respuestaAPI[e.currentTarget.getAttribute('data-rowid')]);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                  <DeleteCategoryButton
                                    show={editCategoryShow}
                                    onHide={() => setEditCategoryShow(false)}
                                    categoryData={selectCategory}
                                    fetchdata={fetchData}
                                    width={width}
                                  />

                                  <Button
                                    data-rowid={a}
                                    onClick={
                                      category.id
                                        === respuestaAPI[0].id
                                        ? null
                                        : (e) => {
                                          upCategory(respuestaAPI[e.currentTarget.getAttribute('data-rowid')]);
                                        }
                                    }
                                  >
                                    {category.id === respuestaAPI[0].id ? <ArrowCircleUpRoundedIcon sx={{ color: 'gray' }} /> : <ArrowCircleUpRoundedIcon sx={{ color: 'black' }} />}
                                  </Button>
                                  <Button
                                    data-rowid={a}
                                    onClick={
                                      category.id
                                        // eslint-disable-next-line max-len
                                        === respuestaAPI[respuestaAPI.length - 1].id
                                        ? null
                                        : (e) => {
                                          downCategory(respuestaAPI[e.currentTarget.getAttribute('data-rowid')]);
                                        }
                                    }
                                  >
                                    {
                                      category.id
                                        // eslint-disable-next-line max-len
                                        === respuestaAPI[respuestaAPI.length - 1].id
                                        ? <ArrowCircleDownRoundedIcon sx={{ color: 'gray' }} />
                                        : <ArrowCircleDownRoundedIcon sx={{ color: 'black' }} />
                                    }
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        ))}
                    </Container>
                  )
                  : (
                    <Container>
                      <h3>{t('App.InfoLocal.Informacion.Categoria.name')}</h3>
                      {respuestaAPI.length !== 0
                        && respuestaAPI.map((category, a) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div key={a}>
                            {(category.name[language] === null)
                              && (
                                <div className="noLanguageCategoryMovil">
                                  <Button
                                    variant="contained"
                                    data-rowid={a}
                                    onClick={(e) => {
                                      setShowChangeLanguage(true);
                                      setSelectCategory(respuestaAPI[e.currentTarget.getAttribute('data-rowid')]);
                                    }}
                                  >
                                    {t('App.InfoLocal.Informacion.Categoria.addButtonLanguage')}
                                  </Button>
                                  <AddLanguageCategoryModal
                                    show={showChangeLanguage}
                                    onHide={() => setShowChangeLanguage(false)}
                                    categoryData={selectCategory}
                                    fetchdata={fetchData}
                                    width={width}
                                    language={language}
                                  />
                                </div>
                              )}
                            <Grid
                              container
                              // eslint-disable-next-line react/no-array-index-key
                              key={a}
                              style={{
                                marginBottom: '10px', border: '1px solid #EEEEEE', borderRadius: '10px', maxWidth: '700px',
                              }}
                            >
                              <Grid item lg={8} xs={8}>
                                <div
                                  style={{
                                    marginLeft: '16px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    height: '100%',
                                    fontSize: '22px',
                                  }}
                                >
                                  <span>
                                    {category.name[language] === null
                                      ? category.name.es
                                      : category.name[language]}
                                  </span>
                                </div>
                              </Grid>
                              <Grid item lg={2} xs={2}>
                                <Button
                                  variant="outline-secondary"
                                  data-rowid={a}
                                  onClick={(e) => {
                                    setUpdateCategoryShow(true);
                                    setSelectCategory(respuestaAPI[e.currentTarget.getAttribute('data-rowid')]);
                                  }}
                                >
                                  <EditIcon />
                                </Button>
                                <UpdateCategoryModal
                                  show={updateCategoryShow}
                                  onHide={() => setUpdateCategoryShow(false)}
                                  categoryData={selectCategory}
                                  fetchdata={fetchData}
                                  width={width}
                                  language={language}
                                />
                                <Button
                                  variant="outline-secondary"
                                  data-rowid={a}
                                  onClick={(e) => {
                                    setEditCategoryShow(true);
                                    setSelectCategory(respuestaAPI[e.currentTarget.getAttribute('data-rowid')]);
                                  }}
                                >
                                  <DeleteIcon sx={{ color: red[400] }} />
                                </Button>
                                <DeleteCategoryButton
                                  show={editCategoryShow}
                                  onHide={() => setEditCategoryShow(false)}
                                  categoryData={selectCategory}
                                  fetchdata={fetchData}
                                  width={width}
                                />
                              </Grid>
                              <Grid item lg={2} xs={2}>
                                <Button
                                  data-rowid={a}
                                  onClick={
                                    category.id
                                      === respuestaAPI[0].id
                                      ? null
                                      : (e) => {
                                        upCategory(respuestaAPI[e.currentTarget.getAttribute('data-rowid')]);
                                      }
                                  }
                                >
                                  {category.id === respuestaAPI[0].id ? <ArrowCircleUpRoundedIcon sx={{ color: 'gray' }} /> : <ArrowCircleUpRoundedIcon sx={{ color: 'black' }} />}
                                </Button>
                                <Button
                                  data-rowid={a}
                                  onClick={
                                    category.id
                                      // eslint-disable-next-line max-len
                                      === respuestaAPI[respuestaAPI.length - 1].id
                                      ? null
                                      : (e) => {
                                        downCategory(respuestaAPI[e.currentTarget.getAttribute('data-rowid')]);
                                      }
                                  }
                                >
                                  {
                                    category.id
                                      // eslint-disable-next-line max-len
                                      === respuestaAPI[respuestaAPI.length - 1].id
                                      ? <ArrowCircleDownRoundedIcon sx={{ color: 'gray' }} />
                                      : <ArrowCircleDownRoundedIcon sx={{ color: 'black' }} />
                                  }
                                </Button>
                              </Grid>
                            </Grid>
                          </div>
                        ))}
                    </Container>
                  )
              )
          }
        </Container>
      </Container>
      <br />
      <br />
    </>
  );
}

export default Category;
