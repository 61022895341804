import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import i18n from 'i18next';

import {
  FormControl, Grid, InputLabel, Select,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from '../Logo_Mesonero_Web/Logo Mesonero Web/website_logo.png';

import { TokenContext } from '../token-context';

import es from '../Banderas/es.svg';
import en from '../Banderas/en.svg';
import fr from '../Banderas/fr.svg';
import it from '../Banderas/it.svg';
import de from '../Banderas/de.svg';
import pt from '../Banderas/pt.svg';

import useWindowDimensions from '../services/sizeScreen/windowsDimension';

function ResponsiveAppBar() {
  const history = useHistory();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [language, setLanguage] = React.useState('es');
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const loginOut = () => {
    localStorage.clear();
    history.push('/Paginaprincipal/Login');
    // window.location.reload();
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleChange = (event) => {
    changeLanguage(event.target.value);
    setLanguage(event.target.value);
  };

  const changeLanguageMovil = (idiom) => {
    changeLanguage(idiom);
    setLanguage(idiom);
  };

  return (
    <div>
      <AppBar position="static" style={{ backgroundColor: '#F9C97F' }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <TokenContext.Consumer>
                  {({ token }) => (
                    token !== null
                      ? ('')
                      : (
                        <MenuItem
                          onClick={handleCloseNavMenu}
                          component={Link}
                          to="/Paginaprincipal/Login"
                        >
                          <Typography textAlign="center" xs={{ color: 'black' }}>
                            Iniciar Sesión
                          </Typography>
                        </MenuItem>
                      ))}
                </TokenContext.Consumer>
                <MenuItem
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to="/PaginaEntrada"
                >
                  <Typography textAlign="center" xs={{ color: 'black' }}>
                    {t('paginaentrada.MenuPagina.aboutUs')}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to="/infoTarifa"
                >
                  <Typography textAlign="center" xs={{ color: 'black' }}>
                    {t('paginaentrada.MenuPagina.pricing')}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to="/Contacto"
                >
                  <Typography textAlign="center" xs={{ color: 'black' }}>
                    {t('paginaentrada.MenuPagina.contact')}
                  </Typography>
                </MenuItem>
                <TokenContext.Consumer>
                  {({ token }) => (
                    token !== null
                      ? (
                        <MenuItem
                          onClick={handleCloseNavMenu}
                          component={Link}
                          to="/App/Cuenta/Locales"
                        >
                          <Typography textAlign="center" xs={{ color: 'black' }}>
                            {t('paginaentrada.MenuPagina.account')}
                          </Typography>
                        </MenuItem>
                      )
                      : '')}
                </TokenContext.Consumer>
              </Menu>

              <TokenContext.Consumer>
                {({ token }) => (
                  token !== null
                    ? (
                      <Button
                        // href="/Contacto"
                        component={Link}
                        to="/App/Cuenta/Locales"
                        sx={{
                          display: { xs: 'flex', md: 'none' },
                        }}
                      >
                        <img
                          src={logo}
                          alt="logo"
                          style={{ height: 40, width: 150 }}
                        />
                      </Button>
                    )
                    : (
                      <Button
                        // href="/Contacto"
                        component={Link}
                        to="/Paginaprincipal/Login"
                        sx={{
                          display: { xs: 'flex', md: 'none' },
                        }}
                      >
                        <img
                          src={logo}
                          alt="logo"
                          style={{ height: 40, width: 150 }}
                        />
                      </Button>
                    )
                )}
              </TokenContext.Consumer>

              <TokenContext.Consumer>
                {({ token }) => (
                  token !== null
                    ? (
                      <div>
                        <Tooltip title="Open settings">
                          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          sx={{ mt: '45px' }}
                          id="menu-appbar"
                          anchorEl={anchorElUser}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(anchorElUser)}
                          onClose={handleCloseUserMenu}
                        >
                          {/* const settings = ['Profile', 'Account', 'Dashboard', 'Logout']; */}
                          <MenuItem
                            onClick={handleCloseNavMenu}
                            component={Link}
                            to="/App/Cuenta/Perfil"
                          >
                            <Typography textAlign="center">
                              {t('paginaentrada.MenuPagina.profile')}
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={handleCloseNavMenu}
                            component={Link}
                            to="/App/Cuenta/Locales"
                          >
                            <Typography textAlign="center">
                              {t('paginaentrada.MenuPagina.locals')}
                            </Typography>
                          </MenuItem>
                          <MenuItem onClick={() => { loginOut(); handleCloseUserMenu(); }}>
                            <Typography textAlign="center">
                              {t('paginaentrada.MenuPagina.logout')}
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </div>
                    )
                    : (
                      <div />
                    ))}
              </TokenContext.Consumer>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >

              <TokenContext.Consumer>
                {({ token }) => (
                  token !== null
                    ? (
                      <Button
                        // href="/Contacto"
                        component={Link}
                        to="/App/Cuenta/Locales"
                        sx={{
                          display: { xs: 'none', md: 'flex' },
                        }}
                      >
                        <img
                          src={logo}
                          alt="logo"
                          style={{ height: 40, width: 150 }}
                        />
                      </Button>
                    )
                    : (
                      <Button
                        // href="/Contacto"
                        component={Link}
                        to="/Paginaprincipal/Login"
                        sx={{
                          display: { xs: 'none', md: 'flex' },
                        }}
                      >
                        <img
                          src={logo}
                          alt="logo"
                          style={{ height: 40, width: 150 }}
                        />
                      </Button>
                    )
                )}
              </TokenContext.Consumer>

              <div style={{ display: 'flex', paddingRight: '100px' }}>
                <Button
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to="/PaginaEntrada"
                  sx={{ my: 2, color: 'black', display: 'block' }}
                >
                  {t('paginaentrada.MenuPagina.aboutUs')}
                </Button>
                <Button
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to="/infoTarifa"
                  sx={{ my: 2, color: 'black', display: 'block' }}
                >
                  {t('paginaentrada.MenuPagina.pricing')}
                </Button>
                <Button
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to="/Contacto"
                  sx={{ my: 2, color: 'black', display: 'block' }}
                >
                  {t('paginaentrada.MenuPagina.contact')}
                </Button>
                <TokenContext.Consumer>
                  {({ token }) => (
                    token !== null
                      ? (
                        <Button
                          onClick={handleCloseNavMenu}
                          component={Link}
                          to="/App/Cuenta/Locales"
                          sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                          {t('paginaentrada.MenuPagina.account')}
                        </Button>
                      )
                      : ''
                  )}
                </TokenContext.Consumer>
              </div>
              <TokenContext.Consumer>
                {({ token, setToken }) => (
                  token !== null
                    ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Open settings">
                          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          sx={{ mt: '45px' }}
                          id="menu-appbar"
                          anchorEl={anchorElUser}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(anchorElUser)}
                          onClose={handleCloseUserMenu}
                        >
                          {/* const settings = ['Profile', 'Account', 'Dashboard', 'Logout']; */}
                          <MenuItem
                            onClick={handleCloseNavMenu}
                            component={Link}
                            to="/App/Cuenta/Perfil"
                          >
                            <Typography textAlign="center">
                              {t('paginaentrada.MenuPagina.profile')}
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={handleCloseNavMenu}
                            component={Link}
                            to="/App/Cuenta/Locales"
                          >
                            <Typography textAlign="center">
                              {t('paginaentrada.MenuPagina.locals')}
                            </Typography>
                          </MenuItem>
                          <MenuItem onClick={() => {
                            loginOut(); handleCloseUserMenu(); setToken(null);
                          }}
                          >
                            <Typography textAlign="center">
                              {t('paginaentrada.MenuPagina.logout')}
                            </Typography>
                          </MenuItem>
                        </Menu>
                        <FormControl>
                          <InputLabel id="idiomas" style={{ marginLeft: '20px' }}>Idiomas</InputLabel>
                          <Select
                            labelId="idiomas"
                            id="idiomas"
                            value={language}
                            label="idioma"
                            onChange={handleChange}
                            style={{ marginLeft: '20px' }}
                          >
                            <MenuItem value="es">
                              <img
                                src={es}
                                style={{ height: 24, width: 24 }}
                                alt="flagEs"
                              />
                            </MenuItem>
                            <MenuItem value="en">
                              <img
                                src={en}
                                style={{ height: 24, width: 24 }}
                                alt="flagEn"
                              />
                            </MenuItem>
                            <MenuItem value="fr">
                              <img
                                src={fr}
                                style={{ height: 24, width: 24 }}
                                alt="flagFr"
                              />
                            </MenuItem>
                            <MenuItem value="it">
                              <img
                                src={it}
                                style={{ height: 24, width: 24 }}
                                alt="flagIt"
                              />
                            </MenuItem>
                            <MenuItem value="de">
                              <img
                                src={de}
                                style={{ height: 24, width: 24 }}
                                alt="flagDe"
                              />
                            </MenuItem>
                            <MenuItem value="pt">
                              <img
                                src={pt}
                                style={{ height: 24, width: 24 }}
                                alt="flagPt"
                              />
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    )
                    : (
                      <div>
                        <Grid container spacing={3} style={{ alignItems: 'center' }}>
                          <Grid item lg={8}>
                            <Link to="/Paginaprincipal/Login">
                              <Button variant="contained" style={{ width: '100%' }}>
                                {t('paginaentrada.MenuPagina.login')}
                              </Button>
                            </Link>
                          </Grid>
                          <Grid item lg={4}>
                            <FormControl>
                              <InputLabel id="idiomas">Idiomas</InputLabel>
                              <Select
                                labelId="idiomas"
                                id="idiomas"
                                value={language}
                                label="idioma"
                                onChange={handleChange}
                              >
                                <MenuItem value="es">
                                  <img
                                    src={es}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagEs"
                                  />
                                </MenuItem>
                                <MenuItem value="en">
                                  <img
                                    src={en}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagEn"
                                  />
                                </MenuItem>
                                <MenuItem value="fr">
                                  <img
                                    src={fr}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagFr"
                                  />
                                </MenuItem>
                                <MenuItem value="it">
                                  <img
                                    src={it}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagIt"
                                  />
                                </MenuItem>
                                <MenuItem value="de">
                                  <img
                                    src={de}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagDe"
                                  />
                                </MenuItem>
                                <MenuItem value="pt">
                                  <img
                                    src={pt}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagPt"
                                  />
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                    ))}

              </TokenContext.Consumer>
              {/*  */}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {
        width <= 720
        && (
          <AppBar color="inherit" position="static">
            <Grid container style={{ justifyContent: 'center', marginTop: '5px', marginBottom: '5px' }}>
              <Button
                onClick={() => { changeLanguageMovil('es'); }}
                style={{ padding: '0px', minWidth: '42px' }}
              >
                <img
                  src={es}
                  style={{ height: 32, width: 32 }}
                  alt="flagEs"
                />
              </Button>
              <Button
                onClick={() => { changeLanguageMovil('en'); }}
                style={{ padding: '0px', minWidth: '42px' }}
              >
                <img
                  src={en}
                  style={{ height: 32, width: 32 }}
                  alt="flagEn"
                />
              </Button>
              <Button
                onClick={() => { changeLanguageMovil('fr'); }}
                style={{ padding: '0px', minWidth: '42px' }}
              >
                <img
                  src={fr}
                  style={{ height: 32, width: 32 }}
                  alt="flagFr"
                />
              </Button>
              <Button
                onClick={() => { changeLanguageMovil('it'); }}
                style={{ padding: '0px', minWidth: '42px' }}
              >
                <img
                  src={it}
                  style={{ height: 32, width: 32 }}
                  alt="flagIt"
                />
              </Button>
              <Button
                onClick={() => { changeLanguageMovil('de'); }}
                style={{ padding: '0px', minWidth: '42px' }}
              >
                <img
                  src={de}
                  style={{ height: 32, width: 32 }}
                  alt="flagDe"
                />
              </Button>
              <Button
                onClick={() => { changeLanguageMovil('pt'); }}
                style={{ padding: '0px', minWidth: '42px' }}
              >
                <img
                  src={pt}
                  style={{ height: 32, width: 32 }}
                  alt="flagPt"
                />
              </Button>
            </Grid>
          </AppBar>
        )
      }
    </div>
  );
}
export default withTranslation()(ResponsiveAppBar);
