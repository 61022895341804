import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
// import {
//   Card, Container, Form, Button, Row, Col,
// } from 'react-bootstrap';
import {
  Container, Card, CardContent,
  Grid, Button, FormGroup, TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EditProfile } from '../../InfoLocal/Informacion/Carta/Modal/Modals';
import HeaderWithRouter from '../MenuCuenta';
import { getUser } from '../../../services/mesonero-api/api';

function Perfil() {
  const [modalShow, setModalShow] = React.useState(false);
  const { t } = useTranslation();

  const datos = JSON.parse(localStorage.getItem('datosUsuario'));

  const [respuestaUser, setRespuestaUser] = React.useState(undefined);

  async function fetchData() {
    const user = await getUser(datos.id);
    setRespuestaUser(user);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Container>
        <HeaderWithRouter />
        <hr />
        {datos.length === 0 || respuestaUser === undefined
          ? (<div />)
          : (
            <Card>
              <CardContent>
                <h2 style={{ textAlign: 'center' }}>{t('App.Cuenta.Perfil.title')}</h2>
              </CardContent>
              <CardContent>
                <Formik
                  initialValues={{
                    name: respuestaUser.name || '',
                    surname: respuestaUser.surname || '',
                    email: respuestaUser.email || '',
                    phoneNumber: respuestaUser.phoneNumber || '',

                    enterprise: datos.enterprise.name || '',
                    nif: datos.enterprise.passport || '',
                    enterprisePhoneNumber: datos.enterprise.phoneNumber || '',
                    website: datos.enterprise.website || '',
                    enterpriseAddress: datos.enterprise.address || '',
                    enterpriseCountry: datos.enterprise.country || '',
                    enterpriseProvince: datos.enterprise.province || '',
                    enterprisePostalCode: datos.enterprise.postalCode || '',
                  }}
                >
                  {(props) => {
                    const {
                      values,
                    } = props;
                    return (
                      <form>
                        <Grid container spacing={3}>
                          <Grid item lg={4} xs={12}>
                            <FormGroup>
                              {/* <Form.Label>Nombre</Form.Label> */}
                              <TextField
                                label={t('App.Cuenta.Perfil.form.user.name')}
                                name="name"
                                value={values.name}
                                disabled
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item lg={8} xs={12}>
                            <FormGroup>
                              {/* <Form.Label>Apellidos</Form.Label> */}
                              <TextField
                                label={t('App.Cuenta.Perfil.form.user.surname')}
                                name="surname"
                                value={values.surname}
                                disabled
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item lg={6} xs={12}>
                            <FormGroup>
                              {/* <Form.Label>Correo Electrónico</Form.Label> */}
                              <TextField
                                label={t('App.Cuenta.Perfil.form.user.email')}
                                name="email"
                                value={values.email}
                                disabled
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <FormGroup>
                              {/* <Form.Label>Número de Teléfono</Form.Label> */}
                              <TextField
                                label={t('App.Cuenta.Perfil.form.user.phoneNumber')}
                                name="phoneNumber"
                                value={values.phoneNumber}
                                disabled
                              />
                            </FormGroup>
                          </Grid>

                          {/* <Row className='mb-3'>
                          <FormGroup>
                            <Form.Check
                              name='autonomo'
                              label='Autónomo'
                              id='validationFormik1'
                              onChange={e => setdisplayValue(!displayValue)}
                            />
                          </FormGroup>
                        </Row> */}

                          <Grid item lg={6} xs={12}>
                            <FormGroup>
                              {/* <Form.Label>Nombre de Empresa</Form.Label> */}
                              <TextField
                                label={t('App.Cuenta.Perfil.form.enterprise.enterprise')}
                                name="enterprise"
                                value={values.enterprise}
                                disabled
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <FormGroup>
                              {/* <Form.Label>NIF</Form.Label> */}
                              <TextField
                                label={t('App.Cuenta.Perfil.form.enterprise.nif')}
                                name="nif"
                                value={values.nif}
                                disabled
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item lg={4} xs={12}>
                            <FormGroup>
                              {/* <Form.Label>Teléfono de Contacto Empresa</Form.Label> */}
                              <TextField
                                label={t('App.Cuenta.Perfil.form.enterprise.enterprisePhoneNumber')}
                                name="enterprisePhoneNumber"
                                value={values.enterprisePhoneNumber}
                                disabled
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item lg={8} xs={12}>
                            <FormGroup>
                              {/* <Form.Label>Web Empresarial</Form.Label> */}
                              <TextField
                                label={t('App.Cuenta.Perfil.form.enterprise.website')}
                                name="website"
                                value={values.website}
                                disabled
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item lg={12} xs={12}>
                            <FormGroup>
                              {/* <Form.Label>Dirección Empresa</Form.Label> */}
                              <TextField
                                label={t('App.Cuenta.Perfil.form.enterprise.enterpriseAddress')}
                                name="enterpriseAddress"
                                value={values.enterpriseAddress}
                                disabled
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item lg={4} xs={12}>
                            <FormGroup>
                              {/* <Form.Label>País Empresa</Form.Label> */}
                              <TextField
                                label={t('App.Cuenta.Perfil.form.enterprise.enterpriseCountry')}
                                name="enterpriseCountry"
                                value={values.enterpriseCountry}
                                disabled
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item lg={5} xs={12}>
                            <FormGroup>
                              {/* <Form.Label>Provincia Empresa</Form.Label> */}
                              <TextField
                                label={t('App.Cuenta.Perfil.form.enterprise.enterpriseProvince')}
                                name="enterpriseProvince"
                                value={values.enterpriseProvince}
                                disabled
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item lg={3} xs={12}>
                            <FormGroup>
                              {/* <Form.Label>Código Postal Empresa</Form.Label> */}
                              <TextField
                                label={t('App.Cuenta.Perfil.form.enterprise.enterprisePostalCode')}
                                name="enterprisePostalCode"
                                value={values.enterprisePostalCode}
                                disabled
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item lg={3}>
                            <div />
                          </Grid>
                          <Grid item lg={3} xs={12}>
                            <FormGroup>
                              <Button variant="contained" onClick={() => setModalShow(true)}>{t('App.Cuenta.Perfil.form.editButton')}</Button>
                              <EditProfile
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                datosEmpresa={datos}
                                datosUsuario={respuestaUser}
                              />
                            </FormGroup>
                          </Grid>
                          {/* <Grid item lg={4} xs={12}>
                            <FormGroup>
                              <Button variant="contained">{t('App.Cuenta.Perfil
                              .form.susbriptionAdminButton')}</Button>
                            </FormGroup>
                          </Grid> */}
                          <Grid item lg={3} xs={12}>
                            <FormGroup>
                              <Link to="/Paginaprincipal/ChangePassword">
                                <Button variant="contained" style={{ width: '100%' }}>
                                  {t('App.Cuenta.Perfil.form.changePassword')}
                                </Button>
                              </Link>
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </form>
                    );
                  }}
                </Formik>
              </CardContent>
            </Card>
          )}
      </Container>
      <br />
      <br />
    </>
  );
}

export default Perfil;
