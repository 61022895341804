import React, { useEffect } from 'react';
import QRCodeCanvas from 'qrcode.react';
import * as htmlToImage from 'html-to-image';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

function QRGenerator(props) {
  const {
    valueString, documentId, onLoaded, idMesa,
  } = props;
  const [image, setImage] = React.useState();
  // const [divDisplay, setDivDisplay] = React.useState('flex');
  // let imageLoaded = false;
  const { t } = useTranslation();

  useEffect(() => {
    const node = document.getElementById(`table_number_${idMesa}`);
    htmlToImage.toPng(node)
      .then((dataUrl) => {
        const img = new Image();
        img.src = dataUrl;
        setImage(img);
        onLoaded();
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
    // setDivDisplay('none')
  }, []);
  return (
    <>
      {image !== undefined
        ? (
          <QRCodeCanvas
            id={documentId}
            value={valueString}
            size={200}
            bgColor="#ffffff"
            fgColor="#000000"
            level="H"
            includeMargin
            imageSettings={{
              src: image.src,
              x: null,
              y: null,
              height: 38,
              width: 38,
              excavate: true,
            }}
          />
        ) : (
          <CircularProgress />
        )}

      <div style={{
        display: 'flex', /* display: divDisplay, */ justifyContent: 'center', alignItems: 'center',
      }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('App.InfoLocal.Informacion.GenerarQR.table')}
        </div>
        <div
          id={`table_number_${idMesa}`}
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', width: 38, height: 38,
          }}
        >
          <div>{idMesa}</div>
        </div>
      </div>
    </>
  );
}

export default QRGenerator;
