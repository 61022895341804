import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
// import {
//   Card, Container, Form, Button, Row,
// } from 'react-bootstrap';
import {
  Container, Grid, Card, CardHeader, CardContent, Button, FormGroup, TextField,
} from '@mui/material';

import { toast } from 'react-toastify';
import { postContactus } from '../../services/mesonero-api/api';

function FormularioContacto() {
  const { t } = useTranslation();

  return (
    <Container>
      <Card>
        {/* <Card.Header bsPrefix="card-header-custom"
        // style={{ textAlign: 'center' }}><h2>Fomulario de Contacto</h2></Card.Header>
        <Card.Body> */}
        <CardHeader title={t('paginaentrada.contacto.contactForm.title')} />
        <CardContent>
          <Formik
            initialValues={{
              nombre: '',
              email: '',
              asunto: '',
              mensaje: '',
            }}
            validationSchema={Yup.object().shape({
              nombre: Yup.string().required(t('paginaentrada.contacto.contactForm.form.yup.required.name')),
              email: Yup
                .string(t('paginaentrada.contacto.contactForm.form.email'))
                .email(t('paginaentrada.contacto.contactForm.form.yup.invalid.email'))
                .required(t('paginaentrada.contacto.contactForm.form.yup.required.email')),
              asunto: Yup.string().required(t('paginaentrada.contacto.contactForm.form.yup.required.topic')),
              mensaje: Yup.string().required(t('paginaentrada.contacto.contactForm.form.yup.required.message')),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                // alert(JSON.stringify(values, null, 2));
                const data = {
                  name: values.nombre,
                  email: values.email,
                  subject: values.asunto,
                  message: values.mensaje,
                };
                const consultaAPI = async () => {
                  try {
                    const consulta = await postContactus(data);
                    if (consulta.status === 200) {
                      toast.success('Se ha enviado la petición de contacto satisfactoriamente');
                    }
                  } catch (error) {
                    toast.error('Ha habido un problema para enviar la petición, espere un momento y vuelva a intentarlo');
                  }
                };
                consultaAPI();

                setSubmitting(false);
              }, 250);
            }}
          >
            {(props) => {
              const {
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* y otras más */
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>Nombre</Form.Label> */}
                        <TextField
                          label={t('paginaentrada.contacto.contactForm.form.name')}
                          name="nombre"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nombre}
                          error={touched.nombre && Boolean(errors.nombre)}
                          helperText={touched.nombre && errors.nombre}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>Correo Electrónico</Form.Label> */}
                        <TextField
                          label={t('paginaentrada.contacto.contactForm.form.email')}
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>Asunto</Form.Label> */}
                        <TextField
                          label={t('paginaentrada.contacto.contactForm.form.topic')}
                          name="asunto"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.asunto}
                          error={touched.asunto && Boolean(errors.asunto)}
                          helperText={touched.asunto && errors.asunto}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>Mensaje</Form.Label> */}
                        <TextField
                          label={t('paginaentrada.contacto.contactForm.form.message')}
                          multiline
                          rows={2}
                          name="mensaje"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mensaje}
                          error={touched.mensaje && Boolean(errors.mensaje)}
                          helperText={touched.mensaje && errors.mensaje}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <FormGroup>
                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Loading' : t('paginaentrada.contacto.contactForm.form.button')}
                        </Button>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
}

export default FormularioContacto;
