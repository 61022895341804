import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Card, CardContent,
  Grid,
  CardHeader,
  CardMedia,
  CardActions,
  Collapse,
  IconButton,
  Typography,
  Button,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import useWindowDimensions from '../../../../services/sizeScreen/windowsDimension';

import {
  getMenus, getProducts, getImageProduct, getImageMenu, getCategories,
} from '../../../../services/mesonero-api/api';

import celery from '../../../../Icons/allergen-celery.svg';
import crustaceans from '../../../../Icons/allergen-crustaceans.svg';
import eggs from '../../../../Icons/allergen-eggs.svg';
import fish from '../../../../Icons/allergen-fish.svg';
import cereals from '../../../../Icons/allergen-gluten.svg';
import lupin from '../../../../Icons/allergen-lupin.svg';
import milk from '../../../../Icons/allergen-milk.svg';
import molluscs from '../../../../Icons/allergen-mollusks.svg';
import mustard from '../../../../Icons/allergen-mustard.svg';
import nuts from '../../../../Icons/allergen-nuts.svg';
import peanuts from '../../../../Icons/allergen-peanuts.svg';
import sesame from '../../../../Icons/allergen-sesame-seeds.svg';
import soya from '../../../../Icons/allergen-soy.svg';
import sulphites from '../../../../Icons/allergen-sulphur-dioxide.svg';

import traceCelery from '../../../../Icons/allergen-traces-celery.svg';
import traceCrustaceans from '../../../../Icons/allergen-traces-crustaceans.svg';
import traceEggs from '../../../../Icons/allergen-traces-eggs.svg';
import traceFish from '../../../../Icons/allergen-traces-fish.svg';
import traceCereals from '../../../../Icons/allergen-traces-gluten.svg';
import traceLupin from '../../../../Icons/allergen-traces-lupin.svg';
import traceMilk from '../../../../Icons/allergen-traces-milk.svg';
import traceMolluscs from '../../../../Icons/allergen-traces-mollusks.svg';
import traceMustard from '../../../../Icons/allergen-traces-mustard.svg';
import traceNuts from '../../../../Icons/allergen-traces-nuts.svg';
import tracePeanuts from '../../../../Icons/allergen-traces-peanuts.svg';
import traceSesame from '../../../../Icons/allergen-traces-sesame-seeds.svg';
import traceSoya from '../../../../Icons/allergen-traces-soy.svg';
import traceSulphites from '../../../../Icons/allergen-traces-sulphur-dioxide.svg';

import '../../../../index.css';
import { InfoProductCart } from './Modal/Modals';
import uppercase from '../../../../services/uppercase/uppercase';

SwiperCore.use([Navigation, Pagination]);

// import { SectionMenuCart } from './Modal/Modals';
const ExpandMore = styled((props) => {
  const {
    /* expand, */ children, onClick, ariaExpanded, ariaLabel, className,
  } = props;
  return (
    <IconButton
      // expand={expand.toString()}
      // eslint-disable-next-line react/no-children-prop
      children={children}
      onClick={onClick}
      aria-expanded={ariaExpanded}
      aria-label={ariaLabel}
      className={className}
    />
  );
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Carta() {
  const shopIdActual = localStorage.getItem('shop_id');
  const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'));
  const { t } = useTranslation();

  const [respuestaAPI, setRespuestaAPI] = React.useState([]);
  const [respuestaAPIMenus, setRespuestaAPIMenus] = React.useState([]);

  const [imagesProduct, setImagesProduct] = React.useState([]);
  const [imagesMenu, setImagesMenu] = React.useState([]);
  const [imagesMenuProducts, setImagesMenuProducts] = React.useState([]);

  const [categoryProducts, setCategoryProdutcs] = React.useState([]);

  const [expandState, setExpandState] = React.useState([]);
  const [allergensMenu, setAllergensMenu] = React.useState([]);

  const [selectProduct, setSelectProduct] = React.useState();
  const [selectImageProduct, setSelectImageProduct] = React.useState();
  const [modalShowProduct, setModalShowProduct] = React.useState(false);

  // const [respuestaAPICategories, setRespuestaAPICategories] = React.useState([]);

  const { width } = useWindowDimensions();

  let contadorProduct = 0;
  let contadorMenu = 0;

  // const [categoriesT, setCategoriesT] = React.useState([]);

  const listImageMenu = [];
  const listImageMenuSectionProduct = [];

  const handleExpandClick = (index) => {
    // expandState.forEach()
    const aux = [...expandState];
    const targetState = aux[index];
    aux[index] = !targetState;
    setExpandState(aux);
  };

  async function fetchImageProduct(idProduct) {
    let image;
    try {
      image = await getImageProduct(datosUsuario.enterprise.id, idProduct);
    } catch (e) {
      image = null;
    }
    return image;
  }

  async function orderProductCategory(categories, products /* , menus */) {
    const mCategoryProducts = [];

    for (let i = 0; i < categories.length; i += 1) {
      const nameCategory = categories[i].name;
      const productsCategory = [];
      const listImageProductPromise = [];
      // if (categories[i].name === 'Menús') {
      //   menus.forEach((menu) => {
      //     productsCategory.push(menu);
      //   });
      // }
      products.forEach((product) => {
        if (categories[i].id === product.categoryId) {
          productsCategory.push(product);

          if (product.pictures.length !== 0) {
            const imagen = fetchImageProduct(product.id);
            listImageProductPromise.push(imagen);
          } else {
            listImageProductPromise.push(null);
          }
        }
      });
      // eslint-disable-next-line no-await-in-loop
      const listImageProduct = await Promise.all(listImageProductPromise);
      mCategoryProducts.push({ name: nameCategory, productsCategory, listImageProduct });
    }

    const productsWithoutCategory = [];
    products.forEach((product) => {
      if (product.categoryId === null) {
        productsWithoutCategory.push(product);
      }
    });
    mCategoryProducts.push({
      name: {
        es: 'Sin Categoría',
        en: 'Without category',
        fr: 'Sans catégorie',
        it: 'Senza categoria',
        de: 'Ohne Kategorie',
        pt: 'Sem categoria',
      },
      productsCategory: productsWithoutCategory,
    });

    return mCategoryProducts;
  }

  function sortProducts(products) {
    products.sort((a, b) => a.listPosition - b.listPosition);
    return products;
  }

  function sortMenus(menus) {
    menus.sort((a, b) => a.listPosition - b.listPosition);
    return menus;
  }

  function sortCategories(categories) {
    categories.sort((a, b) => a.listPosition - b.listPosition);
    return categories;
  }

  async function fetchImageMenu(idMenu) {
    let image;
    try {
      image = await getImageMenu(datosUsuario.enterprise.id, idMenu);
    } catch (e) {
      image = null;
    }
    return image;
  }

  function dataIsNotReady() {
    return (respuestaAPI === undefined
      || (Array.isArray(respuestaAPI) && respuestaAPI.length === 0))
      || (categoryProducts === undefined
        || (Array.isArray(categoryProducts) && categoryProducts.length === 0));
  }

  async function fetchData() {
    const products = sortProducts(
      await getProducts(datosUsuario.enterprise.id, shopIdActual),
    );
    const menus = sortMenus(
      await getMenus(datosUsuario.enterprise.id, shopIdActual),
    );
    const categories = sortCategories(
      await getCategories(datosUsuario.enterprise.id, shopIdActual),
    );

    setRespuestaAPI(products);
    setRespuestaAPIMenus(menus);
    // setRespuestaAPICategories(categories);

    const categoriesProducts = await orderProductCategory(categories, products /* , menus */);
    setCategoryProdutcs(categoriesProducts);

    const listImageProduct = [];
    const listAllergensMenu = [];

    categoriesProducts.forEach((category) => {
      category.productsCategory.forEach((product) => {
        if (category.name !== 'Menús' && product.visible) {
          if (product.pictures.length !== 0) {
            const imagen = fetchImageProduct(product.id);
            listImageProduct.push(imagen);
          } else {
            listImageProduct.push(null);
          }
        } /* else if (product.pictures.length !== 0) {
          console.log(product);//
          const imagen = fetchImageMenu(product.id);
          listImageMenu.push(imagen);
        } */
      });
    });
    setImagesProduct(await Promise.all(listImageProduct));

    menus.forEach((menu) => {
      if (menu.visible) {
        if (menu.pictures.length !== 0) {
          const imagen = fetchImageMenu(menu.id);
          listImageMenu.push(imagen);
        } else {
          listImageMenu.push(null);
        }
      }
    });
    setImagesMenu(await Promise.all(listImageMenu));

    menus.forEach((menu) => {
      menu.products.forEach((product) => {
        if (product.visible) {
          if (product.pictures.length !== 0) {
            const imagen = fetchImageProduct(product.id);
            listImageMenuSectionProduct.push(imagen);
          } else {
            listImageMenuSectionProduct.push(null);
          }
        }
      });
    });
    const imaganenenenene = await Promise.all(listImageMenuSectionProduct);
    setImagesMenuProducts(imaganenenenene);

    menus.forEach((menu) => {
      const auxListAllergensMenu = new Set();
      menu.products.forEach((product) => {
        product.allergens.forEach((allergen) => {
          auxListAllergensMenu.add(allergen.name);
        });
      });
      listAllergensMenu.push(auxListAllergensMenu);
    });
    setAllergensMenu(listAllergensMenu);
  }

  useEffect(() => {
    fetchData();
  }, []);

  function sumContadorProducts() {
    contadorProduct += 1;
  }
  function sumContadorMenus() {
    contadorMenu += 1;
  }
  return (
    <Container style={{ maxWidth: '1200px' }}>
      <Grid container sx={{ marginTop: '15px', marginBottom: '15px', alignItems: 'center' }}>
        <Grid item lg={1}>
          <Link to="/App/InfoLocal/Informacion/PaginaInicialLocal">
            <Button>
              <ArrowBackIcon />
            </Button>
          </Link>
        </Grid>
        <Grid item lg={3}>
          <Typography variant="h6">
            {t('App.InfoLocal.Informacion.Carta.route')}
          </Typography>
        </Grid>
      </Grid>
      <Container style={{ maxWidth: '1000px' }}>
        {
          // MENUSSSSSSS
          // eslint-disable-next-line no-nested-ternary
          respuestaAPIMenus.length === 0 ? <div />
            : (
              width > 720
                ? (
                  <div>
                    {/* <div>
                    <h2>{t('App.InfoLocal.Informacion.Carta.Menu.categoryName')}</h2>
                  </div> */}
                    <Typography variant="h4" sx={{ marginBottom: '4px' }}>
                      {t('App.InfoLocal.Informacion.Carta.Menu.categoryName')}
                    </Typography>
                    <Swiper
                      slidesPerView={2}
                      spaceBetween={30}
                      navigation
                      modules={Navigation}
                    >
                      <Grid container spacing={3}>
                        {respuestaAPIMenus.map((menu, a) => (
                          menu.visible
                          && (
                            // eslint-disable-next-line react/no-array-index-key
                            <SwiperSlide key={a}>
                              <Grid item lg={6} xs={6}>
                                <Card style={{ marginTop: '20px', marginBottom: '20px' }}>
                                  <CardHeader
                                    sx={{ width: '100%' }}
                                    title={
                                      (
                                        <Typography variant="h5" noWrap sx={{ width: '100%' }}>
                                          {menu.name[i18n.language] === null
                                            ? uppercase(menu.name.es)
                                            : uppercase(menu.name[i18n.language])}
                                        </Typography>
                                      )
                                    }
                                  />
                                  {imagesMenu[a] !== null
                                    ? (
                                      <CardMedia
                                        component="img"
                                        height="250"
                                        image={imagesMenu[a]}
                                        alt="imageMenu"
                                        sx={{ objectFit: 'contain', padding: '8px', boxSizing: 'border-box' }}
                                      />
                                    )
                                    : (
                                      <div
                                        style={{
                                          height: '250px',
                                          width: '100%',
                                          backgroundColor: '#ECECEC',
                                        }}
                                      />
                                    )}
                                  <CardContent>
                                    <Grid container spacing={2}>
                                      <Grid item lg={10}>
                                        {menu.description[i18n.language] === null
                                          ? uppercase(menu.description.es)
                                          : uppercase(menu.description[i18n.language])}
                                      </Grid>
                                      <Grid item lg={2} xs={2} style={{ direction: 'rtl' }}>
                                        €
                                        {' '}
                                        {menu.price !== null && String(menu.price).replace('.', ',')}
                                      </Grid>
                                      <Grid item lg={12}>
                                        {allergensMenu[a] === undefined ? <div> </div>
                                          : Array.from(allergensMenu[a]).map((allergens) => {
                                            switch (allergens) {
                                              case 'celery':
                                                return (
                                                  <img
                                                    src={celery}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'crustaceans':
                                                return (
                                                  <img
                                                    src={crustaceans}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'eggs':
                                                return (
                                                  <img
                                                    src={eggs}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'fish':
                                                return (
                                                  <img
                                                    src={fish}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'cereals':
                                                return (
                                                  <img
                                                    src={cereals}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'lupin':
                                                return (
                                                  <img
                                                    src={lupin}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'milk':
                                                return (
                                                  <img
                                                    src={milk}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'molluscs':
                                                return (
                                                  <img
                                                    src={molluscs}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'mustard':
                                                return (
                                                  <img
                                                    src={mustard}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'nuts':
                                                return (
                                                  <img
                                                    src={nuts}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'peanuts':
                                                return (
                                                  <img
                                                    src={peanuts}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'sesame':
                                                return (
                                                  <img
                                                    src={sesame}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'soya':
                                                return (
                                                  <img
                                                    src={soya}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'sulphites':
                                                return (
                                                  <img
                                                    src={sulphites}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_celery':
                                                return (
                                                  <img
                                                    src={traceCelery}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_crustaceans':
                                                return (
                                                  <img
                                                    src={traceCrustaceans}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_eggs':
                                                return (
                                                  <img
                                                    src={traceEggs}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_fish':
                                                return (
                                                  <img
                                                    src={traceFish}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_cereals':
                                                return (
                                                  <img
                                                    src={traceCereals}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_lupin':
                                                return (
                                                  <img
                                                    src={traceLupin}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_milk':
                                                return (
                                                  <img
                                                    src={traceMilk}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_molluscs':
                                                return (
                                                  <img
                                                    src={traceMolluscs}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_mustard':
                                                return (
                                                  <img
                                                    src={traceMustard}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_nuts':
                                                return (
                                                  <img
                                                    src={traceNuts}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_peanuts':
                                                return (
                                                  <img
                                                    src={tracePeanuts}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_sesame':
                                                return (
                                                  <img
                                                    src={traceSesame}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_soya':
                                                return (
                                                  <img
                                                    src={traceSoya}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_sulphites':
                                                return (
                                                  <img
                                                    src={traceSulphites}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              default:
                                                return (
                                                  <img
                                                    src={celery}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                            }
                                          })}
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                  <CardActions disableSpacing>
                                    <ExpandMore
                                      expand={expandState[a]}
                                      onClick={() => handleExpandClick(a)}
                                      ariaExpanded={expandState[a]}
                                      ariaLabel="show more"
                                    >
                                      <ExpandMoreIcon />
                                    </ExpandMore>
                                  </CardActions>
                                  <Collapse in={expandState[a]} timeout="auto" unmountOnExit>
                                    {menu.products.map((product, index) => (
                                      // eslint-disable-next-line react/no-array-index-key
                                      <CardContent key={index}>
                                        <Grid
                                          container
                                          // eslint-disable-next-line react/no-array-index-key
                                          key={index}
                                          style={{
                                            border: '1px solid #EEEEEE',
                                            borderRadius: '10px',
                                            height: '134.5px',
                                          }}
                                        >
                                          <Grid item lg={3} xs={3}>
                                            {imagesMenuProducts[contadorMenu] !== null
                                              ? (
                                                <img
                                                  src={imagesMenuProducts[contadorMenu]}
                                                  style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    borderTopLeftRadius: '10px',
                                                    borderBottomLeftRadius: '10px',
                                                  }}
                                                  alt="imageProduct"
                                                />
                                              )
                                              : (
                                                <div
                                                  style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    backgroundColor: '#ECECEC',
                                                    objectFit: 'cover',
                                                    borderTopLeftRadius: '10px',
                                                    borderBottomLeftRadius: '10px',
                                                  }}
                                                />
                                              )}
                                          </Grid>
                                          <Grid item lg={9} xs={9} style={{ paddingLeft: '20px' }}>
                                            <Grid container spacing={2}>
                                              <Grid item lg={12} xs={12} style={{ marginTop: '25px' }}>
                                                <Typography variant="h6" noWrap>
                                                  {product.name[i18n.language] === null
                                                    ? uppercase(product.name.es)
                                                    : uppercase(product.name[i18n.language])}
                                                </Typography>
                                              </Grid>
                                              <Grid item lg={12} xs={12}>
                                                <Typography
                                                  variant="body1"
                                                  sx={{
                                                    width: '100%',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: '2',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                  }}
                                                >
                                                  {product.description[i18n.language] === null
                                                    ? uppercase(product.description.es)
                                                    : uppercase(product.description[i18n.language])}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        {sumContadorMenus()}
                                      </CardContent>
                                    ))}
                                  </Collapse>
                                </Card>
                              </Grid>
                            </SwiperSlide>
                          )
                        ))}
                      </Grid>
                    </Swiper>
                  </div>
                )
                : (
                  <div>
                    <Container>
                      <h2>{t('App.InfoLocal.Informacion.Carta.Menu.categoryName')}</h2>
                    </Container>
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={30}
                      navigation
                      modules={Navigation}
                    >
                      <Grid container spacing={3}>
                        {respuestaAPIMenus.map((menu, a) => (
                          menu.visible
                          && (
                            // eslint-disable-next-line react/no-array-index-key
                            <SwiperSlide key={a}>
                              <Grid item lg={6} xs={6}>
                                <Card style={{ marginTop: '20px', marginBottom: '20px' }}>
                                  <CardHeader
                                    title={
                                      (
                                        <Typography variant="h5" noWrap sx={{ width: '100%' }}>
                                          {menu.name[i18n.language] === null
                                            ? uppercase(menu.name.es)
                                            : uppercase(menu.name[i18n.language])}
                                        </Typography>
                                      )
                                    }
                                  />
                                  {imagesMenu[a] !== null
                                    ? (
                                      <CardMedia
                                        component="img"
                                        height="194"
                                        image={imagesMenu[a]}
                                        alt="imageMenu"
                                        sx={{ objectFit: 'contain', padding: '8px', boxSizing: 'border-box' }}
                                      />
                                    )
                                    : (
                                      <div
                                        style={{
                                          height: '194px',
                                          width: '100%',
                                          backgroundColor: '#ECECEC',
                                        }}
                                      />
                                    )}
                                  <CardContent>
                                    <Grid container spacing={2}>
                                      <Grid item xs={9}>
                                        <Typography
                                          sx={{
                                            width: '100%',
                                            display: '-webkit-box',
                                            WebkitLineClamp: '4',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          {menu.description[i18n.language] === null
                                            ? uppercase(menu.description.es)
                                            : uppercase(menu.description[i18n.language])}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={3} style={{ direction: 'rtl' }}>
                                        €
                                        {' '}
                                        {menu.price !== null && String(menu.price).replace('.', ',')}
                                      </Grid>
                                      <Grid item xs={12}>
                                        {allergensMenu[a] === undefined ? <div> </div>
                                          : Array.from(allergensMenu[a]).map((allergens) => {
                                            switch (allergens) {
                                              case 'celery':
                                                return (
                                                  <img
                                                    src={celery}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'crustaceans':
                                                return (
                                                  <img
                                                    src={crustaceans}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'eggs':
                                                return (
                                                  <img
                                                    src={eggs}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'fish':
                                                return (
                                                  <img
                                                    src={fish}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'cereals':
                                                return (
                                                  <img
                                                    src={cereals}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'lupin':
                                                return (
                                                  <img
                                                    src={lupin}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'milk':
                                                return (
                                                  <img
                                                    src={milk}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'molluscs':
                                                return (
                                                  <img
                                                    src={molluscs}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'mustard':
                                                return (
                                                  <img
                                                    src={mustard}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'nuts':
                                                return (
                                                  <img
                                                    src={nuts}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'peanuts':
                                                return (
                                                  <img
                                                    src={peanuts}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'sesame':
                                                return (
                                                  <img
                                                    src={sesame}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'soya':
                                                return (
                                                  <img
                                                    src={soya}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'sulphites':
                                                return (
                                                  <img
                                                    src={sulphites}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_celery':
                                                return (
                                                  <img
                                                    src={traceCelery}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_crustaceans':
                                                return (
                                                  <img
                                                    src={traceCrustaceans}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_eggs':
                                                return (
                                                  <img
                                                    src={traceEggs}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_fish':
                                                return (
                                                  <img
                                                    src={traceFish}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_cereals':
                                                return (
                                                  <img
                                                    src={traceCereals}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_lupin':
                                                return (
                                                  <img
                                                    src={traceLupin}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_milk':
                                                return (
                                                  <img
                                                    src={traceMilk}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_molluscs':
                                                return (
                                                  <img
                                                    src={traceMolluscs}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_mustard':
                                                return (
                                                  <img
                                                    src={traceMustard}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_nuts':
                                                return (
                                                  <img
                                                    src={traceNuts}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_peanuts':
                                                return (
                                                  <img
                                                    src={tracePeanuts}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_sesame':
                                                return (
                                                  <img
                                                    src={traceSesame}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_soya':
                                                return (
                                                  <img
                                                    src={traceSoya}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_sulphites':
                                                return (
                                                  <img
                                                    src={traceSulphites}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              default:
                                                return (
                                                  <img
                                                    src={celery}
                                                    style={{ height: 24, width: 24 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                            }
                                          })}
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                  <CardActions disableSpacing>
                                    <ExpandMore
                                      expand={expandState[a]}
                                      onClick={() => handleExpandClick(a)}
                                      ariaExpanded={expandState[a]}
                                      ariaLabel="show more"
                                    >
                                      <ExpandMoreIcon />
                                    </ExpandMore>
                                  </CardActions>
                                  <Collapse in={expandState[a]} timeout="auto" unmountOnExit>
                                    {menu.products.map((product, index) => (
                                      // eslint-disable-next-line react/no-array-index-key
                                      <CardContent key={index} style={{ padding: '4px' }}>
                                        <Grid
                                          container
                                          style={{
                                            border: '1px solid #EEEEEE',
                                            borderRadius: '10px',
                                            height: '84px',
                                          }}
                                        >
                                          <Grid item lg={3} xs={3} style={{ height: '100%' }}>
                                            {imagesMenuProducts[contadorMenu] !== null
                                              ? (
                                                <img
                                                  src={imagesMenuProducts[contadorMenu]}
                                                  style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    borderTopLeftRadius: '10px',
                                                    borderBottomLeftRadius: '10px',
                                                  }}
                                                  alt="imageProduct"
                                                />
                                              )
                                              : (
                                                <div
                                                  style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    backgroundColor: '#ECECEC',
                                                    objectFit: 'cover',
                                                    borderTopLeftRadius: '10px',
                                                    borderBottomLeftRadius: '10px',
                                                  }}
                                                />
                                              )}
                                          </Grid>
                                          <Grid item lg={9} xs={9} style={{ paddingLeft: '20px', height: '100%' }}>
                                            <Grid container style={{ height: '100%' }}>
                                              <Grid item lg={12} xs={12} style={{ marginTop: '2px' }}>
                                                <Typography sx={{ fontSize: '15px', fontWeight: '600' }} noWrap>
                                                  {product.name[i18n.language] === null
                                                    ? uppercase(product.name.es)
                                                    : uppercase(product.name[i18n.language])}
                                                </Typography>
                                              </Grid>
                                              <Grid item lg={12} xs={12} style={{ marginTop: '4px' }}>
                                                <Typography
                                                  sx={{
                                                    fontSize: '14px',
                                                    lineHeight: '1',
                                                    height: '44px',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: '3',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                  }}
                                                >
                                                  {product.description[i18n.language] === null
                                                    ? uppercase(product.description.es)
                                                    : uppercase(product.description[i18n.language])}
                                                </Typography>

                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        {sumContadorMenus()}
                                      </CardContent>
                                    ))}
                                  </Collapse>
                                </Card>
                              </Grid>
                            </SwiperSlide>
                          )
                        ))}
                      </Grid>
                    </Swiper>
                  </div>
                )
            )
        }
        {(dataIsNotReady())
          ? (
            // PRODUCTOSSSSSS
            <div><em>{t('App.InfoLocal.Informacion.Carta.Products.noProducts')}</em></div>
          )
          : categoryProducts.map((category, a) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={a}>
              {(category.name[i18n.language] !== 'Sin Categoría')
                && (
                  width <= 720
                    ? (
                      // eslint-disable-next-line react/no-array-index-key
                      <div style={{ marginTop: '4%' }}>
                        <Typography variant="h5" noWrap sx={{ marginBottom: '4px' }}>
                          {category.name[i18n.language] === null
                            ? uppercase(category.name.es) : uppercase(category.name[i18n.language])}
                        </Typography>
                        {
                          category.productsCategory.map((product, index) => (
                            product.visible
                            && (
                              <div
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                data-rowid={index}
                                onClick={(e) => {
                                  setModalShowProduct(true);
                                  setSelectProduct(category.productsCategory[e.currentTarget.getAttribute('data-rowid')]);
                                  setSelectImageProduct(category.listImageProduct[e.currentTarget.getAttribute('data-rowid')]);
                                }}
                                aria-hidden="true"
                              >
                                <div
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={index}
                                  style={{
                                    marginBottom: '10px', border: '1px solid #EEEEEE', borderRadius: '10px', height: '84px',
                                  }}
                                >
                                  {imagesProduct[contadorProduct] !== null
                                    ? (
                                      <img
                                        src={imagesProduct[contadorProduct]}
                                        style={{
                                          width: 84,
                                          height: 82,
                                          objectFit: 'cover',
                                          borderTopLeftRadius: '10px',
                                          borderBottomLeftRadius: '10px',
                                          float: 'left',
                                        }}
                                        alt="imageProduct"
                                      />
                                    )
                                    : (
                                      <div
                                        style={{
                                          width: 84,
                                          height: 82,
                                          objectFit: 'cover',
                                          borderTopLeftRadius: '10px',
                                          borderBottomLeftRadius: '10px',
                                          backgroundColor: '#ECECEC',
                                          float: 'left',
                                        }}
                                      />
                                    )}
                                  <Grid
                                    container
                                    style={{
                                      width: 'auto', padding: '4px', height: '100%', backgroundColor: 'white',
                                    }}
                                  >
                                    <Grid item lg={9} xs={9}>
                                      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                        <div>
                                          <Typography
                                            sx={{
                                              fontSize: '16px', fontWeight: '600',
                                            }}
                                            noWrap
                                          >
                                            {product.name[i18n.language] === null
                                              ? uppercase(product.name.es)
                                              : uppercase(product.name[i18n.language])}
                                          </Typography>
                                        </div>
                                        <div style={{ flexGrow: 1 }}>
                                          <Typography
                                            sx={{
                                              fontSize: '14px',
                                              lineHeight: '1.1',
                                              display: '-webkit-box',
                                              WebkitLineClamp: '2',
                                              WebkitBoxOrient: 'vertical',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            {product.description[i18n.language] === null
                                              ? uppercase(product.description.es)
                                              : uppercase(product.description[i18n.language])}
                                          </Typography>
                                        </div>
                                        <div
                                          style={{
                                            display: '-webkit-box',
                                            WebkitLineClamp: '1',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          {product.allergens.map((allergens) => {
                                            switch (allergens.name) {
                                              case 'celery':
                                                return (
                                                  <img
                                                    src={celery}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'crustaceans':
                                                return (
                                                  <img
                                                    src={crustaceans}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'eggs':
                                                return (
                                                  <img
                                                    src={eggs}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'fish':
                                                return (
                                                  <img
                                                    src={fish}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'cereals':
                                                return (
                                                  <img
                                                    src={cereals}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'lupin':
                                                return (
                                                  <img
                                                    src={lupin}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'milk':
                                                return (
                                                  <img
                                                    src={milk}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'molluscs':
                                                return (
                                                  <img
                                                    src={molluscs}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'mustard':
                                                return (
                                                  <img
                                                    src={mustard}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'nuts':
                                                return (
                                                  <img
                                                    src={nuts}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'peanuts':
                                                return (
                                                  <img
                                                    src={peanuts}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'sesame':
                                                return (
                                                  <img
                                                    src={sesame}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'soya':
                                                return (
                                                  <img
                                                    src={soya}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'sulphites':
                                                return (
                                                  <img
                                                    src={sulphites}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_celery':
                                                return (
                                                  <img
                                                    src={traceCelery}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_crustaceans':
                                                return (
                                                  <img
                                                    src={traceCrustaceans}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_eggs':
                                                return (
                                                  <img
                                                    src={traceEggs}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_fish':
                                                return (
                                                  <img
                                                    src={traceFish}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_cereals':
                                                return (
                                                  <img
                                                    src={traceCereals}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_lupin':
                                                return (
                                                  <img
                                                    src={traceLupin}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_milk':
                                                return (
                                                  <img
                                                    src={traceMilk}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_molluscs':
                                                return (
                                                  <img
                                                    src={traceMolluscs}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_mustard':
                                                return (
                                                  <img
                                                    src={traceMustard}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_nuts':
                                                return (
                                                  <img
                                                    src={traceNuts}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_peanuts':
                                                return (
                                                  <img
                                                    src={tracePeanuts}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_sesame':
                                                return (
                                                  <img
                                                    src={traceSesame}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_soya':
                                                return (
                                                  <img
                                                    src={traceSoya}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_sulphites':
                                                return (
                                                  <img
                                                    src={traceSulphites}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              default:
                                                return (
                                                  <img
                                                    src={celery}
                                                    style={{ height: 16, width: 16 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                            }
                                          })}
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item lg={3} xs={3}>
                                      <Grid container spacing={2}>
                                        <Grid item lg={12} xs={12} style={{ direction: 'rtl' }}>
                                          €
                                          {' '}
                                          {product.price !== null && String(product.price).replace('.', ',')}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    {sumContadorProducts()}
                                  </Grid>
                                </div>
                              </div>
                            )
                          ))
                        }
                      </div>
                    )
                    : (
                      // eslint-disable-next-line react/no-array-index-key
                      <div style={{ marginTop: '4%' }}>
                        <Typography variant="h4" noWrap sx={{ textTransform: 'capitalize' }}>
                          {category.name[i18n.language] === null
                            ? uppercase(category.name.es) : uppercase(category.name[i18n.language])}
                        </Typography>
                        {
                          category.productsCategory.map((product, index) => (
                            product.visible
                            && (
                              <div
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                data-rowid={index}
                                onClick={(e) => {
                                  setModalShowProduct(true);
                                  setSelectProduct(category.productsCategory[e.currentTarget.getAttribute('data-rowid')]);
                                  setSelectImageProduct(category.listImageProduct[e.currentTarget.getAttribute('data-rowid')]);
                                }}
                                aria-hidden="true"
                              >
                                <div
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={index}
                                  style={{
                                    marginBottom: '10px', border: '1px solid #EEEEEE', borderRadius: '10px', height: '154px',
                                  }}
                                >
                                  {imagesProduct[contadorProduct] !== null
                                    ? (
                                      <img
                                        src={imagesProduct[contadorProduct]}
                                        style={{
                                          width: 150,
                                          height: 152,
                                          objectFit: 'cover',
                                          borderTopLeftRadius: '10px',
                                          borderBottomLeftRadius: '10px',
                                          float: 'left',
                                        }}
                                        alt="imageProduct"
                                      />
                                    )
                                    : (
                                      <div
                                        style={{
                                          width: 150,
                                          height: 152,
                                          objectFit: 'cover',
                                          borderTopLeftRadius: '10px',
                                          borderBottomLeftRadius: '10px',
                                          backgroundColor: '#ECECEC',
                                          float: 'left',
                                        }}
                                      />
                                    )}
                                  <Grid
                                    container
                                    style={{ width: 'auto', padding: '16px', backgroundColor: 'white' }}
                                  >
                                    <Grid item lg={11} xs={11}>
                                      <Grid container>
                                        <Grid item lg={12} xs={12} style={{ fontSize: '16px' }}>
                                          <Typography variant="h5" noWrap>
                                            {product.name[i18n.language] === null
                                              ? uppercase(product.name.es)
                                              : uppercase(product.name[i18n.language])}
                                          </Typography>
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                          <Typography
                                            variant="body1"
                                            sx={{
                                              marginTop: '6px',
                                              wordWrap: 'break-word',
                                              textOverflow: 'ellipsis',
                                              height: '50px',
                                              lineHeight: '1.1',
                                              display: '-webkit-box',
                                              WebkitLineClamp: '2',
                                              WebkitBoxOrient: 'vertical',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            {product.description[i18n.language] === null
                                              ? uppercase(product.description.es)
                                              : uppercase(product.description[i18n.language])}
                                          </Typography>
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                          {product.allergens.map((allergens) => {
                                            switch (allergens.name) {
                                              case 'celery':
                                                return (
                                                  <img
                                                    src={celery}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'crustaceans':
                                                return (
                                                  <img
                                                    src={crustaceans}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'eggs':
                                                return (
                                                  <img
                                                    src={eggs}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'fish':
                                                return (
                                                  <img
                                                    src={fish}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'cereals':
                                                return (
                                                  <img
                                                    src={cereals}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'lupin':
                                                return (
                                                  <img
                                                    src={lupin}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'milk':
                                                return (
                                                  <img
                                                    src={milk}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'molluscs':
                                                return (
                                                  <img
                                                    src={molluscs}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'mustard':
                                                return (
                                                  <img
                                                    src={mustard}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'nuts':
                                                return (
                                                  <img
                                                    src={nuts}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'peanuts':
                                                return (
                                                  <img
                                                    src={peanuts}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'sesame':
                                                return (
                                                  <img
                                                    src={sesame}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'soya':
                                                return (
                                                  <img
                                                    src={soya}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 'sulphites':
                                                return (
                                                  <img
                                                    src={sulphites}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_celery':
                                                return (
                                                  <img
                                                    src={traceCelery}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_crustaceans':
                                                return (
                                                  <img
                                                    src={traceCrustaceans}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_eggs':
                                                return (
                                                  <img
                                                    src={traceEggs}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_fish':
                                                return (
                                                  <img
                                                    src={traceFish}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_cereals':
                                                return (
                                                  <img
                                                    src={traceCereals}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_lupin':
                                                return (
                                                  <img
                                                    src={traceLupin}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_milk':
                                                return (
                                                  <img
                                                    src={traceMilk}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_molluscs':
                                                return (
                                                  <img
                                                    src={traceMolluscs}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_mustard':
                                                return (
                                                  <img
                                                    src={traceMustard}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_nuts':
                                                return (
                                                  <img
                                                    src={traceNuts}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_peanuts':
                                                return (
                                                  <img
                                                    src={tracePeanuts}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_sesame':
                                                return (
                                                  <img
                                                    src={traceSesame}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_soya':
                                                return (
                                                  <img
                                                    src={traceSoya}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              case 't_sulphites':
                                                return (
                                                  <img
                                                    src={traceSulphites}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                              default:
                                                return (
                                                  <img
                                                    src={celery}
                                                    style={{ height: 32, width: 32 }}
                                                    key={allergens.id}
                                                    alt="allergen"
                                                  />
                                                );
                                            }
                                          })}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item lg={1} xs={1}>
                                      <Grid container spacing={2}>
                                        <Grid item lg={12} xs={12} style={{ direction: 'rtl' }}>
                                          €
                                          {' '}
                                          {product.price !== null && String(product.price).replace('.', ',')}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    {sumContadorProducts()}
                                  </Grid>
                                </div>
                              </div>
                            )
                          ))
                        }
                      </div>
                    )
                )}
            </div>
          ))}
        <InfoProductCart
          show={modalShowProduct}
          onHide={() => setModalShowProduct(false)}
          product={selectProduct}
          image={selectImageProduct}
          // eslint-disable-next-line react/jsx-no-bind
          fetchdata={fetchData}
          width={width}
          language={i18n.language}
        />
      </Container>
    </Container>
  );
}

export default Carta;
