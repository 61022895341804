import {
  Button, Card, CardContent, Container, Grid, Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';

import React, { useEffect } from 'react';
import { Link, useLocation /* useHistory */ } from 'react-router-dom';
import { /* getImageShop, */ getShop } from '../../../../services/mesonero-api/api';

export default function PaginaInicialLocal() {
  const [shopNow, setShop] = React.useState([]);
  const location = useLocation();
  const { t } = useTranslation();

  async function fetchData() {
    const shopIdActual = localStorage.getItem('shop_id');
    const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'));

    const shop = await getShop(datosUsuario.enterprise.id, shopIdActual);
    setShop(shop);
    // const image = fetchImageShop(datosUsuario.enterprise.id, shopIdActual);
    // setImageShop(await image);
  }

  useEffect(() => {
    if (location.state !== undefined) {
      localStorage.setItem('shop_id', location.state);
    }
    fetchData();
  }, []);

  return (
    <Container style={{ maxWidth: '1200px' }}>
      <Grid container sx={{ marginTop: '15px', marginBottom: '15px', alignItems: 'center' }}>
        <Grid item lg={1}>
          <Link to="/App/Cuenta/Locales">
            {/* <Button
            onClick={() => history.goBack()}
          > */}
            <ArrowBackIcon />
            {/* </Button> */}
          </Link>
        </Grid>
        <Grid item lg={3}>
          <Typography variant="h6">
            {t('App.InfoLocal.Informacion.PaginaInicialLocal.route')}
          </Typography>
        </Grid>
      </Grid>
      <Container style={{ maxWidth: '1000px' }}>
        <Grid container sx={{ marginTop: '10px', marginBottom: '10px', alignItems: 'center' }}>
          <Grid item lg={3}>
            <Typography variant="h5">
              {shopNow.name}
            </Typography>
          </Grid>
          <Grid item lg={3}>
            <Typography variant="h6">
              {t('App.InfoLocal.Informacion.PaginaInicialLocal.title')}
            </Typography>
          </Grid>
        </Grid>
        <Card sx={{ marginTop: '10px', marginBottom: '10px' }}>
          <CardContent>
            <Grid container rowSpacing={2} sx={{ marginTop: '5px', marginBottom: '5px' }}>
              <Grid item lg={3} xs={6}>
                <Link to="/App/InfoLocal/Informacion/Carta">
                  <Button variant="contained" style={{ width: '90%' }}>
                    {t('App.InfoLocal.Informacion.PaginaInicialLocal.buttonCard')}
                  </Button>
                </Link>
              </Grid>
              <Grid item lg={3} xs={6}>
                <Link to="/App/InfoLocal/Informacion/Productos">
                  <Button variant="contained" style={{ width: '90%', height: '100%' }}>
                    {t('App.InfoLocal.Informacion.PaginaInicialLocal.buttonProducts')}
                  </Button>
                </Link>
              </Grid>
              <Grid item lg={3} xs={6}>
                <Link to="/App/InfoLocal/Informacion/Menus">
                  <Button variant="contained" style={{ width: '90%', height: '100%' }}>
                    {t('App.InfoLocal.Informacion.PaginaInicialLocal.buttonMenus')}
                  </Button>
                </Link>
              </Grid>
              <Grid item lg={3} xs={6}>
                <Link to="/App/InfoLocal/Informacion/Categoria">
                  <Button variant="contained" style={{ width: '90%' }}>
                    {t('App.InfoLocal.Informacion.PaginaInicialLocal.buttonCategories')}
                  </Button>
                </Link>
              </Grid>
              <Grid item lg={3} xs={6}>
                <Link to="/App/InfoLocal/Informacion/GenerarQr">
                  <Button variant="contained" style={{ width: '90%' }}>
                    {t('App.InfoLocal.Informacion.PaginaInicialLocal.buttonQR')}
                  </Button>
                </Link>
              </Grid>
              <Grid item lg={3} xs={6}>
                <Link to="/App/InfoLocal/Informacion/PerfilLocal">
                  <Button variant="contained" style={{ width: '90%', height: '100%' }}>
                    {t('App.InfoLocal.Informacion.PaginaInicialLocal.buttonInfoLocal')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Container>
  );
}
