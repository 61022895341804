import React from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
// import {
//   Card, Container, Form, Button, Col, Row,
// } from 'react-bootstrap';
import {
  Container, Card, CardContent, FormControlLabel,
  Grid, FormGroup, /* FormLabel, */ Button, TextField, Checkbox, FormControl,
  InputLabel, MenuItem, Select,
} from '@mui/material';
import { toast } from 'react-toastify';

import { Link, useHistory } from 'react-router-dom';
import { register } from '../../services/mesonero-api/api';

// import resizeFile from '../../services/resizeImage/ResizeImage';

function CustomizedSelectForFormik({ children, form, field }) {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      name={name}
      value={value}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
        if (e.target.value === 'Autonomo') {
          setFieldValue('enterprise', form.values.name);
          setFieldValue('enterprisePhoneNumber', form.values.phoneNumber);
          setFieldValue('enterpriseAddress', form.values.address);
          setFieldValue('enterpriseCountry', form.values.country);
          setFieldValue('enterpriseProvince', form.values.province);
          setFieldValue('enterprisePostalCode', form.values.postalCode);
        }
      }}
    >
      {children}
    </Select>
  );
}

function Registrarse() {
  // const [displayValue, setdisplayValue] = React.useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Container style={{ marginTop: '2%', marginBottom: '2%' }}>
      <Card className="card-register">
        <CardContent>
          <h2 style={{ textAlign: 'center' }}>{t('paginaprincipal.registrar.title')}</h2>
          <hr />
        </CardContent>
        <CardContent>
          <Formik
            initialValues={{
              name: '',
              surname: '',
              email: '',
              password: '',
              passwordr: '',
              phoneNumber: '',

              enterprise: '',
              legalForm: 'Select',
              passportEnterprise: '',
              enterprisePhoneNumber: '',
              website: '',
              enterpriseAddress: '',
              enterpriseCountry: '',
              enterpriseProvince: '',
              enterprisePostalCode: '',

              file: null,
              term: false,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required(t('paginaprincipal.registrar.form.yup.required.user.name')),
              surname: Yup.string().required(t('paginaprincipal.registrar.form.yup.required.user.surname')),
              email: Yup.string().email(t('paginaprincipal.registrar.form.yup.invalid.email')).required(t('paginaprincipal.registrar.form.yup.required.user.email')),
              phoneNumber: Yup.string().matches(/^(\+34|0034|34)?[6789]\d{8}$/, 'Debe ser un número de teléfono').required('Required'),
              password: Yup.string().min(8, t('paginaprincipal.registrar.form.yup.invalid.password')).required(t('paginaprincipal.registrar.form.yup.required.user.password')),
              passwordr: Yup.string()
                .oneOf([Yup.ref('password'), null], t('paginaprincipal.registrar.form.yup.invalid.repeatPassword')),

              enterprise: Yup.string().required(t('paginaprincipal.registrar.form.yup.required.enterprise.enterprise')),
              passportEnterprise: Yup.string().required('Required'),
              enterprisePhoneNumber: Yup.string().matches(/^(\+34|0034|34)?[6789]\d{8}$/, 'Debe ser un número de teléfono').required(t('paginaprincipal.registrar.form.yup.required.enterprise.passportEnterprise')),
              enterpriseAddress: Yup.string().required(t('paginaprincipal.registrar.form.yup.required.enterprise.enterpriseAddress')),
              enterpriseCountry: Yup.string().required(t('paginaprincipal.registrar.form.yup.required.enterprise.enterpriseCountry')),
              enterpriseProvince: Yup.string().required(t('paginaprincipal.registrar.form.yup.required.enterprise.enterpriseProvince')),
              enterprisePostalCode: Yup.string().matches(/^[0-9]\d{4}/, 'El valor debe ser un código postal').required(t('paginaprincipal.registrar.form.yup.required.enterprise.enterprisePostalCode')),

              term: Yup.bool().required().oneOf([true], t('paginaprincipal.registrar.form.yup.invalid.term')),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                const data = {
                  user: {
                    provider: 'web',
                    name: values.name,
                    surname: values.surname,
                    email: values.email,
                    phoneNumber: values.phoneNumber,
                    password: values.password,
                  },
                  enterprise: {
                    name: values.enterprise,
                    legalForm: values.legalForm,
                    passport: values.passportEnterprise,
                    phoneNumber: values.enterprisePhoneNumber,
                    website: values.website,
                    address: values.enterpriseAddress,
                    country: values.enterpriseCountry,
                    province: values.enterpriseProvince,
                    postalCode: values.enterprisePostalCode,
                  },
                };
                // console.log(values.legalForm);
                const consultaAPI = async () => {
                  try {
                    const dataRegister = await register(data);
                    if (dataRegister.status === 200) {
                      toast.success('Se ha registrado satisfactoriamente');
                      const location = {
                        pathname: '/Tarifa',
                      };
                      history.push(location);
                    }
                  } catch (error) {
                    console.log(error);
                  }
                };
                consultaAPI();

                setSubmitting(false);
              }, 250);
            }}
          >
            {(props) => {
              const {
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                // setFieldValue,
                /* y otras más */
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item lg={4} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>Nombre</Form.Label> */}
                        <TextField
                          label={t('paginaprincipal.registrar.form.user.name')}
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={8} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>Apellidos</Form.Label> */}
                        <TextField
                          label={t('paginaprincipal.registrar.form.user.surname')}
                          name="surname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.surname}
                          error={touched.surname && Boolean(errors.surname)}
                          helperText={touched.surname && errors.surname}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>Correo Electrónico</Form.Label> */}
                        <TextField
                          label={t('paginaprincipal.registrar.form.user.email')}
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>Número de Teléfono</Form.Label> */}
                        <TextField
                          label={t('paginaprincipal.registrar.form.user.phoneNumber')}
                          name="phoneNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phoneNumber}
                          error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>Contraseña</Form.Label> */}
                        <TextField
                          label={t('paginaprincipal.registrar.form.user.password')}
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>Repetir Contraseña</Form.Label> */}
                        <TextField
                          label={t('paginaprincipal.registrar.form.user.repeatPassword')}
                          type="password"
                          name="passwordr"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.passwordr}
                          error={touched.passwordr && Boolean(errors.passwordr)}
                          helperText={touched.passwordr && errors.passwordr}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>Nombre de Empresa</Form.Label> */}
                        <TextField
                          label={t('paginaprincipal.registrar.form.enterprise.enterprise')}
                          name="enterprise"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.enterprise}
                          error={touched.enterprise && Boolean(errors.enterprise)}
                          helperText={touched.enterprise && errors.enterprise}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={3} xs={12}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">{t('paginaprincipal.registrar.form.enterprise.legalForm.title')}</InputLabel>
                          <Field
                            name="legalForm"
                            component={CustomizedSelectForFormik}
                            label="Selecciona la Categoría"
                          >
                            <MenuItem value="Select">{t('paginaprincipal.registrar.form.enterprise.legalForm.defaultValue')}</MenuItem>
                            <MenuItem value="Autonomo">
                              {t('paginaprincipal.registrar.form.enterprise.legalForm.firstValue')}
                            </MenuItem>
                            <MenuItem value="Sociedad">
                              {t('paginaprincipal.registrar.form.enterprise.legalForm.secondValue')}
                            </MenuItem>
                          </Field>
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    {/* style={{ display: values.legalForm === 'Autonomo'
                    || values.legalForm === 'Select' ? 'none' : 'block' }}  */}
                    <Grid item lg={3} xs={12}>
                      <FormGroup>
                        <TextField
                          label={values.legalForm === 'Sociedad' ? t('paginaprincipal.registrar.form.enterprise.passportEnterprise') : 'DNI'}
                          name="passportEnterprise"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.passportEnterprise}
                          error={touched.passportEnterprise && Boolean(errors.passportEnterprise)}
                          helperText={touched.passportEnterprise && errors.passportEnterprise}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={4} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>Teléfono de Contacto Empresa</Form.Label> */}
                        <TextField
                          label={t('paginaprincipal.registrar.form.enterprise.enterprisePhoneNumber')}
                          name="enterprisePhoneNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.enterprisePhoneNumber}
                          error={touched.enterprisePhoneNumber
                            && Boolean(errors.enterprisePhoneNumber)}
                          helperText={touched.enterprisePhoneNumber && errors.enterprisePhoneNumber}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={8} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>Web Empresarial</Form.Label> */}
                        <TextField
                          label={t('paginaprincipal.registrar.form.enterprise.website')}
                          name="website"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.website}
                          error={touched.website && Boolean(errors.website)}
                          helperText={touched.website && errors.website}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>Dirección Empresa</Form.Label> */}
                        <TextField
                          label={t('paginaprincipal.registrar.form.enterprise.enterpriseAddress')}
                          name="enterpriseAddress"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.enterpriseAddress}
                          error={touched.enterpriseAddress && Boolean(errors.enterpriseAddress)}
                          helperText={touched.enterpriseAddress && errors.enterpriseAddress}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={4} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>País Empresa</Form.Label> */}
                        <TextField
                          label={t('paginaprincipal.registrar.form.enterprise.enterpriseCountry')}
                          name="enterpriseCountry"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.enterpriseCountry}
                          error={touched.enterpriseCountry && Boolean(errors.enterpriseCountry)}
                          helperText={touched.enterpriseCountry && errors.enterpriseCountry}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={5} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>Provincia Empresa</Form.Label> */}
                        <TextField
                          label={t('paginaprincipal.registrar.form.enterprise.enterpriseProvince')}
                          name="enterpriseProvince"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.enterpriseProvince}
                          error={touched.enterpriseProvince && Boolean(errors.enterpriseProvince)}
                          helperText={touched.enterpriseProvince && errors.enterpriseProvince}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={3} xs={12}>
                      <FormGroup>
                        {/* <Form.Label>Código Postal Empresa</Form.Label> */}
                        <TextField
                          label={t('paginaprincipal.registrar.form.enterprise.enterprisePostalCode')}
                          name="enterprisePostalCode"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.enterprisePostalCode}
                          error={touched.enterprisePostalCode
                            && Boolean(errors.enterprisePostalCode)}
                          helperText={touched.enterprisePostalCode && errors.enterprisePostalCode}
                        />
                      </FormGroup>
                    </Grid>
                    {/* </div> */}

                    {/* <Grid item lg={12} xs={12}>
                      <FormGroup className="position-relative mb-3">
                        <FormLabel>Foto de Perfil</FormLabel>
                        <input
                          id="file"
                          type="file"
                          name="file"
                          label="Añadir Foto del Producto"
                          onChange={async (event) => {
                            const image = await resizeFile(event.target.files[0]);
                            setFieldValue('file', image);
                          }}
                          className="form-control"
                        />
                      </FormGroup>
                    </Grid> */}

                    <Grid item lg={12} xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={values.term} />}
                          name="term"
                          label={t('paginaprincipal.registrar.form.termCondition')}
                          onChange={handleChange}
                          error={touched.term && Boolean(errors.term)}
                          id="validationFormik0"
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={6} xs={6}>
                      <FormGroup>
                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Loading' : t('paginaprincipal.registrar.form.next')}
                        </Button>
                      </FormGroup>
                    </Grid>

                    <Grid item lg={6} xs={6}>
                      <FormGroup>
                        <Link to="/">
                          <Button variant="contained" style={{ width: '100%' }}>
                            {t('paginaprincipal.registrar.form.comeBack')}
                          </Button>
                        </Link>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
}

export default Registrarse;
