import React from 'react';
import { toast } from 'react-toastify';

import {
  Button,
  Card, CardContent, Container, FormGroup, Grid, TextField, Typography,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { postForgotPass } from '../../services/mesonero-api/api';

export default function ForgetPassword() {
  const { t } = useTranslation();

  return (
    <Container style={{ marginTop: '2%', marginBottom: '2%', maxWidth: '500px' }}>
      <Card className="card-login">
        <CardContent>
          <Typography variant="h4" align="center">
            Verifica tu correo
          </Typography>
          <hr />
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup
                .string(t('paginaprincipal.login.form.yup.email'))
                .email(t('paginaprincipal.login.form.yup.invalid.email'))
                .required(t('paginaprincipal.login.form.yup.required.email')),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                const data = {
                  email: values.email,
                };
                const consultaAPI = async () => {
                  try {
                    const consulta = await postForgotPass(data);
                    if (consulta.status === 200) {
                      toast.success('Se ha enviado un correo a la dirección de su perfil');
                    }
                  } catch (error) {
                    toast.error('Ha habido un problema para enviar el mensaje, espere un momento y vuelva a intentarlo');
                  }
                };
                consultaAPI();
                setSubmitting(false);
              }, 250);
            }}
          >
            {(props) => {
              const {
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* y otras más */
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid item lg={12} xs={12} style={{ paddingTop: '12px' }}>
                      <FormGroup>
                        {/* <FormLabel>Usuario</FormLabel> */}
                        <TextField
                          label={t('paginaprincipal.login.form.email')}
                          id="email"
                          name="email"
                          placeholder="Correo Electrónico"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={12} xs={12} style={{ paddingTop: '12px' }}>
                      <FormGroup>
                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                          {/*
                           Esta parte no esta traducida
                           */}
                          {isSubmitting ? 'Loading' : 'Enviar'}
                        </Button>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
}
