import React, { useEffect } from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
import { Container, Grid, Typography } from '@mui/material';
// import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SuccessDisplay from '../../services/paySubscription/SuccessPage';

// import { Link } from 'react-router-dom';
import Tarifa1 from '../../services/paySubscription/Tarifa1';
// import Tarifa2 from '../../services/paySubscription/Tarifa2';
// import Tarifa3 from '../../services/paySubscription/Tarifa3';

function Tarifa() {
  const { t } = useTranslation();

  // const location = useLocation();
  // const [dataRegister, setDataRegister] = React.useState();

  // useEffect(() => {
  //   setDataRegister(location.state);
  // }, [location]);

  return (
    <div id="inner">
      <Container style={{ marginTop: '2%', marginBottom: '2%' }}>
        <Typography variant="h4" align="center" style={{ marginBottom: '2%' }}>
          {t('paginaentrada.tarifa.title')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            <Tarifa1 />
          </Grid>
          {/* <Grid item lg={4}>
            <Tarifa2 />
          </Grid>
          <Grid item lg={4}>
            <Tarifa3 />
          </Grid> */}
        </Grid>
      </Container>
    </div>
  );
}

export default function Pay() {
  const [message, setMessage] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [sessionId, setSessionId] = React.useState('');

  // async function Register(data) {
  //   console.log('Registrandooooooooooo');
  //   await register(data);
  // }

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    // (query.entries()).forEach((kv) => {

    // });
    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
      // Register(dataRegister);
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        'Order canceled -- continue to shop around and checkout when you are ready.',
      );
    }
  }, [sessionId]);

  if (!success && message === '') {
    return <Tarifa />;
  } if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  }
  return <Tarifa />;
}
