import React, { useEffect /* useRef */ } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
// import {
//   Card, Container, Form, Button, Row, Col, /* Table */
// } from 'react-bootstrap';
import {
  Container, Card, CardContent,
  Grid, Button, FormGroup, TextField, FormLabel,
  FormControl, InputLabel, Select, MenuItem, FormControlLabel,
  Checkbox, RadioGroup, Radio, Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// import { AddCategoryModal
// /* DeleteAttributeInGroup, UpdateAttributeInGroup */ } from '../Carta/Modal/Modals';
import { addPictureProduct, addProduct, getCategories } from '../../../../services/mesonero-api/api';
import allergens from '../../../../utils/alergens.json';
import traceAllergens from '../../../../utils/traceAlergens.json';
import resizeFile from '../../../../services/resizeImage/ResizeImage';
// import { AddAttributeInGroup, NewAddAttributeInGroup } from '../Carta/Modal/Modals';
// import { Section } from '../Carta/Modal/Modals';

import celery from '../../../../Icons/allergen-celery.svg';
import crustaceans from '../../../../Icons/allergen-crustaceans.svg';
import eggs from '../../../../Icons/allergen-eggs.svg';
import fish from '../../../../Icons/allergen-fish.svg';
import cereals from '../../../../Icons/allergen-gluten.svg';
import lupin from '../../../../Icons/allergen-lupin.svg';
import milk from '../../../../Icons/allergen-milk.svg';
import molluscs from '../../../../Icons/allergen-mollusks.svg';
import mustard from '../../../../Icons/allergen-mustard.svg';
import nuts from '../../../../Icons/allergen-nuts.svg';
import peanuts from '../../../../Icons/allergen-peanuts.svg';
import sesame from '../../../../Icons/allergen-sesame-seeds.svg';
import soya from '../../../../Icons/allergen-soy.svg';
import sulphites from '../../../../Icons/allergen-sulphur-dioxide.svg';

import traceCelery from '../../../../Icons/allergen-traces-celery.svg';
import traceCrustaceans from '../../../../Icons/allergen-traces-crustaceans.svg';
import traceEggs from '../../../../Icons/allergen-traces-eggs.svg';
import traceFish from '../../../../Icons/allergen-traces-fish.svg';
import traceCereals from '../../../../Icons/allergen-traces-gluten.svg';
import traceLupin from '../../../../Icons/allergen-traces-lupin.svg';
import traceMilk from '../../../../Icons/allergen-traces-milk.svg';
import traceMolluscs from '../../../../Icons/allergen-traces-mollusks.svg';
import traceMustard from '../../../../Icons/allergen-traces-mustard.svg';
import traceNuts from '../../../../Icons/allergen-traces-nuts.svg';
import tracePeanuts from '../../../../Icons/allergen-traces-peanuts.svg';
import traceSesame from '../../../../Icons/allergen-traces-sesame-seeds.svg';
import traceSoya from '../../../../Icons/allergen-traces-soy.svg';
import traceSulphites from '../../../../Icons/allergen-traces-sulphur-dioxide.svg';

import checkNoAuthError from '../../../../services/checkNoAuthError/checkNoAuthError';

import es from '../../../../Banderas/es.svg';
// import { ErrorSameNameModalProduct } from '../Carta/Modal/Modals';

function CustomizedSelectForFormik({ children, form, field }) {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      name={name}
      value={value}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
      }}
    >
      {children}
    </Select>
  );
}

function Formulario() {
  // const [modalShow, setModalShow] = React.useState(false);
  const [respuestaAPI, setRespuestaAPI] = React.useState([]);
  const [language, setLanguage] = React.useState('es');

  // const [showErrorSameNameModal, setShowErrorSameNameModal] = React.useState(false);
  const { t } = useTranslation();

  // const [modificatorGroups, setModificatorGroups] = React.useState([]);
  // const [groups, setGroups] = React.useState([]);
  // const [nameGroup, setNameGroup] = React.useState('');

  // const [deleteAttributeShow, setDeleteAttributeShow] = React.useState(false);
  // const [updateAttributeShow, setUpdateAttributeShow] = React.useState(false);

  // const [showAttribute, setShowAttribute] = React.useState(false);

  // const [displayValue, setdisplayValue] = React.useState('');

  const shopIdActual = localStorage.getItem('shop_id');
  const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'));

  // function validationDataGroupAttributes() {
  //   setGroups([...groups, { nameGroup: nameGroup, attributes: modificatorGroups }]);
  //   setModificatorGroups([]);
  //   setNameGroup([]);
  // }

  // const formRef = useRef();

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const categories = await getCategories(datosUsuario.enterprise.id, shopIdActual, language);
      setRespuestaAPI(categories);
    };
    fetchData();
  }, []);

  const changeLanguage = (event) => {
    setLanguage(event.target.value);
  };
  return (
    <Container style={{ maxWidth: '1200px' }}>
      <Grid container sx={{ marginTop: '15px', marginBottom: '15px', alignItems: 'center' }}>
        <Grid item lg={1}>
          <Link to="/App/InfoLocal/Informacion/Productos">
            <Button>
              <ArrowBackIcon />
            </Button>
          </Link>
        </Grid>
        <Grid item lg={5}>
          <Typography variant="h6">
            {t('App.InfoLocal.Informacion.AñadirProducto.route')}
          </Typography>
        </Grid>
      </Grid>
      <Container style={{ maxWidth: '1000px' }}>
        <Card style={{ backgroundColor: '#F8F8F8' }}>
          {/* <Card.Header bsPrefix="card-header-custom"
        // style={{ textAlign: 'center' }}><h1>Añadir Datos del Producto</h1></Card.Header> */}
          <CardContent>
            <h2 style={{ textAlign: 'center' }}>{t('App.InfoLocal.Informacion.AñadirProducto.title')}</h2>
          </CardContent>
          <CardContent>
            <Formik
              initialValues={{
                name: '',
                category: 0,
                description: '',
                ingredients: '',
                allergens: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                price: '',
                date: '',
                hour: '',
                file: null,

                // atributeGroupName: ''
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required(t('App.InfoLocal.Informacion.AñadirProducto.form.yup.required.name')),
                // description: Yup.string().required(t('App.InfoLocal.Informacion.AñadirProducto.
                // form.yup.required.description')),
                price: Yup.string().matches(/^[0-9]+([,.][0-9]+)?$/, 'El valor debe ser un número').required(t('App.InfoLocal.Informacion.AñadirProducto.form.yup.required.price')),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                  const product = {
                    name:
                      { es: values.name },
                    description:
                      { es: values.description },
                    ingredients:
                      { es: values.ingredients },
                  };
                  const allergensNumber = [];
                  for (let i = 0; i < values.allergens.length; i += 1) {
                    if (values.allergens[i] === 1) {
                      allergensNumber.push(i + 1);
                    }
                  }
                  const priceProduct = values.price.replace(',', '.');
                  // const finishDate = new Date(values.date + ':' + values.hour);
                  const data = {
                    product: {
                      name: product.name,
                      description: product.description,
                      ingredients: product.ingredients,
                      price: Number(priceProduct),
                      categoryId: values.category === '' ? null : values.category,
                      shopId: parseInt(shopIdActual, 10),
                      // finish_date: finishDate.toUTCString()
                      allergens: allergensNumber,
                    },
                  };
                  const consultaAPI = async () => {
                    try {
                      const dataProduct = await addProduct(data);
                      if (dataProduct.status === 200) {
                        toast.success(t('App.InfoLocal.Informacion.AñadirProducto.form.toast.addDataProduct'));
                        if (values.file !== null) {
                          const formData = new FormData();
                          formData.append('productId', dataProduct.data.product.id);
                          formData.append('picture', values.file);
                          await addPictureProduct(formData, dataProduct.data.product.id);
                          toast.success(t('App.InfoLocal.Informacion.AñadirProducto.form.toast.addPictureProduct'));
                        }
                        resetForm({
                          name: '',
                          category: 0,
                          description: '',
                          ingredients: '',
                          allergens: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                          price: '',
                          date: '',
                          hour: '',
                          file: '',
                        });
                      }
                    } catch (error) {
                      if (error.response.status !== 401) {
                        toast.error(t('newToast.createProduct'));
                      }
                      checkNoAuthError(error, history, toast, t);
                    }
                  };
                  consultaAPI();
                  setSubmitting(false);
                }, 250);
              }}
            >
              {(props) => {
                const {
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  /* y otras más */
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item lg={12} xs={12}>
                        <FormControl>
                          <FormLabel id="languageObject">{t('App.InfoLocal.Informacion.AñadirProducto.form.language')}</FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="languageObject"
                            name="language"
                            value={language}
                            onChange={changeLanguage}
                          >
                            <FormControlLabel
                              value="es"
                              control={<Radio />}
                              label={(
                                <img
                                  src={es}
                                  style={{ height: 24, width: 24 }}
                                  alt="flagEs"
                                />
                              )}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      <Grid item lg={12} xs={12}>
                        <FormGroup>
                          {/* <Form.Label>Nombre</Form.Label> */}
                          <TextField
                            label={t('App.InfoLocal.Informacion.AñadirProducto.form.name')}
                            name="name"
                            placeholder="Ejemplo: Ensalada mixta"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item lg={12} xs={12}>
                        <FormGroup>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{t('App.InfoLocal.Informacion.AñadirProducto.form.category.title')}</InputLabel>
                            <Field
                              name="category"
                              component={CustomizedSelectForFormik}
                              label="Selecciona la Categoría"
                            >
                              <MenuItem value={0}>
                                {t('App.InfoLocal.Informacion.AñadirProducto.form.category.firstOption')}
                              </MenuItem>
                              {respuestaAPI.length !== 0
                                && respuestaAPI.map((category) => (
                                  <MenuItem
                                    value={category.id}
                                    key={category.id}
                                  >
                                    {category.name[language]}
                                  </MenuItem>
                                ))}
                            </Field>
                          </FormControl>
                        </FormGroup>
                      </Grid>

                      <Grid item lg={12} xs={12}>
                        <FormGroup>
                          {/* <Form.Label>Descripción</Form.Label> */}
                          <TextField
                            label={t('App.InfoLocal.Informacion.AñadirProducto.form.description')}
                            name="description"
                            multiline
                            rows={4}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                            error={touched.description && Boolean(errors.description)}
                            helperText={touched.description && errors.description}
                          />
                        </FormGroup>
                      </Grid>

                      {/* <FormGroup>
                    <Form.Label>¿El producto es final o variable?</Form.Label>
                    <Row>
                      <Col lg={2}>
                        <Form.Check
                          inline
                          label='Producto Final'
                          name='radioGroup'
                          type='radio'
                          id={`1`}
                          onChange={e => setdisplayValue('1')}
                        />
                      </Col>

                      <Col lg={3}>
                        <Form.Check
                          inline
                          label='Producto Variable'
                          name='radioGroup'
                          type='radio'
                          id={`2`}
                          onChange={e => setdisplayValue('2')}
                        />
                      </Col>
                    </Row>
                  </FormGroup> */}

                      <br />
                      {/* PRODUCTO FINAL */}
                      {/* <div style={{ display: displayValue === '1' ? 'block' : 'none' }}> */}
                      <Grid item lg={12} xs={12}>
                        <FormGroup>
                          {/* <Form.Label>Ingredientes</Form.Label> */}
                          <TextField
                            label={t('App.InfoLocal.Informacion.AñadirProducto.form.ingredients')}
                            name="ingredients"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ingredients}
                            error={touched.ingredients && Boolean(errors.ingredients)}
                            helperText={touched.ingredients && errors.ingredients}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item lg={12} xs={12}>
                        <FormLabel>{t('App.InfoLocal.Informacion.AñadirProducto.form.allergens')}</FormLabel>
                      </Grid>
                      <Grid item lg={12} xs={12}>
                        <Grid container rowSpacing={2}>
                          {allergens.allergens.map((allergen) => {
                            let allergenImg;
                            let allergenName;
                            switch (allergen.name) {
                              case 'celery':
                                allergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                                allergenName = 'Apio';
                                break;
                              case 'crustaceans':
                                allergenImg = <img src={crustaceans} style={{ height: 32, width: 32 }} alt="allergen" />;
                                allergenName = 'Crustaceo';
                                break;
                              case 'eggs':
                                allergenImg = <img src={eggs} style={{ height: 32, width: 32 }} alt="allergen" />;
                                allergenName = 'Huevo';
                                break;
                              case 'fish':
                                allergenImg = <img src={fish} style={{ height: 32, width: 32 }} alt="allergen" />;
                                allergenName = 'Pescado';
                                break;
                              case 'cereals':
                                allergenImg = <img src={cereals} style={{ height: 32, width: 32 }} alt="allergen" />;
                                allergenName = 'Gluten';
                                break;
                              case 'lupin':
                                allergenImg = <img src={lupin} style={{ height: 32, width: 32 }} alt="allergen" />;
                                allergenName = 'Lúpulo';
                                break;
                              case 'milk':
                                allergenImg = <img src={milk} style={{ height: 32, width: 32 }} alt="allergen" />;
                                allergenName = 'Leche';
                                break;
                              case 'molluscs':
                                allergenImg = <img src={molluscs} style={{ height: 32, width: 32 }} alt="allergen" />;
                                allergenName = 'Moluscos';
                                break;
                              case 'mustard':
                                allergenImg = <img src={mustard} style={{ height: 32, width: 32 }} alt="allergen" />;
                                allergenName = 'Mostaza';
                                break;
                              case 'nuts':
                                allergenImg = <img src={nuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                                allergenName = 'Nueces';
                                break;
                              case 'peanuts':
                                allergenImg = <img src={peanuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                                allergenName = 'Manices';
                                break;
                              case 'sesame':
                                allergenImg = <img src={sesame} style={{ height: 32, width: 32 }} alt="allergen" />;
                                allergenName = 'Sésamo';
                                break;
                              case 'soya':
                                allergenImg = <img src={soya} style={{ height: 32, width: 32 }} alt="allergen" />;
                                allergenName = 'Soja';
                                break;
                              case 'sulphites':
                                allergenImg = <img src={sulphites} style={{ height: 32, width: 32 }} alt="allergen" />;
                                allergenName = 'Sulfitos';
                                break;
                              default:
                                allergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                                allergenName = 'Apio';
                                break;
                            }
                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <Grid item lg={3} xs={4}>
                                <FormControlLabel
                                  control={<Checkbox />}
                                  checked={values.allergens[allergen.allergen_id - 1] === 1}
                                  name="allergens"
                                  label={(
                                    <>
                                      {allergenImg}
                                      {allergenName}
                                    </>
                                  )}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      values.allergens[allergen.allergen_id - 1] = 1;
                                      values.allergens[allergen.allergen_id - 1 + 14] = 0;
                                    } else {
                                      values.allergens[allergen.allergen_id - 1] = 0;
                                    }
                                    setFieldValue('allergens', values.allergens);
                                  }}
                                  onBlur={handleBlur}
                                />
                              </Grid>
                            );
                          })}
                          {/* </FormGroup> */}
                        </Grid>
                      </Grid>

                      <Grid item lg={12} xs={12}>
                        <FormLabel>{t('App.InfoLocal.Informacion.AñadirProducto.form.traceAllergens')}</FormLabel>
                      </Grid>
                      <Grid item lg={12} xs={12}>
                        <Grid container rowSpacing={2}>
                          {traceAllergens.traceAllergens.map((traceAllergen) => {
                            let traceAllergenImg;
                            let traceAllergenName;
                            switch (traceAllergen.name) {
                              case 'traceCelery':
                                traceAllergenImg = <img src={traceCelery} style={{ height: 32, width: 32 }} alt="allergen" />;
                                traceAllergenName = 'Apio';
                                break;
                              case 'traceCrustaceans':
                                traceAllergenImg = <img src={traceCrustaceans} style={{ height: 32, width: 32 }} alt="allergen" />;
                                traceAllergenName = 'Crustaceo';
                                break;
                              case 'traceEggs':
                                traceAllergenImg = <img src={traceEggs} style={{ height: 32, width: 32 }} alt="allergen" />;
                                traceAllergenName = 'Huevo';
                                break;
                              case 'traceFish':
                                traceAllergenImg = <img src={traceFish} style={{ height: 32, width: 32 }} alt="allergen" />;
                                traceAllergenName = 'Pescado';
                                break;
                              case 'traceCereals':
                                traceAllergenImg = <img src={traceCereals} style={{ height: 32, width: 32 }} alt="allergen" />;
                                traceAllergenName = 'Gluten';
                                break;
                              case 'traceLupin':
                                traceAllergenImg = <img src={traceLupin} style={{ height: 32, width: 32 }} alt="allergen" />;
                                traceAllergenName = 'Lúpulo';
                                break;
                              case 'traceMilk':
                                traceAllergenImg = <img src={traceMilk} style={{ height: 32, width: 32 }} alt="allergen" />;
                                traceAllergenName = 'Leche';
                                break;
                              case 'traceMolluscs':
                                traceAllergenImg = <img src={traceMolluscs} style={{ height: 32, width: 32 }} alt="allergen" />;
                                traceAllergenName = 'Moluscos';
                                break;
                              case 'traceMustard':
                                traceAllergenImg = <img src={traceMustard} style={{ height: 32, width: 32 }} alt="allergen" />;
                                traceAllergenName = 'Mostaza';
                                break;
                              case 'traceNuts':
                                traceAllergenImg = <img src={traceNuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                                traceAllergenName = 'Nueces';
                                break;
                              case 'tracePeanuts':
                                traceAllergenImg = <img src={tracePeanuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                                traceAllergenName = 'Manices';
                                break;
                              case 'traceSesame':
                                traceAllergenImg = <img src={traceSesame} style={{ height: 32, width: 32 }} alt="allergen" />;
                                traceAllergenName = 'Sésamo';
                                break;
                              case 'traceSoya':
                                traceAllergenImg = <img src={traceSoya} style={{ height: 32, width: 32 }} alt="allergen" />;
                                traceAllergenName = 'Soja';
                                break;
                              case 'traceSulphites':
                                traceAllergenImg = <img src={traceSulphites} style={{ height: 32, width: 32 }} alt="allergen" />;
                                traceAllergenName = 'Sulfitos';
                                break;
                              default:
                                traceAllergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                                traceAllergenName = 'Apio';
                                break;
                            }
                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <Grid item lg={3} xs={4}>
                                <FormControlLabel
                                  control={<Checkbox />}
                                  name="allergens"
                                  checked={values.allergens[traceAllergen.allergen_id - 1] === 1}
                                  label={(
                                    <>
                                      {traceAllergenImg}
                                      {traceAllergenName}
                                    </>
                                  )}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      values.allergens[traceAllergen.allergen_id - 1] = 1;
                                      values.allergens[traceAllergen.allergen_id - 1 - 14] = 0;
                                    } else {
                                      values.allergens[traceAllergen.allergen_id - 1] = 0;
                                    }
                                    setFieldValue('allergens', values.allergens);
                                  }}
                                  onBlur={handleBlur}
                                />
                              </Grid>
                            );
                          })}
                          {/* </FormGroup> */}
                        </Grid>
                      </Grid>

                      <Grid item lg={12} xs={12}>
                        <FormGroup>
                          {/* <Form.Label>Precio</Form.Label> */}
                          <TextField
                            label={t('App.InfoLocal.Informacion.AñadirProducto.form.price')}
                            name="price"
                            placeholder="Ejemplo: 25"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.price}
                            error={touched.price && Boolean(errors.price)}
                            helperText={touched.price && errors.price}
                          />
                        </FormGroup>
                      </Grid>

                      {/* <Row className='mb-3'>
                      <Col lg={3}>
                        <FormGroup>
                          <Form.Label>Fecha de caducida</Form.Label>
                          <Form.Control
                            type='date'
                            name='date'
                            onChange={handleChange}
                            value={values.date}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <FormGroup>
                          <Form.Label>Hora de caducida</Form.Label>
                          <Form.Control
                            type='time'
                            name='hour'
                            onChange={handleChange}
                            value={values.hour}
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}

                      <Grid item lg={12} xs={12}>
                        <FormGroup className="position-relative mb-3">
                          <FormLabel>{t('App.InfoLocal.Informacion.AñadirProducto.form.pictureTitle')}</FormLabel>
                          <input
                            id="file"
                            type="file"
                            name="file"
                            label={t('App.InfoLocal.Informacion.AñadirProducto.form.pictureInformation')}
                            onChange={async (event) => {
                              const image = await resizeFile(event.target.files[0]);
                              setFieldValue('file', image);
                            }}
                            className="form-control"
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item lg={4} xs={4}>
                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.AñadirProducto.form.buttonForm')}
                        </Button>
                      </Grid>
                      {/* </div> */}

                      {/* PRODUCTO VARIABLE */}
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </Container>
    </Container>
  );
}

export default Formulario;
