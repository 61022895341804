import React from 'react';
import {
  Document, Page, /* Text, */ Image, View, StyleSheet,
} from '@react-pdf/renderer';

function generatePage(resultArray) {
  const auxArray = [];
  const resArray = [];
  for (let j = 0; j < resultArray.length; j += 3) {
    auxArray.push([resultArray[j], resultArray[j + 1], resultArray[j + 2]]);
  }
  for (let i = 0; i < auxArray.length; i += 3) {
    resArray.push([auxArray[i], auxArray[i + 1], auxArray[i + 2]]);
  }
  return resArray;
}

function PDFGenerator(props) {
  const { PDFImageIds } = props;
  const IdsArray = [];
  const resultArray = PDFImageIds.map((id) => {
    IdsArray.push(id);
    return document.getElementById(id).toDataURL();
  });
  const formatedArray = generatePage(resultArray);

  const styles = StyleSheet.create({
    page: {
      backgroundColor: 'white',
      display: 'block',
    },
    view: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
    },
    text: {
      color: '#0081C6',
      size: '11em',
    },
    QRImage: {
      width: '30%',
    },
  });

  return (
    <Document>
      {formatedArray.map((page, id) => (
        // eslint-disable-next-line react/no-array-index-key
        <Page key={`PageId_${id}`} size="A4" style={styles.page}>
          {page.map((row, id2) => (
            row !== undefined
            && (
              // eslint-disable-next-line react/no-array-index-key
              <View style={styles.view} key={id2 + 1000}>
                {row[0] !== undefined
                  && (
                    <Image
                      allowDangerousPaths
                      src={row[0]}
                      style={styles.QRImage}
                      // eslint-disable-next-line react/no-array-index-key
                      key={id2 + 2000}
                    />
                  )}
                {row[1] !== undefined
                  && (
                    <Image
                      allowDangerousPaths
                      src={row[1]}
                      style={styles.QRImage}
                      // eslint-disable-next-line react/no-array-index-key
                      key={id2 + 3000}
                    />
                  )}
                {row[2] !== undefined
                  && (
                    <Image
                      allowDangerousPaths
                      src={row[2]}
                      style={styles.QRImage}
                      // eslint-disable-next-line react/no-array-index-key
                      key={id2 + 4000}
                    />
                  )}
              </View>
            )
          ))}
        </Page>
      ))}
    </Document>
  );
}

export default PDFGenerator;
