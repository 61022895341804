import React from 'react';
import Descripcion from './DescripcionEntrada';

function PaginaEntrada() {
  return (
    <div id="inner">
      <Descripcion />
    </div>
  );
}

export default PaginaEntrada;
