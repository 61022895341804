import React from 'react';
import {
  Container, Typography, Grid, Card, CardHeader, /* CardActions, */ CardContent,
  ListItem, ListItemIcon, ListItemText, /* Button, */
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import CheckIcon from '@mui/icons-material/Check';
// import { Link } from 'react-router-dom';

function infoTarifa() {
  const { t } = useTranslation();

  return (
    <Container style={{ marginTop: '2%', marginBottom: '2%' }}>
      <Typography variant="h4" align="center" style={{ marginBottom: '2%' }}>
        {t('paginaentrada.infotarifa.title')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item lg={4} xs={12}>
          <Card>
            <CardHeader title="Plan Básico" style={{ padding: 0, paddingLeft: '16px', paddingTop: '16px' }} />
            <CardContent style={{ padding: 0, paddingLeft: '16px' }}>
              <Typography variant="body">
                {t('paginaentrada.infotarifa.firstPricing.titlePricing')}
              </Typography>
            </CardContent>
            <hr />
            <CardContent>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary={t('paginaentrada.infotarifa.firstPricing.firstPoint')} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary={t('paginaentrada.infotarifa.firstPricing.secondPoint')} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary={t('paginaentrada.infotarifa.firstPricing.thirdPoint')} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary={t('paginaentrada.infotarifa.firstPricing.fourthPoint')} />
              </ListItem>
            </CardContent>
            {/* <CardActions sx={{ justifyContent: 'center', padding: '16px', marginBottom: '5%' }}>
              <Link to="/Paginaprincipal/Registrar" style={{ width: '80%' }}>
                <Button variant="contained" style={{ width: '100%' }}>
                  {t('paginaentrada.infotarifa.firstPricing.button')}
                </Button>
              </Link>
            </CardActions> */}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default infoTarifa;
