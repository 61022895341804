import React from 'react';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useTranslation } from 'react-i18next';

const {
  Typography, Container, ListItem, ListItemIcon, ListItemText,
} = require('@mui/material');

export default function CondicionesGenerales() {
  const { t } = useTranslation();

  return (
    <Container style={{ marginTop: '40px', marginBottom: '40px' }}>
      <Typography variant="h3" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.title')}
      </Typography>
      <Typography variant="h4" style={{ marginBottom: '10px', marginTop: '10px' }}>
        {t('legal.condicionesGenerales.prestacionesServicio.title')}
      </Typography>
      <Typography variant="body1" align="justify">
        {t('legal.condicionesGenerales.prestacionesServicio.info1')}
      </Typography>
      <Typography>
        <ListItem>
          <ListItemIcon>
            <ArrowRightAltIcon />
          </ListItemIcon>
          <ListItemText primary={t('legal.condicionesGenerales.prestacionesServicio.firstPoint')} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ArrowRightAltIcon />
          </ListItemIcon>
          <ListItemText primary={t('legal.condicionesGenerales.prestacionesServicio.secondPoint')} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ArrowRightAltIcon />
          </ListItemIcon>
          <ListItemText primary={t('legal.condicionesGenerales.prestacionesServicio.thirdPoint')} />
        </ListItem>
        {/* <ListItem>
          <ListItemIcon>
            <ArrowRightAltIcon />
          </ListItemIcon>
          <ListItemText primary=" Incremento en la rotación de mesas gracias al" />
        </ListItem> */}
        <ListItem>
          <ListItemIcon>
            <ArrowRightAltIcon />
          </ListItemIcon>
          <ListItemText primary={t('legal.condicionesGenerales.prestacionesServicio.fourthPoint')} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ArrowRightAltIcon />
          </ListItemIcon>
          <ListItemText primary={t('legal.condicionesGenerales.prestacionesServicio.fifthPoint')} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ArrowRightAltIcon />
          </ListItemIcon>
          <ListItemText primary={t('legal.condicionesGenerales.prestacionesServicio.sixthPoint')} />
        </ListItem>
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.prestacionesServicio.info2')}
      </Typography>
      <Typography variant="body1" align="justify">
        {t('legal.condicionesGenerales.prestacionesServicio.info3')}
      </Typography>

      <Typography variant="h4" style={{ marginTop: '15px', marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.terminosCondiciones.title')}
      </Typography>
      <Typography variant="body1" align="justify">
        {t('legal.condicionesGenerales.terminosCondiciones.info1')}
      </Typography>
      <Typography>
        <ListItem>
          <ListItemIcon>
            <ArrowRightAltIcon />
          </ListItemIcon>
          <ListItemText primary={t('legal.condicionesGenerales.terminosCondiciones.firstPoint')} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ArrowRightAltIcon />
          </ListItemIcon>
          <ListItemText primary={t('legal.condicionesGenerales.terminosCondiciones.secondPoint')} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ArrowRightAltIcon />
          </ListItemIcon>
          <ListItemText primary={t('legal.condicionesGenerales.terminosCondiciones.thirdPoint')} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ArrowRightAltIcon />
          </ListItemIcon>
          <ListItemText primary={t('legal.condicionesGenerales.terminosCondiciones.fourthPoint')} />
        </ListItem>
      </Typography>
      <Typography>
        {t('legal.condicionesGenerales.terminosCondiciones.info2')}
      </Typography>

      <Typography variant="h4" style={{ marginTop: '15px', marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.condicionesAcceso.title')}
      </Typography>
      <Typography variant="body1" align="justify">
        {t('legal.condicionesGenerales.condicionesAcceso.info')}
      </Typography>

      <Typography variant="h4" style={{ marginTop: '15px', marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.politicaEnlace.title')}
      </Typography>
      <Typography variant="body1" align="justify">
        {t('legal.condicionesGenerales.politicaEnlace.info')}
      </Typography>

      <Typography variant="h4" style={{ marginTop: '15px', marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.derechoPropiedad.title')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.derechoPropiedad.info1')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.derechoPropiedad.info2')}
      </Typography>
      <Typography variant="body1" align="justify">
        {t('legal.condicionesGenerales.derechoPropiedad.info3')}
      </Typography>

      <Typography variant="h4" style={{ marginTop: '15px', marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.limitacionesResponsabilidad.title')}
      </Typography>
      <Typography variant="body1" align="justify">
        {t('legal.condicionesGenerales.limitacionesResponsabilidad.info')}
      </Typography>

      <Typography variant="h4" style={{ marginTop: '15px', marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.cookies.title')}
      </Typography>
      <Typography variant="body1" align="justify">
        {t('legal.condicionesGenerales.cookies.info')}
      </Typography>

      <Typography variant="h4" style={{ marginTop: '15px', marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.politicaPrivacidad.title')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.politicaPrivacidad.info1')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.politicaPrivacidad.info2')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.politicaPrivacidad.info3')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.politicaPrivacidad.info4')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.politicaPrivacidad.info5')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.politicaPrivacidad.info6')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.politicaPrivacidad.info7')}
      </Typography>

      <Typography variant="h4" style={{ marginTop: '15px', marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.condicionesPago.title')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.condicionesPago.info1')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.condicionesPago.info2')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.condicionesPago.info3')}
      </Typography>

      <Typography variant="h4" style={{ marginTop: '15px', marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.eventosFueraControl.title')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.eventosFueraControl.info1')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.eventosFueraControl.info2')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.eventosFueraControl.info3')}
      </Typography>

      <Typography variant="h4" style={{ marginTop: '15px', marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.tarifasAplicables.title')}
      </Typography>
      <Typography variant="body1" align="justify">
        {t('legal.condicionesGenerales.tarifasAplicables.info')}
      </Typography>

      <Typography variant="h4" style={{ marginTop: '15px', marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.elevacionPublico.title')}
      </Typography>
      <Typography variant="body1" align="justify">
        {t('legal.condicionesGenerales.elevacionPublico.info')}
      </Typography>

      <Typography variant="h4" style={{ marginTop: '15px', marginBottom: '10px' }}>
        {t('legal.condicionesGenerales.leyAplicable.title')}
      </Typography>
      <Typography variant="body1" align="justify">
        {t('legal.condicionesGenerales.leyAplicable.info')}
      </Typography>
    </Container>
  );
}
