import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

// import {
//   Container, Row, Col, Image,
// } from 'react-bootstrap';
import {
  Container, Grid,
} from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {
  getAccount, getImageShop, getShops,
} from '../../../services/mesonero-api/api';

import HeaderWithRouter from '../MenuCuenta';
import checkNoAuthError from '../../../services/checkNoAuthError/checkNoAuthError';

function Cuenta() {
  const [shopsW, setShopsW] = React.useState([]);
  const [listImage, setListImage] = React.useState([]);
  const history = useHistory();
  const { t } = useTranslation();

  async function fetchShopProduct(enterpriseId, shopId) {
    let image;
    try {
      image = await getImageShop(enterpriseId, shopId);
    } catch (e) {
      image = null;
    }
    return image;
  }

  async function fetchData() {
    try {
      const account = await getAccount();

      localStorage.setItem('datosUsuario', JSON.stringify(account));

      const shops = await getShops(account.enterprise.id);
      setShopsW(shops);

      const listImageShop = [];
      shops.forEach((shop) => {
        if (shop.pictures.length !== 0) {
          const image = fetchShopProduct(account.enterprise.id, shop.id);
          listImageShop.push(image);
        } else {
          listImageShop.push(null);
        }
      });
      setListImage(await Promise.all(listImageShop));
    } catch (error) {
      checkNoAuthError(error, history, toast, t);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      <HeaderWithRouter />
      <hr />
      <Grid container spacing={3}>
        {/* {localStorage.setItem('shops', JSON.stringify(shopsW))} */}
        {(shopsW.length !== 0 || listImage.length !== 0)
          && shopsW.map((shop, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item lg={3} xs={12} key={i}>
              <Link to={{ pathname: '/App/InfoLocal/Informacion/PaginaInicialLocal', state: shop.id }} style={{ width: '100%', textDecoration: 'none' }}>
                {listImage[i] == null ? (
                  <div style={{
                    minWidth: '100%', minHeight: 150, backgroundColor: '#efefef', margin: 'auto',
                  }}
                  />
                )
                  : <img src={listImage[i]} style={{ width: '100%', height: 150, objectFit: 'cover' }} alt="imageShop" />}
                <h5 style={{ color: 'black', textAlign: 'center' }}>{shop.name}</h5>
              </Link>
            </Grid>
          ))}
        <Grid item lg={3} xs={12}>
          {shopsW.length === 0
            ? (
              <Link
                to="/App/Cuenta/AñadirLocal"
                style={{
                  minWidth: '100%',
                  minHeight: 150,
                  backgroundColor: 'white',
                  margin: 'auto',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#7cdf7c',
                  border: '1px solid grey',
                }}
              >
                {/* <img src="https://picsum.photos/150/150" alt="imageAdd" />
              <h5>Añadir Local</h5> */}
                <AddCircleOutlineIcon style={{ fontSize: '75' }} />
              </Link>
            )
            : (
              <Link
                to="/App/Cuenta/AñadirLocal"
                style={{
                  minWidth: '100%',
                  minHeight: 150,
                  backgroundColor: 'white',
                  margin: 'auto',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#7cdf7c',
                  border: '1px solid grey',
                  cursor: 'default',
                  pointerEvents: 'none',
                }}
              >
                {/* <img src="https://picsum.photos/150/150" alt="imageAdd" />
              <h5>Añadir Local</h5> */}
                <AddCircleOutlineIcon style={{ fontSize: '75', color: 'grey' }} />
              </Link>
            )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default Cuenta;
