import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

// import {
//   Card, Container, Form, Button, Col, Row, Alert,
// } from 'react-bootstrap';
import {
  Container, Card, CardContent, Alert, AlertTitle,
  Grid, FormGroup, /* FormLabel, */ Button, TextField, Typography,
} from '@mui/material';

import { TokenContext } from '../../token-context';

// import jwt from 'jsonwebtoken';
import { refreshToken, userLogin } from '../../services/mesonero-api/api';

function Login() {
  const { t } = useTranslation();
  const [respuestaAPI, setRespuestaAPI] = React.useState('');
  const [show, setShow] = React.useState(false);

  const history = useHistory();

  const MostrarRespuesta = (consulta, token, setToken) => {
    // let dataEmployee;
    if (consulta.data) {
      // jwt.verify(consulta.data, 'meloncitorico90', (err, decoded) => {
      //     if (err) {
      //         console.log(err)
      //     }
      //     dataEmployee = decoded;
      // });
      setToken(consulta.data.token);
      localStorage.setItem('token', consulta.data.token);

      refreshToken();
      // localStorage.setItem('enterpriseId', dataEmployee.enterpriseId);
      // localStorage.setItem('datosUsuario', JSON.stringify(dataEmployee));

      history.push('/App/Cuenta/Locales');
      // window.location.reload();
    } else {
      setShow(true);
    }
  };

  return (
    <Container style={{ marginTop: '2%', marginBottom: '2%', maxWidth: '500px' }}>
      {show
        && (
          <Alert severity="error" onClose={() => setShow(false)}>
            <AlertTitle>Error</AlertTitle>
            <p>
              {respuestaAPI}
            </p>
          </Alert>
        )}
      <Card className="card-login">
        <CardContent>
          <Typography variant="h4" align="center">
            {t('paginaprincipal.login.title')}
          </Typography>
          <hr />
          <TokenContext.Consumer>
            {({ token, setToken }) => (
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                validationSchema={Yup.object().shape({
                  email: Yup
                    .string(t('paginaprincipal.login.form.yup.email'))
                    .email(t('paginaprincipal.login.form.yup.invalid.email'))
                    .required(t('paginaprincipal.login.form.yup.required.email')),
                  password: Yup
                    .string(t('paginaprincipal.login.form.yup.password'))
                    // .min(8, t('paginaprincipal.login.form.yup.invalid.password')
                    .required(t('paginaprincipal.login.form.yup.invalid.password')),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    const data = {
                      email: values.email,
                      password: values.password,
                    };
                    const consultaAPI = async () => {
                      try {
                        const consulta = await userLogin(data);
                        MostrarRespuesta(consulta, token, setToken);
                        // setRespuestaAPI(consulta.data.message);
                      } catch (error) {
                        switch (error.response.data.error) {
                          case 'no user':
                            setRespuestaAPI(t('paginaprincipal.login.form.yup.invalid.password'));
                            setShow(true);
                            break;
                          case 'wrong email, password':
                            setRespuestaAPI(t('paginaprincipal.login.form.yup.invalid.password'));
                            setShow(true);
                            break;
                          case 'not verified':
                            setRespuestaAPI(t('paginaprincipal.login.notverified'));
                            setShow(true);
                            break;
                          case 'contract no activated':
                            setRespuestaAPI(t('paginaprincipal.login.contractnotactive'));
                            setShow(true);
                            break;
                          default:
                            break;
                        }
                      }
                    };
                    consultaAPI();

                    setSubmitting(false);
                  }, 250);
                }}
              >
                {(props) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* y otras más */
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container>
                        <Grid item lg={12} xs={12} style={{ paddingTop: '12px' }}>
                          <FormGroup>
                            {/* <FormLabel>Usuario</FormLabel> */}
                            <TextField
                              label={t('paginaprincipal.login.form.email')}
                              id="email"
                              name="email"
                              placeholder="Correo Electrónico"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              error={touched.email && Boolean(errors.email)}
                              helperText={touched.email && errors.email}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item lg={12} xs={12} style={{ paddingTop: '24px' }}>
                          <FormGroup>
                            {/* <FormLabel>Constraseña</FormLabel> */}
                            <TextField
                              label={t('paginaprincipal.login.form.password')}
                              id="password"
                              type="password"
                              name="password"
                              placeholder="Contraseña"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              error={touched.password && Boolean(errors.password)}
                              helperText={touched.password && errors.password}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item lg={12} xs={12} style={{ paddingTop: '8px' }}>
                          <Link to="/Paginaprincipal/forgetPassword">
                            <Typography style={{ fontSize: '14px' }}>
                              {t('paginaprincipal.login.form.forgotten')}
                            </Typography>
                          </Link>
                        </Grid>

                        <Grid item lg={12} xs={12} style={{ paddingTop: '24px' }}>
                          <FormGroup>
                            <Button variant="contained" type="submit" disabled={isSubmitting}>
                              {isSubmitting ? 'Loading' : t('paginaprincipal.login.form.button')}
                            </Button>
                          </FormGroup>
                        </Grid>

                        <Grid item lg={12} xs={12} style={{ paddingTop: '24px' }}>
                          <Typography style={{ fontSize: '14px' }}>
                            {t('paginaprincipal.login.form.newAccount')}
                            &nbsp;
                            <Link to="/Paginaprincipal/Registrar">
                              {t('paginaprincipal.login.form.register')}
                            </Link>
                          </Typography>
                        </Grid>

                        {/* <Grid item lg={12} xs={12} style={{ paddingTop: '12px' }}>
                          <FormGroup>
                            <Link to="/Paginaprincipal/Registrar">
                              <Button variant="outlined" color="inherit" style={{ width: '100%' }}>
                                {t('paginaprincipal.login.form.register')}
                              </Button>
                            </Link>
                          </FormGroup>
                        </Grid> */}

                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            )}
          </TokenContext.Consumer>
        </CardContent>
      </Card>
    </Container>

  );
}

export default Login;
