import React, { useEffect } from 'react';
// import {
//   Container, Card, Button, Form, Row, Col, Table, Image, /* Offcanvas, */
// } from 'react-bootstrap';
import {
  Modal, Card, Button, Grid, FormGroup,
  TextField, FormControl, InputLabel, MenuItem, Select, FormLabel,
  FormControlLabel, Checkbox, CardHeader, CardContent, CardActions,
  Typography, CircularProgress, CardMedia, RadioGroup, Radio,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
// import useCollapse from 'react-collapsed';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { ReactSortable } from 'react-sortablejs';
import {
  addCategory, deleteMenu, deleteProduct, getCategories, updateProduct,
  updateShop, updateMenu, /* updateProfile */ deleteShop, addPictureProduct, addPictureShop,
  /* addPictureEmployee, */ addPictureMenu, deleteCategory, updateCategory,
  /* addLanguageProduct, addLanguageMenu, addLanguageCategory, */
  /* deletePromotion, */
} from '../../../../../services/mesonero-api/api';
import allergens from '../../../../../utils/alergens.json';
import traceAllergens from '../../../../../utils/traceAlergens.json';

import resizeFile from '../../../../../services/resizeImage/ResizeImage';

import celery from '../../../../../Icons/allergen-celery.svg';
import crustaceans from '../../../../../Icons/allergen-crustaceans.svg';
import eggs from '../../../../../Icons/allergen-eggs.svg';
import fish from '../../../../../Icons/allergen-fish.svg';
import cereals from '../../../../../Icons/allergen-gluten.svg';
import lupin from '../../../../../Icons/allergen-lupin.svg';
import milk from '../../../../../Icons/allergen-milk.svg';
import molluscs from '../../../../../Icons/allergen-mollusks.svg';
import mustard from '../../../../../Icons/allergen-mustard.svg';
import nuts from '../../../../../Icons/allergen-nuts.svg';
import peanuts from '../../../../../Icons/allergen-peanuts.svg';
import sesame from '../../../../../Icons/allergen-sesame-seeds.svg';
import soya from '../../../../../Icons/allergen-soy.svg';
import sulphites from '../../../../../Icons/allergen-sulphur-dioxide.svg';

import traceCelery from '../../../../../Icons/allergen-traces-celery.svg';
import traceCrustaceans from '../../../../../Icons/allergen-traces-crustaceans.svg';
import traceEggs from '../../../../../Icons/allergen-traces-eggs.svg';
import traceFish from '../../../../../Icons/allergen-traces-fish.svg';
import traceCereals from '../../../../../Icons/allergen-traces-gluten.svg';
import traceLupin from '../../../../../Icons/allergen-traces-lupin.svg';
import traceMilk from '../../../../../Icons/allergen-traces-milk.svg';
import traceMolluscs from '../../../../../Icons/allergen-traces-mollusks.svg';
import traceMustard from '../../../../../Icons/allergen-traces-mustard.svg';
import traceNuts from '../../../../../Icons/allergen-traces-nuts.svg';
import tracePeanuts from '../../../../../Icons/allergen-traces-peanuts.svg';
import traceSesame from '../../../../../Icons/allergen-traces-sesame-seeds.svg';
import traceSoya from '../../../../../Icons/allergen-traces-soy.svg';
import traceSulphites from '../../../../../Icons/allergen-traces-sulphur-dioxide.svg';

import es from '../../../../../Banderas/es.svg';
import en from '../../../../../Banderas/en.svg';
import fr from '../../../../../Banderas/fr.svg';
import it from '../../../../../Banderas/it.svg';
import de from '../../../../../Banderas/de.svg';
import pt from '../../../../../Banderas/pt.svg';

import { AddCategoryResponseFail, AddCategoryResponseSuccess } from '../../../../../services/response-api/responseApi';
import useWindowDimensions from '../../../../../services/sizeScreen/windowsDimension';
import checkNoAuthError from '../../../../../services/checkNoAuthError/checkNoAuthError';
import uppercase from '../../../../../services/uppercase/uppercase';

function CustomizedSelectForFormik({ children, form, field }) {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      name={name}
      value={value}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
      }}
    >
      {children}
    </Select>
  );
}

export function EditProduct(props) {
  const shopIdActual = localStorage.getItem('shop_id');
  const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'));
  const { t } = useTranslation();

  const datos = props;
  const idProduct = datos.product === undefined ? '' : datos.product.id;

  const [language, setLanguage] = React.useState('');

  const [respuestaAPI, setRespuestaAPI] = React.useState([]);
  const [uploadOverlay, setUploadOverlay] = React.useState(false);

  const [product, setProduct] = React.useState();
  const history = useHistory();

  const allergensAndTraces = [...allergens.allergens, ...traceAllergens.traceAllergens];

  const checkedAllergerns = datos.product !== undefined ? allergensAndTraces.map((allergen) => {
    const updateAllergen = { ...allergen };
    updateAllergen.checked = datos.product.allergens
      .find((a) => a.id === allergen.allergen_id) !== undefined;
    return updateAllergen;
  }) : [];

  const arrayAllergen = [];

  checkedAllergerns.forEach((allergen) => {
    if (allergen.checked) {
      arrayAllergen.push(1);
    } else {
      arrayAllergen.push(0);
    }
  });

  const fetchData = async () => {
    const categories = await getCategories(datosUsuario.enterprise.id, shopIdActual);
    setRespuestaAPI(categories);
  };

  useEffect(() => {
    fetchData();
    setLanguage(datos.language);
    setProduct(datos.product);
  }, [datos.product]);

  const changeLanguage = (event, setFieldValue, values) => {
    product.name[language] = values.name;
    product.description[language] = values.description;
    product.ingredients[language] = values.ingredients;

    setLanguage(event.target.value);
    setFieldValue('name', product.name[event.target.value] || '');
    setFieldValue('description', product.description[event.target.value] || '');
    setFieldValue('ingredients', product.ingredients[event.target.value] || '');
  };

  return (
    <Modal
      open={datos.show}
      onClose={datos.onHide}
    >
      {datos.width > 720
        ? (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.editProduct.load')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
                maxHeight: '95%',
                overflowY: 'scroll',
              }}
            >
              <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.editProduct.title')} style={{ marginBottom: '2%' }} />
              {product !== undefined
                && (
                  <Formik
                    initialValues={{
                      name: product.name[language] || '',
                      category: product.categoryId || '',
                      description: product.description[language] || '',
                      ingredients: product.ingredients[language] || '',
                      allergens: arrayAllergen || [],
                      price: product.price !== undefined && product.price !== null ? (product.price.toString()) : '',
                      file: null,
                    }}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required('Este campo es obligatorio'),
                      // category: Yup.string().required('Please select a product').oneOf(),
                      // description: Yup.string().required('Este campo es obligatorio'),
                      // ingredients: Yup.string().required('Required'),
                      price: Yup.string().matches(/^[0-9]+([,.][0-9]+)?$/, 'El valor debe ser un número').required('Este campo es obligatorio'),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        product.name[language] = values.name;
                        product.description[language] = values.description;
                        product.ingredients[language] = values.ingredients;

                        const allergensNumber = [];
                        for (let i = 0; i < values.allergens.length; i += 1) {
                          if (values.allergens[i] === 1) {
                            allergensNumber.push(i + 1);
                          }
                        }
                        const priceProduct = values.price.replace(',', '.');
                        const data = {
                          product: {
                            name: product.name,
                            categoryId: values.category,
                            description: product.description,
                            ingredients: product.ingredients,
                            price: Number(priceProduct),
                            allergens: allergensNumber,
                          },
                        };
                        const consultaAPI = async () => {
                          try {
                            const dataProduct = await updateProduct(idProduct, data);
                            if (dataProduct.status === 200) {
                              toast.success(t('App.InfoLocal.Informacion.Carta.Modal.editProduct.toast.editDataProduct'));
                              if (values.file != null) {
                                const formData = new FormData();
                                formData.append('productId', idProduct);
                                formData.append('picture', values.file);
                                // eslint-disable-next-line max-len
                                const dataImageProduct = await addPictureProduct(formData, idProduct);
                                if (dataImageProduct.status === 200) {
                                  toast.success(t('App.InfoLocal.Informacion.Carta.Modal.editProduct.toast.editPictureProduct'));
                                }
                              }
                              setUploadOverlay(true);
                              datos.fetchdata().then(() => {
                                setUploadOverlay(false);
                                datos.onHide();
                              });
                            }
                          } catch (error) {
                            if (error.response.status !== 401) {
                              toast.error(t('newToast.editProduct'));
                            }
                            checkNoAuthError(error, history, toast, t);
                          }
                        };
                        consultaAPI();

                        setSubmitting(false);
                      }, 250);
                    }}
                  >
                    {(_props) => {
                      const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        /* y otras más */
                      } = _props;
                      return (
                        <form onSubmit={handleSubmit}>
                          {/* {console.log(values.allergens)} */}
                          <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}>
                              <FormControl>
                                <FormLabel id="languageObject">{t('App.InfoLocal.Informacion.Carta.Modal.editProduct.language')}</FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="languageObject"
                                  name="language"
                                  value={language}
                                  onChange={(e) => { changeLanguage(e, setFieldValue, values); }}
                                >
                                  <FormControlLabel
                                    value="es"
                                    control={<Radio />}
                                    label={(
                                      <img
                                        src={es}
                                        style={{ height: 24, width: 24 }}
                                        alt="flagEs"
                                      />
                                    )}
                                  />
                                  <FormControlLabel
                                    value="en"
                                    control={<Radio />}
                                    label={(
                                      <img
                                        src={en}
                                        style={{ height: 24, width: 24 }}
                                        alt="flagEn"
                                      />
                                    )}
                                  />
                                  <FormControlLabel
                                    value="fr"
                                    control={<Radio />}
                                    label={(
                                      <img
                                        src={fr}
                                        style={{ height: 24, width: 24 }}
                                        alt="flagFr"
                                      />
                                    )}
                                  />
                                  <FormControlLabel
                                    value="it"
                                    control={<Radio />}
                                    label={(
                                      <img
                                        src={it}
                                        style={{ height: 24, width: 24 }}
                                        alt="flagIt"
                                      />
                                    )}
                                  />
                                  <FormControlLabel
                                    value="de"
                                    control={<Radio />}
                                    label={(
                                      <img
                                        src={de}
                                        style={{ height: 24, width: 24 }}
                                        alt="flagDe"
                                      />
                                    )}
                                  />
                                  <FormControlLabel
                                    value="pt"
                                    control={<Radio />}
                                    label={(
                                      <img
                                        src={pt}
                                        style={{ height: 24, width: 24 }}
                                        alt="flagPt"
                                      />
                                    )}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>Nombre</Form.Label> */}
                                <TextField
                                  label={t('App.InfoLocal.Informacion.AñadirProducto.form.name')}
                                  name="name"
                                  placeholder="Ejemplo: Ensalada mixta"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.name}
                                  error={touched.name && Boolean(errors.name)}
                                  helperText={touched.name && errors.name}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormGroup>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">{t('App.InfoLocal.Informacion.AñadirProducto.form.category.title')}</InputLabel>
                                  <Field
                                    name="category"
                                    component={CustomizedSelectForFormik}
                                    label="Selecciona la Categoría"
                                  >
                                    <MenuItem value={0}>
                                      {t('App.InfoLocal.Informacion.AñadirProducto.form.category.firstOption')}
                                    </MenuItem>
                                    {respuestaAPI.length !== 0
                                      && respuestaAPI.map((category) => (
                                        <MenuItem
                                          value={category.id}
                                          key={category.id}
                                        >
                                          {category.name[language] === null
                                            ? category.name.es : category.name[language]}
                                        </MenuItem>
                                      ))}
                                  </Field>
                                </FormControl>
                              </FormGroup>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>Descripción</Form.Label> */}
                                <TextField
                                  label={t('App.InfoLocal.Informacion.AñadirProducto.form.description')}
                                  name="description"
                                  multiline
                                  rows={2}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.description}
                                  error={touched.description && Boolean(errors.description)}
                                  helperText={touched.description && errors.description}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>Ingredientes</Form.Label> */}
                                <TextField
                                  label={t('App.InfoLocal.Informacion.AñadirProducto.form.ingredients')}
                                  name="ingredients"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.ingredients}
                                  error={touched.ingredients && Boolean(errors.ingredients)}
                                  helperText={touched.ingredients && errors.ingredients}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormLabel>{t('App.InfoLocal.Informacion.AñadirProducto.form.allergens')}</FormLabel>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                              <Grid container spacing={3}>
                                {allergens.allergens.map((allergen) => {
                                  let allergenImg;
                                  let allergenName;
                                  switch (allergen.name) {
                                    case 'celery':
                                      allergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Apio';
                                      break;
                                    case 'crustaceans':
                                      allergenImg = <img src={crustaceans} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Crustaceo';
                                      break;
                                    case 'eggs':
                                      allergenImg = <img src={eggs} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Huevo';
                                      break;
                                    case 'fish':
                                      allergenImg = <img src={fish} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Pescado';
                                      break;
                                    case 'cereals':
                                      allergenImg = <img src={cereals} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Gluten';
                                      break;
                                    case 'lupin':
                                      allergenImg = <img src={lupin} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Lúpulo';
                                      break;
                                    case 'milk':
                                      allergenImg = <img src={milk} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Leche';
                                      break;
                                    case 'molluscs':
                                      allergenImg = <img src={molluscs} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Moluscos';
                                      break;
                                    case 'mustard':
                                      allergenImg = <img src={mustard} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Mostaza';
                                      break;
                                    case 'nuts':
                                      allergenImg = <img src={nuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Nueces';
                                      break;
                                    case 'peanuts':
                                      allergenImg = <img src={peanuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Manices';
                                      break;
                                    case 'sesame':
                                      allergenImg = <img src={sesame} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Sésamo';
                                      break;
                                    case 'soya':
                                      allergenImg = <img src={soya} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Soja';
                                      break;
                                    case 'sulphites':
                                      allergenImg = <img src={sulphites} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Sulfitos';
                                      break;
                                    default:
                                      allergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Apio';
                                      break;
                                  }
                                  return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Grid item lg={3} xs={3}>
                                      <FormControlLabel
                                        control={<Checkbox />}
                                        checked={values.allergens[allergen.allergen_id - 1] === 1}
                                        name="allergens"
                                        label={(
                                          <>
                                            {allergenImg}
                                            {allergenName}
                                          </>
                                        )}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            values.allergens[allergen.allergen_id - 1] = 1;
                                            values.allergens[allergen.allergen_id - 1 + 14] = 0;
                                          } else {
                                            values.allergens[allergen.allergen_id - 1] = 0;
                                          }
                                          setFieldValue('allergens', values.allergens);
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormLabel>{t('App.InfoLocal.Informacion.AñadirProducto.form.traceAllergens')}</FormLabel>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                              <Grid container spacing={3}>
                                {traceAllergens.traceAllergens.map((traceAllergen) => {
                                  let traceAllergenImg;
                                  let traceAllergenName;
                                  switch (traceAllergen.name) {
                                    case 'traceCelery':
                                      traceAllergenImg = <img src={traceCelery} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Apio';
                                      break;
                                    case 'traceCrustaceans':
                                      traceAllergenImg = <img src={traceCrustaceans} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Crustaceo';
                                      break;
                                    case 'traceEggs':
                                      traceAllergenImg = <img src={traceEggs} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Huevo';
                                      break;
                                    case 'traceFish':
                                      traceAllergenImg = <img src={traceFish} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Pescado';
                                      break;
                                    case 'traceCereals':
                                      traceAllergenImg = <img src={traceCereals} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Gluten';
                                      break;
                                    case 'traceLupin':
                                      traceAllergenImg = <img src={traceLupin} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Lúpulo';
                                      break;
                                    case 'traceMilk':
                                      traceAllergenImg = <img src={traceMilk} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Leche';
                                      break;
                                    case 'traceMolluscs':
                                      traceAllergenImg = <img src={traceMolluscs} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Moluscos';
                                      break;
                                    case 'traceMustard':
                                      traceAllergenImg = <img src={traceMustard} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Mostaza';
                                      break;
                                    case 'traceNuts':
                                      traceAllergenImg = <img src={traceNuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Nueces';
                                      break;
                                    case 'tracePeanuts':
                                      traceAllergenImg = <img src={tracePeanuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Manices';
                                      break;
                                    case 'traceSesame':
                                      traceAllergenImg = <img src={traceSesame} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Sésamo';
                                      break;
                                    case 'traceSoya':
                                      traceAllergenImg = <img src={traceSoya} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Soja';
                                      break;
                                    case 'traceSulphites':
                                      traceAllergenImg = <img src={traceSulphites} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Sulfitos';
                                      break;
                                    default:
                                      traceAllergenImg = <img src={traceCelery} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Apio';
                                      break;
                                  }
                                  return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Grid item lg={3} xs={3}>
                                      <FormControlLabel
                                        control={<Checkbox />}
                                        checked={values.allergens[traceAllergen
                                          .allergen_id - 1] === 1}
                                        name="allergens"
                                        label={(
                                          <>
                                            {traceAllergenImg}
                                            {traceAllergenName}
                                          </>
                                        )}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            values.allergens[traceAllergen.allergen_id - 1] = 1;
                                            values.allergens[traceAllergen
                                              .allergen_id - 1 - 14] = 0;
                                          } else {
                                            values.allergens[traceAllergen.allergen_id - 1] = 0;
                                          }
                                          setFieldValue('allergens', values.allergens);
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>Precio</Form.Label> */}
                                <TextField
                                  label={t('App.InfoLocal.Informacion.AñadirProducto.form.price')}
                                  name="price"
                                  placeholder="Ejemplo: 25"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.price}
                                  error={touched.price && Boolean(errors.price)}
                                  helperText={touched.price && errors.price}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormGroup className="position-relative mb-3">
                                <FormLabel>{t('App.InfoLocal.Informacion.AñadirProducto.form.pictureTitle')}</FormLabel>
                                <input
                                  id="file"
                                  type="file"
                                  name="file"
                                  label={t('App.InfoLocal.Informacion.AñadirProducto.form.pictureInformation')}
                                  onChange={async (event) => {
                                    const image = await resizeFile(event.target.files[0]);
                                    setFieldValue('file', image);
                                  }}
                                  className="form-control"
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={3} xs={3}>
                              <div> </div>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <FormGroup>
                                <Button variant="contained" type="submit" disabled={isSubmitting}>
                                  {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.Carta.Modal.editProduct.buttonForm')}
                                </Button>
                              </FormGroup>
                            </Grid>

                          </Grid>
                        </form>
                      );
                    }}
                  </Formik>
                )}
            </Card>
          </div>
        )
        : (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.editProduct.load')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '4%',
                boxShadow: 24,
                p: 4,
                maxHeight: '95%',
                overflowY: 'scroll',
                width: '90%',
              }}
            >
              <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.editProduct.title')} style={{ marginBottom: '2%' }} />
              {product !== undefined
                && (
                  <Formik
                    initialValues={{
                      name: product.name[language] || '',
                      category: product.categoryId || '',
                      description: product.description[language] || '',
                      ingredients: product.ingredients[language] || '',
                      allergens: arrayAllergen || [],
                      price: product.price !== undefined && product.price !== null ? (product.price.toString()) : '',
                      file: null,
                    }}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required('Este campo es obligatorio'),
                      // category: Yup.string().required('Please select a product').oneOf(),
                      // description: Yup.string().required('Este campo es obligatorio'),
                      // ingredients: Yup.string().required('Required'),
                      price: Yup.string().matches(/^[0-9]+([,.][0-9]+)?$/, 'El valor debe ser un número').required('Este campo es obligatorio'),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        product.name[language] = values.name;
                        product.description[language] = values.description;
                        product.ingredients[language] = values.ingredients;

                        const allergensNumber = [];
                        for (let i = 0; i < values.allergens.length; i += 1) {
                          if (values.allergens[i] === 1) {
                            allergensNumber.push(i + 1);
                          }
                        }
                        const priceProduct = values.price.replace(',', '.');
                        const data = {
                          product: {
                            name: product.name,
                            categoryId: values.category,
                            description: product.description,
                            ingredients: product.ingredients,
                            price: Number(priceProduct),
                            allergens: allergensNumber,
                          },
                        };
                        const consultaAPI = async () => {
                          try {
                            const dataProduct = updateProduct(idProduct, data);
                            if (dataProduct.status === 200) {
                              toast.success(t('App.InfoLocal.Informacion.Carta.Modal.editProduct.toast.editDataProduct'));
                              if (values.file != null) {
                                const formData = new FormData();
                                formData.append('productId', idProduct);
                                formData.append('picture', values.file);
                                const dataImageProduct = addPictureProduct(formData, idProduct);
                                if (dataImageProduct.status === 200) {
                                  toast.success(t('App.InfoLocal.Informacion.Carta.Modal.editProduct.toast.editPictureProduct'));
                                }
                              }
                              setUploadOverlay(true);
                              datos.fetchdata().then(() => {
                                setUploadOverlay(false);
                                datos.onHide();
                              });
                            }
                          } catch (error) {
                            if (error.response.status !== 401) {
                              toast.error(t('newToast.editProduct'));
                            }
                            checkNoAuthError(error, history, toast, t);
                          }
                        };
                        consultaAPI();

                        setSubmitting(false);
                      }, 250);
                    }}
                  >
                    {(_props) => {
                      const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        /* y otras más */
                      } = _props;
                      return (
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}>
                              <FormControl>
                                <FormLabel id="languageObject">{t('App.InfoLocal.Informacion.Carta.Modal.editProduct.language')}</FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="languageObject"
                                  name="language"
                                  value={language}
                                  onChange={(e) => { changeLanguage(e, setFieldValue, values); }}
                                >
                                  <FormControlLabel
                                    value="es"
                                    control={<Radio />}
                                    label={(
                                      <img
                                        src={es}
                                        style={{ height: 24, width: 24 }}
                                        alt="flagEs"
                                      />
                                    )}
                                  />
                                  <FormControlLabel
                                    value="en"
                                    control={<Radio />}
                                    label={(
                                      <img
                                        src={en}
                                        style={{ height: 24, width: 24 }}
                                        alt="flagEn"
                                      />
                                    )}
                                  />
                                  <FormControlLabel
                                    value="fr"
                                    control={<Radio />}
                                    label={(
                                      <img
                                        src={fr}
                                        style={{ height: 24, width: 24 }}
                                        alt="flagFr"
                                      />
                                    )}
                                  />
                                  <FormControlLabel
                                    value="it"
                                    control={<Radio />}
                                    label={(
                                      <img
                                        src={it}
                                        style={{ height: 24, width: 24 }}
                                        alt="flagIt"
                                      />
                                    )}
                                  />
                                  <FormControlLabel
                                    value="de"
                                    control={<Radio />}
                                    label={(
                                      <img
                                        src={de}
                                        style={{ height: 24, width: 24 }}
                                        alt="flagDe"
                                      />
                                    )}
                                  />
                                  <FormControlLabel
                                    value="pt"
                                    control={<Radio />}
                                    label={(
                                      <img
                                        src={pt}
                                        style={{ height: 24, width: 24 }}
                                        alt="flagPt"
                                      />
                                    )}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>Nombre</Form.Label> */}
                                <TextField
                                  label={t('App.InfoLocal.Informacion.AñadirProducto.form.name')}
                                  name="name"
                                  placeholder="Ejemplo: Ensalada mixta"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.name}
                                  error={touched.name && Boolean(errors.name)}
                                  helperText={touched.name && errors.name}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormGroup>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">{t('App.InfoLocal.Informacion.AñadirProducto.form.category.title')}</InputLabel>
                                  <Field
                                    name="category"
                                    component={CustomizedSelectForFormik}
                                    label="Selecciona la Categoría"
                                  >
                                    <MenuItem value={0}>
                                      {t('App.InfoLocal.Informacion.AñadirProducto.form.category.firstOption')}
                                    </MenuItem>
                                    {respuestaAPI.length !== 0
                                      && respuestaAPI.map((category) => (
                                        <MenuItem
                                          value={category.id}
                                          key={category.id}
                                        >
                                          {category.name[language] === null
                                            ? category.name.es : category.name[language]}
                                        </MenuItem>
                                      ))}
                                  </Field>
                                </FormControl>
                              </FormGroup>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>Descripción</Form.Label> */}
                                <TextField
                                  label={t('App.InfoLocal.Informacion.AñadirProducto.form.description')}
                                  name="description"
                                  multiline
                                  rows={2}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.description}
                                  error={touched.description && Boolean(errors.description)}
                                  helperText={touched.description && errors.description}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>Ingredientes</Form.Label> */}
                                <TextField
                                  label={t('App.InfoLocal.Informacion.AñadirProducto.form.ingredients')}
                                  name="ingredients"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.ingredients}
                                  error={touched.ingredients && Boolean(errors.ingredients)}
                                  helperText={touched.ingredients && errors.ingredients}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormLabel>{t('App.InfoLocal.Informacion.AñadirProducto.form.allergens')}</FormLabel>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                              <Grid container rowSpacing={2}>
                                {allergens.allergens.map((allergen) => {
                                  let allergenImg;
                                  let allergenName;
                                  switch (allergen.name) {
                                    case 'celery':
                                      allergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Apio';
                                      break;
                                    case 'crustaceans':
                                      allergenImg = <img src={crustaceans} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Crustaceo';
                                      break;
                                    case 'eggs':
                                      allergenImg = <img src={eggs} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Huevo';
                                      break;
                                    case 'fish':
                                      allergenImg = <img src={fish} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Pescado';
                                      break;
                                    case 'cereals':
                                      allergenImg = <img src={cereals} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Gluten';
                                      break;
                                    case 'lupin':
                                      allergenImg = <img src={lupin} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Lúpulo';
                                      break;
                                    case 'milk':
                                      allergenImg = <img src={milk} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Leche';
                                      break;
                                    case 'molluscs':
                                      allergenImg = <img src={molluscs} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Moluscos';
                                      break;
                                    case 'mustard':
                                      allergenImg = <img src={mustard} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Mostaza';
                                      break;
                                    case 'nuts':
                                      allergenImg = <img src={nuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Nueces';
                                      break;
                                    case 'peanuts':
                                      allergenImg = <img src={peanuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Manices';
                                      break;
                                    case 'sesame':
                                      allergenImg = <img src={sesame} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Sésamo';
                                      break;
                                    case 'soya':
                                      allergenImg = <img src={soya} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Soja';
                                      break;
                                    case 'sulphites':
                                      allergenImg = <img src={sulphites} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Sulfitos';
                                      break;
                                    default:
                                      allergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      allergenName = 'Apio';
                                      break;
                                  }
                                  return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Grid item lg={4} xs={4}>
                                      <FormControlLabel
                                        control={<Checkbox />}
                                        checked={values.allergens[allergen.allergen_id - 1] === 1}
                                        name="allergens"
                                        label={(
                                          <>
                                            {allergenImg}
                                            {allergenName}
                                          </>
                                        )}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            values.allergens[allergen.allergen_id - 1] = 1;
                                            values.allergens[allergen.allergen_id - 1 + 14] = 0;
                                          } else {
                                            values.allergens[allergen.allergen_id - 1] = 0;
                                          }
                                          setFieldValue('allergens', values.allergens);
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormLabel>{t('App.InfoLocal.Informacion.AñadirProducto.form.traceAllergens')}</FormLabel>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                              <Grid container rowSpacing={2}>
                                {traceAllergens.traceAllergens.map((traceAllergen) => {
                                  let traceAllergenImg;
                                  let traceAllergenName;
                                  switch (traceAllergen.name) {
                                    case 'traceCelery':
                                      traceAllergenImg = <img src={traceCelery} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Apio';
                                      break;
                                    case 'traceCrustaceans':
                                      traceAllergenImg = <img src={traceCrustaceans} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Crustaceo';
                                      break;
                                    case 'traceEggs':
                                      traceAllergenImg = <img src={traceEggs} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Huevo';
                                      break;
                                    case 'traceFish':
                                      traceAllergenImg = <img src={traceFish} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Pescado';
                                      break;
                                    case 'traceCereals':
                                      traceAllergenImg = <img src={traceCereals} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Gluten';
                                      break;
                                    case 'traceLupin':
                                      traceAllergenImg = <img src={traceLupin} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Lúpulo';
                                      break;
                                    case 'traceMilk':
                                      traceAllergenImg = <img src={traceMilk} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Leche';
                                      break;
                                    case 'traceMolluscs':
                                      traceAllergenImg = <img src={traceMolluscs} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Moluscos';
                                      break;
                                    case 'traceMustard':
                                      traceAllergenImg = <img src={traceMustard} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Mostaza';
                                      break;
                                    case 'traceNuts':
                                      traceAllergenImg = <img src={traceNuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Nueces';
                                      break;
                                    case 'tracePeanuts':
                                      traceAllergenImg = <img src={tracePeanuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Manices';
                                      break;
                                    case 'traceSesame':
                                      traceAllergenImg = <img src={traceSesame} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Sésamo';
                                      break;
                                    case 'traceSoya':
                                      traceAllergenImg = <img src={traceSoya} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Soja';
                                      break;
                                    case 'traceSulphites':
                                      traceAllergenImg = <img src={traceSulphites} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Sulfitos';
                                      break;
                                    default:
                                      traceAllergenImg = <img src={traceCelery} style={{ height: 32, width: 32 }} alt="allergen" />;
                                      traceAllergenName = 'Apio';
                                      break;
                                  }
                                  return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Grid item lg={4} xs={4}>
                                      <FormControlLabel
                                        control={<Checkbox />}
                                        checked={values.allergens[traceAllergen
                                          .allergen_id - 1] === 1}
                                        name="allergens"
                                        label={(
                                          <>
                                            {traceAllergenImg}
                                            {traceAllergenName}
                                          </>
                                        )}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            values.allergens[traceAllergen.allergen_id - 1] = 1;
                                            values.allergens[traceAllergen
                                              .allergen_id - 1 - 14] = 0;
                                          } else {
                                            values.allergens[traceAllergen.allergen_id - 1] = 0;
                                          }
                                          setFieldValue('allergens', values.allergens);
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>Precio</Form.Label> */}
                                <TextField
                                  label={t('App.InfoLocal.Informacion.AñadirProducto.form.price')}
                                  name="price"
                                  placeholder="Ejemplo: 25"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.price}
                                  error={touched.price && Boolean(errors.price)}
                                  helperText={touched.price && errors.price}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormGroup className="position-relative mb-3">
                                <FormLabel>{t('App.InfoLocal.Informacion.AñadirProducto.form.pictureTitle')}</FormLabel>
                                <input
                                  id="file"
                                  type="file"
                                  name="file"
                                  label={t('App.InfoLocal.Informacion.AñadirProducto.form.pictureInformation')}
                                  onChange={async (event) => {
                                    const image = await resizeFile(event.target.files[0]);
                                    setFieldValue('file', image);
                                  }}
                                  className="form-control"
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={3} xs={3}>
                              <div> </div>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <FormGroup>
                                <Button variant="contained" type="submit" disabled={isSubmitting}>
                                  {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.Carta.Modal.editProduct.buttonForm')}
                                </Button>
                              </FormGroup>
                            </Grid>

                          </Grid>
                        </form>
                      );
                    }}
                  </Formik>
                )}
            </Card>
          </div>
        )}
    </Modal>
  );
}

export function DeleteProductButton(props) {
  const datos = props;
  const idProduct = datos.product === undefined ? null : datos.product.id;
  const [uploadOverlay, setUploadOverlay] = React.useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  async function onClickEvent(productId) {
    try {
      const dataDelete = await deleteProduct(productId);
      if (dataDelete.status === 200) {
        toast.success(t('App.InfoLocal.Informacion.Carta.Modal.deleteProduct.toast.deleteDataProduct'));
        setUploadOverlay(true);
        datos.fetchdata().then(() => {
          setUploadOverlay(false);
          datos.onHide();
        });
      }
    } catch (error) {
      if (error.response.status !== 401) {
        toast.error(t('App.InfoLocal.Informacion.Carta.Modal.deleteProduct.toast.errorDeleteProduct'));
      }
      checkNoAuthError(error, history, toast, t);
    }
  }

  return (
    <Modal
      onClose={datos.onHide}
      open={datos.show}
    >
      {datos.width > 720
        ? (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.deleteProduct.delete')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
              }}
            >
              <CardHeader
                title={t('App.InfoLocal.Informacion.Carta.Modal.deleteProduct.title')}
              />
              <CardContent>
                {t('App.InfoLocal.Informacion.Carta.Modal.deleteProduct.question')}
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    onClickEvent(idProduct);
                  }}
                >
                  {t('App.InfoLocal.Informacion.Carta.Modal.deleteProduct.deleteButton')}
                </Button>
                <Button variant="contained" onClick={datos.onHide}>{t('App.InfoLocal.Informacion.Carta.Modal.deleteProduct.comeBackButton')}</Button>
              </CardActions>
            </Card>
          </div>
        ) : (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.deleteProduct.delete')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
                maxHeight: '95%',
                width: '90%',
              }}
            >
              <CardHeader
                title={t('App.InfoLocal.Informacion.Carta.Modal.deleteProduct.title')}
              />
              <CardContent>
                {t('App.InfoLocal.Informacion.Carta.Modal.deleteProduct.question')}
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    onClickEvent(idProduct);
                  }}
                >
                  {t('App.InfoLocal.Informacion.Carta.Modal.deleteProduct.deleteButton')}
                </Button>
                <Button variant="contained" onClick={datos.onHide}>{t('App.InfoLocal.Informacion.Carta.Modal.deleteProduct.comeBackButton')}</Button>
              </CardActions>
            </Card>
          </div>
        )}
    </Modal>
  );
}

export function AddLanguageProductModal(props) {
  const datos = props;
  const [uploadOverlay, setUploadOverlay] = React.useState(false);
  const { t } = useTranslation();
  const [language, setLanguage] = React.useState('');
  const [product, setProduct] = React.useState(undefined);
  const history = useHistory();

  useEffect(() => {
    setLanguage(datos.language);
    setProduct(datos.product);
  }, [datos.product]);

  const changeLanguage = (event, setFieldValue, values) => {
    product.name[language] = values.name;
    product.description[language] = values.description;
    product.ingredients[language] = values.ingredients;

    setLanguage(event.target.value);
    setFieldValue('name', product.name[event.target.value] || '');
    setFieldValue('description', product.description[event.target.value] || '');
    setFieldValue('ingredients', product.ingredients[event.target.value] || '');
  };
  return (
    <Modal
      open={datos.show}
      onClose={datos.onHide}
    >
      {datos.width > 720
        ? (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.addCategory.load')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
              }}
            >
              {/* {showSuccess && AddCategoryResponseSuccess(setShowSuccess)}
              {showFail && AddCategoryResponseFail(setShowFail)} */}
              <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.addLanguageProductModal.title')} />
              <CardContent>
                {product !== undefined
                  && (
                    <Formik
                      initialValues={{
                        name: product.name[language] || '',
                        description: product.description[language] || '',
                        ingredients: product.ingredients[language] || '',
                      }}
                      validationSchema={Yup.object().shape({
                        // name: Yup.string().required(t('App.InfoLocal.
                        // Informacion.Carta.Modal.addCategory.form.yup.required.name')),
                        // description: Yup.string().required('Required'),
                        // ingredients: Yup.string().required('Required'),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                          product.name[language] = values.name;
                          product.description[language] = values.description;
                          product.ingredients[language] = values.ingredients;

                          const data = {
                            product: {
                              name: product.name,
                              description: product.description,
                              ingredients: product.ingredients,
                              // shopId: shopIdActual,
                            },
                          };
                          const consultaAPI = async () => {
                            try {
                              const dataLanguage = updateProduct(datos.product.id, data);
                              if (dataLanguage.status === 200) {
                                toast.success(t('newToast.addLanguageProduct.success'));
                                setUploadOverlay(true);
                                datos.fetchdata().then(() => {
                                  setUploadOverlay(false);
                                  datos.onHide();
                                });
                              }
                            } catch (error) {
                              if (error.response.status !== 401) {
                                toast.error(t('newToast.addLanguageProduct.error'));
                              }
                              checkNoAuthError(error, history, toast, t);
                            }
                          };
                          consultaAPI();
                          setSubmitting(false);
                        }, 250);
                      }}
                    >
                      {(_props) => {
                        const {
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          /* y otras más */
                        } = _props;
                        return (
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                              <Grid item lg={12} xs={12}>
                                <FormControl>
                                  <FormLabel id="languageObject">{t('App.InfoLocal.Informacion.Carta.Modal.addLanguageProductModal.language')}</FormLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="languageObject"
                                    name="language"
                                    value={language}
                                    onChange={(e) => { changeLanguage(e, setFieldValue, values); }}
                                  >
                                    <FormControlLabel
                                      value="en"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={en}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagEn"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="fr"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={fr}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagFr"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="it"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={it}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagIt"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="de"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={de}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagDe"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="pt"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={pt}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagPt"
                                        />
                                      )}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>

                              <Grid item lg={12} xs={12}>
                                <FormGroup>
                                  {/* <Form.Label>Categoría</Form.Label> */}
                                  <TextField
                                    label={t('App.InfoLocal.Informacion.AñadirProducto.form.name')}
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                  />
                                </FormGroup>
                              </Grid>

                              <Grid item lg={12} xs={12}>
                                <FormGroup>
                                  <TextField
                                    label={t('App.InfoLocal.Informacion.AñadirProducto.form.description')}
                                    name="description"
                                    multiline
                                    rows={4}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description}
                                    error={touched.description && Boolean(errors.description)}
                                    helperText={touched.description && errors.description}
                                  />
                                </FormGroup>
                              </Grid>

                              <Grid item lg={12} xs={12}>
                                <FormGroup>
                                  {/* <Form.Label>Ingredientes</Form.Label> */}
                                  <TextField
                                    label={t('App.InfoLocal.Informacion.AñadirProducto.form.ingredients')}
                                    name="ingredients"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.ingredients}
                                    error={touched.ingredients && Boolean(errors.ingredients)}
                                    helperText={touched.ingredients && errors.ingredients}
                                  />
                                </FormGroup>
                              </Grid>

                              <Grid item lg={4} xs={4}>
                                <Button variant="contained" type="submit" disabled={isSubmitting}>
                                  {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.AñadirProducto.form.buttonForm')}
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        );
                      }}
                    </Formik>
                  )}

              </CardContent>
            </Card>
          </div>
        )
        : (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.addCategory.load')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
                maxHeight: '95%',
                width: '90%',
              }}
            >
              {/* {showSuccess && AddCategoryResponseSuccess(setShowSuccess)}
              {showFail && AddCategoryResponseFail(setShowFail)} */}
              <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.addLanguageProductModal.title')} />
              <CardContent>
                {product !== undefined
                  && (
                    <Formik
                      initialValues={{
                        name: product.name[language] || '',
                        description: product.description[language] || '',
                        ingredients: product.ingredients[language] || '',
                      }}
                      validationSchema={Yup.object().shape({
                        // name: Yup.string().required(t('App.InfoLocal.
                        // Informacion.Carta.Modal.addCategory.form.yup.required.name')),
                        // description: Yup.string().required('Required'),
                        // ingredients: Yup.string().required('Required'),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                          product.name[language] = values.name;
                          product.description[language] = values.description;
                          product.ingredients[language] = values.ingredients;

                          const data = {
                            product: {
                              name: product.name,
                              description: product.description,
                              ingredients: product.ingredients,
                              // shopId: shopIdActual,
                            },
                          };
                          const consultaAPI = async () => {
                            try {
                              const dataLanguage = updateProduct(datos.product.id, data);
                              if (dataLanguage.status === 200) {
                                toast.success(t('newToast.addLanguageProduct.success'));
                                setUploadOverlay(true);
                                datos.fetchdata().then(() => {
                                  setUploadOverlay(false);
                                  datos.onHide();
                                });
                              }
                            } catch (error) {
                              if (error.response.status !== 401) {
                                toast.error(t('newToast.addLanguageProduct.error'));
                              }
                              checkNoAuthError(error, history, toast, t);
                            }
                          };
                          consultaAPI();
                          setSubmitting(false);
                        }, 250);
                      }}
                    >
                      {(_props) => {
                        const {
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          /* y otras más */
                        } = _props;
                        return (
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                              <Grid item lg={12} xs={12}>
                                <FormControl>
                                  <FormLabel id="languageObject">{t('App.InfoLocal.Informacion.Carta.Modal.addLanguageProductModal.language')}</FormLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="languageObject"
                                    name="language"
                                    value={language}
                                    onChange={(e) => { changeLanguage(e, setFieldValue, values); }}
                                  >
                                    <FormControlLabel
                                      value="en"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={en}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagEn"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="fr"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={fr}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagFr"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="it"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={it}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagIt"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="de"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={de}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagDe"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="pt"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={pt}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagPt"
                                        />
                                      )}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>

                              <Grid item lg={12} xs={12}>
                                <FormGroup>
                                  <TextField
                                    label={t('App.InfoLocal.Informacion.AñadirProducto.form.name')}
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                  />
                                </FormGroup>
                              </Grid>

                              <Grid item lg={12} xs={12}>
                                <FormGroup>
                                  <TextField
                                    label={t('App.InfoLocal.Informacion.AñadirProducto.form.description')}
                                    name="description"
                                    multiline
                                    rows={4}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description}
                                    error={touched.description && Boolean(errors.description)}
                                    helperText={touched.description && errors.description}
                                  />
                                </FormGroup>
                              </Grid>

                              <Grid item lg={12} xs={12}>
                                <FormGroup>
                                  {/* <Form.Label>Ingredientes</Form.Label> */}
                                  <TextField
                                    label={t('App.InfoLocal.Informacion.AñadirProducto.form.ingredients')}
                                    name="ingredients"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.ingredients}
                                    error={touched.ingredients && Boolean(errors.ingredients)}
                                    helperText={touched.ingredients && errors.ingredients}
                                  />
                                </FormGroup>
                              </Grid>

                              <Grid item lg={4} xs={4}>
                                <Button variant="contained" type="submit" disabled={isSubmitting}>
                                  {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.AñadirProducto.form.buttonForm')}
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        );
                      }}
                    </Formik>
                  )}
              </CardContent>
            </Card>
          </div>
        )}
    </Modal>
  );
}

export function InfoProductCart(props) {
  // const shopIdActual = localStorage.getItem('shop_id');
  // const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'));
  const { t } = useTranslation();

  const [showInfoAllergens, setShowInfoAllergens] = React.useState(false);
  const [allergensProduct, setAllergensProduct] = React.useState([]);
  const [traceAllergensProduct, setTraceAllergensProduct] = React.useState([]);

  const datos = props;

  // const idProduct = datos.product === undefined ? '' : datos.product.id;

  function closeModal() {
    datos.onHide();
  }

  function fetchData() {
    const auxAllergensProduct = [];
    const auxTraceAllergensProduct = [];
    if (datos.product !== undefined && datos.product.allergens.length !== 0) {
      for (let i = 0; i < datos.product.allergens.length; i += 1) {
        if (datos.product.allergens[i].id > 14) {
          auxTraceAllergensProduct.push(datos.product.allergens[i]);
        } else {
          auxAllergensProduct.push(datos.product.allergens[i]);
        }
      }
    }
    setAllergensProduct(auxAllergensProduct);
    setTraceAllergensProduct(auxTraceAllergensProduct);
  }

  useEffect(() => {
    fetchData();
  }, [datos]);

  return (
    // eslint-disable-next-line no-nested-ternary
    showInfoAllergens
      ? (
        datos.width > 720
          ? (
            <Modal
              open={datos.show}
              onClose={datos.onHide}
            >
              <Card
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  // maxWidth: '2000px',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#FFFFFF',
                  // border: '2px solid #000',
                  padding: '1%',
                  boxShadow: 24,
                  p: 4,
                  maxHeight: '95%',
                  minWidth: '400px',
                  maxWidth: '450px',
                }}
              >
                {/* <Button
              onClick={() => {
                setShowInfoAllergens(false);
              }}
            >
              <ArrowBackIcon />
            </Button> */}
                <Typography align="center" variant="h4" style={{ marginBottom: '15px' }}>
                  <Button
                    onClick={() => {
                      setShowInfoAllergens(false);
                    }}
                  >
                    <ArrowBackIcon />
                  </Button>
                  {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.allergens.title')}
                </Typography>
                <Grid container rowSpacing={2} style={{ justifyContent: 'center' }}>
                  {allergens.allergens.map((allergen) => {
                    let allergenImg;
                    let allergenName;
                    switch (allergen.name) {
                      case 'celery':
                        allergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Apio';
                        break;
                      case 'crustaceans':
                        allergenImg = <img src={crustaceans} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Crustaceo';
                        break;
                      case 'eggs':
                        allergenImg = <img src={eggs} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Huevo';
                        break;
                      case 'fish':
                        allergenImg = <img src={fish} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Pescado';
                        break;
                      case 'cereals':
                        allergenImg = <img src={cereals} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Gluten';
                        break;
                      case 'lupin':
                        allergenImg = <img src={lupin} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Lúpulo';
                        break;
                      case 'milk':
                        allergenImg = <img src={milk} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Leche';
                        break;
                      case 'molluscs':
                        allergenImg = <img src={molluscs} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Moluscos';
                        break;
                      case 'mustard':
                        allergenImg = <img src={mustard} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Mostaza';
                        break;
                      case 'nuts':
                        allergenImg = <img src={nuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Nueces';
                        break;
                      case 'peanuts':
                        allergenImg = <img src={peanuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Manices';
                        break;
                      case 'sesame':
                        allergenImg = <img src={sesame} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Sésamo';
                        break;
                      case 'soya':
                        allergenImg = <img src={soya} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Soja';
                        break;
                      case 'sulphites':
                        allergenImg = <img src={sulphites} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Sulfitos';
                        break;
                      default:
                        allergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Apio';
                        break;
                    }
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid item lg={3} xs={4} style={{ textAlign: 'center' }}>
                        {allergenImg}
                        <br />
                        {allergenName}
                      </Grid>
                    );
                  })}
                </Grid>
                <Typography align="center" variant="h4" style={{ marginBottom: '15px', marginTop: '15px' }}>
                  {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.traceAllergens')}
                </Typography>
                <Grid container rowSpacing={2} style={{ justifyContent: 'center' }}>
                  {traceAllergens.traceAllergens.map((traceAllergen) => {
                    let traceAllergenImg;
                    let traceAllergenName;
                    switch (traceAllergen.name) {
                      case 'traceCelery':
                        traceAllergenImg = <img src={traceCelery} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Apio';
                        break;
                      case 'traceCrustaceans':
                        traceAllergenImg = <img src={traceCrustaceans} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Crustaceo';
                        break;
                      case 'traceEggs':
                        traceAllergenImg = <img src={traceEggs} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Huevo';
                        break;
                      case 'traceFish':
                        traceAllergenImg = <img src={traceFish} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Pescado';
                        break;
                      case 'traceCereals':
                        traceAllergenImg = <img src={traceCereals} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Gluten';
                        break;
                      case 'traceLupin':
                        traceAllergenImg = <img src={traceLupin} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Lúpulo';
                        break;
                      case 'traceMilk':
                        traceAllergenImg = <img src={traceMilk} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Leche';
                        break;
                      case 'traceMolluscs':
                        traceAllergenImg = <img src={traceMolluscs} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Moluscos';
                        break;
                      case 'traceMustard':
                        traceAllergenImg = <img src={traceMustard} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Mostaza';
                        break;
                      case 'traceNuts':
                        traceAllergenImg = <img src={traceNuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Nueces';
                        break;
                      case 'tracePeanuts':
                        traceAllergenImg = <img src={tracePeanuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Manices';
                        break;
                      case 'traceSesame':
                        traceAllergenImg = <img src={traceSesame} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Sésamo';
                        break;
                      case 'traceSoya':
                        traceAllergenImg = <img src={traceSoya} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Soja';
                        break;
                      case 'traceSulphites':
                        traceAllergenImg = <img src={traceSulphites} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Sulfitos';
                        break;
                      default:
                        traceAllergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Apio';
                        break;
                    }
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid item lg={3} xs={4} style={{ textAlign: 'center' }}>
                        {traceAllergenImg}
                        <br />
                        {traceAllergenName}
                      </Grid>
                    );
                  })}
                  {/* </FormGroup> */}
                </Grid>
              </Card>
            </Modal>
          )
          : (
            <Modal
              open={datos.show}
              onClose={datos.onHide}
            >
              <Card
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  // maxWidth: '2000px',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#FFFFFF',
                  // border: '2px solid #000',
                  // padding: '16px',
                  boxShadow: 24,
                  p: 4,
                  maxHeight: '95%',
                  overflowY: 'scroll',
                  width: '90%',
                }}
              >
                {/* <Button
              onClick={() => {
                setShowInfoAllergens(false);
              }}
            >
              <ArrowBackIcon />
            </Button> */}
                <Typography align="center" variant="h4" style={{ marginBottom: '15px' }}>
                  <Button
                    onClick={() => {
                      setShowInfoAllergens(false);
                    }}
                  >
                    <ArrowBackIcon />
                  </Button>
                  Alérgenos
                </Typography>
                <Grid container rowSpacing={2} style={{ justifyContent: 'center' }}>
                  {allergens.allergens.map((allergen) => {
                    let allergenImg;
                    let allergenName;
                    switch (allergen.name) {
                      case 'celery':
                        allergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Apio';
                        break;
                      case 'crustaceans':
                        allergenImg = <img src={crustaceans} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Crustaceo';
                        break;
                      case 'eggs':
                        allergenImg = <img src={eggs} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Huevo';
                        break;
                      case 'fish':
                        allergenImg = <img src={fish} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Pescado';
                        break;
                      case 'cereals':
                        allergenImg = <img src={cereals} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Gluten';
                        break;
                      case 'lupin':
                        allergenImg = <img src={lupin} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Lúpulo';
                        break;
                      case 'milk':
                        allergenImg = <img src={milk} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Leche';
                        break;
                      case 'molluscs':
                        allergenImg = <img src={molluscs} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Moluscos';
                        break;
                      case 'mustard':
                        allergenImg = <img src={mustard} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Mostaza';
                        break;
                      case 'nuts':
                        allergenImg = <img src={nuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Nueces';
                        break;
                      case 'peanuts':
                        allergenImg = <img src={peanuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Manices';
                        break;
                      case 'sesame':
                        allergenImg = <img src={sesame} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Sésamo';
                        break;
                      case 'soya':
                        allergenImg = <img src={soya} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Soja';
                        break;
                      case 'sulphites':
                        allergenImg = <img src={sulphites} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Sulfitos';
                        break;
                      default:
                        allergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                        allergenName = 'Apio';
                        break;
                    }
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid item lg={3} xs={4} style={{ textAlign: 'center' }}>
                        {allergenImg}
                        <br />
                        {allergenName}
                      </Grid>
                    );
                  })}
                </Grid>
                <Typography align="center" variant="h4" style={{ marginBottom: '15px', marginTop: '15px' }}>
                  Traza Alérgenos
                </Typography>
                <Grid container rowSpacing={2} style={{ justifyContent: 'center' }}>
                  {traceAllergens.traceAllergens.map((traceAllergen) => {
                    let traceAllergenImg;
                    let traceAllergenName;
                    switch (traceAllergen.name) {
                      case 'traceCelery':
                        traceAllergenImg = <img src={traceCelery} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Apio';
                        break;
                      case 'traceCrustaceans':
                        traceAllergenImg = <img src={traceCrustaceans} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Crustaceo';
                        break;
                      case 'traceEggs':
                        traceAllergenImg = <img src={traceEggs} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Huevo';
                        break;
                      case 'traceFish':
                        traceAllergenImg = <img src={traceFish} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Pescado';
                        break;
                      case 'traceCereals':
                        traceAllergenImg = <img src={traceCereals} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Gluten';
                        break;
                      case 'traceLupin':
                        traceAllergenImg = <img src={traceLupin} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Lúpulo';
                        break;
                      case 'traceMilk':
                        traceAllergenImg = <img src={traceMilk} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Leche';
                        break;
                      case 'traceMolluscs':
                        traceAllergenImg = <img src={traceMolluscs} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Moluscos';
                        break;
                      case 'traceMustard':
                        traceAllergenImg = <img src={traceMustard} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Mostaza';
                        break;
                      case 'traceNuts':
                        traceAllergenImg = <img src={traceNuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Nueces';
                        break;
                      case 'tracePeanuts':
                        traceAllergenImg = <img src={tracePeanuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Manices';
                        break;
                      case 'traceSesame':
                        traceAllergenImg = <img src={traceSesame} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Sésamo';
                        break;
                      case 'traceSoya':
                        traceAllergenImg = <img src={traceSoya} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Soja';
                        break;
                      case 'traceSulphites':
                        traceAllergenImg = <img src={traceSulphites} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Sulfitos';
                        break;
                      default:
                        traceAllergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                        traceAllergenName = 'Apio';
                        break;
                    }
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid item lg={3} xs={4} style={{ textAlign: 'center' }}>
                        {traceAllergenImg}
                        <br />
                        {traceAllergenName}
                      </Grid>
                    );
                  })}
                  {/* </FormGroup> */}
                </Grid>
              </Card>
            </Modal>
          )
      ) : (
        <Modal
          open={datos.show}
          onClose={datos.onHide}
        >
          {datos.product === undefined
            ? (
              <Typography variant="spam">
                {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.noInformation')}
              </Typography>
            )
            : (
              datos.product !== undefined
              && (
                datos.width > 720
                  ? (
                    <Card
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        // maxWidth: '2000px',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: '#FFFFFF',
                        // border: '2px solid #000',
                        padding: '1%',
                        boxShadow: 24,
                        p: 4,
                        maxHeight: '95%',
                        minWidth: '400px',
                        maxWidth: '450px',
                      }}
                    >
                      {datos.product.pictures.length !== 0
                        ? (
                          <CardMedia
                            component="img"
                            height="194"
                            image={datos.image}
                            alt="Paella dish"
                            sx={{ objectFit: 'contain', padding: '8px', boxSizing: 'border-box' }}
                          />
                        ) : (
                          <div
                            style={{
                              height: '194px',
                              width: '100%',
                              // padding: '8px',
                              boxSizing: 'border-box',
                              backgroundColor: '#ECECEC',
                            }}
                          />
                        )}
                      <CardContent>
                        <Typography align="center" variant="h4" style={{ marginBottom: '4px' }}>
                          {uppercase(datos.product.name[datos.language])}
                        </Typography>
                        <Typography align="center" variant="body1" style={{ marginBottom: '5%' }}>
                          {uppercase(datos.product.description[datos.language])}
                        </Typography>
                        <div className="divider div-transparent" style={datos.product.ingredients[datos.language] === null ? { display: 'none' } : {}} />
                        <div style={datos.product.ingredients[datos.language] === null ? { display: 'none' } : {}}>
                          <Typography align="center" variant="h6">
                            {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.ingredients')}
                          </Typography>
                          <Typography align="center" variant="body1" style={{ marginBottom: '5%' }}>
                            {uppercase(datos.product.ingredients[datos.language])}
                          </Typography>
                        </div>
                        <Typography align="center" variant="body1" style={{ marginBottom: '5%' }}>
                          {datos.product.ingredients[datos.language]}
                        </Typography>
                        <div className="divider div-transparent" />
                        <Typography align="center" variant="h6">
                          {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.allergens.title')}
                          <Button
                            onClick={() => {
                              setShowInfoAllergens(true);
                            }}
                            style={{ minWidth: '0px' }}
                          >
                            <InfoOutlinedIcon style={{ color: 'black' }} />
                          </Button>
                        </Typography>
                        {allergensProduct.length !== 0
                          ? (
                            <Grid container style={{ marginBottom: '5%', justifyContent: 'center' }}>
                              {/* <Grid item lg={12} xs={12}> */}
                              {allergensProduct.map((allergenProduct) => {
                                switch (allergenProduct.name) {
                                  case 'celery':
                                    return (
                                      <img
                                        src={celery}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'crustaceans':
                                    return (
                                      <img
                                        src={crustaceans}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'eggs':
                                    return (
                                      <img
                                        src={eggs}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'fish':
                                    return (
                                      <img
                                        src={fish}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'cereals':
                                    return (
                                      <img
                                        src={cereals}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'lupin':
                                    return (
                                      <img
                                        src={lupin}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'milk':
                                    return (
                                      <img
                                        src={milk}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'molluscs':
                                    return (
                                      <img
                                        src={molluscs}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'mustard':
                                    return (
                                      <img
                                        src={mustard}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'nuts':
                                    return (
                                      <img
                                        src={nuts}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'peanuts':
                                    return (
                                      <img
                                        src={peanuts}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'sesame':
                                    return (
                                      <img
                                        src={sesame}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'soya':
                                    return (
                                      <img
                                        src={soya}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'sulphites':
                                    return (
                                      <img
                                        src={sulphites}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  default:
                                    return (
                                      <img
                                        src={celery}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                }
                              })}
                              {/* </Grid> */}
                            </Grid>
                          )
                          : (
                            <Typography variant="body1" align="center">
                              {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.allergens.noAllergens')}
                            </Typography>
                          )}
                        <div className="divider div-transparent" />
                        <Typography align="center" variant="h6">
                          {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.traceAllergens')}
                          <Button
                            onClick={() => {
                              setShowInfoAllergens(true);
                            }}
                            style={{ minWidth: '0px' }}
                          >
                            <InfoOutlinedIcon style={{ color: 'black', minWidth: '0px' }} />
                          </Button>
                        </Typography>
                        {traceAllergensProduct.length !== 0
                          ? (
                            <Grid container style={{ marginBottom: '5%', justifyContent: 'center' }}>
                              {/* <Grid item lg={12} xs={12}> */}
                              {traceAllergensProduct.map((traceAllergenProduct) => {
                                switch (traceAllergenProduct.name) {
                                  case 'tcelery':
                                    return (
                                      <img
                                        src={traceCelery}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tcrustaceans':
                                    return (
                                      <img
                                        src={traceCrustaceans}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'teggs':
                                    return (
                                      <img
                                        src={traceEggs}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tfish':
                                    return (
                                      <img
                                        src={traceFish}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tcereals':
                                    return (
                                      <img
                                        src={traceCereals}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tlupin':
                                    return (
                                      <img
                                        src={traceLupin}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tmilk':
                                    return (
                                      <img
                                        src={traceMilk}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tmolluscs':
                                    return (
                                      <img
                                        src={traceMolluscs}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tmustard':
                                    return (
                                      <img
                                        src={traceMustard}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tnuts':
                                    return (
                                      <img
                                        src={traceNuts}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tpeanuts':
                                    return (
                                      <img
                                        src={tracePeanuts}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tsesame':
                                    return (
                                      <img
                                        src={traceSesame}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tsoya':
                                    return (
                                      <img
                                        src={traceSoya}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tsulphites':
                                    return (
                                      <img
                                        src={traceSulphites}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  default:
                                    return (
                                      <img
                                        src={traceCelery}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                }
                              })}
                              {/* </Grid> */}
                            </Grid>
                          )
                          : (
                            <Typography variant="body1" align="center">
                              {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.allergens.noAllergens')}
                            </Typography>
                          )}
                        <div className="divider div-transparent" />
                        <Typography align="center" variant="h6">
                          {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.price')}
                        </Typography>
                        <Typography align="center" variant="body1">
                          {datos.product.price}
                          {' '}
                          €
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '24px' }}>
                          <Button variant="text" onClick={() => { closeModal(); }}>
                            {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.closeButton')}
                          </Button>
                        </div>
                      </CardContent>
                    </Card>
                  )
                  : (
                    <Card
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        // maxWidth: '2000px',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: '#FFFFFF',
                        // border: '2px solid #000',
                        // padding: '16px',
                        boxShadow: 24,
                        p: 4,
                        maxHeight: '95%',
                        overflowY: 'scroll',
                        width: '90%',
                      }}
                    >
                      {datos.product.pictures.length !== 0
                        ? (
                          <CardMedia
                            component="img"
                            height="194"
                            image={datos.image}
                            alt="Paella dish"
                            sx={{ objectFit: 'contain', padding: '8px', boxSizing: 'border-box' }}
                          />
                        ) : (
                          <div
                            style={{
                              height: '194px',
                              width: '100%',
                              // padding: '8px',
                              boxSizing: 'border-box',
                              backgroundColor: '#ECECEC',
                            }}
                          />
                        )}
                      <CardContent>
                        <Typography align="center" variant="h4" style={{ marginBottom: '4px' }}>
                          {uppercase(datos.product.name[datos.language])}
                        </Typography>
                        <Typography align="center" variant="body1" style={{ marginBottom: '5%' }}>
                          {uppercase(datos.product.description[datos.language])}
                        </Typography>
                        <div className="divider div-transparent" sx={datos.product.ingredients === '' ? { display: 'none' } : {}} />
                        <Typography align="center" variant="h6">
                          {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.ingredients')}
                        </Typography>
                        <Typography align="center" variant="body1" style={{ marginBottom: '5%' }}>
                          {uppercase(datos.product.ingredients[datos.language])}
                        </Typography>
                        <div className="divider div-transparent" />
                        <Typography align="center" variant="h6">
                          {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.allergens.title')}
                          <Button
                            onClick={() => {
                              setShowInfoAllergens(true);
                            }}
                            style={{ minWidth: '0px' }}
                          >
                            <InfoOutlinedIcon style={{ color: 'black', width: '24px' }} />
                          </Button>
                        </Typography>
                        {allergensProduct.length !== 0
                          ? (
                            <Grid container style={{ marginBottom: '5%', justifyContent: 'center' }}>
                              {/* <Grid item lg={12} xs={12}> */}
                              {allergensProduct.map((allergenProduct) => {
                                switch (allergenProduct.name) {
                                  case 'celery':
                                    return (
                                      <img
                                        src={celery}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'crustaceans':
                                    return (
                                      <img
                                        src={crustaceans}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'eggs':
                                    return (
                                      <img
                                        src={eggs}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'fish':
                                    return (
                                      <img
                                        src={fish}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'cereals':
                                    return (
                                      <img
                                        src={cereals}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'lupin':
                                    return (
                                      <img
                                        src={lupin}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'milk':
                                    return (
                                      <img
                                        src={milk}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'molluscs':
                                    return (
                                      <img
                                        src={molluscs}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'mustard':
                                    return (
                                      <img
                                        src={mustard}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'nuts':
                                    return (
                                      <img
                                        src={nuts}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'peanuts':
                                    return (
                                      <img
                                        src={peanuts}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'sesame':
                                    return (
                                      <img
                                        src={sesame}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'soya':
                                    return (
                                      <img
                                        src={soya}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'sulphites':
                                    return (
                                      <img
                                        src={sulphites}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  default:
                                    return (
                                      <img
                                        src={celery}
                                        style={{ height: 32, width: 32 }}
                                        key={allergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                }
                              })}
                              {/* </Grid> */}
                            </Grid>
                          )
                          : (
                            <Typography variant="body1" align="center">
                              {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.allergens.noAllergens')}
                            </Typography>
                          )}
                        <div className="divider div-transparent" />
                        <Typography align="center" variant="h6">
                          {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.traceAllergens')}
                          <Button
                            onClick={() => {
                              setShowInfoAllergens(true);
                            }}
                            style={{ minWidth: '0px' }}
                          >
                            <InfoOutlinedIcon style={{ color: 'black', width: '24px' }} />
                          </Button>
                        </Typography>
                        {traceAllergensProduct.length !== 0
                          ? (
                            <Grid container style={{ marginBottom: '5%', justifyContent: 'center' }}>
                              {/* <Grid item lg={12} xs={12}> */}
                              {traceAllergensProduct.map((traceAllergenProduct) => {
                                switch (traceAllergenProduct.name) {
                                  case 'tcelery':
                                    return (
                                      <img
                                        src={traceCelery}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tcrustaceans':
                                    return (
                                      <img
                                        src={traceCrustaceans}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'teggs':
                                    return (
                                      <img
                                        src={traceEggs}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tfish':
                                    return (
                                      <img
                                        src={traceFish}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tcereals':
                                    return (
                                      <img
                                        src={traceCereals}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tlupin':
                                    return (
                                      <img
                                        src={traceLupin}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tmilk':
                                    return (
                                      <img
                                        src={traceMilk}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tmolluscs':
                                    return (
                                      <img
                                        src={traceMolluscs}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tmustard':
                                    return (
                                      <img
                                        src={traceMustard}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tnuts':
                                    return (
                                      <img
                                        src={traceNuts}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tpeanuts':
                                    return (
                                      <img
                                        src={tracePeanuts}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tsesame':
                                    return (
                                      <img
                                        src={traceSesame}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tsoya':
                                    return (
                                      <img
                                        src={traceSoya}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  case 'tsulphites':
                                    return (
                                      <img
                                        src={traceSulphites}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                  default:
                                    return (
                                      <img
                                        src={traceCelery}
                                        style={{ height: 32, width: 32 }}
                                        key={traceAllergenProduct.id}
                                        alt="allergen"
                                      />
                                    );
                                }
                              })}
                              {/* </Grid> */}
                            </Grid>
                          )
                          : (
                            <Typography variant="body1" align="center">
                              {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.allergens.noAllergens')}
                            </Typography>
                          )}
                        <div className="divider div-transparent" />
                        <Typography align="center" variant="h6">
                          {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.price')}
                        </Typography>
                        <Typography align="center" variant="body1">
                          {datos.product.price}
                          {' '}
                          €
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '24px' }}>
                          <Button variant="text" onClick={() => { closeModal(); }}>
                            {t('App.InfoLocal.Informacion.Carta.Modal.infoProductCart.closeButton')}
                          </Button>
                        </div>
                      </CardContent>
                    </Card>
                  )
              )
            )}
        </Modal>
      )
  );
}

export function EditProfile(props) {
  // const urlProfile = `http://mesoneroapi.duonion.com:7072/web/employee/${props.employeeData.employee.employee_id}`;
  const datos = props;
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <Modal
      onClose={datos.onHide}
      open={datos.show}
    >
      {width > 720
        ? (
          <Card
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              // maxWidth: '2000px',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#FFFFFF',
              // border: '2px solid #000',
              padding: '1%',
              boxShadow: 24,
              p: 4,
              maxHeight: '95%',
            }}
          >
            <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.editProfile.title')} style={{ marginBottom: '2%' }} />
            <CardContent>
              <Typography>
                {t('App.InfoLocal.Informacion.Carta.Modal.editProfile.information')}
              </Typography>
            </CardContent>
            <CardActions>
              <Link to="/Contacto">
                <Button variant="contained">
                  {t('App.InfoLocal.Informacion.Carta.Modal.editProfile.button')}
                </Button>
              </Link>
            </CardActions>
          </Card>
        ) : (
          <Card
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              // maxWidth: '2000px',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#FFFFFF',
              // border: '2px solid #000',
              padding: '4%',
              boxShadow: 24,
              p: 4,
              maxHeight: '95%',
              overflowY: 'scroll',
              width: '90%',
            }}
          >
            <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.editProfile.title')} style={{ marginBottom: '2%' }} />
            <CardContent>
              <Typography>
                {t('App.InfoLocal.Informacion.Carta.Modal.editProfile.information')}
              </Typography>
            </CardContent>
            <CardActions>
              <Link to="/Contacto">
                <Button variant="contained">
                  {t('App.InfoLocal.Informacion.Carta.Modal.editProfile.button')}
                </Button>
              </Link>
            </CardActions>
          </Card>
        )}
    </Modal>
  );
}

export function EditShop(props) {
  const shopIdActual = localStorage.getItem('shop_id');
  const datos = props;
  const { width } = useWindowDimensions();
  const [uploadOverlay, setUploadOverlay] = React.useState(false);
  const history = useHistory();

  const { t } = useTranslation();

  return (
    <Modal
      onClose={datos.onHide}
      open={datos.show}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {datos.shop.length === 0
        ? (<div />)
        : (
          width <= 720
            ? (
              <div>
                {uploadOverlay
                  && (
                    <div className="uploadData">
                      <CircularProgress />
                      <Typography sx={{ color: '#ffffff' }}>
                        {t('App.InfoLocal.Informacion.Carta.Modal.editShop.load')}
                      </Typography>
                    </div>
                  )}
                <Card
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    // maxWidth: '2000px',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#FFFFFF',
                    // border: '2px solid #000',
                    padding: '4%',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '95%',
                    overflowY: 'scroll',
                    width: '90%',
                  }}
                >
                  <h2 style={{ textAlign: 'center' }}>{t('App.InfoLocal.Informacion.Carta.Modal.editShop.title')}</h2>
                  <Formik
                    initialValues={{
                      name: datos.shop.name || '',
                      address: datos.shop.address || '',
                      website: datos.shop.website || '',
                      capacity: datos.shop.capacity || '',
                      tables_number: datos.shop.tablesNumber || '',
                      country: datos.shop.country || '',
                      province: datos.shop.province || '',
                      postalCode: datos.shop.postalCode || '',
                      phoneNumber: datos.shop.phoneNumber || '',
                    }}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required(t('App.Cuenta.AñadirLocal.form.yup.name')),
                      address: Yup.string().required(t('App.Cuenta.AñadirLocal.form.yup.address')),
                      capacity: Yup.string().matches(/^[0-9]+$/gi, 'El valor debe ser un número'),
                      tables_number: Yup.string().matches(/^[0-9]+$/gi, 'El valor debe ser un número').required(t('App.Cuenta.AñadirLocal.form.yup.tablesNumber')),
                      postalCode: Yup.string().matches(/^[0-9]\d{4}/, 'El valor debe ser un código postal'),
                      phoneNumber: Yup.string().matches(/^(\+34|0034|34)?[6789]\d{8}$/, 'Debe ser un número de teléfono'),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        const data = {
                          name: values.name,
                          address: values.address,
                          website: values.website,
                          capacity: parseInt(values.capacity, 10),
                          tablesNumber: parseInt(values.tables_number, 10),
                          country: values.country,
                          province: values.province,
                          postalCode: values.postalCode,
                          phoneNumber: values.phoneNumber,
                        };
                        const consultaAPI = async () => {
                          try {
                            const dataShop = await updateShop(shopIdActual, data);
                            if (dataShop.status === 200) {
                              toast.success(t('App.InfoLocal.Informacion.Carta.Modal.editShop.toast.editDataLocal'));
                              if (values.file != null) {
                                const formData = new FormData();
                                formData.append('shopId', shopIdActual);
                                formData.append('picture', values.file);
                                const dataImageShop = await addPictureShop(formData, shopIdActual);
                                if (dataImageShop === 200) {
                                  toast.success(t('App.InfoLocal.Informacion.Carta.Modal.editShop.toast.editPictureLocal'));
                                }
                              }
                              setUploadOverlay(true);
                              datos.fetchdata().then(() => {
                                setUploadOverlay(false);
                                datos.onHide();
                              });
                            }
                          } catch (error) {
                            if (error.response.status !== 401) {
                              toast.error(t('newToast.editShop'));
                            }
                            checkNoAuthError(error, history, toast, t);
                          }
                        };
                        consultaAPI();
                        setSubmitting(false);
                      }, 250);
                    }}
                  >
                    {(_props) => {
                      const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                      } = _props;
                      return (
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={3}>
                            <Grid item lg={6} xs={12}>
                              <FormGroup>
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.name')}
                                  name="name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.name}
                                  error={touched.name && Boolean(errors.name)}
                                  helperText={touched.name && errors.name}
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <FormGroup>
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.web')}
                                  name="website"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.website}
                                  error={touched.website && Boolean(errors.website)}
                                  helperText={touched.website && errors.website}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>Dirección</Form.Label> */}
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.address')}
                                  name="address"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.address}
                                  error={touched.address && Boolean(errors.address)}
                                  helperText={touched.address && errors.address}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={4} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>País</Form.Label> */}
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.country')}
                                  name="country"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.country}
                                  error={touched.country && Boolean(errors.country)}
                                  helperText={touched.country && errors.country}
                                  disabled
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={5} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>Provincia</Form.Label> */}
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.province')}
                                  name="province"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.province}
                                  error={touched.province && Boolean(errors.province)}
                                  helperText={touched.province && errors.province}
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={3} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>Código Postal</Form.Label> */}
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.postalCode')}
                                  name="postalCode"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.postalCode}
                                  error={touched.postalCode && Boolean(errors.postalCode)}
                                  helperText={touched.postalCode && errors.postalCode}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>Número de Teléfono</Form.Label> */}
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.phoneNumber')}
                                  name="phoneNumber"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.phoneNumber}
                                  error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                                  helperText={touched.phoneNumber && errors.phoneNumber}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={3} xs={6}>
                              <FormGroup>
                                {/* <Form.Label>Aforo</Form.Label> */}
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.capacity')}
                                  name="capacity"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.capacity}
                                  error={touched.capacity && Boolean(errors.capacity)}
                                  helperText={touched.capacity && errors.capacity}
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={3} xs={6}>
                              <FormGroup>
                                {/* <Form.Label>Número de Mesas</Form.Label> */}
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.tablesNumber')}
                                  name="tables_number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.tables_number}
                                  error={touched.tables_number && Boolean(errors.tables_number)}
                                  helperText={touched.tables_number && errors.tables_number}
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <FormGroup className="position-relative mb-3">
                                <FormLabel>{t('App.Cuenta.AñadirLocal.form.pictureTitle')}</FormLabel>
                                <input
                                  id="file"
                                  type="file"
                                  name="file"
                                  label={t('App.Cuenta.AñadirLocal.form.pictureInformation')}
                                  onChange={async (event) => {
                                    const image = await resizeFile(event.target.files[0]);
                                    setFieldValue('file', image);
                                  }}
                                  className="form-control"
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={3} xs={3}>
                              <div> </div>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <FormGroup>
                                <Button variant="contained" type="submit" disabled={isSubmitting}>
                                  {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.Carta.Modal.editShop.buttonForm')}
                                </Button>
                              </FormGroup>
                            </Grid>
                          </Grid>
                        </form>
                      );
                    }}
                  </Formik>
                </Card>
              </div>
            )
            : (
              <div>
                {uploadOverlay
                  && (
                    <div className="uploadData">
                      <CircularProgress />
                      <Typography sx={{ color: '#ffffff' }}>
                        {t('App.InfoLocal.Informacion.Carta.Modal.editShop.load')}
                      </Typography>
                    </div>
                  )}
                <Card
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    // maxWidth: '2000px',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#FFFFFF',
                    // border: '2px solid #000',
                    padding: '1%',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '95%',
                    overflowY: 'scroll',
                  }}
                >
                  <h2 style={{ textAlign: 'center' }}>{t('App.InfoLocal.Informacion.Carta.Modal.editShop.title')}</h2>
                  <Formik
                    initialValues={{
                      name: datos.shop.name || '',
                      address: datos.shop.address || '',
                      website: datos.shop.website || '',
                      capacity: datos.shop.capacity || '',
                      tables_number: datos.shop.tablesNumber || '',
                      country: datos.shop.country || '',
                      province: datos.shop.province || '',
                      postalCode: datos.shop.postalCode || '',
                    }}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required(t('App.Cuenta.AñadirLocal.form.yup.name')),
                      address: Yup.string().required(t('App.Cuenta.AñadirLocal.form.yup.address')),
                      capacity: Yup.string().matches(/^[0-9]+$/gi, 'El valor debe ser un número'),
                      tables_number: Yup.string().matches(/^[0-9]+$/gi, 'El valor debe ser un número').required(t('App.Cuenta.AñadirLocal.form.yup.tablesNumber')),
                      postalCode: Yup.string().matches(/^[0-9]\d{4}/, 'El valor debe ser un código postal'),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        const data = {
                          shop: {
                            name: values.name,
                            address: values.address,
                            website: values.website,
                            capacity: parseInt(values.capacity, 10),
                            tablesNumber: parseInt(values.tables_number, 10),
                            country: values.country,
                            province: values.province,
                            postalCode: values.postalCode,
                          },
                        };
                        const consultaAPI = async () => {
                          try {
                            const dataShop = await updateShop(shopIdActual, data);
                            if (dataShop.status === 200) {
                              toast.success(t('App.InfoLocal.Informacion.Carta.Modal.editShop.toast.editDataLocal'));
                              if (values.file != null) {
                                const formData = new FormData();
                                formData.append('shopId', shopIdActual);
                                formData.append('picture', values.file);
                                const dataImageShop = await addPictureShop(formData, shopIdActual);
                                if (dataImageShop === 200) {
                                  toast.success(t('App.InfoLocal.Informacion.Carta.Modal.editShop.toast.editPictureLocal'));
                                }
                              }
                              setUploadOverlay(true);
                              datos.fetchdata().then(() => {
                                setUploadOverlay(false);
                                datos.onHide();
                              });
                            }
                          } catch (error) {
                            if (error.response.status !== 401) {
                              toast.error(t('newToast.editShop'));
                            }
                            checkNoAuthError(error, history, toast, t);
                          }
                        };
                        consultaAPI();

                        setSubmitting(false);
                      }, 250);
                    }}
                  >
                    {(_props) => {
                      const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                      } = _props;
                      return (
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={3}>
                            <Grid item lg={6} xs={12}>
                              <FormGroup>
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.name')}
                                  name="name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.name}
                                  error={touched.name && Boolean(errors.name)}
                                  helperText={touched.name && errors.name}
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>Web</Form.Label> */}
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.web')}
                                  name="website"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.website}
                                  error={touched.website && Boolean(errors.website)}
                                  helperText={touched.website && errors.website}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>Dirección</Form.Label> */}
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.address')}
                                  name="address"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.address}
                                  error={touched.address && Boolean(errors.address)}
                                  helperText={touched.address && errors.address}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={4} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>País</Form.Label> */}
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.country')}
                                  name="country"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.country}
                                  error={touched.country && Boolean(errors.country)}
                                  helperText={touched.country && errors.country}
                                  disabled
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={5} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>Provincia</Form.Label> */}
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.province')}
                                  name="province"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.province}
                                  error={touched.province && Boolean(errors.province)}
                                  helperText={touched.province && errors.province}
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={3} xs={12}>
                              <FormGroup>
                                {/* <Form.Label>Código Postal</Form.Label> */}
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.postalCode')}
                                  name="postalCode"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.postalCode}
                                  error={touched.postalCode && Boolean(errors.postalCode)}
                                  helperText={touched.postalCode && errors.postalCode}
                                />
                              </FormGroup>
                            </Grid>

                            {/* <Grid item lg={12} xs={12}>
                              <FormGroup>
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.phoneNumber')}
                                  name="phoneNumber"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.phoneNumber}
                                  error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                                  helperText={touched.phoneNumber && errors.phoneNumber}
                                />
                              </FormGroup>
                            </Grid> */}

                            <Grid item lg={3} xs={6}>
                              <FormGroup>
                                {/* <Form.Label>Aforo</Form.Label> */}
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.capacity')}
                                  name="capacity"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.capacity}
                                  error={touched.capacity && Boolean(errors.capacity)}
                                  helperText={touched.capacity && errors.capacity}
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={3} xs={6}>
                              <FormGroup>
                                {/* <Form.Label>Número de Mesas</Form.Label> */}
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.tablesNumber')}
                                  name="tables_number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.tables_number}
                                  error={touched.tables_number && Boolean(errors.tables_number)}
                                  helperText={touched.tables_number && errors.tables_number}
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <FormGroup className="position-relative mb-3">
                                <FormLabel>{t('App.Cuenta.AñadirLocal.form.pictureTitle')}</FormLabel>
                                <input
                                  id="file"
                                  type="file"
                                  name="file"
                                  label={t('App.Cuenta.AñadirLocal.form.pictureInformation')}
                                  onChange={async (event) => {
                                    const image = await resizeFile(event.target.files[0]);
                                    setFieldValue('file', image);
                                  }}
                                  className="form-control"
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={3} xs={3}>
                              <div> </div>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <FormGroup>
                                <Button variant="contained" type="submit" disabled={isSubmitting}>
                                  {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.Carta.Modal.editShop.buttonForm')}
                                </Button>
                              </FormGroup>
                            </Grid>
                          </Grid>
                        </form>
                      );
                    }}
                  </Formik>
                </Card>
              </div>
            )
        )}
    </Modal>
  );
}

export function DeleteShopButton(props) {
  const datos = props;
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  const shopIdActual = localStorage.getItem('shop_id');

  const history = useHistory();

  async function onClickEvent(idShop) {
    try {
      const dataDelete = await deleteShop(idShop);
      if (dataDelete.status === 200) {
        toast.success(t('App.InfoLocal.Informacion.Carta.Modal.deleteShop.toast.deleteDataLocal'));
        history.push('/App');
        datos.onHide();
      }
    } catch (error) {
      if (error.response.status !== 401) {
        toast.error(t('App.InfoLocal.Informacion.Carta.Modal.deleteShop.toast.errorDeleteLocal'));
      }
      checkNoAuthError(error, history, toast, t);
    }
  }

  return (
    <Modal
      onClose={datos.onHide}
      open={datos.show}
    >
      {width > 720
        ? (
          <Card
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              // maxWidth: '2000px',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#FFFFFF',
              // border: '2px solid #000',
              padding: '1%',
              boxShadow: 24,
              p: 4,
            }}
          >
            <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.deleteShop.title')} />
            <CardContent>
              {t('App.InfoLocal.Informacion.Carta.Modal.deleteShop.question')}
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  onClickEvent(shopIdActual);
                }}
              >
                {t('App.InfoLocal.Informacion.Carta.Modal.deleteShop.deleteButton')}
              </Button>
              <Button variant="contained" onClick={datos.onHide}>{t('App.InfoLocal.Informacion.Carta.Modal.deleteShop.comeBackButton')}</Button>
            </CardActions>
          </Card>
        ) : (
          <Card
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              // maxWidth: '2000px',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#FFFFFF',
              // border: '2px solid #000',
              padding: '1%',
              boxShadow: 24,
              p: 4,
              maxHeight: '95%',
              width: '90%',
            }}
          >
            <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.deleteShop.title')} />
            <CardContent>
              {t('App.InfoLocal.Informacion.Carta.Modal.deleteShop.question')}
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  onClickEvent(shopIdActual);
                }}
              >
                {t('App.InfoLocal.Informacion.Carta.Modal.deleteShop.deleteButton')}
              </Button>
              <Button variant="contained" onClick={datos.onHide}>{t('App.InfoLocal.Informacion.Carta.Modal.deleteShop.comeBackButton')}</Button>
            </CardActions>
          </Card>
        )}

    </Modal>
  );
}

export function EditMenuPC(props) {
  const datos = props;
  const idMenu = datos.menu === undefined ? '' : datos.menu.id;
  const { t } = useTranslation();

  // const shopIdActual = localStorage.getItem('shop_id');

  const [menu, setMenu] = React.useState();
  const [selectedMenu, setSelectedMenu] = React.useState();
  const [products, setProducts] = React.useState();
  const [condicion, setCondicion] = React.useState(false);
  const [busqueda, setBusqueda] = React.useState('');
  const [respuestaAPIDisplay, setRespuestaAPIDisplay] = React.useState([]);
  const [uploadOverlay, setUploadOverlay] = React.useState(false);
  const [language, setLanguage] = React.useState('');
  const history = useHistory();

  const listProducts = [];
  const array = [];

  if (datos.productsValue !== undefined && datos.menu !== undefined) {
    datos.productsValue.forEach((product) => {
      if (!datos.menu.products.find((a) => a.id === product.id)) {
        array.push(
          { id: product.id, name: product.name },
        );
      }
    });
  }

  if (undefined !== datos.menu) {
    datos.menu.products.forEach((product) => {
      listProducts.push(
        { id: product.id, name: product.name },
      );
    });
  }

  if (datos.menu !== undefined) {
    if (condicion === false) {
      setMenu(listProducts);
      setProducts(array);
      setCondicion(true);
    }
  }

  const filtrar = (terminoBusqueda) => {
    const resultadosBusqueda = products.filter((elemento) => {
      if (elemento.name.toString()
        .toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return true;
      }
      return false;
    });
    const displayList = [];
    products.forEach((product) => {
      let found = false;
      for (let i = 0; i < resultadosBusqueda.length; i += 1) {
        if (product.id === resultadosBusqueda[i].id) {
          found = true;
          break;
        }
      }
      displayList.push(found);
    });
    setRespuestaAPIDisplay(displayList);
  };

  const handleChangeBusqueda = (e) => {
    setBusqueda(e.target.value);
    if (e.target.value !== '') {
      filtrar(e.target.value);
    } else {
      setRespuestaAPIDisplay(Array(products.length).fill(true));
    }
  };

  const handleChangeSortable = (textoBúsqueda) => {
    if (products !== undefined) {
      if (textoBúsqueda !== '') {
        filtrar(textoBúsqueda);
      } else {
        setRespuestaAPIDisplay(Array(products.length).fill(true));
      }
    }
  };

  useEffect(() => {
    handleChangeSortable(busqueda);
    setLanguage(datos.language);
    setSelectedMenu(datos.menu);
  }, [products]);

  const changeLanguage = (event, setFieldValue, values) => {
    selectedMenu.name[language] = values.name;
    selectedMenu.description[language] = values.description;

    setLanguage(event.target.value);
    setFieldValue('name', selectedMenu.name[event.target.value] || '');
    setFieldValue('description', selectedMenu.description[event.target.value] || '');
  };

  return (
    <Modal
      onClose={datos.onHide}
      open={datos.show}
    >
      <div>
        {uploadOverlay
          && (
            <div className="uploadData">
              <CircularProgress />
              <Typography sx={{ color: '#ffffff' }}>
                {t('App.InfoLocal.Informacion.Carta.Modal.editMenu.load')}
              </Typography>
            </div>
          )}
        <Card
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            // maxWidth: '2000px',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFFFFF',
            // border: '2px solid #000',
            padding: '1%',
            boxShadow: 24,
            p: 4,
            maxHeight: '95%',
            overflowY: 'scroll',
          }}
        >
          <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.editMenu.title')} style={{ marginBottom: '2%' }} />
          {selectedMenu === undefined
            ? (<div> </div>)
            : (
              <Formik
                initialValues={{
                  name: selectedMenu.name[language] || '',
                  description: selectedMenu.description[language] || '',
                  price: selectedMenu.price.toString() || '',
                  file: null,
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required(t('App.InfoLocal.Informacion.AñadirMenu.form.yup.required.name')),
                  // description: Yup.string().required(t('App.InfoLocal.Informacion.AñadirMenu.
                  // form.yup.required.description')),
                  price: Yup.string().matches(/^[0-9]+([,.][0-9]+)?$/, 'El valor debe ser un número').required(t('App.InfoLocal.Informacion.AñadirMenu.form.yup.required.price')),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    selectedMenu.name[language] = values.name;
                    selectedMenu.description[language] = values.description;
                    const productsId = [];
                    menu.map((product) => (
                      productsId.push(product.id)
                    ));
                    const priceMenu = values.price.replace(',', '.');
                    const data = {
                      menu: {
                        name: selectedMenu.name,
                        description: selectedMenu.description,
                        price: Number(priceMenu),
                        products: Array.from(productsId),
                        locale: language,
                      },
                    };

                    const consultaAPI = async () => {
                      try {
                        const dataMenu = await updateMenu(idMenu, data);
                        if (dataMenu.status === 200) {
                          toast.success(t('App.InfoLocal.Informacion.Carta.Modal.editMenu.toast.editDataMenu'));
                          if (values.file != null) {
                            const formData = new FormData();
                            formData.append('menu_id', idMenu);
                            formData.append('picture', values.file);
                            const dataImageMenu = await addPictureMenu(formData, idMenu);
                            if (dataImageMenu.status === 200) {
                              toast.success(t('App.InfoLocal.Informacion.Carta.Modal.editMenu.toast.editPictureMenu'));
                            }
                          }
                          setUploadOverlay(true);
                          datos.fetchdata().then(() => {
                            setUploadOverlay(false);
                            datos.onHide();
                          });
                        }
                      } catch (error) {
                        if (error.response.status !== 401) {
                          toast.error(t('newToast.editMenu'));
                        }
                        checkNoAuthError(error, history, toast, t);
                      }
                    };

                    consultaAPI();

                    setSubmitting(false);
                  }, 250);
                }}
              >
                {(_props) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* y otras más */
                  } = _props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item lg={12} xs={12}>
                          <FormControl>
                            <FormLabel id="languageObject">Idioma</FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="languageObject"
                              name="language"
                              value={language}
                              onChange={(e) => { changeLanguage(e, setFieldValue, values); }}
                            >
                              <FormControlLabel
                                value="es"
                                control={<Radio />}
                                label={(
                                  <img
                                    src={es}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagEs"
                                  />
                                )}
                              />
                              <FormControlLabel
                                value="en"
                                control={<Radio />}
                                label={(
                                  <img
                                    src={en}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagEn"
                                  />
                                )}
                              />
                              <FormControlLabel
                                value="fr"
                                control={<Radio />}
                                label={(
                                  <img
                                    src={fr}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagFr"
                                  />
                                )}
                              />
                              <FormControlLabel
                                value="it"
                                control={<Radio />}
                                label={(
                                  <img
                                    src={it}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagIt"
                                  />
                                )}
                              />
                              <FormControlLabel
                                value="de"
                                control={<Radio />}
                                label={(
                                  <img
                                    src={de}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagDe"
                                  />
                                )}
                              />
                              <FormControlLabel
                                value="pt"
                                control={<Radio />}
                                label={(
                                  <img
                                    src={pt}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagPt"
                                  />
                                )}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid item lg={12} xs={12}>
                          <FormGroup>
                            {/* <Form.Label>Nombre</Form.Label> */}
                            <TextField
                              label={t('App.InfoLocal.Informacion.AñadirMenu.form.name')}
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              error={touched.name && Boolean(errors.name)}
                              helperText={touched.name && errors.name}
                            />
                          </FormGroup>
                        </Grid>

                        {/* <Grid item lg={12} xs={12}>
                        <FormGroup>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                            // Selecciona la Categoría</InputLabel>
                            <Field
                              name="category"
                              component={CustomizedSelectForFormik}
                              label="Selecciona la Categoría"
                            >
                              <MenuItem value={0}>
                                Sin Categoría
                              </MenuItem>
                              {respuestaAPICategory.length !== 0
                                && respuestaAPICategory.map((category) => (
                                  <MenuItem
                                    value={category.id}
                                    key={category.id}
                                  >
                                    {category.name}
                                  </MenuItem>
                                ))}
                            </Field>
                          </FormControl>
                        </FormGroup>
                      </Grid> */}

                        <Grid item lg={12} xs={12}>
                          <FormGroup>
                            {/* <Form.Label>Descripción</Form.Label> */}
                            <TextField
                              label={t('App.InfoLocal.Informacion.AñadirMenu.form.description')}
                              name="description"
                              multiline
                              rows={4}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.description}
                              error={touched.description && Boolean(errors.description)}
                              helperText={touched.description && errors.description}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item lg={12} xs={12}>
                          <FormGroup>
                            {/* <Form.Label>Precio</Form.Label> */}
                            <TextField
                              label={t('App.InfoLocal.Informacion.AñadirMenu.form.price')}
                              name="price"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.price}
                              error={touched.price && Boolean(errors.price)}
                              helperText={touched.price && errors.price}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item lg={6} xs={6}>
                          <Card>
                            <CardContent>
                              <h2 style={{ textAlign: 'center' }}>{t('App.InfoLocal.Informacion.AñadirMenu.form.products')}</h2>
                            </CardContent>
                            <CardContent>

                              <div>
                                <input
                                  className="form-control inputBuscar"
                                  value={busqueda}
                                  placeholder="Búsqueda del producto"
                                  onChange={handleChangeBusqueda}
                                />
                              </div>

                              <ReactSortable
                                list={products}
                                setList={setProducts}
                                animation={150}
                                group={{ name: 'cloning-group-name' }}
                                style={{ maxHeight: '500px', overflowY: 'scroll' }}
                              >
                                {products.length !== 0
                                  && products.map((item, index) => (
                                    <div key={item.id} style={{ display: respuestaAPIDisplay[index] ? 'block' : 'none' }}>
                                      <div className="cardProductPC  m-3 cursor-draggable">
                                        <div className="card-body">
                                          {item.name[language] === null
                                            ? item.name.es : item.name[language]}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </ReactSortable>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item lg={6} xs={6}>
                          <Card>
                            <CardContent>
                              <h2 style={{ textAlign: 'center' }}>{t('App.InfoLocal.Informacion.AñadirMenu.form.menuProducts')}</h2>
                            </CardContent>
                            <CardContent>
                              <ReactSortable
                                list={menu}
                                setList={setMenu}
                                animation={150}
                                group={{ name: 'cloning-group-name' }}
                              >

                                {menu.length !== 0
                                  && menu.map((item) => (
                                    <div key={item.id}>
                                      <div className="cardProductPC  m-3 cursor-draggable">
                                        <div className="card-body">
                                          {item.name[language] === null
                                            ? item.name.es : item.name[language]}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </ReactSortable>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item lg={12} xs={12}>
                          <FormGroup className="position-relative mb-3">
                            <FormLabel>{t('App.InfoLocal.Informacion.AñadirMenu.form.pictureTitle')}</FormLabel>
                            <input
                              id="file"
                              type="file"
                              name="file"
                              label={t('App.InfoLocal.Informacion.AñadirMenu.form.pictureInformation')}
                              onChange={async (event) => {
                                const image = await resizeFile(event.target.files[0]);
                                setFieldValue('file', image);
                              }}
                              className="form-control"
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item lg={3} xs={3}>
                          <div> </div>
                        </Grid>

                        <Grid item lg={6} xs={6}>
                          <FormGroup>
                            <Button variant="contained" type="submit" disabled={isSubmitting}>
                              {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.Carta.Modal.editMenu.buttonForm')}
                            </Button>
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            )}
        </Card>
      </div>
    </Modal>
  );
}

export function EditMenuMobile(props) {
  const datos = props;
  const idMenu = datos.menu === undefined ? '' : datos.menu.id;
  const { t } = useTranslation();

  // const shopIdActual = localStorage.getItem('shop_id');

  const [menu, setMenu] = React.useState();
  // const [products, setProducts] = React.useState();
  const [condicion, setCondicion] = React.useState(false);
  const [busqueda, setBusqueda] = React.useState('');
  const [respuestaAPIDisplay, setRespuestaAPIDisplay] = React.useState([]);
  const [uploadOverlay, setUploadOverlay] = React.useState(false);
  const history = useHistory();

  const listProducts = [];
  const [language, setLanguage] = React.useState('');
  const [selectedMenu, setSelectedMenu] = React.useState();

  if (datos.productsValue !== undefined && datos.menu !== undefined) {
    datos.productsValue.forEach((product) => {
      if (!datos.menu.products.find((a) => a.id === product.id)) {
        listProducts.push(
          { id: product.id, name: product.name, check: false },
        );
      } else {
        listProducts.push(
          { id: product.id, name: product.name, check: true },
        );
      }
    });
  }

  // if (undefined !== datos.menu) {
  //   datos.menu.menusections.forEach((menusection) => {
  //     menusection.products.forEach((product) => {
  //       listProducts.push(
  //         { id: product.id, name: product.name },
  //       );
  //     });
  //   });
  // }

  if (datos.menu !== undefined) {
    if (condicion === false) {
      setMenu(listProducts);
      setCondicion(true);
    }
  }

  const filtrar = (terminoBusqueda) => {
    const resultadosBusqueda = datos.productsValue.filter((elemento) => {
      if (elemento.name.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return true;
      }
      return false;
    });
    const displayList = [];
    datos.productsValue.forEach((product) => {
      let found = false;
      for (let i = 0; i < resultadosBusqueda.length; i += 1) {
        if (product.id === resultadosBusqueda[i].id) {
          found = true;
          break;
        }
      }
      displayList.push(found);
    });
    setRespuestaAPIDisplay(displayList);
  };

  const handleChangeBusqueda = (e) => {
    setBusqueda(e.target.value);
    if (e.target.value !== '') {
      filtrar(e.target.value);
    } else {
      setRespuestaAPIDisplay(Array(datos.productsValue.length).fill(true));
    }
  };

  const handleChangeSortable = (textoBúsqueda) => {
    if (datos.productsValue !== undefined) {
      if (textoBúsqueda !== '') {
        filtrar(textoBúsqueda);
      } else {
        setRespuestaAPIDisplay(Array(datos.productsValue.length).fill(true));
      }
    }
  };

  useEffect(() => {
    handleChangeSortable(busqueda);
    setLanguage(datos.language);
    setSelectedMenu(datos.menu);
  }, [datos.menu]);

  function selectProductMenu(targetProduct) {
    const productSelect = targetProduct;
    const products = [...menu];
    if (!productSelect.check) {
      productSelect.check = true;
    } else {
      productSelect.check = false;
    }
    for (let i = 0; i < products.length; i += 1) {
      if (products.id === productSelect.id) {
        products.check = productSelect.check;
      }
    }
    setMenu(products);
  }

  const changeLanguage = (event, setFieldValue, values) => {
    selectedMenu.name[language] = values.name;
    selectedMenu.description[language] = values.description;

    setLanguage(event.target.value);
    setFieldValue('name', selectedMenu.name[event.target.value] || '');
    setFieldValue('description', selectedMenu.description[event.target.value] || '');
  };

  return (
    <Modal
      onClose={datos.onHide}
      open={datos.show}
    >
      <div>
        {uploadOverlay
          && (
            <div className="uploadData">
              <CircularProgress />
              <Typography sx={{ color: '#ffffff' }}>
                {t('App.InfoLocal.Informacion.Carta.Modal.editMenu.load')}
              </Typography>
            </div>
          )}
        <Card
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            // maxWidth: '2000px',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFFFFF',
            // border: '2px solid #000',
            padding: '4%',
            boxShadow: 24,
            p: 4,
            maxHeight: '95%',
            overflowY: 'scroll',
            width: '90%',
          }}
        >
          <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.editMenu.title')} style={{ marginBottom: '2%' }} />
          {selectedMenu === undefined
            ? (<div> </div>)
            : (
              <Formik
                initialValues={{
                  name: selectedMenu.name[language] || '',
                  description: selectedMenu.description[language] || '',
                  price: selectedMenu.price.toString() || '',
                  file: null,
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required(t('App.InfoLocal.Informacion.AñadirMenu.form.yup.required.name')),
                  // description: Yup.string().required(t('App.InfoLocal.Informacion.AñadirMenu.
                  // form.yup.required.description')),
                  price: Yup.string().matches(/^[0-9]+([,.][0-9]+)?$/, 'El valor debe ser un número').required(t('App.InfoLocal.Informacion.AñadirMenu.form.yup.required.price')),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    selectedMenu.name[language] = values.name;
                    selectedMenu.description[language] = values.description;
                    const productsId = [];
                    menu.map((product) => (
                      productsId.push(product.id)
                    ));
                    const priceMenu = values.price.replace(',', '.');
                    const data = {
                      menu: {
                        name: selectedMenu.name,
                        description: selectedMenu.description,
                        price: Number(priceMenu),
                        products: Array.from(productsId),
                        locale: language,
                      },
                    };
                    const consultaAPI = async () => {
                      try {
                        const dataMenu = await updateMenu(idMenu, data);
                        if (dataMenu.status === 200) {
                          toast.success(t('App.InfoLocal.Informacion.Carta.Modal.editMenu.toast.editDataMenu'));
                          if (values.file != null) {
                            const formData = new FormData();
                            formData.append('menu_id', idMenu);
                            formData.append('picture', values.file);
                            const dataImageMenu = await addPictureMenu(formData, idMenu);
                            if (dataImageMenu.status === 200) {
                              toast.success(t('App.InfoLocal.Informacion.Carta.Modal.editMenu.toast.editPictureMenu'));
                            }
                          }
                          setUploadOverlay(true);
                          datos.fetchdata().then(() => {
                            setUploadOverlay(false);
                            datos.onHide();
                          });
                        }
                      } catch (error) {
                        if (error.response.status !== 401) {
                          toast.error(t('newToast.editMenu'));
                        }
                        checkNoAuthError(error, history, toast, t);
                      }
                    };

                    consultaAPI();

                    setSubmitting(false);
                  }, 250);
                }}
              >
                {(_props) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* y otras más */
                  } = _props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item lg={12} xs={12}>
                          <FormControl>
                            <FormLabel id="languageObject">Idioma</FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="languageObject"
                              name="language"
                              value={language}
                              onChange={(e) => { changeLanguage(e, setFieldValue, values); }}
                            >
                              <FormControlLabel
                                value="es"
                                control={<Radio />}
                                label={(
                                  <img
                                    src={es}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagEs"
                                  />
                                )}
                              />
                              <FormControlLabel
                                value="en"
                                control={<Radio />}
                                label={(
                                  <img
                                    src={en}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagEn"
                                  />
                                )}
                              />
                              <FormControlLabel
                                value="fr"
                                control={<Radio />}
                                label={(
                                  <img
                                    src={fr}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagFr"
                                  />
                                )}
                              />
                              <FormControlLabel
                                value="it"
                                control={<Radio />}
                                label={(
                                  <img
                                    src={it}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagIt"
                                  />
                                )}
                              />
                              <FormControlLabel
                                value="de"
                                control={<Radio />}
                                label={(
                                  <img
                                    src={de}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagDe"
                                  />
                                )}
                              />
                              <FormControlLabel
                                value="pt"
                                control={<Radio />}
                                label={(
                                  <img
                                    src={pt}
                                    style={{ height: 24, width: 24 }}
                                    alt="flagPt"
                                  />
                                )}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid item lg={12} xs={12}>
                          <FormGroup>
                            {/* <Form.Label>Nombre</Form.Label> */}
                            <TextField
                              label={t('App.InfoLocal.Informacion.AñadirMenu.form.name')}
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              error={touched.name && Boolean(errors.name)}
                              helperText={touched.name && errors.name}
                            />
                          </FormGroup>
                        </Grid>

                        {/* <Grid item lg={12} xs={12}>
                        <FormGroup>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                            // Selecciona la Categoría</InputLabel>
                            <Field
                              name="category"
                              component={CustomizedSelectForFormik}
                              label="Selecciona la Categoría"
                            >
                              <MenuItem value={0}>
                                Sin Categoría
                              </MenuItem>
                              {respuestaAPICategory.length !== 0
                                && respuestaAPICategory.map((category) => (
                                  <MenuItem
                                    value={category.id}
                                    key={category.id}
                                  >
                                    {category.name}
                                  </MenuItem>
                                ))}
                            </Field>
                          </FormControl>
                        </FormGroup>
                      </Grid> */}

                        <Grid item lg={12} xs={12}>
                          <FormGroup>
                            {/* <Form.Label>Descripción</Form.Label> */}
                            <TextField
                              label={t('App.InfoLocal.Informacion.AñadirMenu.form.description')}
                              name="description"
                              multiline
                              rows={4}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.description}
                              error={touched.description && Boolean(errors.description)}
                              helperText={touched.description && errors.description}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item lg={12} xs={12}>
                          <FormGroup>
                            {/* <Form.Label>Precio</Form.Label> */}
                            <TextField
                              label={t('App.InfoLocal.Informacion.AñadirMenu.form.price')}
                              name="price"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.price}
                              error={touched.price && Boolean(errors.price)}
                              helperText={touched.price && errors.price}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <Card>
                            <CardContent>
                              <h2 style={{ textAlign: 'center' }}>{t('App.InfoLocal.Informacion.AñadirMenu.form.menuProducts')}</h2>
                            </CardContent>
                            <CardContent>

                              <div style={{ marginBottom: '8px' }}>
                                <input
                                  className="form-control inputBuscar"
                                  value={busqueda}
                                  placeholder="Búsqueda del producto"
                                  onChange={handleChangeBusqueda}
                                />
                              </div>
                              {menu.length !== 0
                                && menu.map((product, a) => (
                                  <div key={product.id} style={{ display: respuestaAPIDisplay[a] ? 'block' : 'none' }}>
                                    <Button
                                      style={{ width: '100%' }}
                                      variant="outline-secondary"
                                      data-rowid={a}
                                      onClick={(e) => {
                                        selectProductMenu(menu[e.currentTarget.getAttribute('data-rowid')]);
                                      }}
                                    >
                                      <div className="cardProductMobile">
                                        <div style={product.check ? { backgroundColor: '#a5d6a7' } : { backgroundColor: 'white' }}>
                                          {product.name[language] === null
                                            ? product.name.es : product.name[language]}
                                        </div>
                                      </div>
                                    </Button>
                                  </div>
                                ))}
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item lg={12} xs={12}>
                          <FormGroup className="position-relative mb-3">
                            <FormLabel>{t('App.InfoLocal.Informacion.AñadirMenu.form.pictureTitle')}</FormLabel>
                            <input
                              id="file"
                              type="file"
                              name="file"
                              label={t('App.InfoLocal.Informacion.AñadirMenu.form.pictureInformation')}
                              onChange={async (event) => {
                                const image = await resizeFile(event.target.files[0]);
                                setFieldValue('file', image);
                              }}
                              className="form-control"
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item lg={3} xs={3}>
                          <div> </div>
                        </Grid>

                        <Grid item lg={6} xs={6}>
                          <FormGroup>
                            <Button variant="contained" type="submit" disabled={isSubmitting}>
                              {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.Carta.Modal.editMenu.toast.buttonForm')}
                            </Button>
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            )}
        </Card>
      </div>
    </Modal>
  );
}

export function DeleteMenutButton(props) {
  const datos = props;
  const idMenu = datos.menu === undefined ? '' : datos.menu.id;
  const [uploadOverlay, setUploadOverlay] = React.useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  async function onClickEvent(menuId) {
    try {
      const dataDelete = await deleteMenu(menuId);
      if (dataDelete.status === 200) {
        toast.success(t('App.InfoLocal.Informacion.Carta.Modal.deleteMenu.toast.deleteDataMenu'));
        setUploadOverlay(true);
        datos.fetchdata().then(() => {
          setUploadOverlay(false);
          datos.onHide();
        });
      }
    } catch (error) {
      if (error.response.status !== 401) {
        toast.error(t('App.InfoLocal.Informacion.Carta.Modal.deleteMenu.toast.errorDeleteMenu'));
      }
      checkNoAuthError(error, history, toast, t);
    }
  }

  return (
    <Modal
      onClose={datos.onHide}
      open={datos.show}
    >
      {datos.width > 720
        ? (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.deleteMenu.delete')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
              }}
            >
              <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.deleteMenu.title')} />
              <CardContent>
                {t('App.InfoLocal.Informacion.Carta.Modal.deleteMenu.question')}
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  onClick={() => {
                    onClickEvent(idMenu);
                  }}
                >
                  {t('App.InfoLocal.Informacion.Carta.Modal.deleteMenu.deleteButton')}
                </Button>
                <Button variant="contained" onClick={datos.onHide}>{t('App.InfoLocal.Informacion.Carta.Modal.deleteMenu.comeBackButton')}</Button>
              </CardActions>
            </Card>
          </div>
        ) : (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.deleteMenu.delete')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
                maxHeight: '95%',
                width: '90%',
              }}
            >
              <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.deleteMenu.title')} />
              <CardContent>
                {t('App.InfoLocal.Informacion.Carta.Modal.deleteMenu.question')}
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  onClick={() => {
                    onClickEvent(idMenu);
                  }}
                >
                  {t('App.InfoLocal.Informacion.Carta.Modal.deleteMenu.deleteButton')}
                </Button>
                <Button variant="contained" onClick={datos.onHide}>{t('App.InfoLocal.Informacion.Carta.Modal.deleteMenu.comeBackButton')}</Button>
              </CardActions>
            </Card>
          </div>
        )}
    </Modal>
  );
}

export function AddLanguageMenuModal(props) {
  const datos = props;
  const [uploadOverlay, setUploadOverlay] = React.useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const [language, setLanguage] = React.useState('');
  const [menu, setMenu] = React.useState(undefined);

  useEffect(() => {
    setLanguage(datos.language);
    setMenu(datos.menu);
  }, [datos.menu]);

  const changeLanguage = (event, setFieldValue, values) => {
    menu.name[language] = values.name;
    menu.description[language] = values.description;
    setLanguage(event.target.value);
    setFieldValue('name', menu.name[event.target.value] || '');
    setFieldValue('description', menu.description[event.target.value] || '');
  };

  return (
    <Modal
      open={datos.show}
      onClose={datos.onHide}
    >
      {datos.width > 720
        ? (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.addCategory.load')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
              }}
            >
              {/* {showSuccess && AddCategoryResponseSuccess(setShowSuccess)}
              {showFail && AddCategoryResponseFail(setShowFail)} */}
              <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.addLanguageMenuModal.title')} />
              <CardContent>
                {menu !== undefined
                  && (
                    <Formik
                      initialValues={{
                        name: menu.name[language] || '',
                        description: menu.description[language] || '',
                      }}
                      validationSchema={Yup.object().shape({
                        // name: Yup.string().required(t('App.InfoLocal.
                        // Informacion.Carta.Modal.addCategory.form.yup.required.name')),
                        // description: Yup.string().required('Required'),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                          menu.name[language] = values.name;
                          menu.description[language] = values.description;

                          const data = {
                            menu: {
                              name: menu.name,
                              description: menu.description,
                              // shopId: shopIdActual,
                            },
                          };
                          // console.log(data);
                          const consultaAPI = async () => {
                            try {
                              const dataLanguage = await updateMenu(datos.menu.id, data);
                              if (dataLanguage.status === 200) {
                                toast.success(t('newToast.addLanguageMenu.success'));
                                setUploadOverlay(true);
                                datos.fetchdata().then(() => {
                                  setUploadOverlay(false);
                                  datos.onHide();
                                });
                              }
                            } catch (error) {
                              if (error.response.status !== 401) {
                                toast.error(t('newToast.addLanguageMenu.error'));
                              }
                              checkNoAuthError(error, history, toast, t);
                            }
                          };
                          consultaAPI();
                          setSubmitting(false);
                        }, 250);
                      }}
                    >
                      {(_props) => {
                        const {
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          /* y otras más */
                        } = _props;
                        return (
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                              <Grid item lg={12} xs={12}>
                                <FormControl>
                                  <FormLabel id="languageObject">{t('App.InfoLocal.Informacion.Carta.Modal.addLanguageMenuModal.language')}</FormLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="languageObject"
                                    name="language"
                                    value={language}
                                    onChange={(e) => { changeLanguage(e, setFieldValue, values); }}
                                  >
                                    <FormControlLabel
                                      value="en"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={en}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagEn"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="fr"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={fr}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagFr"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="it"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={it}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagIt"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="de"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={de}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagDe"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="pt"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={pt}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagPt"
                                        />
                                      )}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>

                              <Grid item lg={12} xs={12}>
                                <FormGroup>
                                  {/* <Form.Label>Categoría</Form.Label> */}
                                  <TextField
                                    label={t('App.InfoLocal.Informacion.AñadirMenu.form.name')}
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                  />
                                </FormGroup>
                              </Grid>

                              <Grid item lg={12} xs={12}>
                                <FormGroup>
                                  <TextField
                                    label={t('App.InfoLocal.Informacion.AñadirMenu.form.description')}
                                    name="description"
                                    multiline
                                    rows={4}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description}
                                    error={touched.description && Boolean(errors.description)}
                                    helperText={touched.description && errors.description}
                                  />
                                </FormGroup>
                              </Grid>

                              <Grid item lg={4} xs={4}>
                                <Button variant="contained" type="submit" disabled={isSubmitting}>
                                  {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.AñadirMenu.form.buttonForm')}
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        );
                      }}
                    </Formik>
                  )}
              </CardContent>
            </Card>
          </div>
        )
        : (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.addCategory.load')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
                maxHeight: '95%',
                width: '90%',
              }}
            >
              {/* {showSuccess && AddCategoryResponseSuccess(setShowSuccess)}
              {showFail && AddCategoryResponseFail(setShowFail)} */}
              <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.addLanguageMenuModal.title')} />
              <CardContent>
                {menu !== undefined
                  && (
                    <Formik
                      initialValues={{
                        name: menu.name[language] || '',
                        description: menu.description[language] || '',
                      }}
                      validationSchema={Yup.object().shape({
                        // name: Yup.string().required(t('App.InfoLocal.
                        // Informacion.Carta.Modal.addCategory.form.yup.required.name')),
                        // description: Yup.string().required('Required'),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                          menu.name[language] = values.name;
                          menu.description[language] = values.description;

                          const data = {
                            menu: {
                              name: menu.name,
                              description: menu.description,
                              // shopId: shopIdActual,
                            },
                          };
                          // console.log(data);
                          const consultaAPI = async () => {
                            try {
                              const dataLanguage = await updateMenu(datos.menu.id, data);
                              if (dataLanguage.status === 200) {
                                toast.success(t('newToast.addLanguageMenu.success'));
                                setUploadOverlay(true);
                                datos.fetchdata().then(() => {
                                  setUploadOverlay(false);
                                  datos.onHide();
                                });
                              }
                            } catch (error) {
                              if (error.response.status !== 401) {
                                toast.error(t('newToast.addLanguageMenu.error'));
                              }
                              checkNoAuthError(error, history, toast, t);
                            }
                          };
                          consultaAPI();
                          setSubmitting(false);
                        }, 250);
                      }}
                    >
                      {(_props) => {
                        const {
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          /* y otras más */
                        } = _props;
                        return (
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                              <Grid item lg={12} xs={12}>
                                <FormControl>
                                  <FormLabel id="languageObject">{t('App.InfoLocal.Informacion.Carta.Modal.addLanguageMenuModal.language')}</FormLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="languageObject"
                                    name="language"
                                    value={language}
                                    onChange={(e) => { changeLanguage(e, setFieldValue, values); }}
                                  >
                                    <FormControlLabel
                                      value="en"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={en}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagEn"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="fr"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={fr}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagFr"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="it"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={it}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagIt"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="de"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={de}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagDe"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="pt"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={pt}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagPt"
                                        />
                                      )}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>

                              <Grid item lg={12} xs={12}>
                                <FormGroup>
                                  {/* <Form.Label>Categoría</Form.Label> */}
                                  <TextField
                                    label={t('App.InfoLocal.Informacion.AñadirMenu.form.name')}
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                  />
                                </FormGroup>
                              </Grid>

                              <Grid item lg={12} xs={12}>
                                <FormGroup>
                                  <TextField
                                    label={t('App.InfoLocal.Informacion.AñadirMenu.form.description')}
                                    name="description"
                                    multiline
                                    rows={4}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description}
                                    error={touched.description && Boolean(errors.description)}
                                    helperText={touched.description && errors.description}
                                  />
                                </FormGroup>
                              </Grid>

                              <Grid item lg={4} xs={4}>
                                <Button variant="contained" type="submit" disabled={isSubmitting}>
                                  {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.AñadirMenu.form.buttonForm')}
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        );
                      }}
                    </Formik>
                  )}
              </CardContent>
            </Card>
          </div>
        )}
    </Modal>
  );
}

export function AddCategoryModal(props) {
  const shopIdActual = localStorage.getItem('shop_id');
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showFail, setShowFail] = React.useState(false);
  const [uploadOverlay, setUploadOverlay] = React.useState(false);
  // const [showErrorSameNameModal, setShowErrorSameNameModal] = React.useState(false);

  const [language, setLanguage] = React.useState('es');
  const history = useHistory();
  const { t } = useTranslation();

  const datos = props;

  const changeLanguage = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <Modal
      open={datos.show}
      onClose={datos.onHide}
    >
      {datos.width > 720
        ? (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.addCategory.load')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
              }}
            >
              <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.addCategory.title')} />
              <CardContent>
                <Formik
                  initialValues={{
                    name: '',
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(t('App.InfoLocal.Informacion.Carta.Modal.addCategory.form.yup.required.name')),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      const category = {
                        name: { es: values.name },
                      };
                      const data = {
                        category: {
                          name: category.name,
                          shopId: shopIdActual,
                        },
                      };
                      const consultaAPI = async () => {
                        try {
                          const dataCategory = await addCategory(data);
                          if (dataCategory.status === 200) {
                            toast.success(t('App.InfoLocal.Informacion.Carta.Modal.addCategory.form.toast.addDataCategory'));
                            setUploadOverlay(true);
                            datos.fetchdata().then(() => {
                              setUploadOverlay(false);
                              datos.onHide();
                            });
                          }
                        } catch (error) {
                          if (error.response.status !== 401) {
                            toast.error(t('newToast.createCategory'));
                          }
                          checkNoAuthError(error, history, toast, t);
                        }
                      };
                      consultaAPI();
                      setSubmitting(false);
                    }, 250);
                  }}
                >
                  {(_props) => {
                    const {
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* y otras más */
                    } = _props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                          <Grid item lg={12} xs={12}>
                            <FormControl>
                              <FormLabel id="languageObject">{t('App.InfoLocal.Informacion.Carta.Modal.addCategory.language')}</FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="languageObject"
                                name="language"
                                value={language}
                              // onChange={changeLanguage}
                              >
                                <FormControlLabel
                                  value="es"
                                  control={<Radio />}
                                  label={(
                                    <img
                                      src={es}
                                      style={{ height: 24, width: 24 }}
                                      alt="flagEs"
                                    />
                                  )}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                          <Grid item lg={12} xs={12}>
                            <FormGroup>
                              {/* <Form.Label>Categoría</Form.Label> */}
                              <TextField
                                label={t('App.InfoLocal.Informacion.Carta.Modal.addCategory.form.name')}
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item lg={4} xs={4}>
                            <Button variant="contained" type="submit" disabled={isSubmitting}>
                              {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.Carta.Modal.addCategory.form.button')}
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    );
                  }}
                </Formik>
              </CardContent>
            </Card>
          </div>
        )
        : (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.addCategory.load')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
                maxHeight: '95%',
                width: '90%',
              }}
            >
              {showSuccess && AddCategoryResponseSuccess(setShowSuccess)}
              {showFail && AddCategoryResponseFail(setShowFail)}
              <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.addCategory.title')} />
              <CardContent>
                <Formik
                  initialValues={{
                    name: '',
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(t('App.InfoLocal.Informacion.Carta.Modal.addCategory.form.yup.required.name')),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      const category = {
                        name: { es: values.name },
                      };
                      const data = {
                        category: {
                          name: category.name,
                          shopId: shopIdActual,
                        },
                      };
                      const consultaAPI = async () => {
                        try {
                          const dataCategory = await addCategory(data);
                          if (dataCategory.status === 200) {
                            toast.success(t('App.InfoLocal.Informacion.Carta.Modal.addCategory.form.toast.addDataCategory'));
                            setUploadOverlay(true);
                            datos.fetchdata().then(() => {
                              setUploadOverlay(false);
                              datos.onHide();
                            });
                          }
                        } catch (error) {
                          if (error.response.status !== 401) {
                            toast.error(t('newToast.createCategory'));
                          }
                          checkNoAuthError(error, history, toast, t);
                        }
                      };
                      consultaAPI();
                      setSubmitting(false);
                    }, 250);
                  }}
                >
                  {(_props) => {
                    const {
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* y otras más */
                    } = _props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                          <Grid item lg={12} xs={12}>
                            <FormControl>
                              <FormLabel id="languageObject">{t('App.InfoLocal.Informacion.Carta.Modal.addCategory.language')}</FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="languageObject"
                                name="language"
                                value={language}
                                onChange={changeLanguage}
                              >
                                <FormControlLabel value="es" control={<Radio />} label="ES" />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item lg={12} xs={12}>
                            <FormGroup>
                              {/* <Form.Label>Categoría</Form.Label> */}
                              <TextField
                                label={t('App.InfoLocal.Informacion.Carta.Modal.addCategory.form.name')}
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item lg={4} xs={4}>
                            <Button variant="contained" type="submit" disabled={isSubmitting}>
                              {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.Carta.Modal.addCategory.form.button')}
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    );
                  }}
                </Formik>
              </CardContent>
            </Card>
          </div>
        )}
    </Modal>
  );
}

export function DeleteCategoryButton(props) {
  const [uploadOverlay, setUploadOverlay] = React.useState(false);
  const { t } = useTranslation();
  // const history = useHistory();
  const datos = props;
  const history = useHistory();

  async function onClickEvent(categoryId) {
    try {
      if (categoryId) {
        const dataDelete = await deleteCategory(categoryId);
        if (dataDelete.status === 200) {
          toast.success(t('App.InfoLocal.Informacion.Carta.Modal.deleteCategory.toast.deleteDataCategory'));
          setUploadOverlay(true);
          datos.fetchdata().then(() => {
            setUploadOverlay(false);
            datos.onHide();
          });
        }
      }
    } catch (error) {
      if (error.response.status !== 401) {
        toast.error(t('App.InfoLocal.Informacion.Carta.Modal.deleteCategory.toast.errorDeleteCategory'));
      }
      checkNoAuthError(error, history, toast, t);
    }
  }

  return (
    <Modal
      onClose={datos.onHide}
      open={datos.show}
    >
      {datos.width > 720
        ? (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.deleteCategory.delete')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
              }}
            >
              <CardHeader
                title={t('App.InfoLocal.Informacion.Carta.Modal.deleteCategory.title')}
              />
              <CardContent>
                {t('App.InfoLocal.Informacion.Carta.Modal.deleteCategory.question')}
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    onClickEvent(datos.categoryData.id);
                  }}
                >
                  {t('App.InfoLocal.Informacion.Carta.Modal.deleteCategory.deleteButton')}
                </Button>
                <Button variant="contained" onClick={datos.onHide}>{t('App.InfoLocal.Informacion.Carta.Modal.deleteCategory.comeBackButton')}</Button>
              </CardActions>
            </Card>
          </div>
        ) : (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.deleteCategory.delete')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
                maxHeight: '95%',
                width: '90%',
              }}
            >
              <CardHeader
                title={t('App.InfoLocal.Informacion.Carta.Modal.deleteCategory.title')}
              />
              <CardContent>
                {t('App.InfoLocal.Informacion.Carta.Modal.deleteCategory.question')}
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    onClickEvent(datos.categoryData.id);
                  }}
                >
                  {t('App.InfoLocal.Informacion.Carta.Modal.deleteCategory.deleteButton')}
                </Button>
                <Button variant="contained" onClick={datos.onHide}>{t('App.InfoLocal.Informacion.Carta.Modal.deleteCategory.comeBackButton')}</Button>
              </CardActions>
            </Card>
          </div>
        )}
    </Modal>
  );
}

export function UpdateCategoryModal(props) {
  // const shopIdActual = localStorage.getItem('shop_id');
  const datos = props;
  const [uploadOverlay, setUploadOverlay] = React.useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const [language, setLanguage] = React.useState('');
  const [category, setCategory] = React.useState(undefined);

  useEffect(() => {
    setLanguage(datos.language);
    setCategory(datos.categoryData);
  }, [datos.categoryData]);

  const changeLanguage = (event, setFieldValue, values) => {
    category.name[language] = values.name;
    setLanguage(event.target.value);
    setFieldValue('name', category.name[event.target.value] || '');
  };

  return (
    <Modal
      onClose={datos.onHide}
      open={datos.show}
    >
      {datos.width > 720
        ? (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.updateCategory.load')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
              }}
            >
              <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.updateCategory.title')} />
              <CardContent>
                {category !== undefined
                  && (
                    <Formik
                      initialValues={{
                        name: category.name[language] || '',
                      }}
                      validationSchema={Yup.object().shape({
                        // name: Yup.string().required(t('App.InfoLocal.
                        // Informacion.Carta.Modal.updateCategory.form.yup.required.name')),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                          category.name[language] = values.name;
                          const data = {
                            category: {
                              name: category.name,
                              // shopId: shopIdActual,
                            },
                          };
                          // if (datos.categoryData !== undefined) {
                          //   updateCategory(datos.categoryData.id, data);
                          //   setUploadOverlay(true);
                          //   datos.fetchdata().then(() => {
                          //     setUploadOverlay(false);
                          //     datos.onHide();
                          //   });
                          // }
                          const consultaAPI = async () => {
                            try {
                              // eslint-disable-next-line max-len
                              const dataCategory = await updateCategory(datos.categoryData.id, data);
                              if (dataCategory.status === 200) {
                                toast.success(t('App.InfoLocal.Informacion.Carta.Modal.updateCategory.form.toast.editDataCategory'));
                                setUploadOverlay(true);
                                datos.fetchdata().then(() => {
                                  setUploadOverlay(false);
                                  datos.onHide();
                                });
                              }
                            } catch (error) {
                              if (error.response.status !== 401) {
                                toast.error(t('newToast.editcategory'));
                              }
                              checkNoAuthError(error, history, toast, t);
                            }
                          };
                          consultaAPI();
                          setSubmitting(false);
                        }, 250);
                      }}
                    >
                      {(_props) => {
                        const {
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          /* y otras más */
                        } = _props;
                        return (
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                              <Grid item lg={12} xs={12}>
                                <FormControl>
                                  <FormLabel id="languageObject">{t('App.InfoLocal.Informacion.Carta.Modal.updateCategory.language')}</FormLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="languageObject"
                                    name="language"
                                    value={language}
                                    onChange={(e) => { changeLanguage(e, setFieldValue, values); }}
                                  >
                                    <FormControlLabel
                                      value="es"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={es}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagEs"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="en"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={en}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagEn"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="fr"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={fr}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagFr"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="it"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={it}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagIt"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="de"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={de}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagDe"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="pt"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={pt}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagPt"
                                        />
                                      )}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>

                              <Grid item lg={12} xs={12}>
                                <FormGroup>
                                  {/* <Form.Label>Categoría</Form.Label> */}
                                  <TextField
                                    label={t('App.InfoLocal.Informacion.Carta.Modal.updateCategory.form.name')}
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                  />
                                </FormGroup>
                              </Grid>

                              <Grid item lg={4} xs={4}>
                                <Button variant="contained" type="submit" disabled={isSubmitting}>
                                  {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.Carta.Modal.updateCategory.form.button')}
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        );
                      }}
                    </Formik>
                  )}
              </CardContent>
            </Card>
          </div>
        )
        : (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.updateCategory.load')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
                maxHeight: '95%',
                width: '90%',
              }}
            >
              <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.updateCategory.title')} />
              <CardContent>
                {category !== undefined
                  && (
                    <Formik
                      initialValues={{
                        name: category.name[language] || '',
                      }}
                      validationSchema={Yup.object().shape({
                        // name: Yup.string().required(t('App.InfoLocal.
                        // Informacion.Carta.Modal.updateCategory.form.yup.required.name')),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                          category.name[language] = values.name;
                          const data = {
                            category: {
                              name: category.name,
                              // shopId: shopIdActual,
                            },
                          };
                          // if (datos.categoryData !== undefined) {
                          //   updateCategory(datos.categoryData.id, data);
                          //   setUploadOverlay(true);
                          //   datos.fetchdata().then(() => {
                          //     setUploadOverlay(false);
                          //     datos.onHide();
                          //   });
                          // }
                          const consultaAPI = async () => {
                            try {
                              // eslint-disable-next-line max-len
                              const dataCategory = await updateCategory(datos.categoryData.id, data);
                              if (dataCategory.status === 200) {
                                toast.success(t('App.InfoLocal.Informacion.Carta.Modal.updateCategory.form.toast.editDataCategory'));
                                setUploadOverlay(true);
                                datos.fetchdata().then(() => {
                                  setUploadOverlay(false);
                                  datos.onHide();
                                });
                              }
                            } catch (error) {
                              if (error.response.status !== 401) {
                                toast.error(t('newToast.editcategory'));
                              }
                              checkNoAuthError(error, history, toast, t);
                            }
                          };
                          consultaAPI();
                          setSubmitting(false);
                        }, 250);
                      }}
                    >
                      {(_props) => {
                        const {
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          /* y otras más */
                        } = _props;
                        return (
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                              <Grid item lg={12} xs={12}>
                                <FormControl>
                                  <FormLabel id="languageObject">{t('App.InfoLocal.Informacion.Carta.Modal.updateCategory.language')}</FormLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="languageObject"
                                    name="language"
                                    value={language}
                                    onChange={(e) => { changeLanguage(e, setFieldValue, values); }}
                                  >
                                    <FormControlLabel
                                      value="es"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={es}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagEs"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="en"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={en}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagEn"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="fr"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={fr}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagFr"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="it"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={it}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagIt"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="de"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={de}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagDe"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="pt"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={pt}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagPt"
                                        />
                                      )}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>

                              <Grid item lg={12} xs={12}>
                                <FormGroup>
                                  {/* <Form.Label>Categoría</Form.Label> */}
                                  <TextField
                                    label={t('App.InfoLocal.Informacion.Carta.Modal.updateCategory.form.name')}
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                  />
                                </FormGroup>
                              </Grid>

                              <Grid item lg={4} xs={4}>
                                <Button variant="contained" type="submit" disabled={isSubmitting}>
                                  {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.Carta.Modal.updateCategory.form.button')}
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        );
                      }}
                    </Formik>
                  )}
              </CardContent>
            </Card>
          </div>
        )}
    </Modal>
  );
}

export function AddLanguageCategoryModal(props) {
  const [uploadOverlay, setUploadOverlay] = React.useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const datos = props;
  const [language, setLanguage] = React.useState('');
  const [category, setCategory] = React.useState(undefined);

  useEffect(() => {
    setLanguage(datos.language);
    setCategory(datos.categoryData);
  }, [datos.categoryData]);

  const changeLanguage = (event, setFieldValue, values) => {
    category.name[language] = values.name;
    setLanguage(event.target.value);
    setFieldValue('name', category.name[event.target.value] || '');
  };

  return (
    <Modal
      open={datos.show}
      onClose={datos.onHide}
    >
      {datos.width > 720
        ? (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.updateCategory.load')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
              }}
            >
              <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.addLanguageCategoryModal.title')} />
              <CardContent>
                {category !== undefined
                  && (
                    <Formik
                      initialValues={{
                        name: category.name[language] || '',
                      }}
                      validationSchema={Yup.object().shape({
                        // name: Yup.string().required(t('App.InfoLocal.
                        // Informacion.Carta.Modal.addCategory.form.yup.required.name')),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                          category.name[language] = values.name;
                          const data = {
                            category: {
                              name: category.name,
                            },
                          };
                          const consultaAPI = async () => {
                            try {
                              const dataLanguage = await updateCategory(category.id, data);
                              if (dataLanguage.status === 200) {
                                toast.success(t('newToast.addLanguageCategory.success'));
                                setUploadOverlay(true);
                                datos.fetchdata().then(() => {
                                  setUploadOverlay(false);
                                  datos.onHide();
                                });
                              }
                            } catch (error) {
                              if (error.response.status !== 401) {
                                toast.error(t('newToast.addLanguageCategory.error'));
                              }
                              checkNoAuthError(error, history, toast, t);
                            }
                          };
                          consultaAPI();
                          setSubmitting(false);
                        }, 250);
                      }}
                    >
                      {(_props) => {
                        const {
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          /* y otras más */
                        } = _props;
                        return (
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                              <Grid item lg={12} xs={12}>
                                <FormControl>
                                  <FormLabel id="languageObject">{t('App.InfoLocal.Informacion.Carta.Modal.addLanguageCategoryModal.language')}</FormLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="languageObject"
                                    name="language"
                                    value={language}
                                    onChange={(e) => { changeLanguage(e, setFieldValue, values); }}
                                  >
                                    <FormControlLabel
                                      value="en"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={en}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagEn"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="fr"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={fr}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagFr"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="it"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={it}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagIt"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="de"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={de}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagDe"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="pt"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={pt}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagPt"
                                        />
                                      )}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>

                              <Grid item lg={12} xs={12}>
                                <FormGroup>
                                  {/* <Form.Label>Categoría</Form.Label> */}
                                  <TextField
                                    label={t('App.InfoLocal.Informacion.Carta.Modal.addCategory.form.name')}
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                  />
                                </FormGroup>
                              </Grid>

                              <Grid item lg={4} xs={4}>
                                <Button variant="contained" type="submit" disabled={isSubmitting}>
                                  {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.Carta.Modal.addCategory.form.button')}
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        );
                      }}
                    </Formik>
                  )}
              </CardContent>
            </Card>
          </div>
        )
        : (
          <div>
            {uploadOverlay
              && (
                <div className="uploadData">
                  <CircularProgress />
                  <Typography sx={{ color: '#ffffff' }}>
                    {t('App.InfoLocal.Informacion.Carta.Modal.updateCategory.load')}
                  </Typography>
                </div>
              )}
            <Card
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // maxWidth: '2000px',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                // border: '2px solid #000',
                padding: '1%',
                boxShadow: 24,
                p: 4,
                maxHeight: '95%',
                width: '90%',
              }}
            >
              <CardHeader title={t('App.InfoLocal.Informacion.Carta.Modal.addLanguageCategoryModal.title')} />
              <CardContent>
                {category !== undefined
                  && (
                    <Formik
                      initialValues={{
                        name: category.name[language] || '',
                      }}
                      validationSchema={Yup.object().shape({
                        // name: Yup.string().required(t('App.InfoLocal.
                        // Informacion.Carta.Modal.addCategory.form.yup.required.name')),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                          category.name[language] = values.name;
                          const data = {
                            category: {
                              name: category.name,
                            },
                          };
                          const consultaAPI = async () => {
                            try {
                              const dataLanguage = await updateCategory(category.id, data);
                              if (dataLanguage.status === 200) {
                                toast.success(t('newToast.addLanguageCategory.success'));
                                setUploadOverlay(true);
                                datos.fetchdata().then(() => {
                                  setUploadOverlay(false);
                                  datos.onHide();
                                });
                              }
                            } catch (error) {
                              if (error.response.status !== 401) {
                                toast.error(t('newToast.addLanguageCategory.error'));
                              }
                              checkNoAuthError(error, history, toast, t);
                            }
                          };
                          consultaAPI();
                          setSubmitting(false);
                        }, 250);
                      }}
                    >
                      {(_props) => {
                        const {
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          /* y otras más */
                        } = _props;
                        return (
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                              <Grid item lg={12} xs={12}>
                                <FormControl>
                                  <FormLabel id="languageObject">{t('App.InfoLocal.Informacion.Carta.Modal.addLanguageCategoryModal.language')}</FormLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="languageObject"
                                    name="language"
                                    value={language}
                                    onChange={(e) => { changeLanguage(e, setFieldValue, values); }}
                                  >
                                    <FormControlLabel
                                      value="es"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={es}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagEs"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="en"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={en}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagEn"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="fr"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={fr}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagFr"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="it"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={it}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagIt"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="de"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={de}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagDe"
                                        />
                                      )}
                                    />
                                    <FormControlLabel
                                      value="pt"
                                      control={<Radio />}
                                      label={(
                                        <img
                                          src={pt}
                                          style={{ height: 24, width: 24 }}
                                          alt="flagPt"
                                        />
                                      )}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>

                              <Grid item lg={12} xs={12}>
                                <FormGroup>
                                  {/* <Form.Label>Categoría</Form.Label> */}
                                  <TextField
                                    label={t('App.InfoLocal.Informacion.Carta.Modal.addCategory.form.name')}
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                  />
                                </FormGroup>
                              </Grid>

                              <Grid item lg={4} xs={4}>
                                <Button variant="contained" type="submit" disabled={isSubmitting}>
                                  {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.Carta.Modal.addCategory.form.button')}
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        );
                      }}
                    </Formik>
                  )}
              </CardContent>
            </Card>
          </div>
        )}
    </Modal>
  );
}

// export function DeletePromotionButton(props) {
//   const history = useHistory();
//   const datos = props;

//   function onClickEvent() {
//     if (datos.promotionData !== undefined) {
//       deletePromotion(datos.promotionData.id);
//       history.push('/App/InfoLocal/Informacion/Categoria');
//       datos.onHide();
//     }
//   }

//   return (
//     <Modal
//       onHide={datos.onHide}
//       show={datos.show}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           Borrar Tienda
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <p>
//           ¿Estas seguro de que quieres eliminar la categoría?
//         </p>

//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={useCallback(onClickEvent, [])}>Eliminar</Button>
//         <Button onClick={datos.onHide}>Volver</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }// Probar

// export function AddAttributeInGroup({
//   name, modificator, updateModificator, ...props
// }) {
//   const [show, setShow] = React.useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   return (
//     <>
//       <Button variant="primary" onClick={handleShow}
// className="me-2" style={{ width: '100%', marginTop: '5px' }}>
//         {name}
//       </Button>
//       <Offcanvas show={show} onHide={handleClose} {...props}>
//         <Offcanvas.Header closeButton>
//           <Offcanvas.Title>Crear Atributo</Offcanvas.Title>
//         </Offcanvas.Header>
//         <Offcanvas.Body>
//           <Container style={{ marginTop: '2%' }}>
//             {/* <Card> */}
//             {/* <Card.Body> */}
//             <Formik
//               initialValues={{
//                 name: '',
//                 price: '',
//                 allergens: [],
//               }}
//               validationSchema={Yup.object().shape({
//                 name: Yup.string().required('Required'),
//                 price: Yup.string().required('Required'),
//               })}
//               onSubmit={(values, { setSubmitting }) => {
//                 setTimeout(() => {
//                   const allergensNumber = values.allergens.map((i) => parseInt(i, 10));
//                   const data = {
//                     name: values.name,
//                     price: values.price,
//                     allergens: allergensNumber,
//                   };
//                   updateModificator([...modificator, data]);

//                   handleClose();
//                   setSubmitting(false);
//                 }, 250);
//               }}
//             >
//               {(props) => {
//                 const {
//                   values,
//                   errors,
//                   handleChange,
//                   handleBlur,
//                   handleSubmit,
//                   isSubmitting,
//                   /* y otras más */
//                 } = props;
//                 return (
//                   <Form onSubmit={handleSubmit}>
//                     <Row className="mb-3">
//                       <FormGroup>
//                         <Form.Label>Nombre</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="name"
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={values.name}
//                           isInvalid={!!errors.name}
//                         />
//                         <Form.Control.Feedback type="invalid">
//                           {errors.name}
//                         </Form.Control.Feedback>
//                       </FormGroup>
//                     </Row>
//                     <Row className="mb-3">
//                       <FormGroup>
//                         <Form.Label>Precio</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="price"
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={values.price}
//                           isInvalid={!!errors.price}
//                         />
//                         <Form.Control.Feedback type="invalid">
//                           {errors.price}
//                         </Form.Control.Feedback>
//                       </FormGroup>
//                     </Row>
//                     <Row className="mb-3">
//                       <FormGroup>
//                         <Form.Label>Alérgenos</Form.Label>
//                         <Row>
//                           {allergens.allergens.map((allergen, a) => {
//                             let allergenImg;
//                             let allergenName;
//                             switch (allergen.name) {
//                               case 'celery':
//                                 allergenImg = <img src={celery}
// style={{ height: 32, width: 32 }} />;
//                                 allergenName = 'Apio';
//                                 break;
//                               case 'crustaceans':
//                                 allergenImg = <img src={crustaceans}
// style={{ height: 32, width: 32 }} />;
//                                 allergenName = 'Crustaceo';
//                                 break;
//                               case 'eggs':
//                                 allergenImg = <img src={eggs}
// style={{ height: 32, width: 32 }} />;
//                                 allergenName = 'Huevo';
//                                 break;
//                               case 'fish':
//                                 allergenImg = <img src={fish}
// style={{ height: 32, width: 32 }} />;
//                                 allergenName = 'Pescado';
//                                 break;
//                               case 'cereals':
//                                 allergenImg = <img src={cereals}
// style={{ height: 32, width: 32 }} />;
//                                 allergenName = 'Gluten';
//                                 break;
//                               case 'lupin':
//                                 allergenImg = <img src={lupin}
// style={{ height: 32, width: 32 }} />;
//                                 allergenName = 'Lúpulo';
//                                 break;
//                               case 'milk':
//                                 allergenImg = <img src={milk}
// style={{ height: 32, width: 32 }} />;
//                                 allergenName = 'Leche';
//                                 break;
//                               case 'molluscs':
//                                 allergenImg = <img src={molluscs}
// style={{ height: 32, width: 32 }} />;
//                                 allergenName = 'Moluscos';
//                                 break;
//                               case 'mustard':
//                                 allergenImg = <img src={mustard}
// style={{ height: 32, width: 32 }} />;
//                                 allergenName = 'Mostaza';
//                                 break;
//                               case 'nuts':
//                                 allergenImg = <img src={nuts}
// style={{ height: 32, width: 32 }} />;
//                                 allergenName = 'Nueces';
//                                 break;
//                               case 'peanuts':
//                                 allergenImg = <img src={peanuts}
// style={{ height: 32, width: 32 }} />;
//                                 allergenName = 'Manices';
//                                 break;
//                               case 'sesame':
//                                 allergenImg = <img src={sesame}
// style={{ height: 32, width: 32 }} />;
//                                 allergenName = 'Sésamo';
//                                 break;
//                               case 'soya':
//                                 allergenImg = <img src={soya}
// style={{ height: 32, width: 32 }} />;
//                                 allergenName = 'Soja';
//                                 break;
//                               case 'sulphites':
//                                 allergenImg = <img src={sulphites}
// style={{ height: 32, width: 32 }} />;
//                                 allergenName = 'Sulfitos';
//                                 break;
//                               default:
//                                 allergenImg = <img src={celery}
// style={{ height: 32, width: 32 }} />;
//                                 allergenName = 'Apio';
//                                 break;
//                             }
//                             return (
//                               <Col lg={4} key={a}>
//                                 <Form.Check
//                                   name="allergens"
//                                   label={(
//                                     <>
//                                       {allergenImg}
//                                       {allergenName}
//                                     </>
//                                   )}
//                                   onChange={handleChange}
//                                   onBlur={handleBlur}
//                                   value={allergen.allergen_id}
//                                 />
//                               </Col>
//                             )
//                           })}
//                         </Row>
//                       </FormGroup>
//                     </Row>
//                     <Row className="mb-3 mx-5 mt-4">
//                       <Button type="submit" disabled={isSubmitting}>
//                         {isSubmitting ? 'Loading' : 'Añadir'}
//                       </Button>
//                     </Row>
//                   </Form>
//                 );
//               }}
//             </Formik>
//             {/* </Card.Body> */}
//             {/* </Card> */}
//           </Container>
//         </Offcanvas.Body>
//       </Offcanvas>
//     </>
//   );
// }

// export function NewAddAttributeInGroup({
//   name, groups, updateGroups, idGroup, ...props
// }) {
//   const [show, setShow] = React.useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   return (
//     <>
//       <Button variant="primary" onClick={handleShow} className="me-2">
//         {name}
//       </Button>
//       <Offcanvas show={show} onHide={handleClose} {...props}>
//         <Offcanvas.Header closeButton>
//           <Offcanvas.Title>Crear Atributo</Offcanvas.Title>
//         </Offcanvas.Header>
//         <Offcanvas.Body>
//           <Container style={{ marginTop: '2%' }}>
//             <Card>
//               <Card.Body>
//                 <Formik
//                   initialValues={{
//                     name: '',
//                     price: '',
//                     allergens: [],
//                   }}
//                   validationSchema={Yup.object().shape({
//                     name: Yup.string().required('Required'),
//                     price: Yup.string().required('Required'),
//                   })}
//                   onSubmit={(values, { setSubmitting }) => {
//                     setTimeout(() => {
//                       const allergensNumber = values.allergens.map((i) => parseInt(i, 10));
//                       const data = {
//                         name: values.name,
//                         price: values.price,
//                         allergens: allergensNumber,
//                       };

//                       groups[idGroup].attributes.push(data);
//                       updateGroups(groups);

//                       handleClose();
//                       setSubmitting(false);
//                     }, 250);
//                   }}
//                 >
//                   {(props) => {
//                     const {
//                       values,
//                       errors,
//                       handleChange,
//                       handleBlur,
//                       handleSubmit,
//                       isSubmitting,
//                       /* y otras más */
//                     } = props;
//                     return (
//                       <Form onSubmit={handleSubmit}>

//                         <FormGroup>
//                           <Form.Label>Nombre</Form.Label>
//                           <Form.Control
//                             type="text"
//                             name="name"
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             value={values.name}
//                             isInvalid={!!errors.name}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.name}
//                           </Form.Control.Feedback>
//                         </FormGroup>

//                         <FormGroup>
//                           <Form.Label>Precio</Form.Label>
//                           <Form.Control
//                             type="text"
//                             name="price"
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             value={values.price}
//                             isInvalid={!!errors.price}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.price}
//                           </Form.Control.Feedback>
//                         </FormGroup>

//                         <FormGroup>
//                           <Form.Label>Alérgenos</Form.Label>
//                           <Row>
//                             {allergens.allergens.map((allergen, a) => {
//                               let allergenImg;
//                               let allergenName;
//                               switch (allergen.name) {
//                                 case 'celery':
//                                   allergenImg = <img src={celery}
// style={{ height: 32, width: 32 }} />;
//                                   allergenName = 'Apio';
//                                   break;
//                                 case 'crustaceans':
//                                   allergenImg = <img src={crustaceans}
// style={{ height: 32, width: 32 }} />;
//                                   allergenName = 'Crustaceo';
//                                   break;
//                                 case 'eggs':
//                                   allergenImg = <img src={eggs}
// style={{ height: 32, width: 32 }} />;
//                                   allergenName = 'Huevo';
//                                   break;
//                                 case 'fish':
//                                   allergenImg = <img src={fish}
// style={{ height: 32, width: 32 }} />;
//                                   allergenName = 'Pescado';
//                                   break;
//                                 case 'cereals':
//                                   allergenImg = <img src={cereals}
// style={{ height: 32, width: 32 }} />;
//                                   allergenName = 'Gluten';
//                                   break;
//                                 case 'lupin':
//                                   allergenImg = <img src={lupin}
// style={{ height: 32, width: 32 }} />;
//                                   allergenName = 'Lúpulo';
//                                   break;
//                                 case 'milk':
//                                   allergenImg = <img src={milk}
// style={{ height: 32, width: 32 }} />;
//                                   allergenName = 'Leche';
//                                   break;
//                                 case 'molluscs':
//                                   allergenImg = <img src={molluscs}
// style={{ height: 32, width: 32 }} />;
//                                   allergenName = 'Moluscos';
//                                   break;
//                                 case 'mustard':
//                                   allergenImg = <img src={mustard}
// style={{ height: 32, width: 32 }} />;
//                                   allergenName = 'Mostaza';
//                                   break;
//                                 case 'nuts':
//                                   allergenImg = <img src={nuts}
// style={{ height: 32, width: 32 }} />;
//                                   allergenName = 'Nueces';
//                                   break;
//                                 case 'peanuts':
//                                   allergenImg = <img src={peanuts}
// style={{ height: 32, width: 32 }} />;
//                                   allergenName = 'Manices';
//                                   break;
//                                 case 'sesame':
//                                   allergenImg = <img src={sesame}
// style={{ height: 32, width: 32 }} />;
//                                   allergenName = 'Sésamo';
//                                   break;
//                                 case 'soya':
//                                   allergenImg = <img src={soya}
// style={{ height: 32, width: 32 }} />;
//                                   allergenName = 'Soja';
//                                   break;
//                                 case 'sulphites':
//                                   allergenImg = <img src={sulphites}
// style={{ height: 32, width: 32 }} />;
//                                   allergenName = 'Sulfitos';
//                                   break;
//                                 default:
//                                   allergenImg = <img src={celery}
// style={{ height: 32, width: 32 }} />;
//                                   allergenName = 'Apio';
//                                   break;
//                               }
//                               return (
//                                 <Col lg={4} key={a}>
//                                   <Form.Check
//                                     name="allergens"
//                                     label={(
//                                       <>
//                                         {allergenImg}
//                                         {allergenName}
//                                       </>
//                                     )}
//                                     onChange={handleChange}
//                                     onBlur={handleBlur}
//                                     value={allergen.allergen_id}
//                                   />
//                                 </Col>
//                               )
//                             })}
//                           </Row>
//                         </FormGroup>

//                         <Button type="submit" disabled={isSubmitting}>
//                           {isSubmitting ? 'Loading' : 'Añadir'}
//                         </Button>
//                       </Form>
//                     );
//                   }}
//                 </Formik>
//               </Card.Body>
//             </Card>
//           </Container>
//         </Offcanvas.Body>
//       </Offcanvas>
//     </>
//   );
// }

// export function DeleteAttributeInGroup(props) {
//   function onClickEvent() {
//     const newAttribute = [];
//     for (let i = 0; i < props.groups[props.idGroup].attributes.length; i++) {
//       if (props.attribute !== props.groups[props.idGroup].attributes[i]) {
//         newAttribute.push(props.groups[props.idGroup].attributes[i]);
//       }
//     }
//     props.groups[props.idGroup].attributes = newAttribute;
//     props.updateGroups(props.groups);
//     props.onHide();
//   }

//   return (
//     <Modal
//       onHide={props.onHide}
//       show={props.show}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           Borrar Atributos
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <p>
//           ¿Estas seguro de que quieres eliminar el atributo?
//         </p>

//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={onClickEvent}>Eliminar</Button>
//         <Button onClick={props.onHide}>Volver</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// // Ahi que mirar chequeo con API
// export function UpdateAttributeInGroup(props) {
//   const checkedAllergens = [];

//   for (let i = 0; i < 14; i++) {
//     checkedAllergens.push({ checked: false, id: i + 1 });
//   }

//   if (props.attributes !== false) {
//     for (let i = 0; i < props.attributes.allergens.length; i++) {
//       for (let j = 0; j < checkedAllergens.length; j++) {
//         console.log(checkedAllergens[j].id);
//         console.log(props.attributes.allergens[i]);
//         if (props.attributes.allergens[i] === checkedAllergens[j].id) {
//           checkedAllergens[j].checked = true;
//         }
//       }
//     }
//   }

//   console.log(checkedAllergens);

//   return (
//     <Modal
//       onHide={props.onHide}
//       show={props.show}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           Actualizar Atributo
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Container style={{ marginTop: '2%' }}>
//           <Card>
//             <Card.Body>
//               {props.attributes != false
//                 && (
//                   <Formik
//                     initialValues={{
//                       name: props.attributes.name || '',
//                       price: props.attributes.price || '',
//                       allergens: props.attributes.allergens
// .map((allergen) => allergen.toString()) || [],
//                     }}
//                     validationSchema={Yup.object().shape({
//                       name: Yup.string().required('Required'),
//                       price: Yup.string().required('Required'),
//                     })}
//                     onSubmit={(values, { setSubmitting }) => {
//                       setTimeout(() => {
//                         const allergensNumber = values.allergens.map((i) => parseInt(i, 10));
//                         const data = {
//                           name: values.name,
//                           price: values.price,
//                           allergens: allergensNumber,
//                         };

//                         for (let i = 0; i < props.groups[props.idGroup].attributes.length; i++) {
//                           if (props.attribute !== props.groups[props.idGroup].attributes[i]) {
//                             props.groups[props.idGroup].attributes[i] = data;
//                             break;
//                           }
//                         }
//                         // props.updateGroups(props.groups);

//                         props.onHide();
//                         setSubmitting(false);
//                       }, 250);
//                     }}
//                   >
//                     {(props) => {
//                       const {
//                         values,
//                         errors,
//                         handleChange,
//                         handleBlur,
//                         handleSubmit,
//                         isSubmitting,
//                         /* y otras más */
//                       } = props;
//                       return (
//                         <Form onSubmit={handleSubmit}>

//                           <FormGroup>
//                             <Form.Label>Nombre</Form.Label>
//                             <Form.Control
//                               type="text"
//                               name="name"
//                               onChange={handleChange}
//                               onBlur={handleBlur}
//                               value={values.name}
//                               isInvalid={!!errors.name}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                               {errors.name}
//                             </Form.Control.Feedback>
//                           </FormGroup>

//                           <FormGroup>
//                             <Form.Label>Precio</Form.Label>
//                             <Form.Control
//                               type="text"
//                               name="price"
//                               onChange={handleChange}
//                               onBlur={handleBlur}
//                               value={values.price}
//                               isInvalid={!!errors.price}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                               {errors.price}
//                             </Form.Control.Feedback>
//                           </FormGroup>

//                           <FormGroup>
//                             <Form.Label>Alérgenos</Form.Label>
//                             <Row>
//                               {checkedAllergens.map((allergen, a) => {
//                                 let allergenImg;
//                                 let allergenName;
//                                 switch (allergen.id) {
//                                   case 2:
//                                     allergenImg = <img src={celery}
// style={{ height: 32, width: 32 }} alt="allergen" />;
//                                     allergenName = 'Apio';
//                                     break;
//                                   case 3:
//                                     allergenImg = <img src={crustaceans}
// style={{ height: 32, width: 32 }} alt="allergen" />;
//                                     allergenName = 'Crustaceo';
//                                     break;
//                                   case 5:
//                                     allergenImg = <img src={eggs}
// style={{ height: 32, width: 32 }} alt="allergen" />;
//                                     allergenName = 'Huevo';
//                                     break;
//                                   case 4:
//                                     allergenImg = <img src={fish}
// style={{ height: 32, width: 32 }} alt="allergen" />;
//                                     allergenName = 'Pescado';
//                                     break;
//                                   case 1:
//                                     allergenImg = <img src={cereals}
// style={{ height: 32, width: 32 }} alt="allergen" />;
//                                     allergenName = 'Gluten';
//                                     break;
//                                   case 12:
//                                     allergenImg = <img src={lupin}
// style={{ height: 32, width: 32 }} alt="allergen" />;
//                                     allergenName = 'Lúpulo';
//                                     break;
//                                   case 8:
//                                     allergenImg = <img src={milk}
// style={{ height: 32, width: 32 }} alt="allergen" />;
//                                     allergenName = 'Leche';
//                                     break;
//                                   case 13:
//                                     allergenImg = <img src={molluscs}
// style={{ height: 32, width: 32 }} alt="allergen" />;
//                                     allergenName = 'Moluscos';
//                                     break;
//                                   case 10:
//                                     allergenImg = <img src={mustard}
// style={{ height: 32, width: 32 }} alt="allergen" />;
//                                     allergenName = 'Mostaza';
//                                     break;
//                                   case 9:
//                                     allergenImg = <img src={nuts}
// style={{ height: 32, width: 32 }} alt="allergen" />;
//                                     allergenName = 'Nueces';
//                                     break;
//                                   case 6:
//                                     allergenImg = <img src={peanuts}
// style={{ height: 32, width: 32 }} alt="allergen" />;
//                                     allergenName = 'Manices';
//                                     break;
//                                   case 11:
//                                     allergenImg = <img src={sesame}
// style={{ height: 32, width: 32 }} alt="allergen" />;
//                                     allergenName = 'Sésamo';
//                                     break;
//                                   case 7:
//                                     allergenImg = <img src={soya}
// style={{ height: 32, width: 32 }} alt="allergen" />;
//                                     allergenName = 'Soja';
//                                     break;
//                                   case 14:
//                                     allergenImg = <img src={sulphites}
// style={{ height: 32, width: 32 }} alt="allergen" />;
//                                     allergenName = 'Sulfitos';
//                                     break;
//                                   default:
//                                     allergenImg = <img src={celery}
// style={{ height: 32, width: 32 }} alt="allergen" />;
//                                     allergenName = 'Apio';
//                                     break;
//                                 }
//                                 return (
//                                   <Col lg={2} key={a}>
//                                     <Form.Check
//                                       name="allergens"
//                                       label={(
//                                         <>
//                                           {allergenImg}
//                                           {allergenName}
//                                         </>
//                                       )}
//                                       onChange={handleChange}
//                                       onBlur={handleBlur}
//                                       value={allergen.id}
//                                       defaultChecked={allergen.checked}
//                                     />
//                                   </Col>
//                                 );
//                               })}
//                             </Row>
//                           </FormGroup>

//                           <Button type="submit" disabled={isSubmitting}>
//                             {isSubmitting ? 'Loading' : 'Añadir'}
//                           </Button>
//                         </Form>
//                       );
//                     }}
//                   </Formik>
//                 )}
//             </Card.Body>
//           </Card>
//         </Container>

//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide}>Cerrar</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// export function DeleteGroupAttributeInGroup(props) {
//   function onClickEvent() {
//     const newGroupAttribute = [];
//     for (let i = 0; i < props.groups.length; i++) {
//       if (props.group.nameGroup !== props.groups[i].nameGroup) {
//         newGroupAttribute.push(props.groups[i]);
//       }
//     }
//     props.updateGroups(newGroupAttribute);
//     props.onHide();
//   }

//   return (
//     <Modal
//       onHide={props.onHide}
//       show={props.show}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           Borrar Grupo de Atributos
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <p>
//           ¿Estas seguro de que quieres eliminar el grupo de atributos?
//         </p>

//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={onClickEvent}>Eliminar</Button>
//         <Button onClick={props.onHide}>Volver</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// export function Section(props) {
//   const [deleteGroupAttributeShow, setDeleteGroupAttributeShow] = React.useState(false);

//   const config = {
//     defaultExpanded: props.defaultExpanded || false,
//     collapsedHeight: props.collapsedHeight || 0,
//   };
//   const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
//   return (
//     <div className="collapsible">
//       <div className="header" {...getToggleProps()}>
//         <Row>
//           <Col lg={2}>
//             <div className="title"><h5>{props.title}</h5></div>
//           </Col>
//           <Col lg={4}>
//             <Button
//               variant="outline-secondary"
//               onClick={(e) => {
//                 setDeleteGroupAttributeShow(true);
//               }}
//             >
//               Eliminar Grupo de Atributos
//             </Button>

//             <DeleteGroupAttributeInGroup
//               show={deleteGroupAttributeShow}
//               onHide={() => setDeleteGroupAttributeShow(false)}
//               idGroup={props.idGroup}
//               groups={props.groups}
//               updateGroups={props.setGroups}
//               group={props.group}
//             />
//           </Col>
//           {/* <div className='icon'>
//                     <i className={'fas fa-chevron-circle-' + (isExpanded ? 'up' : 'down')}></i>
//                 </div> */}
//         </Row>
//       </div>
//       <div {...getCollapseProps()}>
//         <div className="content">
//           {props.children}
//         </div>
//       </div>
//     </div>
//   );
// }

// export function SectionMenu(props) {
//   const [deleteMenuShow, setDeleteMenuShow] = React.useState(false);
//   const [editMenuShow, setEditMenuShow] = React.useState(false);

//   const datos = props;
//   const config = {
//     defaultExpanded: datos.defaultExpanded || false,
//     collapsedHeight: datos.collapsedHeight || 0,
//   };
//   const { getCollapseProps, getToggleProps /* , isExpanded */ } = useCollapse(config);
//   return (
//     <div className="collapsible">
//       <div
//         className="header"
//         // eslint-disable-next-line react/jsx-props-no-spreading
//         {...getToggleProps()}
//       >
//         <Table>
//           <tr>
//             <td className="col-lg-1">
//               <Image
//                 src={datos.image}
//                 rounded
//                 style={{ width: 100, height: 100, objectFit: 'cover' }}
//               />
//             </td>
//             <td className="col-lg-1">{datos.menu.name}</td>
//             <td className="col-lg-4">{datos.menu.description}</td>
//             <td className="col-lg-1">
//               {datos.menu.price}
//               {' '}
//               €
//             </td>
//             <td className="col-lg-1">
//               <Button
//                 variant="outline-secondary"
//                 onClick={() => {
//                   setEditMenuShow(true);
//                   // setMenu(respuestaAPIMenus[e.currentTarget.getAttribute('data-rowid')]);
//                 }}
//               >
//                 Editar
//               </Button>
//               <EditMenu
//                 show={editMenuShow}
//                 onHide={() => setEditMenuShow(false)}
//               // menu={selectMenu}
//               // productsValue={respuestaAPIProducts}
//               // fetchdata={useCallback(fetchData)}
//               />

//               &nbsp;

//               <Button
//                 variant="outline-secondary"
//                 onClick={() => {
//                   setDeleteMenuShow(true);
//                   // setMenu(respuestaAPIMenus[e.currentTarget.getAttribute('data-rowid')]);
//                 }}
//               >
//                 Eliminar
//               </Button>
//               <DeleteMenutButton
//                 show={deleteMenuShow}
//                 onHide={() => setDeleteMenuShow(false)}
//                 idMenu={datos.idMenu}
//               />
//             </td>
//           </tr>
//         </Table>
//       </div>
//       <div
//         // eslint-disable-next-line react/jsx-props-no-spreading
//         {...getCollapseProps()}
//       >
//         <div className="content">
//           {datos.children}
//         </div>
//       </div>
//     </div>
//   );
// }

// export function SectionMenuCart(props) {
//   const datos = props;
//   const config = {
//     defaultExpanded: datos.defaultExpanded || false,
//     collapsedHeight: datos.collapsedHeight || 0,
//   };
//   const { getCollapseProps, getToggleProps /* , isExpanded */ } = useCollapse(config);
//   return (
//     <div className="collapsible">
//       <div
//         className="header"
//         // eslint-disable-next-line react/jsx-props-no-spreading
//         {...getToggleProps()}
//       >
//         <Table>
//           <tbody>
//             <tr>
//               <td className="col-lg-1">
//                 <Image
//                   src={datos.image}
//                   rounded
//                   style={{ width: 100, height: 100, objectFit: 'cover' }}
//                 />
//               </td>
//               <td className="col-lg-1">{datos.menu.name}</td>
//               <td className="col-lg-4">{datos.menu.description}</td>
//               <td className="col-lg-1">
//                 {datos.menu.price}
//                 {' '}
//                 €
//               </td>
//             </tr>
//           </tbody>
//         </Table>
//       </div>
//       <div
//         // eslint-disable-next-line react/jsx-props-no-spreading
//         {...getCollapseProps()}
//       >
//         <div className="content">
//           {datos.children}
//         </div>
//       </div>
//     </div>
//   );
// }
