import * as React from 'react';
import { Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { useTranslation } from 'react-i18next';

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const { t } = useTranslation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: 'white',
        boxShadow: '0',
        transition: '0',
        WebkitTransition: '0',
      }}
    >
      <Container style={{ maxWidth: '1500px' }}>
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h5"
              noWrap
              style={{ color: 'black' }}
            >
              {t('App.Cuenta.MenuCuenta.account')}
            </Typography>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              style={{ color: 'black' }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem
                onClick={handleCloseNavMenu}
                component={Link}
                to="/App/Cuenta/Locales"
              >
                <Typography textAlign="center" xs={{ color: 'black' }}>
                  {t('App.Cuenta.MenuCuenta.locals')}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={handleCloseNavMenu}
                component={Link}
                to="/App/Cuenta/Perfil"
              >
                <Typography textAlign="center" xs={{ color: 'black' }}>
                  {t('App.Cuenta.MenuCuenta.profile')}
                </Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              // justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h4"
              noWrap
              sx={{ color: 'black' }}
              style={{ paddingRight: '32px' }}
            >
              {t('App.Cuenta.MenuCuenta.account')}
            </Typography>
            <div style={{ display: 'flex', paddingRight: '100px' }}>
              <Button
                onClick={handleCloseNavMenu}
                component={Link}
                to="/App/Cuenta/Locales"
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                {t('App.Cuenta.MenuCuenta.locals')}
              </Button>
              <Button
                onClick={handleCloseNavMenu}
                component={Link}
                to="/App/Cuenta/Perfil"
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                {t('App.Cuenta.MenuCuenta.profile')}
              </Button>
            </div>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
