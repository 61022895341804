import React from 'react';
// import {
//   Container, Row, Col, Image,
// } from 'react-bootstrap';
import './QuienesSomos.scss';
import {
  Card,
  Container, Grid, ListItem, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useTranslation } from 'react-i18next';
import Angel from '../nosotros/caamano.PNG';
import Quesada from '../nosotros/quesada.jpg';
import Javier from '../nosotros/alayon.jpg';

function Descripcion() {
  const { t } = useTranslation();

  return (
    <Container style={{ marginTop: '2%', marginBottom: '2%', maxWidth: '1000px' }}>
      <Typography variant="h4" align="center" style={{ marginBottom: '10px' }}>
        {t('paginaentrada.descripcionEntrada.aboutUs')}
      </Typography>
      <Typography variant="body1" align="justify">
        {t('paginaentrada.descripcionEntrada.infoAboutUs')}
      </Typography>

      <div className="divider div-transparent" />
      <Typography variant="h4" align="center" style={{ marginBottom: '10px' }}>
        {t('paginaentrada.descripcionEntrada.team.title')}
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid item lg={4} xs={4} style={{ width: 250 }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={Quesada} style={{ width: 150, height: 200, borderRadius: '300px' }} alt="imageQuesada" />
            <Typography variant="h5">
              Alejandro Quesada
            </Typography>
            <Typography variant="body1">
              (
              {t('paginaentrada.descripcionEntrada.team.study1')}
              )
            </Typography>
          </div>
        </Grid>
        <Grid item lg={4} xs={4} style={{ width: 250 }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={Javier} style={{ width: 150, height: 200, borderRadius: '300px' }} alt="imageJavier" />
            <Typography variant="h5">
              Javier Alayón
            </Typography>
            <Typography variant="body1">
              (
              {t('paginaentrada.descripcionEntrada.team.study2')}
              )
            </Typography>
          </div>
        </Grid>
        <Grid item lg={4} xs={4} style={{ width: 250 }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={Angel} style={{ width: 150, height: 200, borderRadius: '300px' }} alt="imageAngel" />
            <Typography variant="h5">
              Angel Caamaño
            </Typography>
            <Typography variant="body1">
              (
              {t('paginaentrada.descripcionEntrada.team.study2')}
              )
            </Typography>
          </div>
        </Grid>
      </div>
      <div className="divider div-transparent" />
      <Typography variant="h4" align="center" style={{ marginBottom: '10px' }}>
        {t('paginaentrada.descripcionEntrada.mesonero.title')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '5px' }}>
        {t('paginaentrada.descripcionEntrada.mesonero.infoMesonero1')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '5px' }}>
        {t('paginaentrada.descripcionEntrada.mesonero.infoMesonero2')}
      </Typography>
      <Typography variant="body1" align="justify" style={{ marginBottom: '5px' }}>
        {t('paginaentrada.descripcionEntrada.mesonero.infoMesonero3')}
      </Typography>
      <Typography style={{ marginBottom: '10px' }}>
        {t('paginaentrada.descripcionEntrada.mesonero.infoMesonero4')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item lg={4}>
          <Card>
            <Typography align="center" sx={{ paddingTop: '8px' }}>
              <strong>{t('paginaentrada.descripcionEntrada.mesonero.fase1.title')}</strong>
            </Typography>
            <ListItem>
              <ListItemIcon>
                <ArrowRightAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('paginaentrada.descripcionEntrada.mesonero.fase1.firstPoint')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ArrowRightAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('paginaentrada.descripcionEntrada.mesonero.fase1.secondPoint')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ArrowRightAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('paginaentrada.descripcionEntrada.mesonero.fase1.thirdPoint')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ArrowRightAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('paginaentrada.descripcionEntrada.mesonero.fase1.fourthPoint')} />
            </ListItem>
          </Card>
        </Grid>
        <Grid item lg={4}>
          <Card>
            <Typography align="center" sx={{ paddingTop: '8px' }}>
              <strong>{t('paginaentrada.descripcionEntrada.mesonero.fase2.title')}</strong>
            </Typography>
            <ListItem>
              <ListItemIcon>
                <ArrowRightAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('paginaentrada.descripcionEntrada.mesonero.fase2.firstPoint')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ArrowRightAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('paginaentrada.descripcionEntrada.mesonero.fase2.secondPoint')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ArrowRightAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('paginaentrada.descripcionEntrada.mesonero.fase2.thirdPoint')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ArrowRightAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('paginaentrada.descripcionEntrada.mesonero.fase2.fourthPoint')} />
            </ListItem>
          </Card>
        </Grid>
        <Grid item lg={4}>
          <Card>
            <Typography align="center" sx={{ paddingTop: '8px' }}>
              <strong>{t('paginaentrada.descripcionEntrada.mesonero.fase3.title')}</strong>
            </Typography>
            <ListItem>
              <ListItemIcon>
                <ArrowRightAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('paginaentrada.descripcionEntrada.mesonero.fase3.firstPoint')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ArrowRightAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('paginaentrada.descripcionEntrada.mesonero.fase3.secondPoint')} />
            </ListItem>
          </Card>
        </Grid>
      </Grid>

    </Container>
  );
}

export default Descripcion;
