import React, { useEffect } from 'react';
// import {
//   Card, Container, Form, Button, Row, Col,
// } from 'react-bootstrap';

import {
  Container, Card, CardContent,
  Grid, Button, FormGroup, TextField, Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { Formik } from 'formik';
import { getImageShop, getShop } from '../../../../services/mesonero-api/api';
import { DeleteShopButton, EditShop } from '../Carta/Modal/Modals';

function PerfilLocal() {
  const [modalShow, setModalShow] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [imageShop, setImageShop] = React.useState(null);

  const [respuestaAPI, setRespuestaAPI] = React.useState(undefined);

  const { t } = useTranslation();

  async function fetchImageShop(idEnterprise, idShop) {
    let image;
    try {
      image = await getImageShop(idEnterprise, idShop);
    } catch (e) {
      image = null;
    }
    return image;
  }

  const fetchData = async () => {
    const shopIdActual = localStorage.getItem('shop_id');

    const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'));
    const shop = await getShop(datosUsuario.enterprise.id, shopIdActual);
    setRespuestaAPI(shop);
    let imageShopApi = '';
    if (shop.pictures.length !== 0) {
      const image = fetchImageShop(datosUsuario.enterprise.id, shopIdActual);
      imageShopApi = image;
    } else {
      imageShopApi = null;
    }
    setImageShop(await imageShopApi);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {typeof respuestaAPI === 'undefined'
        ? (<div />)
        : (
          <Container style={{ maxWidth: '1200px' }}>
            <Grid container sx={{ marginTop: '15px', marginBottom: '15px', alignItems: 'center' }}>
              <Grid item lg={1}>
                <Link to="/App/InfoLocal/Informacion/PaginaInicialLocal">
                  <Button>
                    <ArrowBackIcon />
                  </Button>
                </Link>
              </Grid>
              <Grid item lg={3}>
                <Typography variant="h6">
                  {t('App.InfoLocal.Informacion.PerfilLocal.route')}
                </Typography>
              </Grid>
            </Grid>
            <Container style={{ maxWidth: '1000px' }}>
              <Card>
                <CardContent>
                  <h2 style={{ textAlign: 'center' }}>{t('App.InfoLocal.Informacion.PerfilLocal.title')}</h2>
                </CardContent>
                {imageShop !== null
                  ? (
                    <img
                      style={{
                        height: '160px',
                        width: '284px',
                        margin: 'auto',
                        display: 'block',
                      }}
                      src={imageShop}
                      alt="imageMenu"
                    />
                  )
                  : (
                    <div
                      style={{
                        height: '160px',
                        width: '284px',
                        backgroundColor: '#ECECEC',
                        margin: 'auto',
                      }}
                    />
                  )}

                <CardContent>
                  <Formik
                    initialValues={{
                      name: respuestaAPI.name || '',
                      // phoneNumber: respuestaAPI.phoneNumber || '',
                      address: respuestaAPI.address || '',
                      country: 'España',
                      province: respuestaAPI.province || '',
                      postalCode: respuestaAPI.postalCode || '',
                      website: respuestaAPI.website || '',
                      capacity: respuestaAPI.capacity || '',
                      tablesNumber: respuestaAPI.tablesNumber || '',
                    }}
                  >
                    {(props) => {
                      const {
                        values,
                      } = props;
                      return (
                        <form>
                          <Grid container spacing={3}>
                            <Grid item lg={6} xs={12}>
                              <FormGroup>
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.name')}
                                  name="name"
                                  value={values.name}
                                  disabled
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <FormGroup>
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.address')}
                                  name="address"
                                  value={values.address}
                                  disabled
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={4} xs={12}>
                              <FormGroup>
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.country')}
                                  name="country"
                                  value={values.country}
                                  disabled
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={5} xs={12}>
                              <FormGroup>
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.province')}
                                  name="province"
                                  value={values.province}
                                  disabled
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={3} xs={12}>
                              <FormGroup>
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.postalCode')}
                                  name="postalCode"
                                  value={values.postalCode}
                                  disabled
                                />
                              </FormGroup>
                            </Grid>

                            {/* <Grid item lg={12} xs={12}>
                              <FormGroup>
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.phoneNumber')}
                                  name="phoneNumber"
                                  value={values.phoneNumber}
                                  disabled
                                />
                              </FormGroup>
                            </Grid> */}

                            <Grid item lg={4} xs={12}>
                              <FormGroup>
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.web')}
                                  name="website"
                                  value={values.website}
                                  disabled
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={4} xs={12}>
                              <FormGroup>
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.capacity')}
                                  name="capacity"
                                  value={values.capacity}
                                  disabled
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item lg={4} xs={12}>
                              <FormGroup>
                                <TextField
                                  label={t('App.Cuenta.AñadirLocal.form.tablesNumber')}
                                  name="tablesNumber"
                                  value={values.tablesNumber}
                                  disabled
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={4} xs={12} />

                            <Grid item lg={2} xs={6}>
                              <FormGroup>
                                <Button variant="contained" onClick={() => setModalShow(true)}>
                                  {t('App.InfoLocal.Informacion.PerfilLocal.editButton')}
                                </Button>
                                <EditShop
                                  show={modalShow}
                                  onHide={() => setModalShow(false)}
                                  shop={respuestaAPI}
                                  fetchdata={fetchData}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item lg={2} xs={6}>
                              <FormGroup>
                                <Button variant="contained" onClick={() => setShow(true)}>
                                  {t('App.InfoLocal.Informacion.PerfilLocal.deleteButton')}
                                </Button>
                                <DeleteShopButton
                                  show={show}
                                  onHide={() => setShow(false)}
                                  shop={respuestaAPI}
                                />
                              </FormGroup>
                            </Grid>

                          </Grid>
                        </form>
                      );
                    }}
                  </Formik>
                </CardContent>
              </Card>
            </Container>
          </Container>
        )}
      <br />
      <br />

    </>
  );
}

export default PerfilLocal;
