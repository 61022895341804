export default function checkNoAuthError(error, history, toast, t) {
  if (error.response) {
    if (error.response.status === 401) {
      toast.error(t('newToast.checkNoAuthError'));
      localStorage.clear();
      history.push('/Paginaprincipal/Login');
      window.location.reload();
    }
  }
}
