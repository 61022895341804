import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Button, Grid, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import QRGenerator from './CrearQr';
import PDFGenerator from './QrPDF';
import { getShop } from '../../../../services/mesonero-api/api';

function QRCodeGenerator() {
  const shopIdActual = localStorage.getItem('shop_id');
  const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'));
  const [valueStrings, setValueStrings] = React.useState([]);
  const [qrUpdatedState, setQrUpdatedState] = React.useState(null);
  const [qrUpdated, setQrUpdated] = React.useState(false);
  const [tablesNumber, setTablesNumber] = React.useState(9999);
  const { t } = useTranslation();

  let contador = 0;
  function setQrUpdatedStateById() {
    if (contador === tablesNumber) {
      setTimeout(() => { setQrUpdated(true); }, 100);
    }
    contador += 1;
  }

  async function fetchData() {
    const shop = await getShop(datosUsuario.enterprise.id, shopIdActual);
    setTablesNumber(shop.tablesNumber);
    if (qrUpdatedState === null) {
      setQrUpdatedState(Array(shop.tablesNumber).fill(false));
    }
    const tablesNumberList = [];
    if (shop !== undefined) {
      for (let i = 1; i <= shop.tablesNumber; i += 1) {
        tablesNumberList.push(`https://carta.tumesonero.es?enterpriseId=${datosUsuario.enterprise.id}&shopId=${shopIdActual}&tableNumber=${i}`);
      }
      setValueStrings(tablesNumberList);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const downloadName = 'qrcodes';

  return (
    <>
      <Container style={{ maxWidth: '1400px' }}>
        <Grid container sx={{ marginTop: '15px', marginBottom: '15px', alignItems: 'center' }}>
          <Grid item lg={1}>
            <Link to="/App/InfoLocal/Informacion/PaginaInicialLocal">
              <Button>
                <ArrowBackIcon />
              </Button>
            </Link>
          </Grid>
          <Grid item lg={3}>
            <Typography variant="h6">
              {t('App.InfoLocal.Informacion.GenerarQR.route')}
            </Typography>
          </Grid>
        </Grid>
        <Container style={{ maxWidth: '1200px' }}>
          <div>
            {qrUpdated
              ? (
                <PDFDownloadLink
                  document={<PDFGenerator PDFImageIds={valueStrings} />}
                  fileName={`${downloadName}.pdf`}
                >
                  {({ loading }) => (
                    loading
                      ? (
                        <Button variant="contained" disabled style={{ marginBottom: '8px' }}>
                          {t('App.InfoLocal.Informacion.GenerarQR.load')}
                        </Button>
                      )
                      : <Button variant="contained" style={{ marginBottom: '8px' }}>{t('App.InfoLocal.Informacion.GenerarQR.buttonDownload')}</Button>
                  )}
                </PDFDownloadLink>
              )
              : <Button variant="contained" disabled style={{ marginBottom: '8px' }}>{t('App.InfoLocal.Informacion.GenerarQR.load')}</Button>}
            <div>
              <Row>
                {valueStrings === undefined ? (<div />)
                  : valueStrings.map((valueString, id) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Col lg={2} key={id}>
                      <div key={`qrGenerator_${valueString}`}>
                        <QRGenerator
                          valueString={valueString}
                          documentId={valueString}
                          // eslint-disable-next-line react/jsx-no-bind
                          onLoaded={setQrUpdatedStateById}
                          idMesa={id + 1}
                        />
                      </div>
                    </Col>
                  ))}
              </Row>
              {setQrUpdatedStateById()}
            </div>
          </div>
        </Container>
      </Container>
      <br />
      <br />
    </>
  );
}

export default QRCodeGenerator;
