import React from 'react';
import InfoMenus from './InfoMenus';

function Menus() {
  return (
    <>
      <InfoMenus />
      <br />
      <br />
    </>
  );
}

export default Menus;
