import React, { useEffect /* useCallback */ } from 'react';
// import {
//   Container, Button, Table, Image, Row, Col,
// } from 'react-bootstrap';
import {
  Container, /* Card, CardContent, */
  Grid, Button, Typography, FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
// import i18n from 'i18next';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { red } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import {
  EditProduct, DeleteProductButton, AddLanguageProductModal,
} from '../Carta/Modal/Modals';
import {
  getProducts, getImageProduct, getCategories,
  updatePositionProduct, updatePositionVisible,
} from '../../../../services/mesonero-api/api';
// import Gluten from '../../../../Icons/SvgGluten'
import celery from '../../../../Icons/allergen-celery.svg';
import crustaceans from '../../../../Icons/allergen-crustaceans.svg';
import eggs from '../../../../Icons/allergen-eggs.svg';
import fish from '../../../../Icons/allergen-fish.svg';
import cereals from '../../../../Icons/allergen-gluten.svg';
import lupin from '../../../../Icons/allergen-lupin.svg';
import milk from '../../../../Icons/allergen-milk.svg';
import molluscs from '../../../../Icons/allergen-mollusks.svg';
import mustard from '../../../../Icons/allergen-mustard.svg';
import nuts from '../../../../Icons/allergen-nuts.svg';
import peanuts from '../../../../Icons/allergen-peanuts.svg';
import sesame from '../../../../Icons/allergen-sesame-seeds.svg';
import soya from '../../../../Icons/allergen-soy.svg';
import sulphites from '../../../../Icons/allergen-sulphur-dioxide.svg';

import traceCelery from '../../../../Icons/allergen-traces-celery.svg';
import traceCrustaceans from '../../../../Icons/allergen-traces-crustaceans.svg';
import traceEggs from '../../../../Icons/allergen-traces-eggs.svg';
import traceFish from '../../../../Icons/allergen-traces-fish.svg';
import traceCereals from '../../../../Icons/allergen-traces-gluten.svg';
import traceLupin from '../../../../Icons/allergen-traces-lupin.svg';
import traceMilk from '../../../../Icons/allergen-traces-milk.svg';
import traceMolluscs from '../../../../Icons/allergen-traces-mollusks.svg';
import traceMustard from '../../../../Icons/allergen-traces-mustard.svg';
import traceNuts from '../../../../Icons/allergen-traces-nuts.svg';
import tracePeanuts from '../../../../Icons/allergen-traces-peanuts.svg';
import traceSesame from '../../../../Icons/allergen-traces-sesame-seeds.svg';
import traceSoya from '../../../../Icons/allergen-traces-soy.svg';
import traceSulphites from '../../../../Icons/allergen-traces-sulphur-dioxide.svg';

import es from '../../../../Banderas/es.svg';
import en from '../../../../Banderas/en.svg';
import fr from '../../../../Banderas/fr.svg';
import it from '../../../../Banderas/it.svg';
import de from '../../../../Banderas/de.svg';
import pt from '../../../../Banderas/pt.svg';

import '../../../../index.css';
import useWindowDimensions from '../../../../services/sizeScreen/windowsDimension';

function Productos() {
  const [modalShowProduct, setModalShowProduct] = React.useState(false);
  const [showProduct, setShowProduct] = React.useState(false);
  const [showAddLanguage, setShowAddLanguage] = React.useState(false);

  const [selectProduct, setProduct] = React.useState();

  const shopIdActual = localStorage.getItem('shop_id');
  const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'));

  const [respuestaAPI, setRespuestaAPI] = React.useState([]);
  const [imagesProduct, setImagesProduct] = React.useState([]);
  const [respuestaAPICategories, setRespuestaAPICategories] = React.useState([]);
  const [categoryProducts, setCategoryProdutcs] = React.useState([]);

  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const [language, setLanguage] = React.useState('es');

  const listImageProduct = [];

  // const listProductDefault = [];

  let contador = 0;
  // let productIndex = 0;

  function orderProductCategory(categories, products) {
    const mCategoryProducts = [];
    categories.forEach((category) => {
      const nameCategory = category.name;
      const productsCategory = [];
      products.forEach((product) => {
        if (category.id === product.categoryId) {
          productsCategory.push(product);
        }
      });
      mCategoryProducts.push({ name: nameCategory, productsCategory });
    });

    const productsWithoutCategory = [];
    products.forEach((product) => {
      if (product.categoryId === null) {
        productsWithoutCategory.push(product);
      }
    });
    mCategoryProducts.push({
      name: {
        es: 'Sin Categoría',
        en: 'Without category',
        fr: 'Sans catégorie',
        it: 'Senza categoria',
        de: 'Ohne Kategorie',
        pt: 'Sem categoria',
      },
      productsCategory: productsWithoutCategory,
    });

    return mCategoryProducts;
  }

  async function fetchImageProduct(idProduct) {
    let image;
    try {
      image = await getImageProduct(datosUsuario.enterprise.id, idProduct);
    } catch (e) {
      image = null;
    }
    return image;
  }

  function sortProduct(products) {
    products.sort((a, b) => a.listPosition - b.listPosition);
    return products;
  }

  function sortCategories(categories) {
    categories.sort((a, b) => a.listPosition - b.listPosition);
    return categories;
  }

  // async function fetchDataChangeLanguage(idiom) {
  //   const productChangeLanguage = {};
  //   const categoriesChangeLanguage = {};
  //   const products = await getProducts(datosUsuario.enterprise.id, shopIdActual, idiom);
  //   const categories = await getCategories(datosUsuario.enterprise.id, shopIdActual, idiom);

  //   for (let i = 0; i < products.length; i += 1) {
  //     productChangeLanguage[products[i].id] = null;
  //   }
  //   for (let i = 0; i < categories.length; i += 1) {
  //     categoriesChangeLanguage[categories[i].id] = null;
  //   }

  //   for (let i = 0; i < respuestaAPI.length; i += 1) {
  //     if (!(respuestaAPI[i].id in productChangeLanguage)) {
  //       products.push(respuestaAPI[i]);
  //     }
  //   }
  //   for (let i = 0; i < respuestaAPICategories.length; i += 1) {
  //     if (!(respuestaAPICategories[i].id in categoriesChangeLanguage)) {
  //       categories.push(respuestaAPICategories[i]);
  //     }
  //   }

  //   const productsSort = sortProduct(products);
  //   const categoriesSort = sortCategories(categories);

  //   const categoriesProducts = orderProductCategory(categoriesSort, productsSort);
  //   setCategoryProdutcs(categoriesProducts);
  // }

  async function fetchData() {
    const products = sortProduct(
      await getProducts(datosUsuario.enterprise.id, shopIdActual),
    );
    const categories = sortCategories(
      await getCategories(datosUsuario.enterprise.id, shopIdActual),
    );
    setRespuestaAPI(products);
    setRespuestaAPICategories(categories);

    const categoriesProducts = orderProductCategory(categories, products);
    setCategoryProdutcs(categoriesProducts);

    categoriesProducts.forEach((category) => {
      category.productsCategory.forEach((product) => {
        if (category.name !== 'Menús') {
          if (product.pictures.length !== 0) {
            const imagen = fetchImageProduct(product.id);
            listImageProduct.push(imagen);
          } else {
            listImageProduct.push(null);
          }
        } /* else if (product.pictures.length !== 0) {
          console.log(product);//
          const imagen = fetchImageMenu(product.id);
          listImageMenu.push(imagen);
        } */
      });
    });
    setImagesProduct(await Promise.all(listImageProduct));
  }

  useEffect(() => {
    fetchData();
  }, []);

  function sumContador() {
    contador += 1;
  }

  async function upProduct(targetProduct) {
    let product1;
    let product2;
    const productsInCategory = [];
    for (let i = 0; i < respuestaAPI.length; i += 1) {
      if (respuestaAPI[i].categoryId === targetProduct.categoryId) {
        productsInCategory.push(respuestaAPI[i]);
      }
    }
    sortProduct(productsInCategory);
    if (productsInCategory[0].id === targetProduct.id) {
      return;
    }
    for (let i = 0; i < productsInCategory.length; i += 1) {
      if (productsInCategory[i].id === targetProduct.id) {
        product1 = productsInCategory[i - 1];
        product2 = productsInCategory[i];
        break;
      }
    }

    const listPositionProduct1 = product1.listPosition;
    product1.listPosition = product2.listPosition;
    product2.listPosition = listPositionProduct1;
    // mandar Angel, llamar fetchdata
    await updatePositionProduct(targetProduct.id, 2);
    fetchData();
  }

  async function downProduct(targetProduct) {
    let product1;
    let product2;
    const productsInCategory = [];
    for (let i = 0; i < respuestaAPI.length; i += 1) {
      if (respuestaAPI[i].categoryId === targetProduct.categoryId) {
        productsInCategory.push(respuestaAPI[i]);
      }
    }
    sortProduct(productsInCategory);
    if (productsInCategory[productsInCategory.length - 1].id === targetProduct.id) {
      return;
    }
    for (let i = 0; i < productsInCategory.length; i += 1) {
      if (productsInCategory[i].id === targetProduct.id) {
        product1 = productsInCategory[i + 1];
        product2 = productsInCategory[i];
        break;
      }
    }

    const listPositionProduct1 = product1.listPosition;
    product1.listPosition = product2.listPosition;
    product2.listPosition = listPositionProduct1;
    // mandar Angel, llamar fetchdata
    await updatePositionProduct(targetProduct.id, 1);
    fetchData();
  }

  async function visibleProduct(targetProduct) {
    await updatePositionVisible(targetProduct.id);
    fetchData();
  }

  const handleChange = (event) => {
    setLanguage(event.target.value);
    // fetchDataChangeLanguage(event.target.value);
  };

  return (
    <>
      <Container style={{ maxWidth: '1200px' }}>
        <Grid container sx={{ marginTop: '15px', marginBottom: '15px', alignItems: 'center' }}>
          <Grid item lg={1}>
            <Link to="/App/InfoLocal/Informacion/PaginaInicialLocal">
              <Button>
                <ArrowBackIcon />
              </Button>
            </Link>
          </Grid>
          <Grid item lg={3}>
            <Typography variant="h6">
              {t('App.InfoLocal.Informacion.Productos.route')}
            </Typography>
          </Grid>
        </Grid>
        <Container style={{ maxWidth: '1000px' }}>
          <Grid container>
            <Grid item lg={3} xs={6}>
              <Link to="/App/InfoLocal/Informacion/AñadirProducto">
                <Button variant="contained" style={{ width: '100%' }}>
                  <em>{t('App.InfoLocal.Informacion.Productos.addButton')}</em>
                </Button>
              </Link>
            </Grid>
            <Grid item lg={3} xs={6} style={{ textAlign: 'end' }}>
              <FormControl>
                <InputLabel id="idiomas">{t('App.InfoLocal.Informacion.Productos.language')}</InputLabel>
                <Select
                  labelId="idiomas"
                  id="idiomas"
                  value={language}
                  label="idioma"
                  onChange={handleChange}
                >
                  <MenuItem value="es">
                    <img
                      src={es}
                      style={{ height: 24, width: 24 }}
                      alt="flagEs"
                    />
                  </MenuItem>
                  <MenuItem value="en">
                    <img
                      src={en}
                      style={{ height: 24, width: 24 }}
                      alt="flagEn"
                    />
                  </MenuItem>
                  <MenuItem value="fr">
                    <img
                      src={fr}
                      style={{ height: 24, width: 24 }}
                      alt="flagFr"
                    />
                  </MenuItem>
                  <MenuItem value="it">
                    <img
                      src={it}
                      style={{ height: 24, width: 24 }}
                      alt="flagIt"
                    />
                  </MenuItem>
                  <MenuItem value="de">
                    <img
                      src={de}
                      style={{ height: 24, width: 24 }}
                      alt="flagDe"
                    />
                  </MenuItem>
                  <MenuItem value="pt">
                    <img
                      src={pt}
                      style={{ height: 24, width: 24 }}
                      alt="flagPt"
                    />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* <Col lg={4}>
            <Link to="/App/InfoLocal/Informacion/AñadirProducto">
              <Button variant="outline-secondary"><em>Añadir productos con plantilla</em></Button>
            </Link>
          </Col> */}
          </Grid>
          <br />
          {(respuestaAPI.length === 0)
            ? <div />
            : respuestaAPICategories.length !== 0
            && categoryProducts.map((category, a) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={a}>
                {width > 720
                  ? (
                    <div>
                      <Typography variant="h4" noWrap style={{ marginBottom: '8px' }}>
                        {category.name[language] === null
                          ? category.name.es
                          : category.name[language]}
                      </Typography>
                      {category.productsCategory.map((product, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index}>
                          {(product.name[language] === null)
                            && (
                              <div className="noLanguageProduct">
                                <Button
                                  variant="contained"
                                  data-rowid={index}
                                  onClick={(e) => {
                                    setShowAddLanguage(true);
                                    setProduct(category.productsCategory[e.currentTarget.getAttribute('data-rowid')]);
                                  }}
                                >
                                  {t('App.InfoLocal.Informacion.Productos.addButtonLanguage')}
                                </Button>
                              </div>
                            )}
                          <Grid
                            container
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            style={{
                              marginBottom: '10px', marginLeft: '16px', border: '1px solid #EEEEEE', borderRadius: '10px',
                            }}
                            // classes={product.visible ? '' : 'notVisibleProduct'}
                            sx={product.visible ? { backgroundColor: 'white' } : { opacity: '0.2' }}
                          >
                            <Grid item lg={2} xs={2}>
                              {imagesProduct[contador] !== null
                                ? (
                                  <img
                                    src={imagesProduct[contador]}
                                    style={{
                                      width: 130, height: 130, objectFit: 'cover', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px',
                                    }}
                                    alt="imageProduct"
                                  />
                                )
                                : (
                                  <div
                                    style={{
                                      width: 130,
                                      height: 130,
                                      objectFit: 'cover',
                                      borderTopLeftRadius: '10px',
                                      borderBottomLeftRadius: '10px',
                                      backgroundColor: '#ECECEC',
                                    }}
                                  />
                                )}
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <Grid container>
                                <Grid item lg={12} xs={12} style={{ marginTop: '8px' }}>
                                  <Typography variant="h5" noWrap>
                                    {product.name[language] === null
                                      ? product.name.es
                                      : product.name[language]}
                                  </Typography>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {product.description[language] === null
                                      ? product.description.es
                                      : product.description[language]}
                                  </Typography>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {product.ingredients[language] === null
                                      ? product.ingredients.es
                                      : product.ingredients[language]}
                                  </Typography>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  {product.allergens.map((allergens) => {
                                    switch (allergens.name) {
                                      case 'celery':
                                        return (
                                          <img
                                            src={celery}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 'crustaceans':
                                        return (
                                          <img
                                            src={crustaceans}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 'eggs':
                                        return (
                                          <img
                                            src={eggs}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 'fish':
                                        return (
                                          <img
                                            src={fish}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 'cereals':
                                        return (
                                          <img
                                            src={cereals}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 'lupin':
                                        return (
                                          <img
                                            src={lupin}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 'milk':
                                        return (
                                          <img
                                            src={milk}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 'molluscs':
                                        return (
                                          <img
                                            src={molluscs}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 'mustard':
                                        return (
                                          <img
                                            src={mustard}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 'nuts':
                                        return (
                                          <img
                                            src={nuts}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 'peanuts':
                                        return (
                                          <img
                                            src={peanuts}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 'sesame':
                                        return (
                                          <img
                                            src={sesame}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 'soya':
                                        return (
                                          <img
                                            src={soya}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 'sulphites':
                                        return (
                                          <img
                                            src={sulphites}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 't_celery':
                                        return (
                                          <img
                                            src={traceCelery}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 't_crustaceans':
                                        return (
                                          <img
                                            src={traceCrustaceans}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 't_eggs':
                                        return (
                                          <img
                                            src={traceEggs}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 't_fish':
                                        return (
                                          <img
                                            src={traceFish}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 't_cereals':
                                        return (
                                          <img
                                            src={traceCereals}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 't_lupin':
                                        return (
                                          <img
                                            src={traceLupin}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 't_milk':
                                        return (
                                          <img
                                            src={traceMilk}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 't_molluscs':
                                        return (
                                          <img
                                            src={traceMolluscs}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 't_mustard':
                                        return (
                                          <img
                                            src={traceMustard}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 't_nuts':
                                        return (
                                          <img
                                            src={traceNuts}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 't_peanuts':
                                        return (
                                          <img
                                            src={tracePeanuts}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 't_sesame':
                                        return (
                                          <img
                                            src={traceSesame}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 't_soya':
                                        return (
                                          <img
                                            src={traceSoya}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      case 't_sulphites':
                                        return (
                                          <img
                                            src={traceSulphites}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                      default:
                                        return (
                                          <img
                                            src={celery}
                                            style={{ height: 32, width: 32 }}
                                            key={allergens.id}
                                            alt="allergen"
                                          />
                                        );
                                    }
                                  })}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item lg={2} xs={2}>
                              <Grid container spacing={2}>
                                <Grid item lg={12} xs={12} style={{ direction: 'rtl', marginTop: '16px' }}>
                                  €
                                  {' '}
                                  {product.price !== null && String(product.price).replace('.', ',')}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item lg={1} xs={1}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: '100%',
                                  justifyContent: 'center',
                                }}
                              >
                                <Button
                                  data-rowid={index}
                                  onClick={(e) => {
                                    visibleProduct(category.productsCategory[e.currentTarget.getAttribute('data-rowid')]);
                                  }}
                                >
                                  {product.visible ? <VisibilityOffIcon sx={{ color: 'black' }} /> : <VisibilityIcon sx={{ color: 'black' }} />}
                                </Button>
                                <Button
                                  variant="outline-secondary"
                                  data-rowid={index}
                                  onClick={(e) => {
                                    setModalShowProduct(true);
                                    setProduct(category.productsCategory[e.currentTarget.getAttribute('data-rowid')]);
                                  }}
                                >
                                  <EditIcon />
                                </Button>

                                <Button
                                  variant="outline-secondary"
                                  data-rowid={index}
                                  onClick={(e) => {
                                    setShowProduct(true);
                                    setProduct(category.productsCategory[e.currentTarget.getAttribute('data-rowid')]);
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </div>
                            </Grid>
                            <Grid item lg={1} xs={1}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: '100%',
                                  justifyContent: 'center',
                                }}
                              >
                                <Button
                                  data-rowid={index}
                                  onClick={
                                    product.id
                                      === category.productsCategory[0].id
                                      ? null
                                      : (e) => {
                                        upProduct(category.productsCategory[e.currentTarget.getAttribute('data-rowid')]);
                                      }
                                  }
                                >
                                  {product.id === category.productsCategory[0].id ? <ArrowCircleUpRoundedIcon sx={{ color: 'gray' }} /> : <ArrowCircleUpRoundedIcon sx={{ color: 'black' }} />}
                                </Button>
                                <Button
                                  data-rowid={index}
                                  onClick={
                                    product.id
                                      // eslint-disable-next-line max-len
                                      === category.productsCategory[category.productsCategory.length - 1].id
                                      ? null
                                      : (e) => {
                                        downProduct(category.productsCategory[e.currentTarget.getAttribute('data-rowid')]);
                                      }
                                  }
                                >
                                  {
                                    product.id
                                      // eslint-disable-next-line max-len
                                      === category.productsCategory[category.productsCategory.length - 1].id
                                      ? <ArrowCircleDownRoundedIcon sx={{ color: 'gray' }} />
                                      : <ArrowCircleDownRoundedIcon sx={{ color: 'black' }} />
                                  }
                                </Button>
                              </div>
                            </Grid>
                            {sumContador()}
                          </Grid>
                        </div>
                      ))}
                    </div>
                  )
                  : (
                    <div>
                      <Typography variant="h5" noWrap style={{ marginBottom: '8px' }}>
                        {category.name[language] === null
                          ? category.name.es
                          : category.name[language]}
                      </Typography>
                      {category.productsCategory.map((product, index) => (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          style={{
                            marginBottom: '10px', border: '1px solid #EEEEEE', borderRadius: '10px', height: '84px',
                          }}
                        >
                          {(product.name[language] === null)
                            && (
                              <div className="noLanguageProductMovil">
                                <Button
                                  variant="contained"
                                  data-rowid={index}
                                  onClick={(e) => {
                                    // setShowChangeLanguage(true);
                                    setProduct(category.productsCategory[e.currentTarget.getAttribute('data-rowid')]);
                                  }}
                                >
                                  {t('App.InfoLocal.Informacion.Productos.addButtonLanguage')}
                                </Button>
                              </div>
                            )}
                          {imagesProduct[contador] !== null
                            ? (
                              <img
                                src={imagesProduct[contador]}
                                style={{
                                  width: 84,
                                  height: 82,
                                  objectFit: 'cover',
                                  borderTopLeftRadius: '10px',
                                  borderBottomLeftRadius: '10px',
                                  float: 'left',
                                }}
                                alt="imageProduct"
                              />
                            )
                            : (
                              <div
                                style={{
                                  width: 84,
                                  height: 82,
                                  objectFit: 'cover',
                                  borderTopLeftRadius: '10px',
                                  borderBottomLeftRadius: '10px',
                                  backgroundColor: '#ECECEC',
                                  float: 'left',
                                }}
                              />
                            )}
                          <Grid
                            container
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            style={{ width: 'auto', padding: '0px 6px 4px 4px', height: '100%' }}
                            sx={product.visible ? { backgroundColor: 'white' } : { backgroundColor: '#ff4000', opacity: '0.2' }}
                          >
                            <Grid item lg={8} xs={8}>
                              <div
                                data-rowid={index}
                                onClick={(e) => {
                                  setModalShowProduct(true);
                                  setProduct(category.productsCategory[e.currentTarget.getAttribute('data-rowid')]);
                                }}
                                aria-hidden="true"
                              >
                                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                  <div>
                                    <Typography sx={{ fontSize: '16px' }} noWrap>
                                      {product.name[language] === null
                                        ? product.name.es
                                        : product.name[language]}
                                    </Typography>
                                  </div>
                                  <div style={{ flexGrow: 1 }}>
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        lineHeight: '1.1',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '2',
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {product.description[language] === null
                                        ? product.description.es
                                        : product.description[language]}
                                    </Typography>
                                  </div>
                                  <div
                                    style={{
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {product.allergens.map((allergens) => {
                                      switch (allergens.name) {
                                        case 'celery':
                                          return (
                                            <img
                                              src={celery}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'crustaceans':
                                          return (
                                            <img
                                              src={crustaceans}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'eggs':
                                          return (
                                            <img
                                              src={eggs}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'fish':
                                          return (
                                            <img
                                              src={fish}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'cereals':
                                          return (
                                            <img
                                              src={cereals}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'lupin':
                                          return (
                                            <img
                                              src={lupin}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'milk':
                                          return (
                                            <img
                                              src={milk}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'molluscs':
                                          return (
                                            <img
                                              src={molluscs}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'mustard':
                                          return (
                                            <img
                                              src={mustard}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'nuts':
                                          return (
                                            <img
                                              src={nuts}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'peanuts':
                                          return (
                                            <img
                                              src={peanuts}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'sesame':
                                          return (
                                            <img
                                              src={sesame}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'soya':
                                          return (
                                            <img
                                              src={soya}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'sulphites':
                                          return (
                                            <img
                                              src={sulphites}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_celery':
                                          return (
                                            <img
                                              src={traceCelery}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_crustaceans':
                                          return (
                                            <img
                                              src={traceCrustaceans}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_eggs':
                                          return (
                                            <img
                                              src={traceEggs}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_fish':
                                          return (
                                            <img
                                              src={traceFish}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_cereals':
                                          return (
                                            <img
                                              src={traceCereals}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_lupin':
                                          return (
                                            <img
                                              src={traceLupin}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_milk':
                                          return (
                                            <img
                                              src={traceMilk}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_molluscs':
                                          return (
                                            <img
                                              src={traceMolluscs}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_mustard':
                                          return (
                                            <img
                                              src={traceMustard}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_nuts':
                                          return (
                                            <img
                                              src={traceNuts}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_peanuts':
                                          return (
                                            <img
                                              src={tracePeanuts}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_sesame':
                                          return (
                                            <img
                                              src={traceSesame}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_soya':
                                          return (
                                            <img
                                              src={traceSoya}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_sulphites':
                                          return (
                                            <img
                                              src={traceSulphites}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                        default:
                                          return (
                                            <img
                                              src={celery}
                                              style={{ height: 16, width: 16 }}
                                              key={allergens.id}
                                              alt="allergen"
                                            />
                                          );
                                      }
                                    })}
                                  </div>
                                </div>
                              </div>
                            </Grid>
                            <Grid item lg={2} xs={2}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: '100%',
                                  justifyContent: 'center',
                                }}
                              >
                                <Button
                                  data-rowid={index}
                                  onClick={
                                    product.id
                                      === category.productsCategory[0].id
                                      ? null
                                      : (e) => {
                                        upProduct(category.productsCategory[e.currentTarget.getAttribute('data-rowid')]);
                                      }
                                  }
                                  style={{ minWidth: 0, padding: '4px' }}
                                >
                                  {product.id === category.productsCategory[0].id ? <ArrowCircleUpRoundedIcon sx={{ color: 'gray' }} /> : <ArrowCircleUpRoundedIcon sx={{ color: 'black' }} />}
                                </Button>

                                <Button
                                  data-rowid={index}
                                  onClick={
                                    product.id
                                      // eslint-disable-next-line max-len
                                      === category.productsCategory[category.productsCategory.length - 1].id
                                      ? null
                                      : (e) => {
                                        downProduct(category.productsCategory[e.currentTarget.getAttribute('data-rowid')]);
                                      }
                                  }
                                  style={{ minWidth: 0, padding: '4px' }}
                                >
                                  {
                                    product.id
                                      // eslint-disable-next-line max-len
                                      === category.productsCategory[category.productsCategory.length - 1].id
                                      ? <ArrowCircleDownRoundedIcon sx={{ color: 'gray' }} />
                                      : <ArrowCircleDownRoundedIcon sx={{ color: 'black' }} />
                                  }
                                </Button>
                              </div>
                            </Grid>
                            <Grid item lg={2} xs={2}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: '100%',
                                  justifyContent: 'center',
                                }}
                              >
                                <Button
                                  data-rowid={index}
                                  onClick={(e) => {
                                    visibleProduct(category.productsCategory[e.currentTarget.getAttribute('data-rowid')]);
                                  }}
                                  style={{ minWidth: 0, padding: '4px' }}
                                >
                                  {product.visible ? <VisibilityOffIcon sx={{ color: 'black' }} /> : <VisibilityIcon sx={{ color: 'black' }} />}
                                </Button>

                                <Button
                                  data-rowid={index}
                                  onClick={(e) => {
                                    setShowProduct(true);
                                    setProduct(category.productsCategory[e.currentTarget.getAttribute('data-rowid')]);
                                  }}
                                  style={{ minWidth: 0, padding: '4px' }}
                                >
                                  <DeleteIcon sx={{ color: red[400] }} />
                                </Button>
                              </div>
                            </Grid>
                            {sumContador()}
                          </Grid>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            ))}

          <EditProduct
            show={modalShowProduct}
            onHide={() => setModalShowProduct(false)}
            product={selectProduct}
            // eslint-disable-next-line react/jsx-no-bind
            fetchdata={fetchData}
            width={width}
            language={language}
          />
          <DeleteProductButton
            show={showProduct}
            onHide={() => setShowProduct(false)}
            product={selectProduct}
            // eslint-disable-next-line react/jsx-no-bind
            fetchdata={fetchData}
            width={width}
          />
          <AddLanguageProductModal
            show={showAddLanguage}
            onHide={() => setShowAddLanguage(false)}
            product={selectProduct}
            // eslint-disable-next-line react/jsx-no-bind
            fetchdata={fetchData}
            width={width}
            language={language}
          />
        </Container>
      </Container>
      <br />
      <br />
    </>
  );
}

export default Productos;
