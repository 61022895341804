import React, { Suspense, useMemo } from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import { TokenContext } from './token-context';

import Login from './Paginaprincipal/Login/Login';
import Register from './Paginaprincipal/Registrar/Resgistrarse';
import NavbarMenu from './PaginaEntrada/MenuPagina';
import Footer from './Paginaprincipal/Footer';
// import App from './App/App';
import PaginaEntrada from './PaginaEntrada/PaginaEntrada';
import infoTarifa from './PaginaEntrada/infoTarifa/InfoTarifa';
import Tarifa from './PaginaEntrada/Tarifa/Tarifa';
import Contacto from './PaginaEntrada/Contacto/Contacto';
import Verificar from './Paginaprincipal/Verification/Verification';
import ChangePassword from './Paginaprincipal/ChangePassword/ChangePassword';
import SuccessDisplay from './services/paySubscription/SuccessPage';
import CondicionesGenerales from './legal/CondicionesGenerales/CondicionesGenerales';
import Cuenta from './App/Cuenta/Locales/Locales';
import Perfil from './App/Cuenta/Perfil/Perfil';
import AñadirLocal from './App/Cuenta/AñadirLocal/AñadirLocal';
// import Employees from './App/Cuenta/Empleados/Empleados';
// import EmployRegister from './App/Cuenta/AñadirEmpleados/AñadirEmpleados';
import PerfilLocal from './App/InfoLocal/Informacion/PerfilLocal/PerfilLocal';
import Carta from './App/InfoLocal/Informacion/Carta/Carta';
import Productos from './App/InfoLocal/Informacion/Productos/Productos';
import Añadir from './App/InfoLocal/Informacion/AñadirProducto/Añadir';
import ListaMenus from './App/InfoLocal/Informacion/Menus/ListaMenu';
import AñadirMenu from './App/InfoLocal/Informacion/AñadirMenu/Menu';
import Category from './App/InfoLocal/Informacion/Categoria/Categoria';
// import RestaurantZone from './App/InfoLocal/Informacion/Zonas/Zonas';
import QRCodeGenerator from './App/InfoLocal/Informacion/GenerarQr/Codigoqr';
import PaginaInicialLocal from './App/InfoLocal/Informacion/PaginaInicialLocal/PaginaInicialLocal';
import CookiesAlert from './services/cookies/cookies';
import ForgetPassword from './Paginaprincipal/forgetPassword/forgetPassword';

import './i18n';

function IndexRoute() {
  const [token, setToken] = React.useState(localStorage.getItem('token'));
  // function Body() {
  //   document.body.classList.add('body');
  //   return (
  //     <div />
  //   );
  // }
  const valueContext = useMemo(() => ({
    // eslint-disable-next-line object-shorthand
    token: token,
    // eslint-disable-next-line object-shorthand
    setToken: setToken,
  }), [token]);

  return (
    <Suspense fallback="loading">
      <TokenContext.Provider value={valueContext}>
        <Router>
          {/* <Body /> */}
          <NavbarMenu />
          <ToastContainer
            position="bottom-center"
            autoClose={2500}
            theme="light"
            hideProgressBar
          />
          {/* {localStorage.getItem('token') ? <Route exact path="/"><Redirect
          to="/App" /></Route> : <Route exact path="/"><Redirect to="/
          Paginaprincipal/Login" /></Route>} */}
          <div id="inner">
            {token !== null ? <Route exact path="/"><Redirect to="/App/Cuenta/Locales" /></Route> : <Route exact path="/"><Redirect to="/Paginaprincipal/Login" /></Route>}
            <Route path="/Paginaprincipal/Login" component={Login} />
            <Route path="/Paginaprincipal/Registrar" component={Register} />
            <Route path="/Paginaprincipal/Verification" component={Verificar} />
            <Route path="/Paginaprincipal/forgetPassword" component={ForgetPassword} />
            <Route path="/Paginaprincipal/ChangePassword" component={ChangePassword} />
            <Route path="/PaginaEntrada" component={PaginaEntrada} />
            <Route path="/infoTarifa" component={infoTarifa} />
            <Route path="/Tarifa" component={Tarifa} />
            <Route path="/services/paySubscription" component={SuccessDisplay} />
            {/* <Route path='/services/paySubscription' component={Pay} /> */}
            <Route path="/Contacto" component={Contacto} />
            {/* <Route path='/VerCarta' component={VerCarta} /> */}
            {/* <Route path="/App" component={App} /> */}
            <Route path="/legal/CondicionesGenerales" component={CondicionesGenerales} />

            <Route path="/App/Cuenta/Locales" component={Cuenta} />
            <Route path="/App/Cuenta/Perfil" component={Perfil} />
            <Route path="/App/Cuenta/AñadirLocal" component={AñadirLocal} />
            {/* <Route path="/App/Cuenta/Empleados" component={Employees} /> */}
            {/* <Route path="/App/Cuenta/AñadirEmpleados" component={EmployRegister} /> */}

            <Route path="/App/InfoLocal/Informacion/PerfilLocal" component={PerfilLocal} />
            <Route path="/App/InfoLocal/Informacion/Carta" component={Carta} />
            <Route path="/App/InfoLocal/Informacion/Productos" component={Productos} />
            <Route path="/App/InfoLocal/Informacion/AñadirProducto" component={Añadir} />
            <Route path="/App/InfoLocal/Informacion/Menus" component={ListaMenus} />
            <Route path="/App/InfoLocal/Informacion/AñadirMenu" component={AñadirMenu} />
            <Route path="/App/InfoLocal/Informacion/Categoria" component={Category} />
            {/* <Route path="/App/InfoLocal/Informacion/Zonas" component={RestaurantZone} /> */}
            <Route path="/App/InfoLocal/Informacion/GenerarQr" component={QRCodeGenerator} />
            <Route path="/App/InfoLocal/Informacion/PaginaInicialLocal" component={PaginaInicialLocal} />
          </div>
          <CookiesAlert />
          <Footer />
        </Router>
      </TokenContext.Provider>
    </Suspense>
  );
}

export default IndexRoute;
