import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  Container, Grid,
} from '@mui/material';
import FormularioContacto from './FormularioContacto';

function Contacto() {
  const { t } = useTranslation();

  return (
    <>
      <div id="inner">
        <Container style={{ marginTop: '2%' }}>

          <h1 className="mb-5">{t('paginaentrada.contacto.title')}</h1>
          <Grid container spacing={3}>

            <Grid item lg={6} xs={6} style={{ marginTop: '60px' }}>
              <p>
                {t('paginaentrada.contacto.info')}
              </p>
              <h5>{t('paginaentrada.contacto.phoneContact')}</h5>
              <p>639918681</p>
              <h5>{t('paginaentrada.contacto.email')}</h5>
              <p>contacto@duonion.com</p>
              <p>
                {t('paginaentrada.contacto.anotherInfo')}
              </p>

            </Grid>

            <Grid item lg={6} xs={6}>
              <FormularioContacto />
            </Grid>

          </Grid>
        </Container>
      </div>
      <br />
      <br />
    </>

  );
}

export default Contacto;
