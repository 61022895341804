import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import {
  Button, Card, CardContent, Container, FormGroup, Grid, TextField,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import { Link } from 'react-router-dom';
import { postChangePassword } from '../../services/mesonero-api/api';

function changePassword() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Container style={{ marginTop: '2%', marginBottom: '2%' }}>
      <Card className="card-login">
        {/* <Card.Header bsPrefix='card-header-custom'> <h2>Inicio de sesión </h2></Card.Header> */}
        <CardContent>
          <h2 style={{ textAlign: 'center' }}>{t('paginaprincipal.changepassword.changepassword')}</h2>
          <hr />
        </CardContent>
        <CardContent>
          <Formik
            initialValues={{
              password: '',
              newPassword: '',
            }}
            validationSchema={Yup.object().shape({
              password: Yup
                .string(t('paginaprincipal.changepassword.form.yup.password'))
                // .min(8, t('paginaprincipal.changepassword.form.invalid.password'))
                .required(t('paginaprincipal.changepassword.form.yup.required.passwordl')),
              newPassword: Yup
                .string(t('paginaprincipal.changepassword.form.yup.newpassword'))
                .min(8, t('paginaprincipal.changepassword.form.yup.invalid.newpassword'))
                .required(t('paginaprincipal.changepassword.form.yup.required.newpassword')),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                const data = {
                  password: values.password,
                  newPassword: values.newPassword,
                };
                const consultaAPI = async () => {
                  try {
                    const consulta = await postChangePassword(data);
                    if (consulta.status === 200) {
                      toast.success(t('paginaprincipal.changepassword.form.toast.changePassword'));
                      localStorage.clear();
                      history.push('/Paginaprincipal/Login');
                    }
                  } catch (error) {
                    switch (error.response.data.error) {
                      case 'Wrong email or password':
                        toast.error(t('paginaprincipal.changepassword.form.toast.errorChangePassword'));
                        break;
                      default:
                        break;
                    }
                  }
                };
                consultaAPI();

                setSubmitting(false);
              }, 250);
            }}
          >
            {(props) => {
              const {
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* y otras más */
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                      <FormGroup>
                        {/* <FormLabel>Constraseña</FormLabel> */}
                        <TextField
                          label={t('paginaprincipal.changepassword.form.password')}
                          type="password"
                          id="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <FormGroup>
                        {/* <FormLabel>Constraseña</FormLabel> */}
                        <TextField
                          label={t('paginaprincipal.changepassword.form.newpassword')}
                          type="password"
                          id="newPassword"
                          name="newPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.newPassword}
                          error={touched.newPassword && Boolean(errors.newPassword)}
                          helperText={touched.newPassword && errors.newPassword}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <FormGroup>
                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                          {/*
                           Esta parte no esta traducida
                           */}
                          {isSubmitting ? 'Loading' : t('paginaprincipal.changepassword.form.button')}
                        </Button>
                      </FormGroup>
                    </Grid>

                  </Grid>
                </form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
}

export default changePassword;
