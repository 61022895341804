import React, { /* useCallback, */ useEffect /* useRef */ } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Formik /* , Field */ } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
// import {
//   Card, Container, Row, Col, Form, Button,
// } from 'react-bootstrap';
import {
  Container, Card, CardContent,
  Grid, Button, FormGroup, TextField, FormLabel,
  FormControl, RadioGroup, FormControlLabel, Radio, Typography,
  /* FormControl, InputLabel, MenuItem, Select, */
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { ReactSortable } from 'react-sortablejs';
import { useTranslation } from 'react-i18next';
import {
  getProducts, addMenu, addPictureMenu, /* getCategories, */
} from '../../../../services/mesonero-api/api';
import resizeFile from '../../../../services/resizeImage/ResizeImage';
// import { AddMenuResponseFail, AddMenuResponseSuccess }
// from '../../../../services/response-api/responseApi';
import useWindowDimensions from '../../../../services/sizeScreen/windowsDimension';
import checkNoAuthError from '../../../../services/checkNoAuthError/checkNoAuthError';

import es from '../../../../Banderas/es.svg';
// import { ErrorSameNameModalMenu } from '../Carta/Modal/Modals';

// import MenuCategory from './MenuCategory';
// import { AddCategoryModal /*
// DeleteAttributeInGroup, UpdateAttributeInGroup */ } from '../Carta/Modal/Modals';

// function CustomizedSelectForFormik({ children, form, field }) {
//   const { name, value } = field;
//   const { setFieldValue } = form;

//   return (
//     <Select
//       name={name}
//       value={value}
//       onChange={(e) => {
//         setFieldValue(name, e.target.value);
//       }}
//     >
//       {children}
//     </Select>
//   );
// }

function InfoMenus() {
  const shopIdActual = localStorage.getItem('shop_id');
  const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'));

  // const formRef = useRef();

  const history = useHistory();
  const { t } = useTranslation();

  const [respuestaAPI, setRespuestaAPI] = React.useState(undefined);
  const [productsDefault, setProductsDefault] = React.useState(undefined);
  // const [respuestaAPICategory, setRespuestaAPICategory] = React.useState([]);
  // const [modalShow, setModalShow] = React.useState(false);
  const [listProductMenu, setListProductMenu] = React.useState([]);
  // const [nameCategoryMenu, setNameCategoryMenu] = React.useState('');
  const [busqueda, setBusqueda] = React.useState('');
  // const [productsTable, setProductTable] = React.useState([]);
  const [respuestaAPIDisplay, setRespuestaAPIDisplay] = React.useState([]);
  // const [menuCategoryStateList, setMenuCategoryStateList] = React.useState([]);
  // const [showErrorSameNameModal, setShowErrorSameNameModal] = React.useState(false);

  // function objectsAreSame(x, y) {
  //   let areSame = true;
  //   const keysFromX = Object.keys(x);
  //   keysFromX.forEach((key) => {
  //     if (x[key] !== y[key]) {
  //       areSame = false;
  //     }
  //   });
  //   return areSame;
  // }

  // function createMenuCategory(menuCategory) {
  //   setMenuCategoryStateList([...menuCategoryStateList, menuCategory]);
  // }
  // function updateMenuCategory(index, categoryName, categoryProductList) {
  //   if (menuCategoryStateList[index].length !== categoryProductList.length) {
  //     const editedMenuCategoryStateList = [...menuCategoryStateList];
  //     editedMenuCategoryStateList[index] = { name: categoryName, products: categoryProductList };
  //     setMenuCategoryStateList(editedMenuCategoryStateList);
  //   } else {
  //     for (let i = 0; i < menuCategoryStateList[index].length; i += 1) {
  //       if (!objectsAreSame(menuCategoryStateList[index][i], categoryProductList[i])) {
  //         const editedMenuCategoryStateList = [...menuCategoryStateList];
  //         editedMenuCategoryStateList[index] = {
  //           name: categoryName, productList: categoryProductList,
  //         };
  //         setMenuCategoryStateList(editedMenuCategoryStateList);
  //       }
  //     }
  //   }
  // }

  const listProducts = [];
  const { width } = useWindowDimensions();
  const [language, setLanguage] = React.useState('es');

  // const [showSuccess, setShowSuccess] = React.useState(false);
  // const [showFail, setShowFail] = React.useState(false);

  const fetchData = async () => {
    const products = await getProducts(datosUsuario.enterprise.id, shopIdActual, language);
    // const categories = await getCategories(datosUsuario.enterprise.id, shopIdActual);
    products.forEach((element) => {
      listProducts.push(
        { id: element.id, name: element.name, check: false },
      );
    });
    setRespuestaAPI(listProducts);
    setProductsDefault(listProducts);
    // setProductTable(listProducts);
    // setRespuestaAPICategory(categories);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filtrar = (terminoBusqueda) => {
    const resultadosBusqueda = respuestaAPI.filter((elemento) => {
      if (elemento.name.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return true;
      }
      return false;
    });
    const displayList = [];
    respuestaAPI.forEach((product) => {
      let found = false;
      for (let i = 0; i < resultadosBusqueda.length; i += 1) {
        if (product.id === resultadosBusqueda[i].id) {
          found = true;
          break;
        }
      }
      displayList.push(found);
    });
    setRespuestaAPIDisplay(displayList);
  };

  const handleChangeBusqueda = (e) => {
    setBusqueda(e.target.value);
    if (e.target.value !== '') {
      filtrar(e.target.value);
    } else {
      setRespuestaAPIDisplay(Array(respuestaAPI.length).fill(true));
    }
  };

  const handleChangeSortable = (textoBúsqueda) => {
    if (respuestaAPI !== undefined) {
      if (textoBúsqueda !== '') {
        filtrar(textoBúsqueda);
      } else {
        setRespuestaAPIDisplay(Array(respuestaAPI.length).fill(true));
      }
    }
  };

  useEffect(() => {
    handleChangeSortable(busqueda);
  }, [respuestaAPI]);

  function selectProductMenu(targetProduct) {
    const productSelect = targetProduct;
    const products = [...respuestaAPI];
    if (!productSelect.check) {
      productSelect.check = true;
    } else {
      productSelect.check = false;
    }
    for (let i = 0; i < products.length; i += 1) {
      if (products.id === productSelect.id) {
        products.check = productSelect.check;
      }
    }
    setRespuestaAPI(products);
  }

  const changeLanguage = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <Container style={{ maxWidth: '1200px' }}>
      <Grid container sx={{ marginTop: '15px', marginBottom: '15px', alignItems: 'center' }}>
        <Grid item lg={1}>
          <Link to="/App/InfoLocal/Informacion/Menus">
            <Button>
              <ArrowBackIcon />
            </Button>
          </Link>
        </Grid>
        <Grid item lg={5}>
          <Typography variant="h6">
            {t('App.InfoLocal.Informacion.AñadirMenu.route')}
          </Typography>
        </Grid>
      </Grid>
      <Container style={{ maxWidth: '1000px' }}>
        {// eslint-disable-next-line no-nested-ternary
          respuestaAPI === undefined
            ? (<div><em>{t('App.InfoLocal.Informacion.AñadirMenu.condition')}</em></div>)
            : (
              width > 720
                ? (
                  <Card>
                    {/* <Card.Header bsPrefix="card-header-custom">
              {' '}
              <h2 style={{ textAlign: 'center' }}>Añadir Datos del Menú</h2>
            </Card.Header> */}
                    <CardContent>
                      <h2 style={{ textAlign: 'center' }}>{t('App.InfoLocal.Informacion.AñadirMenu.title')}</h2>
                    </CardContent>
                    <CardContent>
                      <Formik
                        initialValues={{
                          name: '',
                          category: 0,
                          description: '',
                          price: '',
                          file: null,
                        }}
                        validationSchema={Yup.object().shape({
                          name: Yup.string().required(t('App.InfoLocal.Informacion.AñadirMenu.form.yup.required.name')),
                          // description: Yup.string().required(t('App.InfoLocal.Informacion.
                          // AñadirMenu.form.yup.required.description')),
                          price: Yup.string().matches(/^[0-9]+([,.][0-9]+)?$/, 'El valor debe ser un número').required(t('App.InfoLocal.Informacion.AñadirMenu.form.yup.required.price')),
                        })}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                          setTimeout(() => {
                            const menu = {
                              name: { es: values.name },
                              description: { es: values.description },
                            };
                            const productsId = [];
                            listProductMenu.map((product) => (
                              productsId.push(product.id)
                            ));
                            const priceMenu = values.price.replace(',', '.');
                            // const finishDate = new Date(values.date + ':' + values.hour);
                            // const menusectionList = [];
                            // menuCategoryStateList.forEach((menuSection) => {
                            //   const nameSection = menuSection.name;
                            //   const productsId = [];
                            //   menuSection.products.forEach((menuSectionProduct) => {
                            //     productsId.push(menuSectionProduct.id);
                            //   });
                            //   menusectionList.push({ name: nameSection, productsId });
                            // });
                            const data = {
                              menu: {
                                name: menu.name,
                                description: menu.description,
                                price: Number(priceMenu),
                                shopId: parseInt(shopIdActual, 10),
                                // finish_date: finishDate.toUTCString()
                                products: Array.from(productsId),
                              },
                              // menusection: menuCategoryStateList,
                            };

                            const consultaAPI = async () => {
                              try {
                                const dataMenu = await addMenu(data);
                                if (dataMenu.status === 200) {
                                  toast.success(t('App.InfoLocal.Informacion.AñadirMenu.form.toast.addDataMenu'));
                                  if (values.file !== null) {
                                    const formData = new FormData();
                                    formData.append('menu_id', dataMenu.data.menu.id);
                                    formData.append('picture', values.file);
                                    // eslint-disable-next-line max-len
                                    const dataPictureMenu = addPictureMenu(formData, dataMenu.data.menu.id);
                                    if (dataPictureMenu.status === 200) {
                                      toast.success(t('App.InfoLocal.Informacion.AñadirMenu.form.toast.addPictureMenu'));
                                    }
                                  }
                                  resetForm({
                                    name: '',
                                    category: 0,
                                    description: '',
                                    price: '',
                                    file: null,
                                  });
                                  setRespuestaAPI(productsDefault);
                                  setListProductMenu([]);
                                }
                              } catch (error) {
                                if (error.response.status !== 401) {
                                  toast.error(t('newToast.createMenu'));
                                }
                                checkNoAuthError(error, history, toast, t);
                              }
                            };
                            consultaAPI();
                            resetForm();
                            setSubmitting(false);
                          }, 250);
                        }}
                      >
                        {(props) => {
                          const {
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            /* y otras más */
                          } = props;
                          return (
                            <form onSubmit={handleSubmit}>
                              <Grid container spacing={3}>
                                <Grid item lg={12} xs={12}>
                                  <FormControl>
                                    <FormLabel id="languageObject">Idioma</FormLabel>
                                    <RadioGroup
                                      row
                                      aria-labelledby="languageObject"
                                      name="language"
                                      value={language}
                                      onChange={changeLanguage}
                                    >
                                      <FormControlLabel
                                        value="es"
                                        control={<Radio />}
                                        label={(
                                          <img
                                            src={es}
                                            style={{ height: 24, width: 24 }}
                                            alt="flagEs"
                                          />
                                        )}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                <Grid item lg={12}>
                                  <FormGroup>
                                    {/* <Form.Label>Nombre</Form.Label> */}
                                    <TextField
                                      label={t('App.InfoLocal.Informacion.AñadirMenu.form.name')}
                                      name="name"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.name}
                                      error={touched.name && Boolean(errors.name)}
                                      helperText={touched.name && errors.name}
                                    />
                                  </FormGroup>
                                </Grid>

                                {/* <Grid item lg={12} xs={12}>
                          <FormGroup>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                              // Selecciona la Categoría</InputLabel>
                              <Field
                                name="category"
                                component={CustomizedSelectForFormik}
                                label="Selecciona la Categoría"
                              >
                                <MenuItem value={0}>
                                  Sin Categoría
                                </MenuItem>
                                {respuestaAPICategory.length !== 0
                                  && respuestaAPICategory.map((category) => (
                                    <MenuItem
                                      value={category.id}
                                      key={category.id}
                                    >
                                      {category.name}
                                    </MenuItem>
                                  ))}
                              </Field>
                            </FormControl>
                          </FormGroup>
                        </Grid> */}

                                <Grid item lg={12}>
                                  <FormGroup>
                                    {/* <Form.Label>Descripción</Form.Label> */}
                                    <TextField
                                      label={t('App.InfoLocal.Informacion.AñadirMenu.form.description')}
                                      name="description"
                                      multiline
                                      rows={4}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.description}
                                      error={touched.description && Boolean(errors.description)}
                                      helperText={touched.description && errors.description}
                                    />
                                  </FormGroup>
                                </Grid>

                                <Grid item lg={12}>
                                  <FormGroup>
                                    {/* <Form.Label>Precio</Form.Label> */}
                                    <TextField
                                      label={t('App.InfoLocal.Informacion.AñadirMenu.form.price')}
                                      name="price"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.price}
                                      error={touched.price && Boolean(errors.price)}
                                      helperText={touched.price && errors.price}
                                    />
                                  </FormGroup>
                                </Grid>

                                <Grid item lg={6}>
                                  <Card>
                                    <CardContent>
                                      <h2 style={{ textAlign: 'center' }}>{t('App.InfoLocal.Informacion.AñadirMenu.form.products')}</h2>
                                    </CardContent>
                                    <CardContent>

                                      <div>
                                        <input
                                          className="form-control inputBuscar"
                                          value={busqueda}
                                          placeholder="Búsqueda del producto"
                                          onChange={handleChangeBusqueda}
                                        />
                                      </div>

                                      <ReactSortable
                                        list={respuestaAPI}
                                        setList={setRespuestaAPI}
                                        animation={150}
                                        group={{ name: 'cloning-group-name' }}
                                        style={{ maxHeight: '500px', overflowY: 'scroll' }}
                                      // onChange={() => handleChangeSortable(busqueda)}
                                      // onMove={(evt, originalEvent) => {
                                      //   handleChangeSortable(busqueda);
                                      // }}
                                      // onSort={() => handleChangeSortable(busqueda)}
                                      >
                                        {respuestaAPI.length !== 0
                                          && respuestaAPI.map((item, index) => (
                                            <div key={item.id} style={{ display: respuestaAPIDisplay[index] ? 'block' : 'none' }}>
                                              <div className="cardProductPC  m-3 cursor-draggable">
                                                <div className="card-body">
                                                  {item.name[language]}
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                      </ReactSortable>
                                    </CardContent>
                                  </Card>
                                </Grid>

                                <Grid item lg={6}>
                                  <Card>
                                    <CardContent>
                                      <h2 style={{ textAlign: 'center' }}>{t('App.InfoLocal.Informacion.AñadirMenu.form.menuProducts')}</h2>
                                    </CardContent>
                                    <CardContent>
                                      <ReactSortable
                                        list={listProductMenu}
                                        setList={setListProductMenu}
                                        animation={150}
                                        group={{ name: 'cloning-group-name' }}
                                      >

                                        {listProductMenu.length !== 0
                                          && listProductMenu.map((item) => (
                                            <div key={item.id}>
                                              <div className="cardProductPC  m-3 cursor-draggable">
                                                <div className="card-body">
                                                  {item.name[language]}
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                      </ReactSortable>
                                    </CardContent>
                                  </Card>
                                  {/* {menuCategoryStateList.map((menuCategory, i) => (
                                <MenuCategory
                                  menuCategoryName={menuCategory.name}
                                  updateMenuCategory={useCallback(updateMenuCategory, [])}
                                  index={i}
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={i}
                                />
                              ))} */}
                                  {/* <br /> */}
                                  {/* <Row>
                                <Col lg={8}>
                                  <FormGroup>
                                    <Form.Label>Nombre sección menú</Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="atributeGroupName"
                                      onChange={(e) => { setNameCategoryMenu(e.target.value); }}
                                      value={nameCategoryMenu}
                                      ref={formRef}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={4} style={{ marginTop: '32px' }}>
                                  <Button
                                    variant="outline-secondary"
                                    onClick={() => {
                                      if (formRef.current.reportValidity()) {
                                        createMenuCategory({
                                          name: nameCategoryMenu,
                                          productList: [],
                                        }, 0);
                                        setNameCategoryMenu([]);
                                      }
                                    }}
                                  >
                                    <em>Añadir Sección</em>
                                  </Button>
                                </Col>
                              </Row> */}
                                </Grid>

                                {/* <Row className='mb-3'>
                        <Col lg={3}>
                          <FormGroup>
                            <Form.Label>Fecha de caducida</Form.Label>
                            <Form.Control
                              type='date'
                              name='date'
                              onChange={handleChange}
                              value={values.date}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={2}>
                          <FormGroup>
                            <Form.Label>Hora de caducida</Form.Label>
                            <Form.Control
                              type='time'
                              name='hour'
                              onChange={handleChange}
                              value={values.hour}
                            />
                          </FormGroup>
                        </Col>
                      </Row> */}

                                <Grid item lg={12}>
                                  <FormGroup className="position-relative mb-3">
                                    <FormLabel>{t('App.InfoLocal.Informacion.AñadirMenu.form.pictureTitle')}</FormLabel>
                                    <input
                                      id="file"
                                      type="file"
                                      name="file"
                                      label={t('App.InfoLocal.Informacion.AñadirMenu.form.pictureInformation')}
                                      onChange={async (event) => {
                                        const image = await resizeFile(event.target.files[0]);
                                        setFieldValue('file', image);
                                      }}
                                      className="form-control"
                                    />
                                  </FormGroup>
                                </Grid>

                                <Grid item lg={2}>
                                  <FormGroup>
                                    <Button variant="contained" type="submit" disabled={isSubmitting}>
                                      {isSubmitting ? 'Loading' : t('App.InfoLocal.Informacion.AñadirMenu.form.buttonForm')}
                                    </Button>
                                  </FormGroup>
                                </Grid>
                              </Grid>
                            </form>
                          );
                        }}
                      </Formik>
                    </CardContent>
                  </Card>
                )
                : (
                  <Card>
                    {/* <Card.Header bsPrefix="card-header-custom">
              {' '}
              <h2 style={{ textAlign: 'center' }}>Añadir Datos del Menú</h2>
            </Card.Header> */}
                    <CardContent>
                      <h2 style={{ textAlign: 'center' }}>{t('App.InfoLocal.Informacion.AñadirMenu.title')}</h2>
                    </CardContent>
                    <CardContent>
                      <Formik
                        initialValues={{
                          name: '',
                          category: 0,
                          description: '',
                          price: '',
                          file: null,
                        }}
                        validationSchema={Yup.object().shape({
                          name: Yup.string().required(t('App.InfoLocal.Informacion.AñadirMenu.form.yup.required.name')),
                          // description: Yup.string().required(t('App.InfoLocal.Informacion.
                          // AñadirMenu.form.yup.required.description')),
                          price: Yup.string().matches(/^[0-9]+([,.][0-9]+)?$/, 'El valor debe ser un número').required(t('App.InfoLocal.Informacion.AñadirMenu.form.yup.required.price')),
                        })}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                          setTimeout(() => {
                            const menu = {
                              name: { es: values.name },
                              description: { es: values.description },
                            };
                            const productsId = [];
                            respuestaAPI.map((product) => (
                              product.check && productsId.push(product.id)
                            ));
                            const priceMenu = values.price.replace(',', '.');
                            // const finishDate = new Date(values.date + ':' + values.hour);
                            // const menusectionList = [];
                            // menuCategoryStateList.forEach((menuSection) => {
                            //   const nameSection = menuSection.name;
                            //   const productsId = [];
                            //   menuSection.products.forEach((menuSectionProduct) => {
                            //     productsId.push(menuSectionProduct.id);
                            //   });
                            //   menusectionList.push({ name: nameSection, productsId });
                            // });
                            const data = {
                              menu: {
                                name: menu.name,
                                description: menu.description,
                                price: Number(priceMenu),
                                shopId: parseInt(shopIdActual, 10),
                                // finish_date: finishDate.toUTCString()
                                products: Array.from(productsId),
                              },
                              // menusection: menuCategoryStateList,
                            };
                            const consultaAPI = async () => {
                              try {
                                const dataMenu = await addMenu(data);
                                if (dataMenu.status === 200) {
                                  toast.success(t('App.InfoLocal.Informacion.AñadirMenu.form.toast.addDataMenu'));
                                  if (values.file !== null) {
                                    const formData = new FormData();
                                    formData.append('menu_id', dataMenu.data.menu.id);
                                    formData.append('picture', values.file);
                                    // eslint-disable-next-line max-len
                                    const dataPictureMenu = addPictureMenu(formData, dataMenu.data.menu.id);
                                    if (dataPictureMenu.status === 200) {
                                      toast.success(t('App.InfoLocal.Informacion.AñadirMenu.form.toast.addPictureMenu'));
                                    }
                                  }
                                  resetForm({
                                    name: '',
                                    category: 0,
                                    description: '',
                                    price: '',
                                    file: null,
                                  });
                                  setRespuestaAPI(productsDefault);
                                  setListProductMenu([]);
                                }
                              } catch (error) {
                                if (error.response.status !== 401) {
                                  toast.error(t('newToast.createMenu'));
                                }
                                checkNoAuthError(error, history, toast, t);
                              }
                            };
                            consultaAPI();
                            setSubmitting(false);
                          }, 250);
                        }}
                      >
                        {(props) => {
                          const {
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            /* y otras más */
                          } = props;
                          return (
                            <form onSubmit={handleSubmit}>
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <FormGroup>
                                    {/* <Form.Label>Nombre</Form.Label> */}
                                    <TextField
                                      label="Nombre"
                                      name="name"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.name}
                                      error={touched.name && Boolean(errors.name)}
                                      helperText={touched.name && errors.name}
                                    />
                                  </FormGroup>
                                </Grid>

                                {/* <Grid item lg={12} xs={12}>
                          <FormGroup>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                              // Selecciona la Categoría</InputLabel>
                              <Field
                                name="category"
                                component={CustomizedSelectForFormik}
                                label="Selecciona la Categoría"
                              >
                                <MenuItem value={0}>
                                  Sin Categoría
                                </MenuItem>
                                {respuestaAPICategory.length !== 0
                                  && respuestaAPICategory.map((category) => (
                                    <MenuItem
                                      value={category.id}
                                      key={category.id}
                                    >
                                      {category.name}
                                    </MenuItem>
                                  ))}
                              </Field>
                            </FormControl>
                          </FormGroup>
                        </Grid> */}

                                <Grid item xs={12}>
                                  <FormGroup>
                                    {/* <Form.Label>Descripción</Form.Label> */}
                                    <TextField
                                      label="Descripción"
                                      name="description"
                                      multiline
                                      rows={4}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.description}
                                      error={touched.description && Boolean(errors.description)}
                                      helperText={touched.description && errors.description}
                                    />
                                  </FormGroup>
                                </Grid>

                                <Grid item xs={12}>
                                  <FormGroup>
                                    {/* <Form.Label>Precio</Form.Label> */}
                                    <TextField
                                      label="Precio"
                                      name="price"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.price}
                                      error={touched.price && Boolean(errors.price)}
                                      helperText={touched.price && errors.price}
                                    />
                                  </FormGroup>
                                </Grid>

                                <Grid item xs={12}>
                                  <Card>
                                    <CardContent>
                                      <h2 style={{ textAlign: 'center' }}>Productos del Menú</h2>
                                    </CardContent>
                                    <CardContent>

                                      <div style={{ marginBottom: '8px' }}>
                                        <input
                                          className="form-control inputBuscar"
                                          value={busqueda}
                                          placeholder="Búsqueda del producto"
                                          onChange={handleChangeBusqueda}
                                        />
                                      </div>
                                      {respuestaAPI.length !== 0
                                        && respuestaAPI.map((product, a) => (
                                          <div key={product.id} style={{ display: respuestaAPIDisplay[a] ? 'block' : 'none' }}>
                                            <Button
                                              style={{ width: '100%' }}
                                              variant="outline-secondary"
                                              data-rowid={a}
                                              onClick={(e) => {
                                                selectProductMenu(respuestaAPI[e.currentTarget.getAttribute('data-rowid')]);
                                              }}
                                            >
                                              <div className="cardProductMobile">
                                                <div style={product.check ? { backgroundColor: '#a5d6a7' } : { backgroundColor: 'white' }}>
                                                  {product.name[language]}
                                                </div>
                                              </div>
                                            </Button>
                                          </div>
                                        ))}
                                    </CardContent>
                                  </Card>
                                </Grid>

                                {/* <Row className='mb-3'>
                        <Col lg={3}>
                          <FormGroup>
                            <Form.Label>Fecha de caducida</Form.Label>
                            <Form.Control
                              type='date'
                              name='date'
                              onChange={handleChange}
                              value={values.date}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={2}>
                          <FormGroup>
                            <Form.Label>Hora de caducida</Form.Label>
                            <Form.Control
                              type='time'
                              name='hour'
                              onChange={handleChange}
                              value={values.hour}
                            />
                          </FormGroup>
                        </Col>
                      </Row> */}

                                <Grid item xs={12}>
                                  <FormGroup className="position-relative mb-3">
                                    <FormLabel>Foto del Producto</FormLabel>
                                    <input
                                      id="file"
                                      type="file"
                                      name="file"
                                      label="Añadir Foto del Producto"
                                      onChange={async (event) => {
                                        const image = await resizeFile(event.target.files[0]);
                                        setFieldValue('file', image);
                                      }}
                                      className="form-control"
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item lg={3} xs={3}>
                                  <div> </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormGroup>
                                    <Button variant="contained" type="submit" disabled={isSubmitting}>
                                      {isSubmitting ? 'Loading' : 'Añadir'}
                                    </Button>
                                  </FormGroup>
                                </Grid>
                              </Grid>
                            </form>
                          );
                        }}
                      </Formik>
                    </CardContent>
                  </Card>
                )
            )
        }
      </Container>
    </Container>
  );
}

export default InfoMenus;
