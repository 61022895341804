import {
  Button,
  Card, CardActions, CardContent, CardHeader, ListItem,
  ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import React from 'react';
// import { Card  Container, Row, Col  } from 'react-bootstrap';
import { /* addPictureEmployee, */ } from '../mesonero-api/api';
import './pay.css';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

export default function ProductDisplay({ dataRegister }) {
  const { t } = useTranslation();

  return (
    <>
      {/* <Card> */}
      {console.log(dataRegister)}
      <Card>
        <CardHeader title="Plan Básico" style={{ padding: 0, paddingLeft: '16px', paddingTop: '16px' }} />
        <CardContent style={{ padding: 0, paddingLeft: '16px' }}>
          <Typography variant="body">
            {t('paginaentrada.infotarifa.firstPricing.titlePricing')}
          </Typography>
        </CardContent>
        <hr />
        <CardContent>
          <ListItem>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText primary={t('paginaentrada.infotarifa.firstPricing.firstPoint')} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText primary={t('paginaentrada.infotarifa.firstPricing.secondPoint')} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText primary={t('paginaentrada.infotarifa.firstPricing.thirdPoint')} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText primary={t('paginaentrada.infotarifa.firstPricing.fourthPoint')} />
          </ListItem>
        </CardContent>
        <CardActions sx={{ justifyContent: 'center', padding: '16px', marginBottom: '5%' }}>
          <form action="https://stripe.tumesonero.es/stripe/checkout-session" method="POST">
            {/* Add a hidden field with the lookup_key of your Price */}
            <input type="hidden" name="prod_M5OQ3bNfJzIel4" value="price_1LwTKrDNKQOyEDUSmF3TgMwH" />
            {/* <input type="hidden" name="customer_email" value={dataRegister.user.email}
                  /> */}
            <Button variant="contained" id="checkout-and-portal-button" type="submit">
              {t('services.paySubscription.tarifa1.button')}
            </Button>
          </form>
        </CardActions>
      </Card>
      {/* <Card.Header bsPrefix="card-header-pay"><h2>Tarifa 1</h2></Card.Header>
        <div className="product">
          <Logo />
          <div className="description">
            <h3>Plan Básico</h3>
            <h5>8€ / mes</h5>
          </div>
        </div>
        <form action="https://api.tumesonero.es/stripe/checkout-session"
        method="POST"> */}
      {/* Add a hidden field with the lookup_key of your Price */}
      {/* <input type="hidden" name="prod_M5OQ3bNfJzIel4"
           value="price_1LwTKrDNKQOyEDUSmF3TgMwH" /> */}
      {/* <input type="hidden" name="customer_email" value={dataRegister.user.email} /> */}
      {/* <button className="buttonPay" id="checkout-and-portal-button" type="submit">
            Pagar
          </button>
        </form>
      </Card> */}
    </>
  );
}
