import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

export default function CookiesAlert() {
  const { t } = useTranslation();

  return (
    <CookieConsent
      location="bottom"
      buttonText={t('services.cookies.buttonText')}
      style={{ background: '#000' }}
      buttonStyle={{ background: '#fff' }}
      expires={365}
    >
      {t('services.cookies.infoCookies')}
    </CookieConsent>
  );
}
